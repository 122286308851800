import http from "../../services/general/httpService";
import config from "../../constants/config";

//need to be able to add to a particular property unit and existing task group but if it doesnt exist create a new one and assign it
//add_tasks_to_task_group
export async function addTasksToTaskGroup(
  user_id,
  created_by,
  property_unit_id,
  tasks
) {
  const fullApiEndpoint =
    config.apiEndpoint + `/user_task/add_tasks_to_task_group`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
    created_by: created_by,
    property_unit_id: property_unit_id,
    tasks: tasks,
  });

  return result;
}

///user_task/create_user_tasks

export async function createUserTasks(
  property_unit_id,
  tasks,
  users_to_assign
) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/create_user_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
    tasks: tasks,
    users_to_assign: users_to_assign,
  });

  return result;
}

///user_task/bulk_create_tasks
export async function bulkCreateTasks(
  property_unit_ids,
  tasks,
  users_to_assign
) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/bulk_create_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_ids: property_unit_ids,
    tasks: tasks,
    users_to_assign: users_to_assign,
  });

  return result;
}

///user_task/assign_users_to_task_group
export async function assignUsersToTaskGroup(task_group_id, users) {
  const fullApiEndpoint =
    config.apiEndpoint + `/user_task/assign_users_to_task_group`;
  const { data: result } = await http.post(fullApiEndpoint, {
    task_group_id: task_group_id,
    users: users,
  });

  return result;
}

///user_task/get_user_tasks
export async function getUserTasks(user_id) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/get_user_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

export async function getUserTasksByLoggedInUser() {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint = config.apiEndpoint + `/user_task/get_user_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

///user_task/get_property_unit_tasks
export async function getPropertyUnitTasks(property_unit_id) {
  const fullApiEndpoint =
    config.apiEndpoint + `/user_task/get_property_unit_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}

///user_task/update_task_group
export async function updateTaskGroup(
  task_group_id,
  property_unit_id,
  tasks,
  users_to_assign
) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/update_task_group`;
  const { data: result } = await http.post(fullApiEndpoint, {
    task_group_id: task_group_id,
    property_unit_id: property_unit_id,
    tasks: tasks,
    users_to_assign: users_to_assign,
  });

  return result;
}

///user_task/delete_task_group
export async function deleteTaskGroup(task_group_id) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/delete_task_group`;
  const { data: result } = await http.post(fullApiEndpoint, {
    task_group_id: task_group_id,
  });

  return result;
}

///user_task/complete_task
export async function completeTask(task_id, is_done) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/complete_task`;
  console.log("task_id", task_id);
  console.log("is_done", is_done);
  const { data: result } = await http.post(fullApiEndpoint, {
    task_id: task_id,
    is_done: is_done,
  });

  return result;
}

///user_task/complete_task_group
export async function completeTaskGroup(task_group_id) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/complete_task_group`;
  const { data: result } = await http.post(fullApiEndpoint, {
    task_group_id: task_group_id,
  });

  return result;
}

///user_task/delete_task
export async function deleteTask(task_id) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/delete_task`;
  const { data: result } = await http.post(fullApiEndpoint, {
    task_id: task_id,
  });

  return result;
}

///user_task/delete_user_task
export async function deleteUserTask(user_task_id) {
  const fullApiEndpoint = config.apiEndpoint + `/user_task/delete_user_task`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_task_id: user_task_id,
  });

  return result;
}

//get_user_deleted_tasks
export async function getUserDeletedTasks(user_id) {
  const fullApiEndpoint =
    config.apiEndpoint + `/user_task/get_user_deleted_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

//get_deleted_property_unit_tasks
export async function getDeletedPropertyUnitTasks(property_unit_id) {
  const fullApiEndpoint =
    config.apiEndpoint + `/user_task/get_deleted_property_unit_tasks`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}
