import React from "react";
import { Link } from "react-router-dom";

interface ActionButtonsProps {
  propertyUnitId: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  propertyUnitId,
}) => {
  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="d-flex justify-content-end gap-2">
          <Link
            to={`/propertyUnitBankAccounts/${propertyUnitId}`}
            className="btn btn-info"
          >
            <i className="ri-bank-line align-bottom me-1"></i> Bank Accounts
          </Link>
          <Link
            to={`/newUnitTransaction/${propertyUnitId}`}
            className="btn btn-success border border-light"
          >
            <i className="ri-add-line align-bottom me-1"></i> Add Transaction
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
