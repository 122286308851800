import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { getReportById } from "../../../services/reports/reportService";
import { PropertyFinancialReportModel } from "types/reports";
import { toast } from "react-toastify";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import { devLog } from "services/utils/logger";
import FilePreview from "../tickets/detail/sub/filePreview";
import FilePDFImagePreview from "../tickets/detail/sub/filePdfImagePreview";
import { Modal, Button } from "react-bootstrap";
import { formatReportType } from "services/utils/formatters";
import { usePageTitle } from "services/utils/hooks/usePageTitle";

interface ReportDetailProps {
  isDarkMode: boolean;
}

const ReportDetail: React.FC<ReportDetailProps> = ({ isDarkMode }) => {
  const { reportId } = useParams<{ reportId: string }>();

  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<PropertyFinancialReportModel | null>(
    null
  );
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [itemToPreview, setItemToPreview] = useState<any>(null);
  usePageTitle({
    title: `${formatReportType(report?.report_type || "")} Detail`,
  });

  useEffect(() => {
    if (reportId) {
      loadReport();
    }
  }, [reportId]);

  const loadReport = async () => {
    if (!reportId) {
      toast.error("Report ID is required");
      return;
    }

    setIsLoading(true);
    try {
      const data = await getReportById(reportId);
      devLog(data);
      setReport(data);
    } catch (error) {
      console.error("Error loading report:", error);
      toast.error("Failed to load report");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
    setItemToPreview(null);
  };

  if (isLoading) {
    return (
      <div className={`${getThemeClasses()} tw-min-h-screen`}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <ShimmerEffect
              type="list"
              height={200}
              width={800}
              className="mb-2"
              darkMode={isDarkMode}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!report) {
    return (
      <div className={`${getThemeClasses()} tw-min-h-screen`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Report Not Found"
            previousPageLink="/reports"
            previousPageTitle="Reports Dashboard"
          />
          <div className="alert alert-warning">
            Report not found or has been deleted.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${getThemeClasses()} tw-min-h-screen`}>
      <Modal
        show={showPreviewModal}
        onHide={handleClosePreviewModal}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          {itemToPreview && (
            <FilePDFImagePreview
              itemToPreview={itemToPreview}
              setItemToDelete={() => {}}
              setShowConfirm={() => {}}
              showDeleteBtn={false}
              onClose={handleClosePreviewModal}
            />
          )}
        </Modal.Body>
      </Modal>

      <div className="container-fluid">
        <PageTitleBox
          pageTitle={`${formatReportType(report?.report_type || "")} Detail`}
          previousPageLink="/reports"
          previousPageTitle="Reports Dashboard"
        />

        <div className="row">
          <div className="col-12">
            <div
              className={`card ${
                isDarkMode ? "tw-bg-gray-800 tw-border-gray-700" : "tw-bg-white"
              }`}
            >
              <div className="card-body">
                <div className="row">
                  {/* Left Column - Report Details */}
                  <div className="col-md-4">
                    <h6
                      className={`${
                        isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
                      } mb-3`}
                    >
                      Report Details
                    </h6>
                    <div
                      className={`${
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                      }`}
                    >
                      <div className="mb-3">
                        <div className="text-muted">Date</div>
                        <div>
                          {new Date(
                            report?.report_date || ""
                          ).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="text-muted">Status</div>
                        <div>
                          <span
                            className={`badge ${
                              report?.status === "completed"
                                ? "bg-success"
                                : "bg-warning"
                            }`}
                          >
                            {report?.status}
                          </span>
                        </div>
                      </div>
                      {report?.pdf_document && (
                        <div className="mt-4">
                          <div className="d-flex align-items-center border border-dashed p-2 rounded">
                            <div className="flex-shrink-0">
                              <i className="ri-file-pdf-line fs-24 text-danger"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">
                                {report.pdf_document.file_name}
                              </h6>
                              <small className="text-muted">
                                {(report.pdf_document.file_size / 1024).toFixed(
                                  2
                                )}{" "}
                                KB
                              </small>
                            </div>
                            <div className="hstack gap-3 fs-16">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setItemToPreview({
                                    url: report.pdf_document.url,
                                    file_name: report.pdf_document.file_name,
                                    file_type: "application/pdf",
                                    file_size: report.pdf_document.file_size,
                                  });
                                  setShowPreviewModal(true);
                                }}
                                className="text-muted"
                                title="View"
                              >
                                <i className="ri-eye-line"></i>
                              </a>
                              <a
                                href={report.pdf_document.url}
                                download={report.pdf_document.file_name}
                                className="text-muted"
                                title="Download"
                                target="_blank"
                              >
                                <i className="ri-download-2-line"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Right Column - Monthly Metrics */}
                  {report.monthly_noi_metrics && (
                    <div className="col-md-8">
                      <h6
                        className={`${
                          isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
                        } mb-3`}
                      >
                        Monthly Metrics
                      </h6>
                      <div
                        className={`${
                          isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                        }`}
                      >
                        <div className="mb-3">
                          <div className="text-muted">Total Revenue</div>
                          <div>
                            $
                            {report.monthly_noi_metrics.total_revenue.toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="text-muted">Total Expenses</div>
                          <div>
                            $
                            {report.monthly_noi_metrics.total_expenses.toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="text-muted">Net Operating Income</div>
                          <div>
                            $
                            {report.monthly_noi_metrics.net_operating_income.toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="text-muted">Occupancy Rate</div>
                          <div>
                            {(
                              report.monthly_noi_metrics.occupancy_rate * 100
                            ).toFixed(1)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail;
