import http from "../../general/httpService";
import api from "../../../constants/config";

///country/countries
export async function fetchAllCountries() {
  const fullApiEndpoint = api.apiEndpoint + `/country/countries`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//create_new_user_defined_country
export async function createNewUserDefinedCountry(country) {
  const fullApiEndpoint =
    api.apiEndpoint + `/country/create_new_user_defined_country`;
  const { data: result } = await http.post(fullApiEndpoint, country);

  return result;
}
