import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderView from "../../../../reusable/loading/loaderView";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import { Badge, Tabs, Tab } from "react-bootstrap";
import newDocumentImg from "../../../../../styletheme/images/new-document.png";

import {
  GridColDef,
  GridRenderCellParams,
  gridDateComparator,
  GridValueOptionsParams,
} from "@mui/x-data-grid";
import { formatCurrency } from "../../../../../services/utils/currencyUtils";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../services/utils/hooks/usePageTitle";
import type {
  RevenueCategory as RevenueCategoryType,
  ExpenseCategory as ExpenseCategoryType,
  Transaction,
  RevenueCategory,
  ExpenseCategory,
  PlaidTransaction,
  ExtendedPlaidTransaction,
} from "./types/transactionTypes";
import { useTransactions } from "./hooks/useTransactions";
import { getFilteredTransactions } from "./utils/transactionUtils";
import CategorizedTransactionsTable from "./components/CategorizedTransactionsTable";
import UncategorizedTransactionsTable from "./components/UncategorizedTransactionsTable";
import StatusDashboard from "./components/StatusDashboard";
import ActionButtons from "./components/ActionButtons";
import ReAuthenticationAlerts from "./components/ReAuthenticationAlerts";

//TODO: need to give the ability to set the category of the transaction and import them into the database after i do the basic tickets
//TODO: next then work on the guide book, canceling lease etc
//When the tenant successfully pays rent, we should add this as a revenue transaction and link it to the stripe transaction id, so we can track the payment
//it is stored in the payment_details and payment collection we should store both in the revenue transaction as a sub collection, this transaction auto created on the
//tenant api when the payment is successful and shows up in the landlord portal and need the stripe payment id to be able to link it to the revenue transaction

//TODO: need to handle fetching the transactions for the sync on the background thread instead of the main thread

//TODO: need to test this further to ensure it is working as expected
//TODO: next work on the tenant create ticket and see guidebooks and canceling lease
//TODO: need to ensure when I press to cateogirize the transaction is stored in the database and the categorized list is updated
//TODO: need to update the way how we update bank accounts and should add the ability to edit and remove bank accounts (remove if not in use)
// Plaid Models

//TODO: need to fix the noticiation to use a different approach process the notication on the server then cache the results so it doesnt take much time to update it, the notication is invalidated when something changes on the aoi for the user
//TODO: then the edit of guidebooks and canceling lease

//TODO: need to create a custom table to do the transactions bulk categorization etc: http://localhost:30020/unitTransactions/fc5cb9e1-c312-4ae3-8f98-0438a139e1cc

const createTransactionColumns = (
  revenueCategories: RevenueCategory[],
  expenseCategories: ExpenseCategory[],
  findCategoryName: (categoryId: string, isRevenue: string) => string
): GridColDef<Transaction>[] => [
  {
    field: "date",
    headerName: "Date",
    width: 150,
    type: "date",
    editable: false,
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {params.row.date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </div>
      </div>
    ),
    sortComparator: gridDateComparator,
  },
  {
    field: "merchant_name",
    headerName: "Merchant",
    width: 200,
    editable: false,
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {params.row.merchant_name || params.row.title}
        </div>
      </div>
    ),
  },
  {
    field: "logo_url",
    headerName: "",
    width: 90,
    editable: false,
    renderCell: (params: GridRenderCellParams<Transaction>) => {
      if (params.row.logo_url) {
        return (
          <img
            src={params.row.logo_url}
            style={{ width: "80%", height: "80%" }}
            alt="bank logo"
          />
        );
      }

      if ((params.row.transaction_receipt_image_uploads ?? []).length > 0) {
        const firstUpload = params.row.transaction_receipt_image_uploads?.[0];
        if (!firstUpload) return null;

        if (firstUpload.file_type === "application/pdf") {
          return (
            <i
              className="ri-file-pdf-line fs-24"
              style={{ color: "#ff4d4d" }}
            ></i>
          );
        }

        if (firstUpload.file_type.startsWith("image/")) {
          return (
            <img
              src={firstUpload.url}
              style={{ width: "80%", height: "80%" }}
              alt="receipt"
            />
          );
        }
      }

      return (
        <img
          src={newDocumentImg}
          style={{ width: "80%", height: "80%" }}
          alt="document"
        />
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    width: 250,
    editable: true,
    type: "string",
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">{params.row.title}</div>
      </div>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    width: 350,
    editable: true,
    type: "string",
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">{params.row.description}</div>
      </div>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 110,
    editable: true,
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {formatCurrency(params.row.amount)}
        </div>
      </div>
    ),
  },
  {
    field: "category",
    headerName: "Category",
    description: "Category of the transaction",
    width: 300,
    editable: true,
    type: "singleSelect",
    valueOptions: (params: GridValueOptionsParams<Transaction>) => {
      const row = params.row;
      if (!row) return [];

      if (row.is_revenue_or_expense === "revenue") {
        return revenueCategories.map((category: RevenueCategory) => ({
          value: category.revenue_category_id,
          label: category.revenue_category,
        }));
      } else {
        return expenseCategories.map((category: ExpenseCategory) => ({
          value: category.expense_category_id,
          label: category.expense_category,
        }));
      }
    },
    renderCell: (params: GridRenderCellParams<Transaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {findCategoryName(
            params.row.category_id,
            params.row.is_revenue_or_expense
          )}
        </div>
      </div>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    width: 110,
    editable: false,
    renderCell: (params: GridRenderCellParams<Transaction>) => {
      return (
        <span className="badge bg-primary-subtle text-primary">
          {params.row.is_revenue_or_expense}
        </span>
      );
    },
  },
];

// Add loading components

// Error handling utility

// Update the uncategorized columns definition

const UnitPropertyTransactions: React.FC<{ isDarkMode: boolean }> = ({
  isDarkMode,
}): JSX.Element => {
  const params = useParams<{ propertyUnitId: string }>();
  const propertyUnitId = params.propertyUnitId as string;
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Unit Transactions";
  usePageTitle({ title: pageTitle });

  // Use the custom hook for transactions
  const {
    transactions,
    uncategorizedTransactions,
    transactionStatus,
    isLoading,
    isUpdating,
    fetchTransactions,
    handleReauthenticate,
    sysRevenueCategories,
    sysExpenseCategories,
  } = useTransactions(propertyUnitId);

  const [activeTab, setActiveTab] = useState<"categorized" | "uncategorized">(
    "categorized"
  );

  // Add cleanup for async operations
  useEffect(() => {
    let isSubscribed = true;

    const safeUpdate = async () => {
      try {
        if (!isSubscribed) return;
        await fetchTransactions();
      } catch (error) {
        if (isSubscribed) {
          console.error("Error fetching transactions:", error);
          toast.error("Failed to fetch transactions");
        }
      }
    };

    safeUpdate();

    return () => {
      isSubscribed = false;
    };
  }, [fetchTransactions, propertyUnitId]);

  // Category name lookup function
  const findCategoryName = useCallback(
    (categoryId: string, isRevenue: string): string => {
      if (isRevenue === "revenue") {
        const category = sysRevenueCategories.find(
          (cat: RevenueCategoryType) => cat.revenue_category_id === categoryId
        );
        return category ? category.revenue_category : "";
      } else {
        const category = sysExpenseCategories.find(
          (cat: ExpenseCategoryType) => cat.expense_category_id === categoryId
        );
        return category ? category.expense_category : "";
      }
    },
    [sysRevenueCategories, sysExpenseCategories]
  );

  // Handle tab selection
  const handleTabSelect = useCallback((key: string | null) => {
    if (key === "categorized" || key === "uncategorized") {
      setActiveTab(key);
    }
  }, []);

  // Create theme based on dark mode
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
        },
      }),
    [isDarkMode]
  );

  // Create columns with dependencies
  const transactionCols = useMemo(
    () =>
      createTransactionColumns(
        sysRevenueCategories,
        sysExpenseCategories,
        findCategoryName
      ),
    [sysRevenueCategories, sysExpenseCategories, findCategoryName]
  );

  const handleUncategorizedTransactionUpdate = async (
    newRow: ExtendedPlaidTransaction,
    oldRow: ExtendedPlaidTransaction
  ): Promise<ExtendedPlaidTransaction> => {
    try {
      // TODO: Implement the logic to update the transaction
      console.log("Updating transaction:", { newRow, oldRow });
      return newRow;
    } catch (error) {
      console.error("Failed to update transaction:", error);
      throw new Error("Failed to update transaction");
    }
  };

  const handleTransactionsProcessed = useCallback(() => {
    // Refresh the transactions data
    fetchTransactions();
  }, [fetchTransactions]);

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid px-2 px-md-3">
        <div style={{ minHeight: "100vh" }}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "200px" }}
            >
              <LoaderView />
            </div>
          ) : (
            <>
              <PageTitleBox
                pageTitle={pageTitle}
                previousPageTitle="Property Unit"
                previousPageLink={`/propertyunit/${propertyUnitId}`}
              />

              <div className="row g-3">
                <div className="col-12">
                  <StatusDashboard
                    transactionStatus={transactionStatus}
                    categorizedCount={transactions.length}
                    uncategorizedCount={uncategorizedTransactions.length}
                  />
                </div>

                <div className="col-12">
                  <ActionButtons propertyUnitId={propertyUnitId} />
                </div>

                <div className="col-12">
                  <ReAuthenticationAlerts
                    transactionStatus={transactionStatus}
                    onReAuthenticate={handleReauthenticate}
                    isUpdating={isUpdating}
                  />
                </div>
              </div>

              <div className="card mt-4 mb-4">
                <div className="card-body p-2 p-md-3">
                  <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    className="mb-4"
                    transition={false}
                  >
                    <Tab
                      eventKey="categorized"
                      title="Categorized Transactions"
                      mountOnEnter={false}
                      unmountOnExit={false}
                    >
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          minHeight: "400px",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <CategorizedTransactionsTable
                            transactions={transactions}
                            columns={transactionCols}
                            loading={isLoading}
                            isDarkMode={isDarkMode}
                          />
                        </ThemeProvider>
                      </div>
                    </Tab>

                    <Tab
                      eventKey="uncategorized"
                      title={
                        <div className="d-flex align-items-center">
                          <span className="d-none d-sm-inline">
                            Uncategorized Transactions
                          </span>
                          <span className="d-inline d-sm-none">
                            Uncategorized
                          </span>
                          {uncategorizedTransactions.length > 0 && (
                            <Badge bg="warning" className="ms-2">
                              {uncategorizedTransactions.length}
                            </Badge>
                          )}
                        </div>
                      }
                      mountOnEnter={false}
                      unmountOnExit={false}
                    >
                      <div
                        style={{
                          height: "650px",
                          width: "100%",
                          minHeight: "400px",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <UncategorizedTransactionsTable
                            propertyUnitId={propertyUnitId}
                            transactions={
                              uncategorizedTransactions as ExtendedPlaidTransaction[]
                            }
                            onSelectionModelChange={() => {}}
                            loading={isLoading}
                            isDarkMode={isDarkMode}
                            onTransactionUpdate={
                              handleUncategorizedTransactionUpdate
                            }
                            revenueCategories={sysRevenueCategories}
                            expenseCategories={sysExpenseCategories}
                            onTransactionsProcessed={
                              handleTransactionsProcessed
                            }
                          />
                        </ThemeProvider>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnitPropertyTransactions;
