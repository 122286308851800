import { useState, useCallback } from "react";
import config from "../constants/config";
import {
  StripeConnectState,
  StripeConnectFormData,
  StripeAccountStatus,
} from "../types/stripe-connect";
import {
  createOrUpdateConnectAccount,
  getConnectAccountStatus,
  refreshOnboardingLink,
  getAccountRequirements,
  openOnboardingWindow,
  pollOnboardingCompletion,
} from "../services/stripe-connect";

const initialState: StripeConnectState = {
  status: "pending",
  isLoading: false,
};

export function useStripeConnect(accountId: string) {
  const [state, setState] = useState<StripeConnectState>(initialState);

  const updateState = useCallback((updates: Partial<StripeConnectState>) => {
    setState((prev) => ({ ...prev, ...updates }));
  }, []);

  const handleError = useCallback(
    (error: any) => {
      console.error("Stripe Connect Error:", error);
      if (error?.response?.status === 404 && !accountId) {
        updateState({
          status: "pending",
          isLoading: false,
          requirements: ["Complete Stripe onboarding to enable payments"],
        });
      } else {
        updateState({
          error: error.message || "An error occurred",
          isLoading: false,
        });
      }
    },
    [updateState, accountId]
  );

  const checkStatus = useCallback(async () => {
    if (!accountId) {
      updateState({
        status: "pending",
        isLoading: false,
        requirements: ["Complete Stripe onboarding to enable payments"],
      });
      return null;
    }

    try {
      updateState({ isLoading: true });
      const status = await getConnectAccountStatus(accountId);
      updateState({
        status: status.status,
        requirements: status.requirements,
        isLoading: false,
      });
      return status;
    } catch (error) {
      handleError(error);
      return null;
    }
  }, [accountId, updateState, handleError]);

  const startOnboarding = useCallback(
    async (formData: StripeConnectFormData) => {
      try {
        updateState({ isLoading: true, error: undefined });

        const response = await createOrUpdateConnectAccount(formData);

        if (response.stripe_account_id) {
          localStorage.setItem(
            config.stripe_connect_account_id,
            response.stripe_account_id
          );
          updateState({ accountId: response.stripe_account_id });
        }

        if (response.stripe_account_link) {
          const windowRef = openOnboardingWindow(response.stripe_account_link);
          if (windowRef) {
            await pollOnboardingCompletion(windowRef);
            await checkStatus();
          }
        }
      } catch (error) {
        handleError(error);
      }
    },
    [updateState, checkStatus, handleError]
  );

  const resumeOnboarding = useCallback(async () => {
    try {
      updateState({ isLoading: true, error: undefined });

      const response = await refreshOnboardingLink(accountId);
      if (response.onboarding_link) {
        const windowRef = openOnboardingWindow(response.onboarding_link);
        if (windowRef) {
          await pollOnboardingCompletion(windowRef);
          await checkStatus();
        }
      }
    } catch (error) {
      handleError(error);
    }
  }, [accountId, updateState, checkStatus, handleError]);

  const getRequirements = useCallback(async () => {
    try {
      updateState({ isLoading: true });
      const requirements = await getAccountRequirements(accountId);
      updateState({
        requirements: requirements.status.requirements,
        isLoading: false,
      });
      return requirements;
    } catch (error) {
      handleError(error);
      return null;
    }
  }, [accountId, updateState, handleError]);

  return {
    state,
    startOnboarding,
    resumeOnboarding,
    checkStatus,
    getRequirements,
  };
}
