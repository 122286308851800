import React from "react";
import { TransactionStatus } from "../types/transactionTypes";
import { toast } from "react-toastify";

interface ReAuthenticationAlertsProps {
  transactionStatus: TransactionStatus;
  onReAuthenticate: (item: any) => Promise<void>;
  isUpdating: boolean;
}

export const ReAuthenticationAlerts: React.FC<ReAuthenticationAlertsProps> = ({
  transactionStatus,
  onReAuthenticate,
  isUpdating,
}) => {
  const renderReAuthButton = (item: any) => (
    <button
      key={item.item_id}
      className="btn btn-warning me-2 mb-2"
      onClick={() => onReAuthenticate(item)}
      disabled={isUpdating}
    >
      {isUpdating ? (
        <span>
          <i className="mdi mdi-loading mdi-spin me-2"></i>
          Updating...
        </span>
      ) : (
        <>
          <i className="ri-refresh-line me-1"></i>
          Re-authenticate {item.institution_name}
        </>
      )}
    </button>
  );

  if (
    !(transactionStatus.items_requiring_attention?.items_requiring_update || [])
      .length
  ) {
    return null;
  }

  return (
    <div className="alert alert-warning mb-4">
      <h5 className="alert-heading">
        <i className="ri-error-warning-line me-2"></i>
        Accounts Requiring Re-authentication
      </h5>
      <div className="mt-3">
        {(
          transactionStatus.items_requiring_attention?.items_requiring_update ||
          []
        ).map((item) => renderReAuthButton(item))}
      </div>
    </div>
  );
};

export default ReAuthenticationAlerts;
