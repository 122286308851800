import React, { useState, useEffect, Fragment } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { baseImageUrl } from "../../../constants/images/defaultImgs";
import config from "../../../constants/config";
import logo from "../../../styletheme/logos/gurenter_logo.png";
import { getCurrentUser } from "../../../services/authentication/auth";
import { useSettings } from "../../../services/authentication/contexts/settings/settingsContext";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import NotificationHub from "../../notification/NotificationHub";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavigationBar = (props) => {
  const { settings, setSettings } = useSettings();
  const { userProfile } = props;
  const { isDarkMode } = props;
  const { getThemeClasses } = useThemeClasses(settings.isDarkMode);
  const location = useLocation();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    profile_img: "",
    email: "",
  });

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem(config.access_token);
      setIsAuthenticated(!!token);

      // Only update user data if authenticated
      if (token) {
        setUserData({
          first_name: localStorage.getItem(config.first_name) || "",
          last_name: localStorage.getItem(config.last_name) || "",
          profile_img: localStorage.getItem(config.profile_img) || "",
          email: localStorage.getItem(config.email) || "",
        });
      }
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);
    return () => window.removeEventListener("storage", checkAuth);
  }, []);

  // Update userData state with a function that can be called from multiple places
  const updateUserData = () => {
    if (isAuthenticated) {
      setUserData({
        first_name: localStorage.getItem(config.first_name) || "",
        last_name: localStorage.getItem(config.last_name) || "",
        profile_img: localStorage.getItem(config.profile_img) || "",
        email: localStorage.getItem(config.email) || "",
      });
    }
  };

  // Create a custom event for local storage updates
  const localStorageChanged = new Event("localStorageChanged");

  // Override localStorage setItem and removeItem to trigger our custom event
  const originalSetItem = localStorage.setItem;
  const originalRemoveItem = localStorage.removeItem;

  localStorage.setItem = function (key, value) {
    originalSetItem.apply(this, arguments);
    window.dispatchEvent(localStorageChanged);
  };

  localStorage.removeItem = function (key) {
    originalRemoveItem.apply(this, arguments);
    window.dispatchEvent(localStorageChanged);
  };

  useEffect(() => {
    // Listen for both storage events (other tabs) and our custom event (same tab)
    window.addEventListener("storage", updateUserData);
    window.addEventListener("localStorageChanged", updateUserData);

    // Initial update
    updateUserData();

    // Cleanup
    return () => {
      window.removeEventListener("storage", updateUserData);
      window.removeEventListener("localStorageChanged", updateUserData);
      // Restore original localStorage methods
      localStorage.setItem = originalSetItem;
      localStorage.removeItem = originalRemoveItem;
    };
  }, [isAuthenticated]); // Add isAuthenticated as dependency

  const toggleDarkMode = () => {
    setSettings((prevSettings) => {
      const newDarkMode = !prevSettings.isDarkMode;
      // Save to localStorage
      localStorage.setItem(config.is_dark_mode, newDarkMode.toString());
      return {
        ...prevSettings,
        isDarkMode: newDarkMode,
      };
    });
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleLogout = () => {
    // Clear the landlord profile
    if (props.landlordProfile) {
      props.landlordProfile = {};
    }

    // Clear localStorage items
    localStorage.removeItem(config.access_token);
    localStorage.removeItem(config.refresh_token);
    localStorage.removeItem(config.first_name);
    localStorage.removeItem(config.last_name);
    localStorage.removeItem(config.profile_img);
    localStorage.removeItem(config.email);
    localStorage.removeItem(config.is_dark_mode);
    localStorage.removeItem(config.number_of_free_trial_days_left);
    localStorage.removeItem(config.subscription_name);
    localStorage.removeItem(config.subscription_status);
    localStorage.removeItem(config.subscription_id);
    localStorage.removeItem(config.user_id);
    localStorage.removeItem(config.stripe_customer_id);
    localStorage.removeItem(config.is_paid);
    localStorage.removeItem(config.user_type);
    localStorage.removeItem(config.stripe_connect_account_id);

    // Clear user data state
    setUserData({
      first_name: "",
      last_name: "",
      profile_img: "",
      email: "",
    });

    // Update authentication state
    setIsAuthenticated(false);
  };

  const navigation = isAuthenticated
    ? [
        { name: "Dashboard", href: "/dashboard" },
        {
          name: "Properties",
          items: [
            { name: "Create New Property", href: "/newproperty" },
            { name: "My Properties", href: "/myproperties" },
          ],
        },
        {
          name: "Requests",
          items: [
            { name: "Create New Request", href: "/newUnitTicket" },
            { name: "All Requests", href: "/allTicketsInPortfolio" },
          ],
        },
        {
          name: "Contractors",
          items: [
            { name: "Create New Contractor", href: "/createContractor" },
            { name: "All Contractors", href: "/contractors" },
          ],
        },
        {
          name: "Contract Templates",
          items: [
            { name: "Create New Template", href: "/newContractTemplate" },
            { name: "All Templates", href: "/contractTemplates" },
          ],
        },
        { name: "All Advertisements", href: "/allAdvertisements" },
        { name: "Guide Books", href: "/guidebook", icon: "ri-book-2-line" },
        { name: "Reports", href: "/reports", icon: "ri-file-chart-line" },
      ]
    : [];

  const authButtons = !isAuthenticated ? (
    <div className="tw-flex tw-items-center tw-gap-4">
      <Link
        to="/signup"
        className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
      >
        Sign up
      </Link>
      <Link
        to="/"
        className={`tw-text-sm tw-font-semibold ${
          settings.isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
        }`}
      >
        Log in
      </Link>
    </div>
  ) : null;

  return (
    <Disclosure
      as="nav"
      className={`tw-sticky tw-top-0 tw-z-50 tw-shadow tw-w-full ${getThemeClasses()}`}
    >
      {({ open }) => (
        <>
          <div className="tw-w-full">
            <div className="tw-flex tw-h-16 tw-justify-between tw-px-4">
              <div className="tw-flex tw-items-center">
                <div className="tw-flex tw-shrink-0 tw-items-center">
                  <Link to={isAuthenticated ? "/dashboard" : "/"}>
                    <img
                      src={logo}
                      alt="Gurenter Logo"
                      className="tw-h-10 tw-w-auto"
                    />
                  </Link>
                </div>

                {isAuthenticated && (
                  <div className="tw-hidden lg:tw-ml-8 lg:tw-flex lg:tw-space-x-8">
                    {navigation.map((item) =>
                      !item.items ? (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? settings.isDarkMode
                                ? "tw-border-blue-400 tw-text-blue-400"
                                : "tw-border-indigo-500 tw-text-indigo-700"
                              : settings.isDarkMode
                              ? "tw-border-transparent tw-text-gray-300 hover:tw-border-gray-600 hover:tw-text-white"
                              : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700",
                            "tw-inline-flex tw-items-center tw-border-b-2 tw-px-1 tw-py-4 tw-text-sm tw-font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <Menu
                          as="div"
                          key={item.name}
                          className="tw-relative tw-inline-block"
                        >
                          <Menu.Button
                            className={classNames(
                              location.pathname.startsWith(
                                item.items[0].href.split("/")[1]
                              )
                                ? settings.isDarkMode
                                  ? "tw-border-blue-400 tw-text-blue-400"
                                  : "tw-border-indigo-500 tw-text-indigo-700"
                                : settings.isDarkMode
                                ? "tw-border-transparent tw-text-gray-300 hover:tw-border-gray-600 hover:tw-text-white"
                                : "tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700",
                              "tw-inline-flex tw-items-center tw-border-b-2 tw-px-1 tw-py-4 tw-text-sm tw-font-medium"
                            )}
                          >
                            {item.name}
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="tw-transition tw-ease-out tw-duration-100"
                            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                            enterTo="tw-transform tw-opacity-100 tw-scale-100"
                            leave="tw-transition tw-ease-in tw-duration-75"
                            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                          >
                            <Menu.Items
                              className={classNames(
                                "tw-absolute tw-left-0 tw-z-[1000] tw-mt-2 tw-w-56 tw-origin-top-left tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none",
                                settings.isDarkMode
                                  ? "tw-bg-gray-800"
                                  : "tw-bg-white"
                              )}
                            >
                              <div className="tw-py-1">
                                {item.items.map((subItem, index) => (
                                  <Menu.Item key={subItem.name}>
                                    {({ active }) => (
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          location.pathname === subItem.href
                                            ? settings.isDarkMode
                                              ? "tw-bg-gray-900 tw-text-blue-400"
                                              : "tw-bg-indigo-50 tw-text-indigo-700"
                                            : active
                                            ? settings.isDarkMode
                                              ? "tw-bg-gray-700 tw-text-white"
                                              : "tw-bg-gray-100 tw-text-gray-900"
                                            : settings.isDarkMode
                                            ? "tw-text-gray-300"
                                            : "tw-text-gray-700",
                                          "tw-block tw-px-4 tw-py-2 tw-text-sm",
                                          index === 0 && "tw-border-b tw-mb-1",
                                          settings.isDarkMode
                                            ? "tw-border-gray-700"
                                            : "tw-border-gray-200"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="tw-flex tw-items-center tw-gap-2">
                {!isAuthenticated ? (
                  authButtons
                ) : (
                  <>
                    <div className="tw-flex tw-items-center lg:tw-hidden">
                      <Disclosure.Button className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500 dark:hover:tw-bg-gray-700">
                        <span className="tw-absolute tw--inset-0.5" />
                        <span className="tw-sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="tw-block tw-h-6 tw-w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="tw-block tw-h-6 tw-w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>

                    <div className="tw-hidden lg:tw-flex lg:tw-items-center lg:tw-gap-3">
                      {/* AI Assistant Button */}
                      {localStorage.getItem(config.ai_chat_enabled) ===
                        "true" && (
                        <button
                          onClick={() => navigate("/gurenAi")}
                          className={`
                            tw-flex tw-items-center tw-gap-2 
                            tw-px-3 tw-py-1.5 
                            tw-rounded-lg 
                            tw-border 
                            tw-transition-all 
                            tw-duration-200
                            ${
                              isDarkMode
                                ? "tw-bg-[#1e2124] tw-border-gray-700 tw-text-gray-100 hover:tw-bg-[#2a2d31] hover:tw-border-gray-600"
                                : "tw-bg-white tw-border-gray-200 tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-border-gray-300"
                            }
                          `}
                          title="Open AI Assistant"
                        >
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <div className="tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-rounded">
                              <span className="tw-text-base">✨</span>
                            </div>
                            <span className="tw-text-sm tw-font-medium">
                              Guren AI
                            </span>
                          </div>
                        </button>
                      )}

                      <button
                        type="button"
                        className="tw-text-gray-500 hover:tw-text-gray-700 dark:tw-text-gray-400 dark:hover:tw-text-gray-300"
                        onClick={toggleFullscreen}
                      >
                        <i className="bx bx-fullscreen tw-text-xl"></i>
                      </button>

                      <button
                        type="button"
                        className="tw-text-gray-500 hover:tw-text-gray-700 dark:tw-text-gray-400 dark:hover:tw-text-gray-300"
                        onClick={toggleDarkMode}
                      >
                        <i
                          className={`bx ${
                            settings.isDarkMode ? "bx-sun" : "bx-moon"
                          } tw-text-xl`}
                        ></i>
                      </button>

                      {isAuthenticated && (
                        <div className="tw-relative">
                          <NotificationHub isDarkMode={isDarkMode} />
                        </div>
                      )}

                      <Menu as="div" className="tw-relative">
                        <Menu.Button
                          className={classNames(
                            "tw-flex tw-items-center tw-gap-3 tw-rounded-full tw-text-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2",
                            settings.isDarkMode
                              ? "tw-bg-gray-900"
                              : "tw-bg-white",
                            settings.isDarkMode
                              ? "dark:tw-ring-offset-gray-900"
                              : ""
                          )}
                        >
                          <span className="tw-sr-only">Open user menu</span>
                          <img
                            className="tw-h-8 tw-w-8 tw-rounded-full"
                            src={userData.profile_img || baseImageUrl}
                            alt="Profile"
                          />
                          <div className="tw-hidden lg:tw-block">
                            <div
                              className={classNames(
                                "tw-font-medium",
                                settings.isDarkMode
                                  ? "tw-text-white"
                                  : "tw-text-gray-900"
                              )}
                            >
                              {`${
                                (userData.first_name || "")
                                  .charAt(0)
                                  .toUpperCase() +
                                (userData.first_name || "").slice(1)
                              } ${
                                (userData.last_name || "")
                                  .charAt(0)
                                  .toUpperCase() +
                                (userData.last_name || "").slice(1)
                              }`.trim()}
                            </div>
                            <div
                              className={classNames(
                                "tw-text-xs",
                                settings.isDarkMode
                                  ? "tw-text-gray-400"
                                  : "tw-text-gray-500"
                              )}
                            >
                              Landlord
                            </div>
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="tw-transition tw-ease-out tw-duration-100"
                          enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                          enterTo="tw-transform tw-opacity-100 tw-scale-100"
                          leave="tw-transition tw-ease-in tw-duration-75"
                          leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                          leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                        >
                          <Menu.Items
                            className={classNames(
                              "tw-absolute tw-right-0 tw-z-[1000] tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none",
                              settings.isDarkMode
                                ? "tw-bg-gray-900"
                                : "tw-bg-white"
                            )}
                          >
                            <div
                              className={classNames(
                                "tw-px-4 tw-py-3 tw-border-b",
                                settings.isDarkMode
                                  ? "tw-border-gray-700"
                                  : "tw-border-gray-200"
                              )}
                            >
                              <p
                                className={classNames(
                                  "tw-text-sm tw-truncate",
                                  settings.isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                )}
                              >
                                {userData.email}
                              </p>
                            </div>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/profile"
                                  className={classNames(
                                    active
                                      ? settings.isDarkMode
                                        ? "tw-bg-gray-800"
                                        : "tw-bg-gray-100"
                                      : "",
                                    settings.isDarkMode
                                      ? "tw-text-gray-300"
                                      : "tw-text-gray-700",
                                    "tw-block tw-px-4 tw-py-2 tw-text-sm"
                                  )}
                                >
                                  Profile
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/billing"
                                  className={classNames(
                                    active
                                      ? settings.isDarkMode
                                        ? "tw-bg-gray-800"
                                        : "tw-bg-gray-100"
                                      : "",
                                    settings.isDarkMode
                                      ? "tw-text-gray-300"
                                      : "tw-text-gray-700",
                                    "tw-block tw-px-4 tw-py-2 tw-text-sm"
                                  )}
                                >
                                  Billing
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/logout"
                                  onClick={handleLogout}
                                  className={classNames(
                                    active
                                      ? settings.isDarkMode
                                        ? "tw-bg-red-900/20"
                                        : "tw-bg-red-50"
                                      : "",
                                    "tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-red-600 dark:tw-text-red-400"
                                  )}
                                >
                                  Sign out
                                </Link>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {isAuthenticated && (
            <Disclosure.Panel
              className={classNames(
                "lg:tw-hidden tw-fixed tw-inset-y-0 tw-right-0 tw-z-[1001] tw-w-full tw-max-w-sm tw-shadow-lg tw-overflow-y-auto",
                settings.isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
              )}
            >
              <div className="tw-h-16 tw-px-4 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
                <div className="tw-flex tw-items-center tw-gap-3">
                  <img
                    className="tw-h-8 tw-w-8 tw-rounded-full"
                    src={userData.profile_img || baseImageUrl}
                    alt="Profile"
                  />
                  <div>
                    <div
                      className={classNames(
                        "tw-font-medium",
                        settings.isDarkMode
                          ? "tw-text-gray-100"
                          : "tw-text-gray-900"
                      )}
                    >
                      {`${
                        (userData.first_name || "").charAt(0).toUpperCase() +
                        (userData.first_name || "").slice(1)
                      } ${
                        (userData.last_name || "").charAt(0).toUpperCase() +
                        (userData.last_name || "").slice(1)
                      }`.trim()}
                    </div>
                    <div
                      className={classNames(
                        "tw-text-sm",
                        settings.isDarkMode
                          ? "tw-text-gray-400"
                          : "tw-text-gray-500"
                      )}
                    >
                      {userData.email}
                    </div>
                  </div>
                </div>
                <Disclosure.Button className="tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-text-gray-200 hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-500">
                  <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>

              <div className="tw-px-2 tw-py-3 tw-space-y-1">
                {navigation.map((item) =>
                  !item.items ? (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? settings.isDarkMode
                            ? "tw-bg-gray-900 tw-text-blue-400"
                            : "tw-bg-indigo-50 tw-text-indigo-700"
                          : settings.isDarkMode
                          ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                          : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <Disclosure
                      key={item.name}
                      as="div"
                      className="tw-space-y-1"
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              "tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium",
                              settings.isDarkMode
                                ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                                : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900"
                            )}
                          >
                            {item.name}
                            <svg
                              className={classNames(
                                open ? "tw-rotate-180" : "",
                                "tw-h-5 tw-w-5 tw-transform tw-transition-transform"
                              )}
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="tw-space-y-1">
                            {item.items.map((subItem) => (
                              <Disclosure.Button
                                key={subItem.name}
                                as={Link}
                                to={subItem.href}
                                className={classNames(
                                  location.pathname === subItem.href
                                    ? settings.isDarkMode
                                      ? "tw-bg-gray-900 tw-text-blue-400"
                                      : "tw-bg-indigo-50 tw-text-indigo-700"
                                    : settings.isDarkMode
                                    ? "tw-text-gray-300 hover:tw-bg-gray-700 hover:tw-text-white"
                                    : "tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                                  "tw-block tw-rounded-md tw-py-2 tw-pl-6 tw-pr-3 tw-text-base tw-font-medium"
                                )}
                              >
                                {subItem.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </div>

              <div className="tw-border-t tw-border-gray-700">
                <div className="tw-px-4 tw-py-4 tw-space-y-4">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <button
                      type="button"
                      className={classNames(
                        "tw-flex tw-items-center tw-gap-2",
                        settings.isDarkMode
                          ? "tw-text-gray-300 hover:tw-text-white"
                          : "tw-text-gray-600 hover:tw-text-gray-900"
                      )}
                      onClick={toggleDarkMode}
                    >
                      <i
                        className={`bx ${
                          settings.isDarkMode ? "bx-sun" : "bx-moon"
                        } tw-text-xl`}
                      ></i>
                      <span className="tw-text-sm tw-font-medium">
                        {settings.isDarkMode ? "Light Mode" : "Dark Mode"}
                      </span>
                    </button>
                    <NotificationHub isDarkMode={isDarkMode} />
                  </div>

                  <div className="tw-space-y-1">
                    <Disclosure.Button
                      as={Link}
                      to="/profile"
                      className={classNames(
                        settings.isDarkMode
                          ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                          : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      Profile
                    </Disclosure.Button>
                    <Disclosure.Button
                      as={Link}
                      to="/billing"
                      className={classNames(
                        settings.isDarkMode
                          ? "tw-text-gray-100 hover:tw-bg-gray-700 hover:tw-text-white"
                          : "tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-900",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      Billing
                    </Disclosure.Button>
                    <Disclosure.Button
                      as={Link}
                      to="/logout"
                      onClick={handleLogout}
                      className={classNames(
                        settings.isDarkMode
                          ? "tw-text-red-400 hover:tw-bg-red-900/20"
                          : "tw-text-red-600 hover:tw-bg-red-50",
                        "tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                      )}
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;
