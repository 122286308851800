import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getPropertyByPropertyId,
  getPropertyUnitsById,
} from "../../../services/property/retrieve/propertyRetrievalService";
//import photo_1 from "../../../styletheme/assets/img/photos/1.jpg";
import Moment from "react-moment";
//import loader from "../../../styletheme/assets/img/svgicons/loader.svg";
import HeaderLabelBreadCrumb from "../../reusable/label/headerLabelBreadCrumb";
//import user_2 from "../../../styletheme/assets/img/users/2.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import Carousel from "react-bootstrap/Carousel";
import LoaderView from "../../reusable/loading/loaderView";
import {
  getOwnershipStructuresByPortfolioId,
  getTheRentedVsVacantUnitsByPropertyId,
} from "../../../services/portfolio/ownership/portfolioOwnershipManagement";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { getPortfolioByPortfolioId } from "../../../services/portfolio/portfolioManagement";
import { set } from "react-hook-form";
import { capitalizeFirstLetterOfEachWord } from "../../../services/utils/stringUtils";
//if disabled or isActivated is false then you cant do anything new only update the unit
import user_img from "../../../styletheme/images/user-icon/avatar.png";
import { fetchAllCurrencies } from "../../../services/systemDefined/currency/sysCurrencyManagement";
import defaultHouseImg from "../../../styletheme/images/buildings/house.png";
import PropertyUnitCard from "./reusable/propertyUnitCard";
import { darkenColor } from "../../../services/helpers/color/colorService";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { get5RecentRevenueForPropertyUnitMonthYearIndividual } from "../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import RecentPropRevenueCard from "./reusable/recentPropRevenueCard";
import { get5RecentExpensesForPropertyUnitMonthYearIndividual } from "../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import RecentPropExpenseCard from "./reusable/recentPropExpenseCard";
import { getNewTicketsByPropertyId } from "../../../services/tickets/unitTicketsManagement";
import TicketCard from "../tickets/list/kanban/reusable/ticketCard";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import "../../../styletheme/css/shimmer.css";
import { devLog } from "../../../services/utils/logger";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../services/utils/hooks/usePageTitle";
import TopRevenueCard from "../../../components/portal/dashboard/reusable/topRevenueCard";

import Lottie from "react-lottie";
import empty_list_animation from "../../reusable/animations/lottie/empty_people_list.json";
import empty_expense_list_animation from "../../reusable/animations/lottie/empty_expenses_animation.json";

const PropertyDetail = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isRevenueLoading, setIsRevenueLoading] = useState(true);
  const [isExpenseLoading, setIsExpenseLoading] = useState(true);
  const [isTicketLoading, setIsTicketLoading] = useState(true);
  const [isOwnersLoading, setIsOwnersLoading] = useState(true);
  const [isUnitsLoading, setIsUnitsLoading] = useState(true);
  const { propertyId } = useParams();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const pageTitle = "Property Detail";
  usePageTitle({ title: pageTitle });
  const [headerTitleDetail] = useState({
    title: "My Properties Units",
    subHeadline: "All the Units associated with this Property.",
  });

  const [emptyHeaderTitleDetail] = useState({
    title: "No Properties Units Found",
    subHeadline:
      "You should consider adding a property unit that is rented within this property.",
  });

  //Get te property details -> get the owners -> get the units
  const [property, setProperty] = useState({
    property_id: "",
    portfolio_id: "",
    currency_id: "",
    property_type_id: "",
    sub_property_type_id: "",
    property_alias_name: "",
    street_address_1: "",
    street_address_2: "",
    city_town: "",
    state_parish: "",
    zip_postal_code: "",
    country_id: "",
    country: "",
    property_image_urls: [
      {
        base64_data: "",
        file_name: "",
        unique_file_name: "",
        file_size: 0,
        file_size_formatted: "",
        file_type: "",
        upload_time_stamp: Date.now(),
        url: "",
      },
    ],
  });

  const [propertyUnits, setPropertyUnits] = useState([
    {
      property_unit_id: "",
      property_id: "",
      unit_name: "",
      market_rent_amount: 0.0,
      number_of_bathrooms: 0,
      number_of_bedrooms: 0,
      square_footage: 0,
      is_rented: false,
      property_unit_image_urls: [
        {
          base64_data: "",
          file_name: "",
          unique_file_name: "",
          file_size: 0,
          file_size_formatted: "",
          file_type: "",
          upload_time_stamp: Date.now(),
          url: "",
        },
      ],
    },
  ]);

  const [owners, setOwners] = useState([
    {
      ownership_structure_id: "",
      portfolio_id: "",
      user_id: "",
      first_name: "",
      last_name: "",
      owner_email: "",
      owner_phone: "",
      ownership_start_date: "",
      ownership_end_date: "",
      ownership_percentage: 0,
      is_controlling_partner: false,
      partnership_type: "",
    },
  ]);

  const [portfolio, setPortfolio] = useState({
    portfolio_id: "",
  });

  const [vacancyChartData, setVacancyChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  //revenue use state
  const [recentRevenues, setRecentRevenues] = useState([
    {
      property_unit_id: "",
      unit_name: " ",
      revenue: 0,
      revenue_date: "",
      revenue_title: "",
      unit_details: {
        property_unit_id: "",
        property_id: "",
        unit_name: " ",
        number_of_bedrooms: 0,
        number_of_bathrooms: 0,
        square_footage: 0,
        market_rent_amount: 0,
      },
    },
  ]);

  const [recentExpenses, setRecentExpenses] = useState([
    {
      property_unit_id: "",
      expense_amount: 0,
      expense_date: "",
      unit_name: " ",
      expense_title: "",
      unit_details: {
        property_unit_id: "",
        property_id: "",
        unit_name: " ",
        number_of_bedrooms: 0,
        number_of_bathrooms: 0,
        square_footage: 0,
        market_rent_amount: 0,
      },
    },
  ]);

  //tickets
  const [newUnitTickets, setNewUnitTickets] = useState([
    {
      ticket_id: "",
      property_unit_id: "",
      ticket_type: "",
      ticket_title: "",
      ticket_description: "",
      ticket_priority_id: "",
      ticket_status_id: "",
      ticket_number: "",
      ticket_status: "",
      priority_info: {
        created_at: "",
        is_active: true,
        priority: "",
        priority_id: "",
        updated_at: "",
      },
    },
  ]);

  const [showUnitModal, setShowUnitModal] = useState(false);
  const [transactionType, setTransactionType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    devLog(propertyId);

    // Fetch data and perform other initializations
    getCurrencyOptions();
    getCurrentPropertyProfile();
    getPropertyUnits();
    getRentedVsVacantUnits();
    fetch5RecentRevenueForPropertyUnitsMonthYearIndividual();
    fetch5RecentExpensesForPropertyUnitsMonthYearIndividual();
    fetchAllNewTickets();
  }, []);

  const getRentedVsVacantUnits = async () => {
    setIsChartLoading(true);
    try {
      const response = await getTheRentedVsVacantUnitsByPropertyId(propertyId);

      if (response) {
        console.log(response);

        const rentedCount = response.rented_units;
        const vacantCount = response.vacant_units;

        const labels = [
          `(${rentedCount}) Rented Units`,
          `(${vacantCount}) Vacant Units`,
        ];

        // Define the colors for rented and vacant units
        const predefinedColors = ["#28a745", "#dc3545"]; // Green for rented, Red for vacant

        const borderColor = predefinedColors.map(
          (color) => color.replace("0.2", "1") // Set alpha channel to 1 for solid color
        );

        const hoverColors = predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ); // darken by 30%

        setVacancyChartData({
          ...vacancyChartData,
          labels: labels,
          datasets: [
            {
              label: "Rented vs Vacant Units",
              data: [response.rented_units, response.vacant_units],
              backgroundColor: predefinedColors, // Use the predefined colors
              borderColor: isDarkMode ? "#6c757d" : "white",
              borderWidth: 3,
              hoverBackgroundColor: hoverColors,
            },
          ],
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsChartLoading(false);
    }
  };

  const getCurrentPropertyProfile = async () => {
    const property = await getPropertyByPropertyId(propertyId);

    setProperty(property);

    devLog(property);

    if (property) {
      getOwnersOfProperty(property.portfolio_id);
      getPortfolio(property.portfolio_id);
    }
  };

  //click the unit to see the details and rent the unit
  const getPropertyUnits = async () => {
    setIsUnitsLoading(true);
    try {
      const propertyUnits = await getPropertyUnitsById(propertyId);
      console.log("Property Units", propertyUnits);
      setPropertyUnits(propertyUnits);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUnitsLoading(false);
    }
  };
  //get the owners of the property
  const getOwnersOfProperty = async (portfolio_id) => {
    setIsOwnersLoading(true);
    try {
      const owners = await getOwnershipStructuresByPortfolioId(portfolio_id);
      console.log("Owners", owners);
      setOwners(owners);
    } catch (error) {
      console.error(error);
    } finally {
      setIsOwnersLoading(false);
    }
  };

  const getPortfolio = async (portfolio_id) => {
    //get the owners of the property
    console.log("Portfolio Id");
    console.log(portfolio_id);
    const portfolio = await getPortfolioByPortfolioId(portfolio_id);

    console.log("Portfolio");
    console.log(portfolio);

    setPortfolio(portfolio);
  };

  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const getCurrencyOptions = async () => {
    //set currencyOptions
    const response = await fetchAllCurrencies();
    //set currencyOptions
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === "USD"
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
    }
  };

  //get5RecentRevenueForPropertyUnitMonthYearIndividual
  const fetch5RecentRevenueForPropertyUnitsMonthYearIndividual = async () => {
    setIsRevenueLoading(true);
    try {
      const response =
        await get5RecentRevenueForPropertyUnitMonthYearIndividual(propertyId);
      console.log("Recent Revenue");
      console.log(response);

      if (response) {
        setRecentRevenues(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRevenueLoading(false);
    }
  };

  //get5RecentExpensesForPropertyUnitMonthYearIndividual
  const fetch5RecentExpensesForPropertyUnitsMonthYearIndividual = async () => {
    setIsExpenseLoading(true);
    try {
      const response =
        await get5RecentExpensesForPropertyUnitMonthYearIndividual(propertyId);
      console.log("Recent Expenses");
      console.log(response);

      if (response) {
        setRecentExpenses(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsExpenseLoading(false);
    }
  };

  const fetchAllNewTickets = async () => {
    setIsTicketLoading(true);
    try {
      const response = await getNewTicketsByPropertyId(propertyId);
      setNewUnitTickets(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsTicketLoading(false);
    }
  };

  const handleTransactionClick = (type) => {
    // If there's only one unit, go directly to the transaction page
    if (propertyUnits.length === 1) {
      const unitId = propertyUnits[0].property_unit_id;
      window.location.href = `/newUnitTransaction/${unitId}?type=${type}`;
      return;
    }

    // If there are multiple units, show unit selection modal
    setTransactionType(type);
    setShowUnitModal(true);
  };

  const UnitSelectionModal = () => (
    <div
      className={`modal fade ${showUnitModal ? "show" : ""}`}
      id="unitSelectionModal"
      tabIndex="-1"
      style={{ display: showUnitModal ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content ${isDarkMode ? "bg-dark" : ""}`}>
          <div className="modal-header">
            <h5 className="modal-title">Select Unit</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowUnitModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="list-group">
              {propertyUnits.map((unit) => (
                <a
                  key={unit.property_unit_id}
                  href={`/newUnitTransaction/${unit.property_unit_id}?type=${transactionType}`}
                  className="list-group-item list-group-item-action"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h6 className="mb-1">{unit.unit_name}</h6>
                    {unit.is_rented && (
                      <span className="badge bg-success">Rented</span>
                    )}
                  </div>
                  <small className="text-muted">
                    {unit.number_of_bedrooms} beds • {unit.number_of_bathrooms}{" "}
                    baths • {unit.square_footage} sqft
                  </small>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className={` ${getThemeClasses()}`}>
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="My Properties"
            previousPageLink="/myproperties"
          />
          {/* end page title */}

          <div className="row">
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              {/* Property Info Card */}
              <div className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 2xl:tw-w-[16%] tw-mb-4">
                <div className="card card-height-100">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <i className="ri-group-line align-middle me-1 text-muted"></i>{" "}
                      {portfolio.portfolio_name}
                    </h5>
                  </div>
                  {/* Property Image Carousel */}
                  {property.property_image_urls &&
                  property.property_image_urls.length > 0 ? (
                    <Carousel
                      interval={null}
                      indicators={property.property_image_urls.length > 1}
                      className="tw-h-[250px]"
                    >
                      {property.property_image_urls.map((image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={image.url}
                            alt={image.file_name}
                            style={{
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      src={defaultHouseImg}
                      alt="Default property image"
                      className="d-block w-100"
                      style={{
                        height: "250px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="card-body">
                    <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                      <li className="fw-medium fs-14">
                        {property.property_alias_name}
                      </li>
                      <li>{property.street_address_1}</li>
                      {property.street_address_2 && (
                        <li>{property.street_address_2}</li>
                      )}
                      <li>
                        {property.city_town} {property.state_parish}
                        {", "}
                        {property.zip_postal_code}
                      </li>
                      <li>{property.country}</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Unit Occupancy Chart */}
              <div className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 2xl:tw-w-[20%] tw-mb-4">
                <div className={`card card-height-100 ${getThemeClasses()}`}>
                  <div className="card-header">
                    <h4 className="card-title mb-0">Unit Occupancy</h4>
                  </div>
                  <div className="card-body tw-flex tw-flex-col tw-justify-between">
                    {isChartLoading ? (
                      <ShimmerEffect
                        type="chart"
                        height="250px"
                        darkMode={isDarkMode}
                      />
                    ) : (
                      <>
                        <div
                          className="tw-relative tw-flex tw-justify-center tw-items-center"
                          style={{ height: "200px" }}
                        >
                          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-10">
                            <div className="tw-text-center">
                              <div
                                className={`tw-text-2xl tw-font-bold ${
                                  isDarkMode
                                    ? "tw-text-white"
                                    : "tw-text-gray-800"
                                }`}
                              >
                                {vacancyChartData.datasets[0].data.reduce(
                                  (a, b) => a + b,
                                  0
                                )}
                              </div>
                              <div
                                className={`tw-text-sm ${
                                  isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                }`}
                              >
                                Total Units
                              </div>
                            </div>
                          </div>
                          <Doughnut
                            data={vacancyChartData}
                            options={{
                              maintainAspectRatio: false,
                              responsive: true,
                              cutout: "75%",
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  enabled: true,
                                  callbacks: {
                                    label: function (context) {
                                      const label = context.label
                                        .split("(")[0]
                                        .trim();
                                      const value = context.raw;
                                      const total = context.dataset.data.reduce(
                                        (a, b) => a + b,
                                        0
                                      );
                                      const percentage = (
                                        (value / total) *
                                        100
                                      ).toFixed(1);
                                      return `${label}: ${value} (${percentage}%)`;
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                        <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4">
                          {vacancyChartData.datasets[0].data.map(
                            (value, index) => {
                              const total =
                                vacancyChartData.datasets[0].data.reduce(
                                  (a, b) => a + b,
                                  0
                                );
                              const percentage = (
                                (value / total) *
                                100
                              ).toFixed(1);
                              const isRented =
                                vacancyChartData.labels[index].includes(
                                  "Rented"
                                );

                              return (
                                <div
                                  key={index}
                                  className="tw-flex tw-flex-col tw-items-center tw-p-3 tw-rounded-lg"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "rgba(255,255,255,0.05)"
                                      : "rgba(0,0,0,0.02)",
                                    border: `1px solid ${
                                      isDarkMode
                                        ? "rgba(255,255,255,0.1)"
                                        : "rgba(0,0,0,0.05)"
                                    }`,
                                  }}
                                >
                                  <div
                                    className={`tw-text-xl tw-font-semibold ${
                                      isDarkMode
                                        ? "tw-text-white"
                                        : "tw-text-gray-800"
                                    }`}
                                  >
                                    {value}
                                  </div>
                                  <div
                                    className={`tw-text-xs ${
                                      isDarkMode
                                        ? "tw-text-gray-400"
                                        : "tw-text-gray-500"
                                    } tw-mt-1`}
                                  >
                                    {isRented ? "Rented" : "Vacant"}
                                  </div>
                                  <div className="tw-flex tw-items-center tw-gap-1 tw-mt-1">
                                    <div
                                      className="tw-w-2 tw-h-2 tw-rounded-full"
                                      style={{
                                        backgroundColor:
                                          vacancyChartData.datasets[0]
                                            .backgroundColor[index],
                                      }}
                                    ></div>
                                    <span
                                      className={`tw-text-xs ${
                                        isDarkMode
                                          ? "tw-text-gray-400"
                                          : "tw-text-gray-500"
                                      }`}
                                    >
                                      {percentage}%
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* Recent Revenue */}
              <div className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 2xl:tw-w-[20%] tw-mb-4">
                <div className={`card card-height-100 ${getThemeClasses()}`}>
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="col text-start">
                      <h4 className="card-title mb-0">Recent 5 Revenue</h4>
                    </div>
                    <div className="col text-end">
                      <h4 className="card-title mb-0">
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}
                        {", "}
                        {new Date().getFullYear()}
                      </h4>
                    </div>
                  </div>
                  <div className="card-body p-0 pt-2">
                    <div
                      className="scrollbar"
                      style={{ height: "360px", overflowY: "auto" }}
                    >
                      <div className="container">
                        {isRevenueLoading ? (
                          <ShimmerEffect
                            type="list"
                            rows={5}
                            height={60}
                            className="mb-2"
                            darkMode={isDarkMode}
                          />
                        ) : recentRevenues && recentRevenues.length > 0 ? (
                          recentRevenues.map((recentRevenue, index) => (
                            <TopRevenueCard
                              key={index}
                              unit={recentRevenue}
                              defaultHouseImg={defaultHouseImg}
                            />
                          ))
                        ) : (
                          <div className="text-center p-4">
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: empty_expense_list_animation,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={160}
                              width={160}
                            />
                            <div
                              className={`tw-mt-4 tw-mb-4 ${
                                isDarkMode
                                  ? "tw-text-gray-300"
                                  : "tw-text-gray-600"
                              }`}
                            >
                              <h6 className="tw-font-medium tw-mb-2">
                                No Revenue Records
                              </h6>
                              <p className="tw-text-sm tw-mb-4">
                                Track your property's income by adding revenue
                                records.
                              </p>
                              <button
                                onClick={() =>
                                  handleTransactionClick("revenue")
                                }
                                className="btn btn-primary tw-px-6"
                              >
                                <i className="ri-add-line align-bottom me-1"></i>
                                Add Revenue
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Recent Expenses */}
              <div className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 2xl:tw-w-[20%] tw-mb-4">
                <div className="card card-height-100">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="col text-start">
                      <h4 className="card-title mb-0">Recent 5 Expenses</h4>
                    </div>
                    <div className="col text-end">
                      <h4 className="card-title mb-0">
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}
                        {", "}
                        {new Date().getFullYear()}
                      </h4>
                    </div>
                  </div>
                  <div className="card-body p-0 pt-2">
                    <div
                      className="scrollbar"
                      style={{ height: "360px", overflowY: "auto" }}
                    >
                      <div className="container">
                        {isExpenseLoading ? (
                          <ShimmerEffect
                            type="list"
                            rows={5}
                            height={60}
                            className="mb-2"
                            darkMode={isDarkMode}
                          />
                        ) : recentExpenses && recentExpenses.length > 0 ? (
                          recentExpenses.map((recentExpense, index) => (
                            <RecentPropExpenseCard
                              key={index}
                              recentExpense={recentExpense}
                              defaultHouseImg={defaultHouseImg}
                            />
                          ))
                        ) : (
                          <div className="text-center p-4">
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: empty_expense_list_animation,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={160}
                              width={160}
                            />
                            <div
                              className={`tw-mt-4 tw-mb-4 ${
                                isDarkMode
                                  ? "tw-text-gray-300"
                                  : "tw-text-gray-600"
                              }`}
                            >
                              <h6 className="tw-font-medium tw-mb-2">
                                No Expense Records
                              </h6>
                              <p className="tw-text-sm tw-mb-4">
                                Keep track of your property costs by recording
                                expenses.
                              </p>
                              <button
                                onClick={() =>
                                  handleTransactionClick("expense")
                                }
                                className="btn btn-primary tw-px-6"
                              >
                                <i className="ri-add-line align-bottom me-1"></i>
                                Add Expense
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Ticket Requests */}
              <div className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 2xl:tw-w-[20%] tw-mb-4">
                <div className="card card-height-100">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="col text-start">
                      <h4 className="card-title mb-0">Ticket Requests</h4>
                    </div>
                    <div className="col text-end">
                      <h4 className="card-title mb-0">
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}
                        {", "}
                        {new Date().getFullYear()}
                      </h4>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="container">
                      <div
                        className="table-responsive table-card p-3"
                        style={{ height: "360px", overflowY: "auto" }}
                      >
                        {isTicketLoading ? (
                          <ShimmerEffect
                            type="list"
                            rows={5}
                            height={60}
                            className="mb-2"
                            darkMode={isDarkMode}
                          />
                        ) : newUnitTickets && newUnitTickets.length > 0 ? (
                          newUnitTickets.map((ticket, index) => (
                            <TicketCard ticket={ticket} key={index} />
                          ))
                        ) : (
                          <div className="text-center p-4">
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: empty_list_animation,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={160}
                              width={160}
                            />
                            <div
                              className={`tw-mt-4 tw-mb-4 ${
                                isDarkMode
                                  ? "tw-text-gray-300"
                                  : "tw-text-gray-600"
                              }`}
                            >
                              <h6 className="tw-font-medium tw-mb-2">
                                No Ticket Requests
                              </h6>
                              <p className="tw-text-sm tw-mb-4">
                                Create tickets to track and manage property
                                maintenance requests.
                              </p>
                              <Link
                                to={`/newUnitTicket`}
                                className="btn btn-primary tw-px-6"
                              >
                                <i className="ri-add-line align-bottom me-1"></i>
                                Create Ticket
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Quick Actions Buttons*/}
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h5 className="mb-0">Quick Actions</h5>
                          <p className="text-muted">
                            Quick actions to help you manage your property
                          </p>

                          {/* Replace the d-flex with Tailwind flex-wrap classes */}
                          <div className="tw-flex tw-flex-wrap tw-gap-2">
                            {/* Add new unit*/}
                            <Link
                              to={`/newUnit/${propertyId}`}
                              className="btn btn-outline-dark btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-add-line align-bottom"></i> Add
                              New Unit
                            </Link>

                            {/* Edit Property*/}
                            <Link
                              to={`/updateProperty/${propertyId}`}
                              className="btn btn-outline-success btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-pencil-line align-bottom"></i>{" "}
                              Edit Property
                            </Link>

                            {/*Ticket Requests*/}
                            <Link
                              to={`/allPropertyTickets/${propertyId}`}
                              className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-ticket-2-line align-bottom"></i>{" "}
                              Ticket Requests
                            </Link>

                            {/*GuideBooks*/}
                            <Link
                              to={`/guideBook`}
                              className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-book-read-line align-bottom"></i>{" "}
                              Guide Books
                            </Link>

                            {/*Create Universal List*/}
                            <Link
                              to={`/createUniversalList/${propertyId}`}
                              className="btn btn-outline-primary btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-list-check align-bottom"></i>{" "}
                              Create Universal List
                            </Link>

                            {/*View Universal List*/}
                            <Link
                              to={`/universalList/${propertyId}`}
                              className="btn btn-outline-info btn-md tw-whitespace-nowrap tw-mb-2"
                            >
                              <i className="ri-list-check align-bottom"></i>{" "}
                              View Universal List
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Owners start*/}
          {/* Section title*/}
          <p className="text-muted mb-0 fw-medium">
            Owner {owners && owners.length > 1 ? "s" : ""}
          </p>

          <div className="row">
            {isOwnersLoading ? (
              <ShimmerEffect
                type="list"
                rows={3}
                height={100}
                className="mb-2"
                darkMode={isDarkMode}
              />
            ) : (
              owners &&
              owners.length > 0 &&
              owners.map((owner, index) => (
                <div key={index} className="col-md-2">
                  <div className="card card-animate">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="me-3">
                          <div className="avatar-sm flex-shrink-0">
                            <img
                              src={user_img}
                              alt="User"
                              className="avatar-title bg-info-subtle rounded-circle fs-2"
                            />
                          </div>
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <h4 className="fs-16 mb-1">
                            {owner.first_name} {owner.last_name}
                          </h4>
                          <p className="text-muted mb-0">{/*Capitalize */}</p>

                          <p
                            className="text-muted mb-0"
                            style={{
                              fontSize: "0.8rem",
                              wordWrap: "break-word",
                            }}
                          >
                            {owner.is_controlling_partner ? (
                              <span className="badge bg-success">
                                Controlling Partner
                              </span>
                            ) : (
                              <span className="badge bg-info">
                                {" "}
                                {capitalizeFirstLetterOfEachWord(
                                  owner.partnership_type
                                )}
                              </span>
                            )}
                          </p>
                          <p hidden className="text-muted mb-0">
                            <i className="ri-mail-line me-2"></i>
                            {owner.owner_email}
                          </p>
                          {owner.owner_phone && (
                            <p className="text-muted mb-0">
                              <i className="ri-phone-line me-2"></i>
                              {owner.owner_phone}
                            </p>
                          )}
                          <p className="text-muted mb-0">
                            <i className="ri-calendar-event-line me-2"></i>
                            <Moment format="MMM DD, YYYY">
                              {owner.ownership_start_date}
                            </Moment>
                          </p>

                          <p className="text-muted mb-0">
                            <span className="fw-bold">
                              {owner.ownership_percentage}%
                            </span>{" "}
                            Ownership
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card*/}
                </div>
              ))
            )}
          </div>
          {/* end row */}
          {/* Owners end*/}

          {/*Unit start*/}
          {/* Section title*/}
          <p className="text-muted mb-0 fw-medium">
            {propertyUnits.length} Units
          </p>
          <div className="row">
            {isUnitsLoading ? (
              <ShimmerEffect
                type="list"
                rows={3}
                height={200}
                className="mb-2"
                darkMode={isDarkMode}
              />
            ) : propertyUnits && propertyUnits.length > 0 ? (
              propertyUnits.map((unit, index) => (
                <div
                  key={index}
                  className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3"
                >
                  <PropertyUnitCard
                    unit={unit}
                    defaultHouseImg={defaultHouseImg}
                    selectedCurrencyOption={selectedCurrencyOption}
                  />
                </div>
              ))
            ) : (
              <div className="col-12 text-center p-5">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: empty_list_animation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={250}
                  width={250}
                />
                <p
                  className={`mt-3 ${isDarkMode ? "text-light" : "text-muted"}`}
                >
                  No property units found. Add a unit to get started.
                </p>
                <Link
                  to={`/newUnit/${propertyId}`}
                  className="btn btn-primary mt-2"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Create New
                  Unit
                </Link>
              </div>
            )}
          </div>
          {/* end row */}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      <UnitSelectionModal />
    </React.Fragment>
  );
};

export default PropertyDetail;
