import http from "../../general/httpService";
import api from "../../../constants/config";

///currency/currencies
export async function fetchAllCurrencies() {
  const fullApiEndpoint = api.apiEndpoint + `/currency/currencies`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

///get_currency/{currency_id}
export async function getCurrencyById(currencyId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/currency/get_currency/${currencyId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
