import http from "../../general/httpService";
import config from "../../../constants/config";

///sys_status/statuses
export async function fetchAllStatuses() {
  const fullApiEndpoint = config.apiEndpoint + `/sys_status/statuses`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
