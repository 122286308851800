import React from "react";
import { Link } from "react-router-dom";

interface ViewToggleHeaderProps {
  title: string;
  isKanbanView: boolean;
  toggleView: () => void;
  createLink: string;
  createButtonText?: string;
  isDarkMode?: boolean;
}

export const ViewToggleHeader: React.FC<ViewToggleHeaderProps> = ({
  title,
  isKanbanView,
  toggleView,
  createLink,
  createButtonText = "Create",
  isDarkMode = false,
}) => {
  return (
    <div className="tw-card-header tw-border-0 tw-mb-4">
      <div className="tw-flex tw-items-center">
        <h5 className="tw-card-title tw-mb-0 tw-flex-grow-1 tw-mr-2 tw-px-1">
          {title}
        </h5>
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          <button
            onClick={toggleView}
            className={`tw-px-4 tw-py-2 tw-rounded-lg tw-border ${
              isDarkMode
                ? "tw-border-gray-600 tw-text-gray-200 hover:tw-bg-gray-700"
                : "tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50"
            } tw-transition-colors tw-duration-200`}
          >
            {isKanbanView ? (
              <span className="tw-flex tw-items-center tw-gap-2">
                <i className="ri-list-check"></i>
                List View
              </span>
            ) : (
              <span className="tw-flex tw-items-center tw-gap-2">
                <i className="ri-layout-grid-fill"></i>
                Kanban View
              </span>
            )}
          </button>
          <Link
            to={createLink}
            className="tw-px-4 tw-py-2 tw-rounded-lg tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 tw-transition-colors tw-duration-200"
          >
            <i className="ri-add-line tw-align-bottom tw-mr-1"></i>
            {createButtonText}
          </Link>
        </div>
      </div>
    </div>
  );
};
