import http from "../../../services/general/httpService";
import config from "../../../constants/config";

//portfolio_ownership/create_or_update_partnership_and_ownership
export async function createOrUpdatePartnershipAndOwnership(ownership) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/portfolio_ownership/create_or_update_partnership_and_ownership`;
  const { data: result } = await http.post(fullApiEndpoint, ownership);

  return result;
}

//show the owners of a property by using the owenership_id

//show the owners of a portfolio by using the owenership_id

//show the owners of all the portfolio by using the user_id to filter for who they have authorization to view
//get_properties_owned_by_user_id
export async function getPropertiesOwnedByUserId() {
  const user_id = localStorage.getItem(config.user_id);

  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio_ownership/get_properties_owned_by_user_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

//get_ownership_structures_by_portfolio_id
export async function getOwnershipStructuresByPortfolioId(portfolio_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/portfolio_ownership/get_ownership_structures_by_portfolio_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    portfolio_id: portfolio_id,
  });

  return result;
}

//get_the_rented_vs_vacant_units_by_ownership
export async function getTheRentedVsVacantUnitsByOwnership() {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/portfolio_ownership/get_the_rented_vs_vacant_units_by_ownership`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

//get_the_rented_vs_vacant_units_by_property_id
export async function getTheRentedVsVacantUnitsByPropertyId(property_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/portfolio_ownership/get_the_rented_vs_vacant_units_by_property_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_id: property_id,
  });

  return result;
}
