import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import Input from "../../../reusable/form/Input";
import {
  createContractor,
  getContractorById,
  updateContractor,
} from "../../../../services/contractor/contractorManagement";
import { getPortfolioByOwnershipId } from "../../../../services/portfolio/portfolioManagement";
import config from "../../../../constants/config";
import { fetchSubPropertyTypesByPropertyTypeId } from "../../../../services/systemDefined/property/sysPropertyTypeManagement";
import { fetchPropertyByPortfolioId } from "../../../../services/property/retrieve/propertyRetrievalService";
import { getCode } from "country-list";
import {
  createNewUserDefinedCountry,
  fetchAllCountries,
} from "../../../../services/systemDefined/country/countryManagement";
import { getPaymentRateFrequencies } from "../../../../services/systemDefined/payment/sysPaymentRateFrequencyManager";
import {
  getPaymentRateMethodById,
  getPaymentRateMethods,
} from "../../../../services/systemDefined/payment/sysPaymentRateMethodManager";
import { selectCustomStyle } from "../../../../styletheme/custom/selectStyle";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";

const UpdateContractorProfile = ({ isDarkMode }) => {
  //wont be a param so i need to show the portfolio and property to assign the contractor, the property is optional which means they cover the entire portfolio
  //TODO: next add the new fields like payment etc to the create form then move on to showing the list of contractors -> assign -> tenants
  const pageTitle = "Update Contractor";
  usePageTitle({ title: pageTitle });
  const { contractorId } = useParams();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [contractor, setContractor] = useState({
    contractor_id: uuidv4(),
    portfolio_id: "",
    property_id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    company_name: "",
    company_address: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zip: "",
    company_country: "",
    company_logo: "",
    company_website: "",
    company_phone: "",
    company_email: "",
    company_description: "",
    company_license: "",
    company_insurance: "",
    company_insurance_expiration: new Date(),
    company_first_licensure_date: new Date(),
    company_license_expiration: new Date(),
    company_license_status: "", //nt used
    company_tax_rate: 0,
    company_tax_type: "", //not used
    payment_rate_type: "", //not used
    payment_rate_other: "", //not used
    payment_rate: 0, //not used need to use the payment rate type
    payment_rate_frequency: "",
    company_tax_id: "",
    payment_rate_method: "",
    created_at: new Date(),
    updated_at: new Date(),
    is_active: true,
    is_verified: false,
    is_deleted: false,
    is_suspended: false,
    is_paused: false,
    job_count: 0,
    job_count_completed: 0,
    company_license_state: "",
  });

  const schema = {
    contractor_id: Joi.string(),
    portfolio_id: Joi.string().required().label("Portfolio"),
    property_id: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Property"),
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    email_address: Joi.string()
      .email()
      .optional()
      .allow("")
      .allow(null)
      .label("Email Address"),
    phone_number: Joi.string().required().label("Phone Number"),
    company_name: Joi.string().required().label("Company Name"),
    company_address: Joi.string().required().label("Company Address"),
    company_address_2: Joi.string().allow("").label("Company Address 2"),
    company_city: Joi.string().required().label("Company City"),
    company_state: Joi.string().required().label("Company State"),
    company_zip: Joi.string().required().label("Company Zip"),
    company_country: Joi.string().required().label("Company Country"),
    company_logo: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Logo"),
    company_website: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Website"),
    company_phone: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Phone"),
    company_email: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Email"),
    company_description: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Description"),
    company_license: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company License"),
    company_insurance: Joi.string()
      .optional()
      .allow("")
      .label("Company Insurance"),
    company_insurance_expiration: Joi.date().label(
      "Company Insurance Expiration"
    ),
    company_first_licensure_date: Joi.date().label(
      "Company First Licensure Date"
    ),
    company_license_expiration: Joi.date().label("Company License Expiration"),
    company_license_status: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company License Status"),
    company_tax_rate: Joi.number()
      .optional()
      .allow(null)
      .allow("")
      .label("Company Tax Rate"),
    company_tax_type: Joi.string()
      .optional()
      .allow(null)
      .allow("")
      .label("Company Tax Type"),
    payment_rate_type: Joi.string()
      .optional()
      .allow(null)
      .allow("")
      .label("Payment Rate Type"),
    payment_rate_other: Joi.string()
      .optional()
      .allow(null)
      .allow("")
      .label("Payment Rate Other"),
    payment_rate: Joi.number()
      .optional()
      .allow("")
      .allow(null)
      .label("Payment Rate"),
    payment_rate_frequency: Joi.string()
      .optional()
      .allow("")
      .label("Payment Rate Frequency"),
    company_tax_id: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company Tax ID"),
    payment_rate_method: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Payment Rate Method"),
    created_at: Joi.date().label("Created At"),
    updated_at: Joi.date().label("Updated At"),
    is_active: Joi.boolean().label("Is Active"),
    is_verified: Joi.boolean().label("Is Verified"),
    is_deleted: Joi.boolean().label("Is Deleted"),
    is_suspended: Joi.boolean().label("Is Suspended"),
    is_paused: Joi.boolean().label("Is Paused"),
    job_count: Joi.number().label("Job Count"),
    job_count_completed: Joi.number().label("Job Count Completed"),
    company_license_state: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label("Company License State"),
  };

  //payment rate frequency
  const [paymentRateFrequencyOptions, setPaymentRateFrequencyOptions] =
    useState([{ value: "", label: "", data: {} }]);

  //payment rate method
  const [paymentRateMethodOptions, setPaymentRateMethodOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //payment rate frequency

  const [paymentRateFrequencies, setPaymentRateFrequencies] = useState([
    {
      payment_rate_frequency_id: "",
      payment_rate_frequency: "",
      is_user_defined: "",
      created_at: "",
      updated_at: "",
      created_by_user_id: "",
    },
  ]);
  //payment rate method
  const [paymentRateMethods, setPaymentRateMethods] = useState([
    {
      payment_rate_method_id: "",
      payment_rate_method: "",
      is_user_defined: "",
      created_at: "",
      updated_at: "",
      created_by_user_id: "",
    },
  ]);

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(contractor, schema, options);

    console.log("Error:", error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    console.log("Schema:", schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    return error ? error.details[0].message : null;
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    }
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractor };
    if (value !== undefined) {
      data[name] = value;
    }

    setContractor(data);
    setErrors(currentErrors);
  };
  //company_first_licensure_date handle change
  const handleCompanyFirstLicensureDateChange = (date) => {
    const currentErrors = { ...errors };
    const name = "company_first_licensure_date";
    const value = date;
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractor };
    if (value !== undefined) {
      data[name] = value;
    }

    setContractor(data);

    setErrors(currentErrors);
  };

  //handle change for company_license_expiration
  const handleCompanyLicenseExpirationChange = (date) => {
    const currentErrors = { ...errors };
    const name = "company_license_expiration";
    const value = date;
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractor };

    if (value !== undefined) {
      data[name] = value;
    }

    setContractor(data);

    setErrors(currentErrors);
  };

  //handle change for company_insurance_expiration
  const handleCompanyInsuranceExpirationChange = (date) => {
    const currentErrors = { ...errors };
    const name = "company_insurance_expiration";
    const value = date;
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractor };

    if (value !== undefined) {
      data[name] = value;
    }

    setContractor(data);

    setErrors(currentErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      console.log("Contractor:", contractor);

      const response = await updateContractor(contractor);
      console.log(response);
      if (response.is_error) {
        toast.error(response.server_message);
        return;
      }

      toast.success("Contractor has been successfully created!");
      navigate(`/contractor/${contractor.contractor_id}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("Error:", ex.response.data); //need to log this
        const currentErrors = { ...errors };
        currentErrors.email_address = ex.response.data;
        setErrors(currentErrors);
      }
    }
  };

  //dropdown list for the portfolio
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const handlePortfolioChange = (option) => {
    if (!option) return;
    console.log("Portfolio changed:", option);
    // 'option' is the selected option from the CreatableSelect
    setContractor((prevState) => ({
      ...prevState,
      portfolio_id: option.value,
      portfolio_name: option.label,
    }));
    setSelectedPortfolioOption(option);
    setSelectedPortfolioOption(option);
    //get all the properties for the portfolio
    getPropertiesByPortfolioId(option.value);
  };

  const getExistingPortfolios = async (_portfolio_id, _property_id) => {
    //set existingPortfolio

    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log("No portfolios found for this user");
      return;
    }

    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));
    setExistingPortfolioOptions(options);

    console.log("Portfolios:", options);

    //set the selected portfolio where equal to _portfolio_id
    if (_portfolio_id) {
      const selectedPortfolio = options.find(
        (portfolio) => portfolio.value === _portfolio_id
      );
      setSelectedPortfolioOption(selectedPortfolio);
      console.log("Selected Portfolio:", selectedPortfolio);
      //get the properties for the selected portfolio
      getPropertiesByPortfolioId(selectedPortfolio.value, _property_id);
    }
  };

  //get properties section
  const [existingPropertiesOptions, setExistingPropertiesOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //selected portfolio options
  const [selectedPropertyOption, setSelectedPropertyOption] = useState(null);

  const getPropertiesByPortfolioId = async (portfolioId, _property_id) => {
    //get all the properties for the portfolio
    const response = await fetchPropertyByPortfolioId(portfolioId);

    if (!response) {
      console.log("No properties found for this portfolio");
      return;
    }

    //clear the selected property
    setSelectedPropertyOption(null);
    // contractor.property_id = '';

    console.log("Properties:", response);
    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((property) => ({
      value: property.property_id,
      label: property.property_alias_name,
      data: property,
    }));
    setExistingPropertiesOptions(options);

    console.log("Properties:", options);

    //set the selected portfolio where equal to _portfolio_id
    //only set the property if it was previously set
    if (portfolioId && options.length > 0) {
      //dont set selected property if _property_id is not set
      if (!_property_id) {
        return;
      }

      console.log("Property Id before setting selected:", _property_id);

      const selectedProperty = options.find(
        (property) => property?.data?.portfolio_id === portfolioId
      );
      setSelectedPropertyOption(selectedProperty);
      console.log("Selected Property:", selectedProperty);
    }
  };

  const handlePropertyChange = (option) => {
    if (!option) {
      console.log("Cleared pressed");
      setSelectedPropertyOption(null);
      contractor.property_id = "";
      return;
    }
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log("Property changed:", option);
    // You can update the state or perform other side effects here
    contractor.property_id = option.value;

    //set the selected portfolio
    setSelectedPropertyOption(option);
  };

  //country section
  //need to make this control reusable throughout the application
  //country options
  const [countryOptions, setCountryOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  const formattedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: option.data.is_user_defined
      ? `${option.label} (User Defined)`
      : option.label,
  }));

  const handleCountryChange = async (option) => {
    //ensure it is not empty
    if (!option) return;

    setSelectedCountryOption(option);
    contractor.company_country = option.label;

    if (option.__isNew__) {
      console.log("User created a new option with value:", option.value);
      //call the api to create the country and tag it as user defined

      //then update the countryOptions
      //createNewUserDefinedCountry
      const countryCode = getCode(option.value);
      if (countryCode) {
        createCountry(option, countryCode);
      } else {
        console.log(
          "Could not find the country code for the specified country. Saving without country code."
        );
        createCountry(option, "");
      }
    } else {
      console.log("User selected the option:", option.value);
    }
  };

  //handleChangePaymentMethod
  const handleChangePaymentMethod = (option) => {
    if (!option) return;
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log("Payment Method changed:", option.value);
    // You can update the state or perform other side effects here
    contractor.payment_rate_method = option.value;

    //update
    setSelectedPaymentRateMethodOption(option);
  };
  //setSelectedPaymentRateMethodOption
  const [selectedPaymentRateMethodOption, setSelectedPaymentRateMethodOption] =
    useState(null);

  //handleChangePaymentFrequency
  const handleChangePaymentFrequency = (option) => {
    if (!option) return;
    // This function will be called whenever the portfolio changes
    // 'option' is the selected option from the CreatableSelect
    console.log("Payment Frequency changed:", option.value);
    // You can update the state or perform other side effects here
    contractor.payment_rate_frequency = option.value;
    console.log(
      typeof contractor.payment_rate_frequency,
      typeof contractor.payment_rate_method
    );
    //update
    setSelectedPaymentRateFrequencyOption(option);
  };
  //setSelectedPaymentRateFrequencyOption
  const [
    selectedPaymentRateFrequencyOption,
    setSelectedPaymentRateFrequencyOption,
  ] = useState(null);

  async function createCountry(option, countryCode) {
    const userId = localStorage.getItem(config.user_id);
    const countryName = option.value;

    var country = {
      country_id: uuidv4(),
      country_name: countryName,
      country_code: countryCode, // This will be undefined if the country code is not found
      is_user_defined: true,
      created_at: new Date(),
      updated_at: new Date(),
      created_by_user_id: userId,
    };

    console.log("Country:", country);

    const updatedCountryList = await createNewUserDefinedCountry(country);

    // If the country was created successfully
    if (updatedCountryList) {
      // Update the countryOptions
      const options = updatedCountryList.map((country) => ({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      }));
      setCountryOptions(options);

      // Select this new country
      setSelectedCountryOption({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      });

      toast.success("New country saved successfully");
    } else {
      // Handle the case where the country could not be saved
      toast.error(
        "New country could not be saved successfully, please try again."
      );
    }
  }

  const getCountryOptions = async (_country_name) => {
    const response = await fetchAllCountries();
    const options = response.map((country) => ({
      value: country.country_id,
      label: country.is_user_defined
        ? `${country.country_name} `
        : country.country_name,
      data: country,
    }));
    setCountryOptions(options);

    //set using the country name if it exists
    if (_country_name) {
      console.log("Countries:", options);

      const selectedCountry = options.find(
        (country) => country.label === _country_name
      );
      setSelectedCountryOption(selectedCountry);
      console.log("Selected Country:", selectedCountry);
    }
  };

  useEffect(() => {
    //first get the contractor profile
    getContractorDetail();

    //
  }, []);

  const getContractorDetail = async () => {
    const result = await getContractorById(contractorId);
    console.log("Contractor Detail:", result);
    setContractor(result);

    getExistingPortfolios(result.portfolio_id, result.property_id);

    getCountryOptions(result.company_country);

    //set other details
    //put in its own method
    console.log("Company Tax Id:", result.company_tax_id);
    if (result.company_tax_id === undefined || result.company_tax_id === null) {
      result.company_tax_id = "";
    }

    if (
      result.company_tax_rate === undefined ||
      result.company_tax_rate === null
    ) {
      result.company_tax_rate = 0;
    }

    //set the dates

    const firstLicensureDate = formatDate(result.company_first_licensure_date);
    const licenseExpirationDate = formatDate(result.company_license_expiration);
    const insuranceExpirationDate = formatDate(
      result.company_insurance_expiration
    );

    const paymentRateFrequency = await fetchPaymentRateFrequencies(result);

    const paymentRateMethod = await fetchPaymentRateMethods(result);

    setContractor({
      ...result,
      payment_rate_frequency: paymentRateFrequency,
      payment_rate_method: paymentRateMethod,
      company_first_licensure_date: firstLicensureDate,
      company_license_expiration: licenseExpirationDate,
      company_insurance_expiration: insuranceExpirationDate,
    });
  };

  // Format dates to YYYY-MM-DD
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  //get the payment rate frequency
  const fetchPaymentRateFrequencies = async (result) => {
    const response = await getPaymentRateFrequencies();
    console.log("Payment Rate Frequencies:", response);

    const options = response.map((paymentRateFrequency) => ({
      value: paymentRateFrequency.payment_rate_frequency,
      label: paymentRateFrequency.payment_rate_frequency,
      data: paymentRateFrequency,
    }));

    setPaymentRateFrequencyOptions(options);
    setPaymentRateFrequencies(response);

    //set the payment frequency

    const paymentRateFrequency = options.find(
      (option) =>
        option?.value?.toLowerCase() ===
        result?.payment_rate_frequency?.toLowerCase()
    );

    setSelectedPaymentRateFrequencyOption(paymentRateFrequency);

    return paymentRateFrequency?.value;
  };

  //get the payment rate method
  const fetchPaymentRateMethods = async (result) => {
    const response = await getPaymentRateMethods();
    console.log("Payment Rate Methods:", response);

    const options = response.map((paymentRateMethod) => ({
      value: paymentRateMethod.payment_rate_method,
      label: paymentRateMethod.payment_rate_method,
      data: paymentRateMethod,
    }));

    setPaymentRateMethodOptions(options);
    setPaymentRateMethods(response);

    //set payment rate method
    const paymentRateMethod = options.find(
      (option) =>
        option?.value?.toLowerCase() ===
        result?.payment_rate_method?.toLowerCase()
    );

    setSelectedPaymentRateMethodOption(paymentRateMethod);

    return paymentRateMethod?.value;
  };

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Contractor Profile"
            previousPageLink={`/contractor/${contractorId}`}
          />
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Update Contractor
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        {/* Select Portfolio cant add a new one*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Portfolio
                            </label>
                            <Select
                              name="portfolio_id"
                              options={existingPortfolioOptions}
                              onChange={handlePortfolioChange}
                              value={selectedPortfolioOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        {/* Select Property*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Property (Optional)
                            </label>
                            <Select
                              name="property_id"
                              options={existingPropertiesOptions}
                              onChange={handlePropertyChange}
                              value={selectedPropertyOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="first_name"
                              label="First Name"
                              type={"text"}
                              value={contractor.first_name}
                              onChange={handleChange}
                              error={errors.first_name}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="last_name"
                              label="Last Name"
                              type={"text"}
                              value={contractor.last_name}
                              onChange={handleChange}
                              error={errors.last_name}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="email_address"
                              label="Email Address"
                              type={"text"}
                              value={contractor.email_address}
                              onChange={handleChange}
                              error={errors.email_address}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="phone_number"
                              label="Phone Number"
                              type={"text"}
                              value={contractor.phone_number}
                              onChange={handleChange}
                              error={errors.phone_number}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_name"
                              label="Company Name"
                              type={"text"}
                              value={contractor.company_name}
                              onChange={handleChange}
                              error={errors.company_name}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_address"
                              label="Company Address"
                              type={"text"}
                              value={contractor.company_address}
                              onChange={handleChange}
                              error={errors.company_address}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_address_2"
                              label="Company Address 2 (Optional)"
                              type={"text"}
                              value={contractor.company_address_2}
                              onChange={handleChange}
                              error={errors.company_address_2}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_city"
                              label="Company City"
                              type={"text"}
                              value={contractor.company_city}
                              onChange={handleChange}
                              error={errors.company_city}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_state"
                              label="Company State/Province/Parish"
                              type={"text"}
                              value={contractor.company_state}
                              onChange={handleChange}
                              error={errors.company_state}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_zip"
                              label="Company Zip/Postal Code"
                              type={"text"}
                              value={contractor.company_zip}
                              onChange={handleChange}
                              error={errors.company_zip}
                            />
                          </div>
                        </div>
                        {/*Use the user defined country dropdownlist */}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            {/*<Input
                              name='company_country'
                              label='Company Country'
                              type={'text'}
                              value={contractor.company_country}
                              onChange={handleChange}
                              error={errors.company_country}
                            />*/}
                            <label htmlFor="form-grid-category">Country</label>
                            <CreatableSelect
                              name="company_country"
                              options={formattedCountryOptions}
                              onChange={handleCountryChange}
                              isClearable
                              value={selectedCountryOption}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        {/* company website*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_website"
                              label="Company Website"
                              type={"text"}
                              value={contractor.company_website}
                              onChange={handleChange}
                              error={errors.company_website}
                            />
                          </div>
                        </div>
                        {/* company phone*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_phone"
                              label="Company Phone"
                              type={"text"}
                              value={contractor.company_phone}
                              onChange={handleChange}
                              error={errors.company_phone}
                            />
                          </div>
                        </div>
                        {/* company email*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_email"
                              label="Company Email"
                              type={"text"}
                              value={contractor.company_email}
                              onChange={handleChange}
                              error={errors.company_email}
                            />
                          </div>
                        </div>
                        {/* company description*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_description"
                              label="Company Description"
                              type={"text"}
                              value={contractor.company_description}
                              onChange={handleChange}
                              error={errors.company_description}
                            />
                          </div>
                        </div>
                        {/* company license*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_license"
                              label="Company License (Optional)"
                              type={"text"}
                              value={contractor.company_license}
                              onChange={handleChange}
                              error={errors.company_license}
                            />
                          </div>
                        </div>
                        {/* company first licensure date*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_first_licensure_date"
                              label="Company First Licensure Date (Optional)"
                              type={"date"}
                              value={contractor.company_first_licensure_date} // Use the state value here
                              onChange={handleCompanyFirstLicensureDateChange}
                              error={errors.company_first_licensure_date}
                            />
                          </div>
                        </div>
                        {/* company license expiration*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_license_expiration"
                              label="Company License Expiration Date (Optional)"
                              type={"date"}
                              value={contractor.company_license_expiration}
                              onChange={handleCompanyLicenseExpirationChange}
                              error={errors.company_license_expiration}
                            />
                          </div>
                        </div>
                        {/* company insurance*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_insurance"
                              label="Company Insurance (Optional)"
                              type={"text"}
                              value={contractor.company_insurance}
                              onChange={handleChange}
                              error={errors.company_insurance}
                            />
                          </div>
                        </div>
                        {/* company insurance expiration*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="company_insurance_expiration"
                              label="Company Insurance Expiration Date (Optional)"
                              type={"date"}
                              value={contractor.company_insurance_expiration}
                              onChange={handleCompanyInsuranceExpirationChange}
                              error={errors.company_insurance_expiration}
                            />
                          </div>
                        </div>
                        {/* payment method rate etc */}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="payment_rate"
                              label="Payment Rate"
                              type={"number"}
                              value={contractor.payment_rate}
                              onChange={handleChange}
                              error={errors.payment_rate}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Payment Rate Frequency
                            </label>
                            <Select
                              name="payment_rate_frequency"
                              options={paymentRateFrequencyOptions}
                              onChange={handleChangePaymentFrequency}
                              value={selectedPaymentRateFrequencyOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        {/* payment method*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="form-grid-category">
                              Payment Rate Method
                            </label>
                            <Select
                              name="payment_rate_method"
                              options={paymentRateMethodOptions}
                              onChange={handleChangePaymentMethod}
                              value={selectedPaymentRateMethodOption}
                              isClearable
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Add to the list*/}
                      <div className="row gy-4 mt-3">
                        <div className="col-12 d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() =>
                              navigate(`/contractor/${contractorId}`)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={validate()}
                          >
                            Update Contractor
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateContractorProfile;
