import http from "../../../services/general/httpService";
import config from "../../../constants/config";

///sys_payment_rate_frequency/get_payment_rate_frequencies
export async function getPaymentRateFrequencies() {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/sys_payment_rate_frequency/get_payment_rate_frequencies`;
  const { data: result } = await http.post(fullApiEndpoint);

  return result;
}

//get_payment_rate_frequency_by_payment_rate_frequency_id
export async function getPaymentRateFrequencyById(paymentRateFrequencyId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/sys_payment_rate_frequency/get_payment_rate_frequency_by_payment_rate_frequency_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    payment_rate_frequency_id: paymentRateFrequencyId,
  });

  return result;
}
