import { Link } from "react-router-dom";
import { useSettings } from "services/authentication/contexts/settings/settingsContext";
import classNames from "classnames";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
const PageTitleBox = ({
  previousPageTitle = "Pages",
  pageTitle,
  previousPageLink,
  isDarkMode = false,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  return (
    <div className="row px-4 tw-py-8">
      <div className="col-12">
        <div
          className={`page-title-box d-sm-flex align-items-center justify-content-between ${getThemeClasses()}`}
        >
          <h4 className="mb-sm-0">{pageTitle}</h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={previousPageLink}>{previousPageTitle}</Link>
              </li>
              <li className="breadcrumb-item active">{pageTitle}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBox;

{
  /*
const PageTitleBox = ({
  previousPageTitle = "Pages",
  pageTitle,
  previousPageLink,
}) => {
  const { settings } = useSettings();

  return (
    <div className="tw-py-4">
      <div
        className={classNames(
          "tw-px-6 tw-py-3",
          "tw-border-b",
          settings.isDarkMode
            ? "tw-bg-[#0f172a] tw-border-gray-800"
            : "tw-bg-white tw-border-gray-200",
          "-tw-mt-[1px]" // Remove gap between navbar and title box
        )}
      >
        <div className="tw-flex tw-items-center tw-justify-between">
          <h4
            className={classNames(
              "tw-m-0 tw-text-base tw-font-medium",
              settings.isDarkMode ? "tw-text-white" : "tw-text-gray-700"
            )}
          >
            {pageTitle}
          </h4>

          <div className="tw-flex tw-items-center">
            <ol className="tw-flex tw-items-center tw-space-x-2">
              <li>
                <Link
                  to={previousPageLink}
                  className={classNames(
                    "tw-text-sm",
                    settings.isDarkMode
                      ? "tw-text-gray-400 hover:tw-text-gray-300"
                      : "tw-text-gray-500 hover:tw-text-gray-700"
                  )}
                >
                  {previousPageTitle}
                </Link>
              </li>
              <li
                className={
                  settings.isDarkMode ? "tw-text-gray-600" : "tw-text-gray-400"
                }
              >
                <span className="tw-mx-2">/</span>
              </li>
              <li
                className={classNames(
                  "tw-text-sm",
                  settings.isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
                )}
              >
                {pageTitle}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBox;
*/
}
