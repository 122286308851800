import React from "react";

interface LoadingSpinnerProps {
  isDarkMode: boolean;
  size?: "small" | "medium" | "large";
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  isDarkMode,
  size = "medium",
}) => {
  const sizeClasses = {
    small: "tw-w-4 tw-h-4",
    medium: "tw-w-8 tw-h-8",
    large: "tw-w-12 tw-h-12",
  };

  return (
    <div className="tw-flex tw-justify-center tw-items-center">
      <div
        className={`${
          sizeClasses[size]
        } tw-border-2 tw-rounded-full tw-animate-spin ${
          isDarkMode
            ? "tw-border-gray-600 tw-border-t-gray-300"
            : "tw-border-gray-200 tw-border-t-gray-600"
        }`}
      />
    </div>
  );
};
