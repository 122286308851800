import React, { useState, useEffect } from "react";

const CurrencyInput = ({
  name,
  label,
  value,
  onChange,
  placeholder = "Enter amount",
  error,
  labelClass = "form-label",
  className = "form-control",
  isDisabled = false,
  showCurrencySymbol = true,
  helpText,
}) => {
  // State to handle the formatted display value
  const [displayValue, setDisplayValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  // Format number with commas and decimals
  const formatNumber = (num) => {
    if (!num && num !== 0) return "";
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  // Parse formatted string back to number
  const parseFormattedNumber = (str) => {
    if (!str) return "";
    return parseFloat(str.replace(/,/g, ""));
  };

  // Update display value when the actual value changes
  useEffect(() => {
    if (!isFocused) {
      setDisplayValue(formatNumber(value));
    }
  }, [value, isFocused]);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Remove all non-numeric characters except decimal point
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point
    const parts = numericValue.split(".");
    const sanitizedValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

    // Convert to number if valid
    const numberValue = sanitizedValue ? parseFloat(sanitizedValue) : "";

    // Update the display value with the raw input during focus
    setDisplayValue(sanitizedValue);

    // Call parent's onChange with the numeric value
    onChange({
      ...event,
      target: {
        ...event.target,
        value: numberValue,
        name: name,
      },
    });
  };

  const handleFocus = (event) => {
    setIsFocused(true);
    // Show unformatted value when focused
    const numericValue = parseFormattedNumber(displayValue);
    setDisplayValue(numericValue === 0 ? "" : String(numericValue));
    if (event.target.value === "0") {
      event.target.value = "";
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Format the value when input loses focus
    if (value || value === 0) {
      setDisplayValue(formatNumber(value));
    }
  };

  return (
    <div className="form-group">
      <label className={labelClass} htmlFor={name}>
        {label}
        {helpText && <small className="text-muted ms-1">({helpText})</small>}
      </label>

      <div className="input-group">
        {showCurrencySymbol && <span className="input-group-text">$</span>}
        <input
          value={displayValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          id={name}
          name={name}
          type="text"
          className={className}
          disabled={isDisabled}
          inputMode="decimal"
          autoComplete="off"
        />
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default CurrencyInput;
