import React, { useState, useMemo } from "react";
import { FiSearch } from "react-icons/fi";
import { Column, TableProps } from "./types/TableTypes";
import { Pagination } from "./Pagination";

export function DataTable<T extends { [key: string]: any }>({
  data,
  columns,
  isDarkMode = false,
  onRowClick,
  searchPlaceholder = "Search...",
  searchFields = [],
  filterOptions = [],
  noDataMessage = "No data found",
  headerActions,
  pagination,
  showPagination = true,
}: TableProps<T>) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<Record<string, string>>({});

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      // Search filter
      const matchesSearch =
        searchTerm === "" ||
        searchFields.some((field) => {
          const value = item[field];
          return value
            ?.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        });

      // Custom filters
      const matchesFilters = Object.entries(filters).every(([field, value]) => {
        if (value === "all") return true;
        return item[field]?.toString() === value;
      });

      return matchesSearch && matchesFilters;
    });
  }, [data, searchTerm, filters, searchFields]);

  // Calculate paginated data
  const paginatedData = useMemo(() => {
    if (!pagination) return filteredData;

    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, pagination]);

  return (
    <div
      className={`tw-w-full tw-rounded-lg tw-shadow-lg ${
        isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
      }`}
    >
      {/* Header Section */}
      <div
        className={`tw-p-4 tw-border-b ${
          isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
        }`}
      >
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-gap-4">
          {/* Search */}
          {searchFields.length > 0 && (
            <div className="tw-relative tw-w-full lg:tw-w-1/3">
              <input
                type="text"
                placeholder={searchPlaceholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-rounded-lg tw-border ${
                  isDarkMode
                    ? "tw-bg-gray-700 tw-border-gray-600 tw-text-white tw-placeholder-gray-400"
                    : "tw-bg-white tw-border-gray-300 tw-text-gray-900"
                } focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent`}
              />
              <FiSearch
                className={`tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2 ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                }`}
              />
            </div>
          )}

          {/* Filters */}
          <div className="tw-flex tw-gap-4 tw-w-full lg:tw-w-auto tw-justify-end">
            {filterOptions.map((filter) => (
              <select
                key={filter.field.toString()}
                value={filters[filter.field.toString()] || "all"}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    [filter.field]: e.target.value,
                  }))
                }
                className={`tw-px-4 tw-py-2 tw-rounded-lg tw-border ${
                  isDarkMode
                    ? "tw-bg-gray-700 tw-border-gray-600 tw-text-white"
                    : "tw-bg-white tw-border-gray-300 tw-text-gray-900"
                } focus:tw-ring-2 focus:tw-ring-blue-500`}
              >
                {filter.options.map((option) => (
                  <option
                    key={option.value.toString()}
                    value={option.value.toString()}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            ))}
            {headerActions}
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="tw-overflow-x-auto tw-w-full">
        <table className="tw-w-full tw-table-fixed tw-text-sm">
          {/* Table header */}
          <thead
            className={`${isDarkMode ? "tw-bg-gray-800" : "tw-bg-gray-50"}`}
          >
            <tr>
              {columns.map((column) => (
                <th
                  key={column.field.toString()}
                  className={`tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider ${
                    isDarkMode ? "tw-text-gray-200" : "tw-text-gray-500"
                  }`}
                  style={{ width: `${(column.flex || 1) * 100}px` }}
                >
                  {column.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className={`tw-divide-y ${
              isDarkMode ? "tw-divide-gray-700" : "tw-divide-gray-200"
            }`}
          >
            {paginatedData.length > 0 ? (
              paginatedData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => onRowClick?.(row)}
                  className={`hover:${
                    isDarkMode ? "tw-bg-gray-700/50" : "tw-bg-gray-50"
                  } ${onRowClick ? "tw-cursor-pointer" : ""}`}
                >
                  {columns.map((column) => (
                    <td
                      key={column.field.toString()}
                      className={`tw-px-6 tw-py-4 ${
                        isDarkMode ? "tw-text-gray-200" : "tw-text-gray-900"
                      }`}
                      style={{
                        width: `${(column.flex || 1) * 100}px`,
                        maxWidth: `${(column.flex || 1) * 100}px`,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace:
                          column.field === "ticket_description"
                            ? "normal"
                            : "nowrap",
                      }}
                    >
                      {column.renderCell
                        ? column.renderCell(row)
                        : String(row[column.field])}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className={`tw-px-6 tw-py-8 tw-text-center ${
                    isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                  }`}
                >
                  {noDataMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {showPagination && pagination && (
        <Pagination {...pagination} isDarkMode={isDarkMode} />
      )}
    </div>
  );
}
