import http from "../../general/httpService";
import config from "../../../constants/config";

///portfolio/get_property_by_property_id is a post
export async function getPropertyByPropertyId(propertyId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio/get_property_by_property_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_id: propertyId,
  });

  return result;
}

export async function getPropertyUnitsById(propertyId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio/get_property_units_by_property_id`;
  const { data: results } = await http.post(fullApiEndpoint, {
    property_id: propertyId,
  });

  return results;
}

export async function getPropertyUnitById(propertyUnitId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/portfolio/get_property_unit_by_property_unit_id/${propertyUnitId}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_property_by_portfolio_id
export async function fetchPropertyByPortfolioId(portfolioId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio/get_property_by_portfolio_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    portfolio_id: portfolioId,
  });

  return result;
}

//get_property_detail_by_property_unit_id
export async function getPropertyDetailByPropertyUnitId(propertyUnitId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio/get_property_detail_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: propertyUnitId,
  });

  return result;
}
