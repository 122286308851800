import http from "../../../../services/general/httpService";
import config from "../../../../constants/config";

//tenant_rental_contract/create_tenant

export async function createTenant(data) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_rental_contract/create_tenant`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//create_rental_contract
export async function createRentalContract(data) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_rental_contract/create_rental_contract`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//update_rental_contract_with_landlord_signature
export async function updateRentalContractWithLandlordSignature(data) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/update_rental_contract_with_landlord_signature`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//create_rental_contract_and_send_email
export async function createRentalContractAndSendEmail(data) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/create_rental_contract_and_send_email`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//get_tenant_by_id
export async function getTenantById(tenant_id) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_rental_contract/get_tenant_by_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    tenant_id: tenant_id,
  });

  return result;
}

//get_rental_contract_by_id
export async function getRentalContractById(rental_contract_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_rental_contract_by_id/${rental_contract_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//TODAY: finish signing, confirm payment, start contract, sending ticket request by tenant, generate rental receipt
//get_latest_rental_contract_by_landlord_id/{landlord_id}
//get request to get the latest rental contract by landlord id
//this may need to be modified when we handle multiple landlords
export async function getLatestRentalContractByLandlordId() {
  const landlord_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_latest_rental_contract_by_landlord_id/${landlord_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_rental_contract_signature_by_id
export async function getRentalContractSignatureById(
  rental_contract_signature_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_rental_contract_signature_by_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    rental_contract_signature_id: rental_contract_signature_id,
  });

  return result;
}

//get_rental_contract_signatures_by_rental_contract_id
export async function getRentalContractSignaturesByRentalContractId(
  rental_contract_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_rental_contract_signatures_by_rental_contract_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    rental_contract_id: rental_contract_id,
  });

  return result;
}

//get_rental_contracts_for_pending_contracts_by_unit_id
export async function getRentalContractsForPendingContractsByUnitId(
  property_unit_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_contract/get_rental_contracts_for_pending_contracts_by_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}
