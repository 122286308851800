import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridRowParams,
} from "@mui/x-data-grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Transaction, CurrencyCode } from "../types/transactionTypes";
import ShimmerEffect from "../../../../../reusable/loading/ShimmerEffect";
import { LinearProgress } from "@mui/material";

interface CategorizedTransactionsTableProps {
  transactions: Transaction[];
  columns: any[]; // We'll accept any column type since we trust the parent component
  loading?: boolean;
  isDarkMode?: boolean;
  onTransactionUpdate?: (
    newRow: Transaction,
    oldRow: Transaction
  ) => Promise<Transaction>;
}

interface CategorizedFiltersProps {
  onDateChange: (range: string) => void;
  onTypeChange: (type: string) => void;
  onCategoryChange: (category: string) => void;
  onAmountChange: (range: string) => void;
  onClear: () => void;
  categories: { value: string; label: string }[];
}

interface FilteredTransactions {
  transactions: Transaction[];
  dateFilter: string;
  typeFilter: string;
  categoryFilter: string;
  amountFilter: string;
}

const LoadingOverlay: React.FC = () => (
  <LinearProgress sx={{ width: "100%", position: "absolute", top: 0 }} />
);

const ShimmerLoadingState: React.FC<{ isDarkMode?: boolean }> = ({
  isDarkMode = false,
}) => (
  <div className="d-flex flex-column gap-3">
    {[...Array(10)].map((_, index) => (
      <ShimmerEffect
        key={index}
        type="line"
        height="52px"
        width="100%"
        darkMode={isDarkMode}
      />
    ))}
  </div>
);

const CustomGridToolbar = () => {
  return (
    <Box sx={{ p: 2 }}>
      <GridToolbar />
    </Box>
  );
};

const CategorizedFilters: React.FC<CategorizedFiltersProps> = ({
  onDateChange,
  onTypeChange,
  onCategoryChange,
  onAmountChange,
  onClear,
  categories,
}) => {
  return (
    <Box sx={{ mb: 3, pt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          alignItems: { xs: "stretch", sm: "center" },
          mb: 2,
        }}
      >
        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Date Range</InputLabel>
          <Select
            label="Date Range"
            defaultValue="all"
            onChange={(e) => onDateChange(e.target.value)}
          >
            <MenuItem value="all">All Dates</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="quarter">This Quarter</MenuItem>
            <MenuItem value="year">This Year</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Transaction Type</InputLabel>
          <Select
            label="Transaction Type"
            defaultValue="all"
            onChange={(e) => onTypeChange(e.target.value)}
          >
            <MenuItem value="all">All Types</MenuItem>
            <MenuItem value="revenue">Revenue</MenuItem>
            <MenuItem value="expense">Expense</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 200 } }}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            defaultValue="all"
            onChange={(e) => onCategoryChange(e.target.value)}
          >
            <MenuItem value="all">All Categories</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Amount Range</InputLabel>
          <Select
            label="Amount Range"
            defaultValue="all"
            onChange={(e) => onAmountChange(e.target.value)}
          >
            <MenuItem value="all">All Amounts</MenuItem>
            <MenuItem value="0-100">$0 - $100</MenuItem>
            <MenuItem value="100-500">$100 - $500</MenuItem>
            <MenuItem value="500-1000">$500 - $1000</MenuItem>
            <MenuItem value="1000-5000">$1000 - $5000</MenuItem>
            <MenuItem value="5000+">$5000+</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            mt: { xs: 2, sm: 0 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Button variant="outlined" size="small" onClick={onClear} fullWidth>
            Clear Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const CategorizedTransactionsTable: React.FC<
  CategorizedTransactionsTableProps
> = ({
  transactions: initialTransactions,
  columns,
  loading = false,
  isDarkMode = false,
  onTransactionUpdate,
}) => {
  const [dateFilter, setDateFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [amountFilter, setAmountFilter] = useState("all");
  const [filteredTransactions, setFilteredTransactions] =
    useState<Transaction[]>(initialTransactions);

  // Add new loading states
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isProcessingUpdate, setIsProcessingUpdate] = useState(false);
  const navigate = useNavigate();

  // Add effect to handle initial loading state
  useEffect(() => {
    if (loading) {
      setIsTableLoading(true);
    } else {
      // Add a small delay before removing the loading state to ensure smooth transition
      const timer = setTimeout(() => {
        setIsTableLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  // Apply all active filters
  const applyFilters = useCallback(
    (
      transactions: Transaction[],
      {
        dateFilter,
        typeFilter,
        categoryFilter,
        amountFilter,
      }: Omit<FilteredTransactions, "transactions">
    ) => {
      let filtered = [...transactions];

      // Apply date filter
      if (dateFilter !== "all") {
        const today = new Date();
        let startDate: Date | null = null;

        switch (dateFilter) {
          case "today":
            startDate = new Date(today.setHours(0, 0, 0, 0));
            break;
          case "week":
            startDate = new Date(today.setDate(today.getDate() - 7));
            break;
          case "month":
            startDate = new Date(today.setMonth(today.getMonth() - 1));
            break;
          case "quarter":
            startDate = new Date(today.setMonth(today.getMonth() - 3));
            break;
          case "year":
            startDate = new Date(today.setFullYear(today.getFullYear() - 1));
            break;
        }

        if (startDate) {
          filtered = filtered.filter((t) => new Date(t.date) >= startDate!);
        }
      }

      // Apply type filter
      if (typeFilter !== "all") {
        filtered = filtered.filter(
          (t) => t.is_revenue_or_expense === typeFilter
        );
      }

      // Apply category filter
      if (categoryFilter !== "all") {
        filtered = filtered.filter((t) => t.category_id === categoryFilter);
      }

      // Apply amount filter
      if (amountFilter !== "all") {
        filtered = filtered.filter((t) => {
          const amount = Math.abs(t.amount);
          const [min, max] =
            amountFilter === "5000+"
              ? [5000, Infinity]
              : amountFilter.split("-").map(Number);
          return amount >= min && amount <= max;
        });
      }

      return filtered;
    },
    []
  );

  // Update filtered transactions when filters or initial transactions change
  useEffect(() => {
    setIsTableLoading(true);
    try {
      const newFilteredTransactions = applyFilters(initialTransactions, {
        dateFilter,
        typeFilter,
        categoryFilter,
        amountFilter,
      });
      setFilteredTransactions(newFilteredTransactions);
    } finally {
      // Add a small delay before removing the loading state
      setTimeout(() => {
        setIsTableLoading(false);
      }, 300);
    }
  }, [
    initialTransactions,
    dateFilter,
    typeFilter,
    categoryFilter,
    amountFilter,
    applyFilters,
  ]);

  // Update filter handlers to use loading states
  const handleDateChange = (range: string) => {
    setIsFiltersLoading(true);
    try {
      setDateFilter(range);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleTypeChange = (type: string) => {
    setIsFiltersLoading(true);
    try {
      setTypeFilter(type);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleCategoryChange = (category: string) => {
    setIsFiltersLoading(true);
    try {
      setCategoryFilter(category);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleAmountChange = (range: string) => {
    setIsFiltersLoading(true);
    try {
      setAmountFilter(range);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const clearFilters = () => {
    setIsFiltersLoading(true);
    try {
      setDateFilter("all");
      setTypeFilter("all");
      setCategoryFilter("all");
      setAmountFilter("all");
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleRowClick = (params: GridRowParams<Transaction>) => {
    if (params.row.is_revenue_or_expense === "expense") {
      navigate(`/expenseDetail/${params.row.unit_revenue_expense_id}`);
    } else {
      navigate(`/revenueDetail/${params.row.unit_revenue_expense_id}`);
    }
  };

  // Update row processing with loading state
  const handleProcessRowUpdate = async (
    newRow: Transaction,
    oldRow: Transaction
  ) => {
    setIsProcessingUpdate(true);
    try {
      if (onTransactionUpdate) {
        return await onTransactionUpdate(newRow, oldRow);
      }
      return newRow;
    } catch (error) {
      toast.error("Failed to update transaction");
      return oldRow;
    } finally {
      setIsProcessingUpdate(false);
    }
  };

  const handleProcessRowUpdateError = (error: Error): void => {
    toast.error(error.message);
  };

  const getCellClassName = (params: GridCellParams): string => {
    if (params.field === "amount" && typeof params.value === "number") {
      return params.value < 0 ? "text-danger" : "text-success";
    }
    return "";
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {isFiltersLoading ? (
          <Box sx={{ p: 2 }}>
            <LinearProgress />
          </Box>
        ) : (
          <CategorizedFilters
            onDateChange={handleDateChange}
            onTypeChange={handleTypeChange}
            onCategoryChange={handleCategoryChange}
            onAmountChange={handleAmountChange}
            onClear={clearFilters}
            categories={[]}
          />
        )}

        {loading ? (
          <ShimmerLoadingState isDarkMode={isDarkMode} />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 120px)",
              minHeight: 400,
              position: "relative",
            }}
          >
            <DataGrid
              rows={filteredTransactions}
              columns={columns}
              getRowId={(row) => row.unit_revenue_expense_id}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25 },
                },
                sorting: {
                  sortModel: [{ field: "date", sort: "desc" }],
                },
              }}
              slots={{
                toolbar: CustomGridToolbar,
                loadingOverlay: LoadingOverlay,
              }}
              loading={isTableLoading || isProcessingUpdate}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              sx={{
                flexGrow: 1,
                width: "100%",
                height: "100%",
                "& .MuiDataGrid-root": {
                  minWidth: { xs: "800px", md: "100%" },
                },
                "& .MuiDataGrid-cell": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                },
                "& .MuiDataGrid-main": {
                  width: "100%",
                },
                "& .MuiDataGrid-virtualScroller": {
                  width: "100%",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid rgba(224, 224, 224, 0.2)",
                },
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "1px solid rgba(224, 224, 224, 0.2)",
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              processRowUpdate={handleProcessRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              onRowClick={handleRowClick}
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default CategorizedTransactionsTable;
