import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import PageTitleBox from "../../../../../../reusable/title/pageTitleBox";

import Input from "../../../../../../reusable/form/Input";

import { getPortfolioByOwnershipId } from "../../../../../../../services/portfolio/portfolioManagement";
import config from "../../../../../../../constants/config";
import { fetchSubPropertyTypesByPropertyTypeId } from "../../../../../../../services/systemDefined/property/sysPropertyTypeManagement";
import { fetchPropertyByPortfolioId } from "../../../../../../../services/property/retrieve/propertyRetrievalService";
import "simplebar"; // for the side effects
import "simplebar/dist/simplebar.min.css";
import HTMLEditor from "../../../../../../reusable/form/ckeditor/cKEditorComponent";
import {
  createOrUpdateUserRentalContractTemplate,
  getUserRentalContractTemplateByPortfolioId,
} from "../../../../../../../services/rental_contract/rentalContractService";
import { createRentalContractAndSendEmail } from "../../../../../../../services/rent/tenant/invite/tenantRentalContractService";
import { selectCustomStyle } from "../../../../../../../styletheme/custom/selectStyle";

//Need to work on this: if there is an existing template then it will poplate the form from the dropdown and the user can modify it as needed
//if none is found or the user presses the add or new button it will let them add a new complete detail and give them the option to name
//the template and set is_new_contract to true so it knows it needs to create a new contract (so its reusable)
// then associate it instead of only assoicating and copying
const SetTenantContractForm = ({
  isDarkMode,
  tenantDetail,
  contractDetail,
}) => {
  const pageTitle = "Create New Contract Template";

  const navigate = useNavigate();

  /*
class UserDefinedRentalContractTemplateModel(BaseModel):
    contract_template_id: str
    user_id: str
    portfolio_id: Optional[str]
    property_id: Optional[str]
    contract_name: str
    contract_description: str
    contract_template: str
    created_at: datetime
    updated_at: datetime
    is_active: bool
    is_deleted: bool
  */

  const [existingContractTemplates, setExistingContractTemplates] = useState([
    {
      contract_template_id: "",
      user_id: "",
      portfolio_id: "",
      property_id: "",
      contract_name: "",
      contract_description: "",
      contract_template: "",
      created_at: Date.now(),
      updated_at: Date.now(),
      is_active: true,
      is_deleted: false,
    },
  ]);

  const [existingContractTemplateOptions, setExistingContractTemplateOptions] =
    useState([{ value: "", label: "", data: {} }]);

  const [contractTemplate, setContractTemplate] = useState({
    contract_template_id: uuidv4(),
    user_id: localStorage.getItem(config.user_id),
    portfolio_id: "",
    property_id: "",
    contract_name: "",
    contract_description: "",
    contract_template: "",
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
    is_new_contract: false, //if true it means the contract needs to be created when it goes to the server then referenced
  });

  const schema = {
    contract_template_id: Joi.string().required().label("Contract Template Id"),
    user_id: Joi.string().required().label("User"),
    portfolio_id: Joi.string().allow("").label("Portfolio"),
    property_id: Joi.string().allow("").label("Property"),
    contract_name: Joi.string().required().label("Contract Name"),
    contract_description: Joi.string()
      .optional()
      .allow("")
      .label("Contract Description"),
    contract_template: Joi.string().required().label("Contract Template"),
    created_at: Joi.date().optional().label("Created At"),
    updated_at: Joi.date().optional().label("Updated At"),
    is_active: Joi.boolean().optional().label("Is Active"),
    is_deleted: Joi.boolean().optional().label("Is Deleted"),
    is_new_contract: Joi.boolean().optional().label("Is New Contract"),
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(contractTemplate, schema, options);

    console.log("Error:", error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    // console.log('Name:', name);
    //console.log('Value:', value);
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    console.log("Schema:", schemaLocal);

    const { error } = Joi.validate(obj, schemaLocal, options);

    console.log("Error in  validateProperty:", error);

    return error ? error.details[0].message : null;
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      name = "contract_template";
      value = input;
    }
    //console.log('Name:', name);
    //console.log('Value:', value);
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...contractTemplate };
    if (value !== undefined) {
      data[name] = value;
    }

    setContractTemplate(data);
    setErrors(currentErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      //TODO: need to collect more info like the rent amount etc, initial set it by
      //the unit
      if (contractTemplate.is_new_contract) {
        console.log("Creating new contract template:", contractTemplate);

        const templateResponse = await createOrUpdateUserRentalContractTemplate(
          contractTemplate
        );
        console.log(templateResponse);
        if (templateResponse.is_error) {
          toast.error(templateResponse.server_message);
          return;
        }

        console.log("Contract Template has been successfully created!");

        //first create a new contract template
        console.log("contract next:", contractDetail);
        //set contract_template_id
        contractDetail.contract_template_id =
          templateResponse.contract_template_id;

        console.log(
          "Ensure the template id is set on the contract detail object:",
          contractDetail
        );
        //then create the tenant contract and send them an email

        const tenantContractResponse = await createRentalContractAndSendEmail(
          contractDetail
        );
        console.log(tenantContractResponse);
        if (tenantContractResponse.is_error) {
          toast.error(tenantContractResponse.server_message);

          console.log("Error:", tenantContractResponse);
        }

        toast.success(
          "Contract has been successfully created and sent to the tenant!"
        );

        //need to navigate the the unit profile page
        navigate(`/contractTemplate/${contractTemplate.contract_template_id}`);
      } else if (!contractTemplate.is_new_contract) {
        //need to test and handle this next
        //send the contract to the tenant, since a template already exist
        //alert('Need to implement basically createRentalContractAndSendEmail');
        console.log(
          "Creating tenant contract and send invite:",
          contractDetail
        );

        const tenantContractResponse = await createRentalContractAndSendEmail(
          contractDetail
        );
        console.log(tenantContractResponse);
        if (tenantContractResponse.is_error) {
          toast.error(tenantContractResponse.server_message);

          console.log("Error:", tenantContractResponse);
        }

        toast.success(
          "Contract has been successfully created and sent to the tenant!"
        );

        //need to navigate the the unit profile page
        navigate(`/contractTemplate/${contractTemplate.contract_template_id}`);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("Error:", ex.response.data); //need to log this
        const currentErrors = { ...errors };
        currentErrors.email_address = ex.response.data;
        setErrors(currentErrors);
      }
    }
  };

  //dropdown list for the portfolio
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);

  const handleContractTemplateChange = (option) => {
    if (!option) {
      console.log("Cleared pressed");
      setSelectedPortfolioOption(null);
      contractTemplate.portfolio_id = "";
      return;
    }
    //when changed i should set its properties on the form
    toast.info("Contract Template Selected:", option);
    console.log("Contract Template changed:", option);

    const contract_name = option.data.contract_name;
    const contract_template = option.data.contract_template;
    const contract_description = option.data.contract_description;
    const contract_template_id = option.data.contract_template_id;

    console.log("Contract Name:", contract_name);
    console.log("Contract Template:", contract_template);
    console.log("Contract Description:", contract_description);
    console.log("Contract Template Id:", contract_template_id);

    //set the template
    //setContractTemplate(option.data);
    alert("need to set the contract name etc");
    //set the contract name
    setContractTemplate({
      ...contractTemplate,
      contract_name: contract_name,
      contract_template: contract_template,
      contract_description: contract_description,
      contract_template_id: contract_template_id,
    });
    contractTemplate.contract_name = contract_name;
    contractTemplate.contract_description = contract_description;
    contractTemplate.contract_template_id = contract_template_id;

    console.log("Updated Contract Template:", contractTemplate);
  };

  useEffect(() => {
    // getExistingPortfolios();
    //alert('here');
    console.log("Tenant Detail:", tenantDetail);
    console.log("Contract Detail:", contractDetail);
    const portfolioId = contractDetail.portfolio_id;
    console.log("Portfolio Id:", contractDetail.portfolio_id);
    fetchRentalContractsForPortfolio(
      contractDetail.portfolio_id,
      contractDetail
    );
  }, []);

  //if it cant find a contract then just set the info i can from the contractDetail and let the user create te rest like contract detail
  const fetchRentalContractsForPortfolio = async (
    _portfolioId,
    _contractDetail
  ) => {
    //alert(_portfolioId);
    //get all the rental contracts for the portfolio
    const response = await getUserRentalContractTemplateByPortfolioId(
      _portfolioId
    );
    //alert('Need to test this');
    console.log("Response getting contract templates :", response);

    if (!response) {
      console.log("No rental contracts found for this portfolio");
      toast.info(
        "No rental contracts found for this portfolio. Not to worry you can create it here."
      );
      //set the contract detail
      setContractTemplate({
        ...contractTemplate,
        property_id: _contractDetail.property_id,
        //property_name: _contractDetail.property_name,
        portfolio_id: _contractDetail.portfolio_id,
        //portfolio_name: _contractDetail.portfolio_name,
        //property_unit_id: _contractDetail.property_unit_id, //this is for the rental contract
        //property_unit_name: _contractDetail.property_unit_name,
        is_new_contract: true,
      });

      return;
    }

    if (response.is_error) {
      toast.error(response.server_message);
      console.log("Error:", response);
      return;
    }

    if (response.length === 0 || response[0] === null || !response[0]) {
      toast.info(
        "No rental contracts found for this portfolio. Not to worry you can create it here."
      );
      //set the contract detail
      setContractTemplate({
        ...contractTemplate,
        property_id: _contractDetail.property_id,
        //property_name: _contractDetail.property_name,
        portfolio_id: _contractDetail.portfolio_id,
        //portfolio_name: _contractDetail.portfolio_name,
        //property_unit_id: _contractDetail.property_unit_id, //this is for the rental contract
        //property_unit_name: _contractDetail.property_unit_name,
        is_new_contract: true,
      });

      return;
    }

    //else only set the portfolio info, property and unit info
    console.log("Rental Contracts:", response);
    setExistingContractTemplates(response);
    //set placeholder values for setExistingPortfolioOptions
    if (response && response.length > 0) {
      const options = response.map((contract) => ({
        value: contract.contract_template_id,
        label: contract.contract_name,
        data: contract,
      }));
      setExistingContractTemplateOptions(options);

      console.log("Existing Contracts:", existingContractTemplateOptions);

      //set the contract detail
      setContractTemplate({
        ...contractTemplate,
        property_id: _contractDetail.property_id,
        //property_name: _contractDetail.property_name,
        portfolio_id: _contractDetail.portfolio_id,
        //portfolio_name: _contractDetail.portfolio_name,
        // property_unit_id: _contractDetail.property_unit_id, //this is for the rental contract
      });
    }
  };

  //can select from existing contracts and modify it as needed or create a new one and send invite only or send Invite to sign and create template
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="live-preview">
            <div className="row gy-4">
              {/* Select Portfolio cant add a new one*/}
              {!contractTemplate.is_new_contract && (
                <div className="col-xxl-3 col-md-6">
                  <div>
                    <label htmlFor="form-grid-category">
                      Contract Template
                    </label>
                    <Select
                      name="rental_contract_id"
                      options={existingContractTemplateOptions}
                      onChange={handleContractTemplateChange}
                      isClearable
                      styles={selectCustomStyle(isDarkMode)}
                    />
                  </div>
                </div>
              )}
              {/* portfolio_id*/}
              {contractTemplate.is_new_contract && (
                <div className="col-xxl-3 col-md-6">
                  <Input
                    name="contract_name"
                    label="Contract Name"
                    value={contractTemplate.contract_name}
                    onChange={handleChange}
                    error={errors.contract_name}
                  />
                </div>
              )}
              {/* contract_description*/}
              {contractTemplate.is_new_contract && (
                <div className="col-xxl-3 col-md-6">
                  <Input
                    name="contract_description"
                    label="Contract Description"
                    value={contractTemplate.contract_description}
                    onChange={handleChange}
                    error={errors.contract_description}
                  />
                </div>
              )}

              <div>
                <div className="row g-3 pt-3">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-8 d-flex flex-column">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Contract Template
                        </label>

                        <HTMLEditor
                          handleChange={handleChange}
                          name="contract_template"
                          content={contractTemplate.contract_template}
                          height="400px"
                        />
                      </div>
                      {contractTemplate.contract_template &&
                        contractTemplate.contract_template !== "" && (
                          <div className="col-md-4">
                            <label
                              for="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Preview
                            </label>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  contractTemplate.contract_template.replace(
                                    /<img /g,
                                    '<img style="max-width: 100%; height: auto;" '
                                  ),
                              }}
                              style={{
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                overflow: "auto", // Changed from 'hidden' to 'auto'
                                height: "440px",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Add to the list*/}
              <div className="row gy-4 mt-3">
                <div className="col-xxl-3 col-md-6">
                  {contractTemplate.is_new_contract ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={validate()}
                    >
                      Create & Send Contract
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      //check that the contract has a template
                      disabled={
                        validate() ||
                        !contractTemplate.contract_template ||
                        contractTemplate.contract_template === ""
                      }
                    >
                      Send Invite
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SetTenantContractForm;
