import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { FaEdit, FaTrash } from "react-icons/fa";

import Input from "../../../reusable/form/Input";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import {
  createUniversalList,
  updateUniversalListItems,
} from "../../../../services/task/universalListManagement";
import { getAllCampaignIdForLandlordId } from "../../../../services/propertyUnits/advertisment/advertismentManagement";
import { getPropertyUnitById } from "../../../../services/property/retrieve/propertyRetrievalService";
import config from "../../../../constants/config";
import { selectCustomStyle } from "../../../../styletheme/custom/selectStyle";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";
//need to update thte types of list as well as the campaigns and type of actions to come from the backend
//TODO: dont commit the list items until the user create the list and atleast one task is added
const CreateNewUniversalList = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const user_id = localStorage.getItem(config.user_id);
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Create New Universal List";
  usePageTitle({ title: pageTitle });
  const [listData, setListData] = useState({
    title: "",
    type: "",
    campaigns: [],
    description: "",
  });

  const [taskData, setTaskData] = useState({
    task_name: "",
    task_description: "",
    action_type: "",
  });

  const [tasks, setTasks] = useState([]);

  const [errors, setErrors] = useState({});

  //add to the database in the future
  //general, tenant screening, daily, weekly, monthly, move-in, move-out
  const [listTypes, setListTypes] = useState([
    { value: "general", label: "General" },
    { value: "tenant_screening", label: "Tenant Screening" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "move_in", label: "Move-In" },
    { value: "move_out", label: "Move-Out" },
    { value: "maintenance", label: "Maintenance" },
    { value: "seasonal", label: "Seasonal" },
    { value: "emergency", label: "Emergency" },
    { value: "legal_compliance", label: "Legal Compliance" },
    { value: "financial", label: "Financial" },
  ]);
  //selected list types
  const [selectedListType, setSelectedListType] = useState([]);
  //handle the change of the list types dropdown
  const handleListTypesChange = (selectedOption) => {
    setSelectedListType(selectedOption);
    setListData((prevData) => ({
      ...prevData,
      type: selectedOption.value,
      campaigns:
        selectedOption.value === "tenant_screening" ? prevData.campaigns : [],
    }));

    if (selectedOption.value !== "tenant_screening") {
      setSelectedCampaigns([]);
    }
  };
  const [campaignsOptions, setCampaignsOptions] = useState([
    {
      value: "",
      label: "Select Campaign",
      data: null,
    },
  ]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const [campaigns, setCampaigns] = useState([
    {
      campaign_id: "",
      date_advert_ends: new Date(),
      property_id: null,
      property_name: null,
      property_unit_id: "",
      property_unit: {
        property_unit_id: "",
        property_id: "",
        market_rent_amount: 0,
        number_of_bathrooms: 0,
        number_of_bedrooms: 0,
        square_footage: 0,
        unit_name: "",
      },
    },
  ]);

  const [actionTypes, setActionTypes] = useState([
    { value: "none", label: "None (Information Only)" },
    { value: "schedule_viewing", label: "Schedule Viewing" },
    { value: "complete_application", label: "Complete Application" },
    { value: "upload_document", label: "Upload Document" },
    { value: "schedule_interview", label: "Schedule Interview" },
    { value: "sign_lease", label: "Sign Lease" },
    { value: "submit_payment", label: "Submit Payment" },
    { value: "schedule_move_in", label: "Schedule Move-In" },
    { value: "report_issue", label: "Report Issue" },
    { value: "request_maintenance", label: "Request Maintenance" },
    { value: "update_contact_info", label: "Update Contact Information" },
    { value: "review_policies", label: "Review Policies" },
    { value: "confirm_details", label: "Confirm Details" },
  ]);

  const [editingTaskId, setEditingTaskId] = useState(null);

  const listSchema = {
    title: Joi.string().required().label("Title"),
    type: Joi.string().required().label("Type of List"),
    campaigns: Joi.when("type", {
      is: "tenant_screening",
      then: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Assign to Campaigns"),
      otherwise: Joi.array()
        .items(Joi.string())
        .optional()
        .label("Assign to Campaigns"),
    }),
    description: Joi.string().allow("").optional().label("Description"),
  };

  const taskSchema = {
    task_name: Joi.string().required().label("Task Name"),
    task_description: Joi.string()
      .allow("")
      .optional()
      .label("Task Description"),
    action_type: Joi.string().required().label("Type of Action"),
  };

  //get all the campaigns for the landlord id and map to the dropdown list

  const fetchCampaigns = async () => {
    try {
      const response = await getAllCampaignIdForLandlordId();

      if (response) {
        // Convert datetime strings to Date objects and sort by expiration date
        const formattedCampaigns = response
          .map((campaign) => ({
            ...campaign,
            date_advert_ends: new Date(campaign.date_advert_ends),
          }))
          .sort((a, b) => a.date_advert_ends - b.date_advert_ends);

        setCampaigns(formattedCampaigns);
        setCampaignsOptions(
          formattedCampaigns.map((campaign) => ({
            value: campaign.campaign_id,
            label: `${
              campaign.property_unit.unit_name
            } - Ends ${campaign.date_advert_ends.toLocaleDateString()}`,
            data: campaign,
          }))
        );
        console.log("Campaigns", formattedCampaigns);
        console.log("campaignsOptions", campaignsOptions);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  //handle the change of the campaign dropdown
  const handleCampaignChange = (selectedOptions) => {
    setSelectedCampaigns(selectedOptions);
    setListData({
      ...listData,
      campaigns: selectedOptions.map((option) => option.value),
    });
  };

  useEffect(() => {
    // Fetch list types, campaigns, and action types
    // This is a placeholder. Replace with actual API calls.

    fetchCampaigns();
  }, []);

  useEffect(() => {
    console.log("taskData updated:", taskData);
  }, [taskData]);

  const validateProperty = ({ name, value }, schema) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleListChange = ({ currentTarget: input }) => {
    const errorMessage = validateProperty(input, listSchema);
    const currentErrors = { ...errors };

    if (errorMessage) currentErrors[input.name] = errorMessage;
    else delete currentErrors[input.name];

    setListData({ ...listData, [input.name]: input.value });
    setErrors(currentErrors);
  };

  const handleTaskChange = (input) => {
    const { name, value } = input.target ? input.target : input;
    const errorMessage = validateProperty({ name, value }, taskSchema);
    const currentErrors = { ...errors };

    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    setTaskData((prevTaskData) => ({ ...prevTaskData, [name]: value }));
    setErrors(currentErrors);
  };

  const handleAddTask = (e) => {
    e.preventDefault();
    console.log("handleAddTask called");
    const errors = validateTask();
    setErrors(errors || {});
    if (errors) {
      console.log("Validation errors:", errors);
      // Display errors as toasts
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    const newTask = { ...taskData, id: uuidv4() };
    console.log("New task to be added:", newTask);

    setTasks((prevTasks) => {
      const updatedTasks = [...prevTasks, newTask];
      console.log("Updated tasks after adding:", updatedTasks);
      return updatedTasks;
    });

    resetTaskForm();
    console.log("Task form reset after adding");
    toast.success("Task added successfully!");
  };

  const handleEditTask = (task) => {
    console.log("handleEditTask called with task:", task);
    setTaskData({
      task_name: task.task_name,
      task_description: task.task_description,
      action_type: task.action_type,
    });
    setEditingTaskId(task.id);
    console.log("Set editingTaskId to:", task.id);
  };

  const handleUpdateTask = (e) => {
    e.preventDefault();
    console.log(
      "handleUpdateTask called. Current editingTaskId:",
      editingTaskId
    );
    const errors = validateTask();
    setErrors(errors || {});
    if (errors) return;

    setTasks((prevTasks) => {
      const updatedTasks = prevTasks.map((task) =>
        task.id === editingTaskId
          ? { ...task, ...taskData, id: editingTaskId }
          : task
      );
      console.log("Updated tasks:", updatedTasks);
      return updatedTasks;
    });

    resetTaskForm();
    setEditingTaskId(null);
    console.log("Task updated, form reset, and editingTaskId cleared");
    toast.success("Task updated successfully!");
  };

  const resetTaskForm = () => {
    console.log("Resetting task form");
    setTaskData({ task_name: "", task_description: "", action_type: "" });
    setEditingTaskId(null);
  };

  const handleRemoveTask = (taskId) => {
    setTasks(tasks.filter((task) => task.id !== taskId));
    toast.success("Task removed successfully!");
  };

  const isCreateListDisabled = () => {
    return (
      !listData.title ||
      !listData.type ||
      (listData.type === "tenant_screening" &&
        listData.campaigns.length === 0) ||
      tasks.length === 0
    );
  };

  const handleCreateList = async (e) => {
    e.preventDefault();
    const errors = validateList();
    setErrors(errors || {});
    if (errors) return;

    try {
      const universal_list = {
        universal_list_id: uuidv4(), // Generate a new UUID for the list
        name: listData.title,
        description: listData.description,
        list_type: listData.type,
        campaign_ids:
          listData.type === "tenant_screening" ? listData.campaigns : [],
        // created_by, created_at, updated_at, and is_deleted will be handled by the backend
        user_id: user_id,
      };

      const universal_list_items = tasks.map((task) => ({
        universal_list_item_id: uuidv4(),
        universal_list_id: universal_list.universal_list_id, // Use the same ID as the parent list
        name: task.task_name,
        description: task.task_description,
        action_type: task.action_type,
        // created_by and created_at will be handled by the backend
      }));

      const result = await createUniversalList(
        universal_list,
        universal_list_items
      );
      console.log("Universal list created successfully:", result);
      toast.success("Universal list created successfully!");
      navigate(`/property/${propertyId}`);
      resetForm();
    } catch (error) {
      console.error("Error creating universal list:", error);
      toast.error("Error creating universal list");
    }
  };

  const resetForm = () => {
    setListData({
      title: "",
      type: "",
      campaigns: [],
      description: "",
    });
    setTasks([]);
    setSelectedListType(null);
    setSelectedCampaigns([]);
  };

  const validateList = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(listData, listSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      // Skip campaign errors if the type is not tenant_screening
      if (
        item.path[0] === "campaigns" &&
        listData.type !== "tenant_screening"
      ) {
        continue;
      }
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateTask = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(taskData, taskSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  // Add this effect to log tasks state changes
  useEffect(() => {
    console.log("Tasks updated:", tasks);
  }, [tasks]);

  const getActionTypeLabel = (value) => {
    const actionType = actionTypes.find((action) => action.value === value);
    return actionType ? actionType.label : value;
  };

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Create New Universal List"
            previousPageLink={`/universalList/${propertyId}`}
            previousPageTitle="Universal List"
          />

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">List Details</h4>
                  <form onSubmit={handleCreateList}>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <Input
                          name="title"
                          label="Title"
                          value={listData.title}
                          onChange={handleListChange}
                          error={errors.title}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Type of List</label>
                        <Select
                          value={selectedListType}
                          onChange={handleListTypesChange}
                          options={listTypes}
                          styles={selectCustomStyle(isDarkMode)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      {selectedListType &&
                        selectedListType.value === "tenant_screening" && (
                          <div className="col-lg-6">
                            <label className="form-label">
                              Assign to Campaigns
                            </label>
                            <Select
                              isMulti
                              value={selectedCampaigns}
                              onChange={handleCampaignChange}
                              options={campaignsOptions}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                            {errors.campaigns && (
                              <div className="text-danger">
                                {errors.campaigns}
                              </div>
                            )}
                          </div>
                        )}
                      <div className="col-lg-6">
                        <Input
                          name="description"
                          label="Description"
                          value={listData.description}
                          onChange={handleListChange}
                          error={errors.description}
                        />
                      </div>
                    </div>

                    <div className="col-12 d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => navigate(`/universalList/${propertyId}`)}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={isCreateListDisabled()}
                      >
                        Create List
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">
                    {editingTaskId ? "Edit Task" : "Add Task to List"}
                  </h4>
                  <form
                    onSubmit={(e) => {
                      console.log(
                        "Form submitted. editingTaskId:",
                        editingTaskId
                      );
                      if (editingTaskId) {
                        handleUpdateTask(e);
                      } else {
                        handleAddTask(e);
                      }
                    }}
                  >
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <Input
                          name="task_name"
                          label="Task Name"
                          value={taskData.task_name}
                          onChange={handleTaskChange}
                          error={errors.task_name}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Type of Action</label>
                        <Select
                          value={
                            taskData.action_type
                              ? actionTypes.find(
                                  (action) =>
                                    action.value === taskData.action_type
                                )
                              : null
                          }
                          onChange={(selectedOption) =>
                            handleTaskChange({
                              name: "action_type",
                              value: selectedOption ? selectedOption.value : "",
                            })
                          }
                          options={actionTypes}
                          isClearable={true}
                          styles={selectCustomStyle(isDarkMode)}
                        />
                        {errors.action_type && (
                          <div className="text-danger">
                            {errors.action_type}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Input
                        name="task_description"
                        label="Task Description (Optional)"
                        value={taskData.task_description}
                        onChange={handleTaskChange}
                        error={errors.task_description}
                      />
                    </div>
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary me-2">
                        {editingTaskId ? "Update Task" : "Add Task"}
                      </button>
                      {editingTaskId && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            console.log("Cancel edit clicked");
                            resetTaskForm();
                          }}
                        >
                          Cancel Edit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {tasks.length > 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <div>
                  <div>
                    <h4 className="card-title mb-4">Added Tasks</h4>
                    <div className="row">
                      {tasks.map((task) => (
                        <div key={task.id} className="col-md-6 col-lg-4 mb-3">
                          <div className="card h-100">
                            <div className="card-body">
                              <h5 className="card-title">{task.task_name}</h5>
                              <p className="card-text">
                                {task.task_description}
                              </p>
                              <p className="card-text">
                                <strong>Action Type:</strong>{" "}
                                {getActionTypeLabel(task.action_type)}
                              </p>
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-sm btn-primary me-2"
                                  onClick={() => handleEditTask(task)}
                                >
                                  <FaEdit /> Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleRemoveTask(task.id)}
                                >
                                  <FaTrash /> Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewUniversalList;
