import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PaymentReceipt,
  PaymentMethod,
  PaymentStatus,
  PaymentReceiptLineItem,
} from "../../../types/payment/payment-receipt";
import { createPaymentReceipt } from "../../../services/payment/paymentReceiptService";
import { getLatestAndCurrentRentalContract } from "../../../services/rental_contract/rentalContractService";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import { useThemeClasses } from "../../../services/utils/hooks/useThemeClasses";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { devLog } from "services/utils/logger";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import config from "../../../constants/config";
import { getRentalFeePackById } from "services/templates/fees/rentalFeePackService";
import { getCurrencyById } from "services/systemDefined/currency/sysCurrencyManagement";
import { CurrencyModel } from "services/systemDefined/currency/model/currency-model";
import { usePageTitle } from "../../../services/utils/hooks/usePageTitle";
interface CreatePaymentReceiptProps {
  isDarkMode?: boolean;
}
//TODO: Need to fix the Create Receipt button because its now working presently before deployment
const CreatePaymentReceipt: React.FC<CreatePaymentReceiptProps> = ({
  isDarkMode = false,
}) => {
  const pageTitle = "Create Payment Receipt";
  usePageTitle({ title: pageTitle });
  const navigate = useNavigate();
  const { propertyUnitId } = useParams<{ propertyUnitId: string }>();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [receiptDate, setReceiptDate] = useState<Date | null>(new Date());
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CASH
  );
  const [notes, setNotes] = useState("");
  const [rentalContract, setRentalContract] = useState<any>(null);
  const [lineItems, setLineItems] = useState<Partial<PaymentReceiptLineItem>[]>(
    []
  );
  const [currency, setCurrency] = useState<CurrencyModel | null>(null);
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    fetchRentalContractDetails();
  }, [propertyUnitId]);

  const fetchRentalContractDetails = async () => {
    if (!propertyUnitId) return;

    try {
      setIsLoading(true);
      const contractDetails = await getLatestAndCurrentRentalContract(
        propertyUnitId
      );
      setRentalContract(contractDetails);
      devLog("contractDetails", contractDetails);

      // Initialize line items with just the monthly rent
      const initialLineItems = [
        {
          item_name: "Monthly Rent",
          description: `Rent for ${new Date().toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}`,
          amount: contractDetails.monthly_rent,
          period_start: new Date().toISOString().split("T")[0],
          period_end: new Date(new Date().setMonth(new Date().getMonth() + 1))
            .toISOString()
            .split("T")[0],
        },
      ];

      setLineItems(initialLineItems);

      //get the fee pack then use that to find the currency code
      if (contractDetails.rental_fee_pack_id) {
        const feePack = await getRentalFeePackById(
          contractDetails.rental_fee_pack_id
        );
        if (feePack) {
          const currencyId = feePack.currency_id;
          const currency = await getCurrencyById(currencyId);
          setCurrency(currency);
        }
      }
    } catch (error) {
      toast.error("Failed to fetch rental contract details");
      console.error("Error fetching contract details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddLineItem = () => {
    setLineItems([
      ...lineItems,
      {
        item_name: "",
        description: "",
        amount: 0,
        period_start: new Date().toISOString().split("T")[0],
        period_end: new Date().toISOString().split("T")[0],
      },
    ]);
  };

  const handleRemoveLineItem = (index: number) => {
    // Only allow removing custom line items (not the monthly rent)
    if (index > 0) {
      setLineItems(lineItems.filter((_, i) => i !== index));
    }
  };

  const handleLineItemChange = (
    index: number,
    field: keyof PaymentReceiptLineItem,
    value: any
  ) => {
    // Only allow editing custom line items (not the monthly rent)
    if (index > 0) {
      const updatedItems = [...lineItems];
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value,
      };
      setLineItems(updatedItems);
    }
  };

  const calculateTotal = () => {
    return lineItems.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!propertyUnitId || !rentalContract) {
      toast.error("Property unit and rental contract details are required");
      return;
    }

    if (!receiptDate) {
      toast.error("Receipt date is required");
      return;
    }

    try {
      setIsSubmitting(true);
      const receipt = await createPaymentReceipt({
        property_unit_id: propertyUnitId,
        rental_contract_id: rentalContract.rental_contract_id,
        tenant_id: rentalContract.tenant_id,
        landlord_id: rentalContract.owner_manager_user_id,
        receipt_date: receiptDate.toISOString(),
        payment_method: paymentMethod,
        payment_status: PaymentStatus.COMPLETED,
        total_amount: calculateTotal(),
        currency: currency?.currency_code || "USD",
        is_manually_created: true,
        created_by:
          `${localStorage.getItem(config.first_name)} ${localStorage.getItem(
            config.last_name
          )}` || "", // This should be replaced with actual user ID from auth context
        line_items: lineItems.map((item) => ({
          item_name: item.item_name || "",
          amount: item.amount || 0,
          description: item.description || "",
          period_start: item.period_start || "",
          period_end: item.period_end || "",
        })),
        notes: notes || undefined,
      });

      toast.success("Payment receipt created successfully");
      navigate(`/paymentReceipts/${propertyUnitId}`);
    } catch (error) {
      toast.error("Failed to create payment receipt");
      console.error("Error creating receipt:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "400px" }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!rentalContract) {
    return (
      <div className="alert alert-warning">
        No active rental contract found for this property unit.
      </div>
    );
  }

  return (
    <div>
      <PageTitleBox
        pageTitle="Create Payment Receipt"
        previousPageTitle="Payment Receipts"
        previousPageLink={`/paymentReceipts/${propertyUnitId}`}
      />

      <form onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-pb-24 sm:tw-pb-8 tw-max-h-[calc(100vh-12rem)] tw-overflow-y-auto">
            <Card className="tw-shadow-sm">
              <div className="tw-p-4 sm:tw-p-6">
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 sm:tw-gap-6 tw-mb-8">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Receipt Date"
                      value={receiptDate}
                      onChange={(date) => setReceiptDate(date)}
                      slotProps={{
                        textField: { fullWidth: true, required: true },
                      }}
                    />
                  </LocalizationProvider>
                  <FormControl fullWidth required>
                    <InputLabel>Payment Method</InputLabel>
                    <Select
                      value={paymentMethod}
                      onChange={(e) =>
                        setPaymentMethod(e.target.value as PaymentMethod)
                      }
                    >
                      {Object.values(PaymentMethod).map((method) => (
                        <MenuItem key={method} value={method}>
                          {method}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="tw-space-y-6">
                  <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-4">
                    <h6 className="tw-text-lg tw-font-semibold">Line Items</h6>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleAddLineItem}
                      size="small"
                      className="tw-w-full sm:tw-w-auto"
                    >
                      Add Additional Item
                    </Button>
                  </div>

                  <div className="tw-space-y-6">
                    {lineItems.map((item, index) => (
                      <div
                        key={index}
                        className="tw-grid tw-grid-cols-1 tw-gap-4 tw-items-start tw-p-4 tw-rounded-lg tw-border dark:tw-border-gray-700"
                      >
                        <div className="tw-w-full">
                          <TextField
                            fullWidth
                            label="Item Name"
                            required
                            value={item.item_name}
                            onChange={(e) =>
                              handleLineItemChange(
                                index,
                                "item_name",
                                e.target.value
                              )
                            }
                            disabled={index === 0}
                          />
                        </div>
                        <div className="tw-w-full">
                          <TextField
                            fullWidth
                            label="Description"
                            value={item.description}
                            onChange={(e) =>
                              handleLineItemChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            disabled={index === 0}
                          />
                        </div>
                        <div className="tw-w-full">
                          <TextField
                            fullWidth
                            label="Amount"
                            type="number"
                            required
                            value={item.amount}
                            onChange={(e) =>
                              handleLineItemChange(
                                index,
                                "amount",
                                Number(e.target.value)
                              )
                            }
                            disabled={index === 0}
                          />
                        </div>
                        <div className="tw-w-full">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                              <DatePicker
                                label="Start Date"
                                value={
                                  item.period_start
                                    ? new Date(item.period_start)
                                    : null
                                }
                                onChange={(date) =>
                                  handleLineItemChange(
                                    index,
                                    "period_start",
                                    date?.toISOString().split("T")[0]
                                  )
                                }
                                disabled={index === 0}
                                slotProps={{
                                  textField: { fullWidth: true },
                                }}
                              />
                              <DatePicker
                                label="End Date"
                                value={
                                  item.period_end
                                    ? new Date(item.period_end)
                                    : null
                                }
                                onChange={(date) =>
                                  handleLineItemChange(
                                    index,
                                    "period_end",
                                    date?.toISOString().split("T")[0]
                                  )
                                }
                                disabled={index === 0}
                                slotProps={{
                                  textField: { fullWidth: true },
                                }}
                              />
                            </div>
                          </LocalizationProvider>
                        </div>
                        {index !== 0 && (
                          <div className="tw-flex tw-justify-end">
                            <IconButton
                              onClick={() => handleRemoveLineItem(index)}
                              size="small"
                              className="tw-text-red-500"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="tw-mt-8">
                  <TextField
                    fullWidth
                    label="Notes"
                    multiline
                    rows={3}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>

                <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-4 tw-mt-8 tw-pt-6 tw-border-t dark:tw-border-gray-700">
                  <div className="tw-p-4 tw-rounded-lg tw-w-full sm:tw-w-auto">
                    <div className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
                      Total Amount
                    </div>
                    <div className="tw-text-2xl tw-font-bold">
                      {formatCurrency(calculateTotal())}
                    </div>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    className="tw-w-full sm:tw-w-auto tw-min-w-[140px]"
                  >
                    {isSubmitting ? "Creating..." : "Create Receipt"}
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </ThemeProvider>
      </form>
    </div>
  );
};

export default CreatePaymentReceipt;
