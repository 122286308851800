import React from "react";
import { DataTable } from "components/common/table/DataTable";
import { Column } from "components/common/table/types/TableTypes";
import { Link } from "react-router-dom";

interface ContractTemplate {
  contract_template_id: string;
  user_id: string;
  portfolio_id: string;
  property_id: string;
  contract_name: string;
  contract_description: string;
  contract_template: string;
  created_at: number;
  updated_at: number;
  is_active: boolean;
  is_deleted: boolean;
}

interface ContractTemplateTableProps {
  data: ContractTemplate[];
  isDarkMode: boolean;
  onRowClick: (template: ContractTemplate) => void;
}

export const ContractTemplateTable: React.FC<ContractTemplateTableProps> = ({
  data,
  isDarkMode,
  onRowClick,
}) => {
  const columns: Column<ContractTemplate>[] = [
    {
      field: "contract_name",
      headerName: "Contract Name",
      flex: 2,
      renderCell: (row) => (
        <Link
          to={`/contractTemplate/${row.contract_template_id}`}
          className={`tw-font-medium ${
            isDarkMode ? "tw-text-blue-400" : "tw-text-blue-600"
          } hover:tw-underline`}
          onClick={(e) => e.stopPropagation()}
        >
          {row.contract_name}
        </Link>
      ),
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      renderCell: (row) => (
        <div className="tw-flex tw-justify-center">
          <span
            className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium tw-inline-block ${
              row.is_active
                ? "tw-bg-green-100 tw-text-green-800 dark:tw-bg-green-900/30 dark:tw-text-green-400"
                : "tw-bg-gray-100 tw-text-gray-800 dark:tw-bg-gray-700/30 dark:tw-text-gray-400"
            }`}
          >
            {row.is_active ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      field: "contract_description",
      headerName: "Description",
      flex: 3,
      renderCell: (row) => (
        <div className="tw-line-clamp-2 tw-text-sm">
          {row.contract_description}
        </div>
      ),
    },
    {
      field: "contract_template",
      headerName: "Preview",
      flex: 4,
      renderCell: (row) => {
        // Extract title from HTML content
        const titleMatch = row.contract_template.match(/<h1[^>]*>(.*?)<\/h1>/);
        const title = titleMatch ? titleMatch[1] : "Contract Preview";

        // Clean and prepare HTML content
        const cleanHtml = row.contract_template
          .replace(/<h1[^>]*>.*?<\/h1>/, "") // Remove the h1 title since we display it separately
          .replace(/<img /g, '<img style="max-width: 100%; height: auto;" ');

        return (
          <div className="tw-relative tw-group tw-max-w-full">
            <div
              className={`tw-p-4 tw-border tw-rounded-lg tw-shadow-sm tw-bg-opacity-50 ${
                isDarkMode
                  ? "tw-border-gray-700 tw-bg-gray-800"
                  : "tw-border-gray-200 tw-bg-gray-50"
              }`}
            >
              <div className="tw-text-xs tw-font-medium tw-mb-2 tw-opacity-70 tw-truncate">
                {title}
              </div>

              <div className="tw-relative">
                <div
                  className="tw-h-20 tw-overflow-hidden tw-text-xs tw-prose dark:tw-prose-invert tw-max-w-none"
                  dangerouslySetInnerHTML={{
                    __html: cleanHtml,
                  }}
                />
                <div
                  className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-8 tw-bg-gradient-to-t ${
                    isDarkMode ? "tw-from-gray-800" : "tw-from-gray-50"
                  } tw-to-transparent`}
                />
              </div>

              <div
                className={`tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-200 tw-bg-black tw-bg-opacity-50 tw-rounded-lg`}
              >
                <span className="tw-text-white tw-text-sm tw-font-medium tw-flex tw-items-center">
                  <i className="ri-eye-line tw-mr-2" />
                  View Full Contract
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      flex: 2,
      renderCell: (row) => (
        <div className="tw-text-sm">
          {new Date(row.updated_at).toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </div>
      ),
    },
  ];

  return (
    <DataTable<ContractTemplate>
      data={data}
      columns={columns}
      isDarkMode={isDarkMode}
      onRowClick={onRowClick}
      searchPlaceholder="Search contracts..."
      searchFields={["contract_name", "contract_description"]}
      noDataMessage="No contracts found"
    />
  );
};
