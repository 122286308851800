//this is to link the bank account that the property will be using collecting the rent and other payments through (where plaid will be used to track the balances, can link plaid here)

import React, { useEffect, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Input from "../../../../../reusable/form/Input";
import { usePlaidLink } from "react-plaid-link";
import {
  createBankAndBankAccountManually,
  createLinkToken,
  createOrUpdatePropertyBankAccount,
  exchangePublicToken,
  getBankAccounts,
} from "../../../../../../services/bank/bankManager";
import Joi from "joi-browser";
import LoaderView from "../../../../../reusable/loading/loaderView";
import { set } from "react-hook-form";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Modal, Badge, Button } from "react-bootstrap";
import BankAccount from "../../../../../../services/model/bankAccountModel";
import {
  createNewUserDefinedCountry,
  fetchAllCountries,
} from "../../../../../../services/systemDefined/country/countryManagement";
import { getCode } from "country-list";
import config from "../../../../../../constants/config";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { selectCustomStyle } from "../../../../../../styletheme/custom/selectStyle";
import { usePageTitle } from "../../../../../../services/utils/hooks/usePageTitle";
//TODO: add the ability to assign a bank account to a property then ownership
//TODO: editing banks and removing should be done on a separate page
const PropertyBankAccounts = ({
  isDarkMode,
  property_id,
  isPropertyAlreadyCreated,
  handleSubmit, // Add this prop
}) => {
  const [isLoading, setIsLoading] = useState(false);
  usePageTitle({ title: "Property Bank Accounts" });
  //plaid
  const [linkToken, setLinkToken] = useState(null);

  const [bankAccounts, setBankAccounts] = useState([
    {
      account_id: "",
      account_mask: "",
      account_name: "",
      account_subtype: "",
      account_type: "",
      available_balance: 0,
      bank_account_id: "",
      bank_id: "",
      created_at: "",
      currency_id: "",
      current_balance: 0,
      is_default_account: false,
      updated_at: "",
      routing_number: "",
      routing_numbers: [""],
      logo: "",
      bank_name: "",
      institution_id: "",
      url: "",
    },
  ]);

  //plaid
  const generateToken = async () => {
    const link_token_response = await createLinkToken();
    console.log(link_token_response);
    setLinkToken(link_token_response.link_token);
  };

  //plaid
  const onSuccess = async (public_token, metadata) => {
    const updatedBankAccounts = await exchangePublicToken(public_token);

    console.log("Response:", updatedBankAccounts);
    //update the UI with the bank account details (not sensitive details)
    //update the bankAccounts state with the new bank account details

    setBankAccounts(updatedBankAccounts);

    //like what bank was added and then you now have the option to set if its the resrve account or the rent collection account etc
  };

  //search for bank accounts
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("Account Name");

  const filteredBankAccounts = bankAccounts
    .filter(
      (bankAccount) =>
        bankAccount.account_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        bankAccount.bank_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        bankAccount.routing_numbers.some((routing_number) =>
          routing_number.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        (bankAccount.url &&
          bankAccount.url.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      switch (sortOption) {
        case "Account Name":
          return a.account_name.localeCompare(b.account_name);
        case "Balance":
          return a.current_balance - b.current_balance;
        // Add more cases if you have other fields to sort by...
        default:
          return 0;
      }
    });

  useEffect(() => {
    generateToken();
    findAllBankAccounts();
    getCountryOptions();
  }, []);

  //the bank accounts that are linked to the logged in user
  const findAllBankAccounts = async () => {
    const bankAccounts = await getBankAccounts();
    console.log(bankAccounts);
    setBankAccounts(bankAccounts);
  };

  //TODO: next be able to select a bank account and press confirm to let it be account associated with the property
  //TODO: ability to add a bank account manually
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedBankAccountIndex, setSlectedBankAccountIndex] = useState(null);

  //manually creating a bank account (one at a time)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // Define your schema for validation
  const schema = Joi.object({
    account_id: Joi.string().required(),
    account_type: Joi.string().required(),
    routing_number: Joi.string().required(),
    bank_name: Joi.string().required(),
    account_number: Joi.string().required(),
  });

  const [accountTypes, setAccountTypes] = useState([
    { value: "Checking", label: "Checking" },
    { value: "Savings", label: "Savings" },
  ]);

  // Define your state
  const [newBankAccount, setNewBankAccount] = useState({
    account_id: "",
    account_name: "",
    account_type: "",
    routing_number: "",
    bank_name: "",
    account_number: "",
    bank_country: "",
  });

  // Define your change handler
  const handleChange = (e) => {
    setNewBankAccount({
      ...newBankAccount,
      [e.target.name]: e.target.value,
    });
  };

  // Define your submit handler
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      // alert('Submit');
      console.log(newBankAccount);
      //get the bank account details

      const bankAccountDetail = new BankAccount();
      bankAccountDetail.bank_account_id =
        newBankAccount.bank_account_id || "N/A";
      bankAccountDetail.bank_id = newBankAccount.bank_id || "N/A";
      bankAccountDetail.currency_id = newBankAccount.currency_id || "N/A";
      bankAccountDetail.account_name = newBankAccount.account_name || "N/A";
      bankAccountDetail.account_type = newBankAccount.account_type || "N/A";
      bankAccountDetail.account_subtype =
        newBankAccount.account_subtype || "N/A";
      bankAccountDetail.account_mask = newBankAccount.account_mask || "N/A";
      bankAccountDetail.account_id = newBankAccount.account_id || "N/A";
      bankAccountDetail.available_balance =
        newBankAccount.available_balance || 0.0;
      bankAccountDetail.current_balance = newBankAccount.current_balance || 0.0;
      bankAccountDetail.is_default_account =
        newBankAccount.is_default_account || false;
      bankAccountDetail.created_at =
        newBankAccount.created_at || new Date().toISOString();
      bankAccountDetail.updated_at =
        newBankAccount.updated_at || new Date().toISOString();
      bankAccountDetail.bank_name = newBankAccount.bank_name || "N/A";
      bankAccountDetail.routing_number = newBankAccount.routing_number || "N/A";
      bankAccountDetail.routing_numbers = newBankAccount.routing_numbers || [
        newBankAccount.routing_number,
      ];
      bankAccountDetail.logo = newBankAccount.logo || "N/A";
      bankAccountDetail.institution_id = newBankAccount.institution_id || "N/A";
      bankAccountDetail.url = newBankAccount.url || "N/A";
      bankAccountDetail.account_number = newBankAccount.account_number || "N/A";
      bankAccountDetail.bank_country = newBankAccount.bank_country || "N/A";
      //create the bank account
      const updatedBankAccounts = await createBankAndBankAccountManually(
        bankAccountDetail
      );
      console.log(updatedBankAccounts);
      //if successful

      if (updatedBankAccounts) {
        //update the UI with the new bank account
        setBankAccounts(updatedBankAccounts);
        //close the modal
        setModalIsOpen(false);
        clearModelForm();
      }
      //verify the results
    } catch (error) {
      console.error(error);
    }
  };
  const clearModelForm = () => {
    setNewBankAccount({
      account_id: "",
      account_name: "",
      account_type: "",
      routing_number: "",
      bank_name: "",
      account_number: "",
      bank_country: "",
    });
  };

  //country options
  //need to refactor this to be a reusable component
  //country options
  const [countryOptions, setCountryOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  const handleCountryChange = async (option) => {
    //ensure it is not empty
    if (!option) return;

    setSelectedCountryOption(option);
    newBankAccount.bank_country = option.label;

    if (option.__isNew__) {
      console.log("User created a new option with value:", option.value);
      //call the api to create the country and tag it as user defined

      //then update the countryOptions
      //createNewUserDefinedCountry
      const countryCode = getCode(option.value);
      if (countryCode) {
        createCountry(option, countryCode);
      } else {
        console.log(
          "Could not find the country code for the specified country. Saving without country code."
        );
        createCountry(option, "");
      }
    } else {
      console.log("User selected the option:", option.value);
    }
  };
  const getCountryOptions = async () => {
    const response = await fetchAllCountries();
    const options = response.map((country) => ({
      value: country.country_id,
      label: country.is_user_defined
        ? `${country.country_name} `
        : country.country_name,
      data: country,
    }));
    setCountryOptions(options);
  };

  const formattedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: option.data.is_user_defined
      ? `${option.label} (User Defined)`
      : option.label,
  }));

  async function createCountry(option, countryCode) {
    const userId = localStorage.getItem(config.user_id);
    const countryName = option.value;

    var country = {
      country_id: uuidv4(),
      country_name: countryName,
      country_code: countryCode, // This will be undefined if the country code is not found
      is_user_defined: true,
      created_at: new Date(),
      updated_at: new Date(),
      created_by_user_id: userId,
    };

    console.log("Country:", country);

    const updatedCountryList = await createNewUserDefinedCountry(country);

    // If the country was created successfully
    if (updatedCountryList) {
      // Update the countryOptions
      const options = updatedCountryList.map((country) => ({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      }));
      setCountryOptions(options);

      // Select this new country
      setSelectedCountryOption({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      });

      toast.success("New country saved successfully");
    } else {
      // Handle the case where the country could not be saved
      toast.error(
        "New country could not be saved successfully, please try again."
      );
    }
  }

  //assign the bank account to the property_id
  const assignBankAccountToProperty = async () => {
    //assign the bank account to the property_id
    const bankAccount = selectedBankAccount;
    /*
    property_bank_account_id: str
    bank_account_id: str
    property_unit_id: str
    bank_alias_name: Optional[str] = None
    account_balance: Optional[float] = None
    is_reserve_account: Optional[bool] = None
    reserve_amount: Optional[float] = None

    */
    const propertyBankAccount = {
      property_bank_account_id: uuidv4(),
      bank_account_id: bankAccount.bank_account_id,
      property_id: property_id,
      bank_alias_name: bankAccount.account_name,
      account_balance: bankAccount.current_balance,
      is_reserve_account: false,
      reserve_amount: 0.0,
    };
    console.log(propertyBankAccount);

    //create or update the property bank account
    const response = await createOrUpdatePropertyBankAccount(
      propertyBankAccount
    );
    console.log(response);

    //if successful
    if (response) {
      //update the UI
      //clear the selected bank account
      setSelectedBankAccount(null);
      setSlectedBankAccountIndex(null);
      //show a success message
      toast.success("Bank account assigned to property successfully");
      handleSubmit(); // Call handleSubmit to move to the next step
    } else {
      //show an error message
      toast.error("Bank account could not be assigned to property");
      return;
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      {/*Refractor later */}
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <form onSubmit={handleSubmitForm}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Bank Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gy-4">
              <div className="col-xxl-12 col-md-12">
                <div>
                  <Input
                    name="bank_name"
                    label="Bank Name"
                    type={"text"}
                    value={newBankAccount.bank_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-12 col-md-12">
                <div>
                  <Input
                    name="account_name"
                    label="Account Name (Alias)"
                    type={"text"}
                    value={newBankAccount.account_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-12 col-md-12">
                <div>
                  <Input
                    name="account_number"
                    label="Account Number"
                    type={"text"}
                    value={newBankAccount.account_number}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-xxl-12 col-md-12">
                <div>
                  <label>Account Type</label>
                  <Select
                    name="account_type"
                    options={accountTypes}
                    value={accountTypes.find(
                      (option) => option.value === newBankAccount.account_type
                    )}
                    onChange={(selectedOption) =>
                      handleChange({
                        target: {
                          name: "account_type",
                          value: selectedOption.value,
                        },
                      })
                    }
                    styles={selectCustomStyle(isDarkMode)}
                  />
                </div>
              </div>

              <div className="col-xxl-12 col-md-12">
                <div>
                  <Input
                    name="routing_number"
                    label="Routing Number"
                    type={"text"}
                    value={newBankAccount.routing_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-12 col-md-12">
                <div>
                  {/*
                  <Input
                    name='bank_country'
                    label='Bank Country'
                    type={'text'}
                    value={newBankAccount.bank_country}
                    onChange={handleChange}
                  />
                  */}
                  <label htmlFor="form-grid-category">Country</label>

                  <CreatableSelect
                    name="country"
                    options={formattedCountryOptions}
                    onChange={handleCountryChange}
                    isClearable
                    styles={selectCustomStyle(isDarkMode)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setModalIsOpen(false);
                //clear the form
                clearModelForm();
              }}
            >
              Close
            </Button>
            <Button variant="success" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/*For adding new accounts to the list of banks */}
      <div className="row pt-4 px-5">
        <div className="col-xl-2 p-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add Bank Account Manually</h5>
              <p className="card-text">
                Click the button below to manually add your bank account
                details.
              </p>
              <button
                style={{ width: "250px" }}
                className="btn btn-outline border"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                Add Bank Account Manually
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-2 p-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Connect a Bank Account using Plaid</h5>
              <p className="card-text">
                Click the button below to connect your bank account using
                Plaid's secure system.
              </p>
              <PlaidLink
                style={{
                  width: "250px",
                  cursor: "pointer",
                  backgroundColor: selectedBankAccount
                    ? "transparent"
                    : isDarkMode
                    ? "#2c3440" // darker background for dark mode
                    : "#099885", // original color for light mode
                  color: selectedBankAccount
                    ? isDarkMode
                      ? "#ffffff" // white text for dark mode
                      : "#000000" // black text for light mode
                    : "#ffffff", // white text for active state
                  borderRadius: "5px",
                  border: isDarkMode ? "1px solid #4a5568" : "none",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
                token={linkToken}
                onSuccess={onSuccess}
                onExit={(error, metadata) => {
                  if (error) {
                    console.error("Error during Plaid Link:", error);
                  }
                }}
              >
                Connect a Bank Account using Plaid
              </PlaidLink>
            </div>
          </div>
        </div>

        {selectedBankAccount && selectedBankAccount.bank_id !== "" && (
          <div className="col-xl-2 p-3">
            <div className="card card-height-100">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="contact-name fs-13 mb-1">
                    <a href="#" className="link text-body">
                      {selectedBankAccount?.account_name}
                    </a>
                  </h5>
                  <button
                    style={{ border: "none", background: "transparent" }}
                    onClick={() => {
                      setSelectedBankAccount(null);
                      setSlectedBankAccountIndex(null);
                    }}
                  >
                    <i className="mdi mdi-close-thick"></i>
                  </button>
                </div>
                <p className="contact-born text-muted mb-0">
                  {selectedBankAccount?.routing_number}
                </p>
                <div className="fs-11 text-muted">
                  {selectedBankAccount?.bank_name}
                </div>
                <div className="row pt-4 px-5">
                  <button
                    style={{ width: "250px" }}
                    className="btn btn-primary border"
                    disabled={!isPropertyAlreadyCreated}
                    onClick={() => {
                      assignBankAccountToProperty();
                    }}
                  >
                    Assign Bank Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*For selecting the bank account to be used  from the list of existing banks and the default bank at the top*/}
      <div className="row pt-4 px-5">
        {/* the selectedBankAccount bank account */}

        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
            <div className="col-md-3">
              <h4 className="mb-sm-0">Existing Bank Accounts</h4>
            </div>

            <div className="col-md-6">
              <div id="contact-existing-list">
                <div className="row mb-2">
                  <div className="col">
                    <input
                      className="search form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 text-end">
              <NavDropdown title={`Sort by: ${sortOption}`} id="nav-dropdown">
                <NavDropdown.Item onClick={() => setSortOption("Account Name")}>
                  Account Name
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setSortOption("Routing Number")}
                >
                  Routing Number
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setSortOption("Bank Name")}>
                  Bank Name
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row px-4"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        {filteredBankAccounts.map((bankAccount, index) => (
          <div
            key={index}
            className={`col-xl-3 ${
              selectedBankAccountIndex === index
                ? "highlighted pt-3 pb-0 m-2"
                : "pt-3 pb-0 m-2"
            }`}
            onClick={() => {
              setSlectedBankAccountIndex(index);
              setSelectedBankAccount(bankAccount);
            }}
          >
            <div className="card card-height-100">
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="contact-name fs-13 mb-1">
                      <a href="#" className="link text-body">
                        {bankAccount.account_name}
                      </a>
                    </h5>
                    <p className="contact-born text-muted mb-0">
                      {bankAccount.routing_number}
                    </p>
                  </div>

                  <div className="flex-shrink-0 ms-2">
                    <div className="fs-11 text-muted">
                      {bankAccount.bank_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row gy-4">
        <div className="col-xxl-3 col-md-6 ms-auto text-end">
          {!isPropertyAlreadyCreated && (
            <p>
              Please create the property first before assigning bank accounts
            </p>
          )}
          <button
            type="button"
            disabled={!selectedBankAccount || !isPropertyAlreadyCreated}
            className="btn btn-success"
            onClick={assignBankAccountToProperty}
          >
            Save and Continue
          </button>
        </div>
      </div>
      {/* end col */}
    </React.Fragment>
  );
};

export default PropertyBankAccounts;
