import React, { useState, useEffect } from "react";
import Pagination from "../pagination/Pagination";
import "./DataTable.css"; // We'll create this next

interface Column {
  header: string;
  accessor: string;
  cell?: (row: any) => React.ReactNode;
  sortable?: boolean;
}

interface DataTableProps {
  columns: Column[];
  data: any[];
  itemsPerPage?: number;
  searchPlaceholder?: string;
  isLoading?: boolean;
  LoadingComponent?: React.ComponentType<any>;
  EmptyComponent?: React.ComponentType<any>;
  className?: string;
  darkMode?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  itemsPerPage = 10,
  searchPlaceholder = "Search...",
  isLoading = false,
  LoadingComponent,
  EmptyComponent,
  className = "",
  darkMode = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc" | null;
  }>({ key: "", direction: null });
  const [isFiltering, setIsFiltering] = useState(false);

  // Filter and sort data
  useEffect(() => {
    setIsFiltering(true);
    const timeoutId = setTimeout(() => {
      let filtered = [...data];

      // Apply search filter
      if (searchTerm) {
        filtered = data.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }

      // Apply sorting
      if (sortConfig.key && sortConfig.direction) {
        filtered.sort((a, b) => {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
          return 0;
        });
      }

      setFilteredData(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      setCurrentPage(1);
      setIsFiltering(false);
    }, 300); // Debounce time

    return () => clearTimeout(timeoutId);
  }, [data, searchTerm, sortConfig, itemsPerPage]);

  const handleSort = (accessor: string) => {
    const column = columns.find((col) => col.accessor === accessor);
    if (!column?.sortable) return;

    setSortConfig((current) => ({
      key: accessor,
      direction:
        current.key === accessor && current.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Get current page items
  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  };

  const renderSortIcon = (accessor: string) => {
    const column = columns.find((col) => col.accessor === accessor);
    if (!column?.sortable) return null;

    if (sortConfig.key !== accessor) {
      return <i className="ri-arrow-up-down-line ms-1"></i>;
    }
    return sortConfig.direction === "asc" ? (
      <i className="ri-arrow-up-line ms-1"></i>
    ) : (
      <i className="ri-arrow-down-line ms-1"></i>
    );
  };

  return (
    <div className={`data-table ${className} ${darkMode ? "dark" : ""}`}>
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control search"
                  placeholder={searchPlaceholder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <i
                  className={`ri-search-line search-icon ${
                    isFiltering ? "spin" : ""
                  }`}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          {isLoading && LoadingComponent ? (
            <LoadingComponent />
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th
                          key={column.accessor}
                          onClick={() => handleSort(column.accessor)}
                          style={{
                            cursor: column.sortable ? "pointer" : "default",
                          }}
                          className={`${column.sortable ? "sortable" : ""} ${
                            sortConfig.key === column.accessor ? "active" : ""
                          }`}
                        >
                          {column.header}
                          {renderSortIcon(column.accessor)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={isFiltering ? "filtering" : ""}>
                    {getCurrentItems().map((row, rowIndex) => (
                      <tr key={rowIndex} className="fade-in">
                        {columns.map((column, colIndex) => (
                          <td key={`${rowIndex}-${colIndex}`}>
                            {column.cell
                              ? column.cell(row)
                              : row[column.accessor]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {filteredData.length === 0 ? (
                EmptyComponent ? (
                  <EmptyComponent />
                ) : (
                  <div className="text-center p-4 fade-in">
                    <p className="text-muted mb-0">No records found</p>
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
