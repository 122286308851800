import http from "../../general/httpService";
import config from "../../../constants/config";

///property_unit_audit/create_property_unit_audit
export async function createPropertyUnitAudit(audit: any) {
  const fullApiEndpoint =
    config.apiEndpoint + `/property_unit_audit/create_property_unit_audit`;
  const { data: result } = await http.post(fullApiEndpoint, audit);

  return result;
}

//get_audit_by_property_unit_id_for_last_7_days
export async function getAuditByPropertyUnitIdForLast7Days(
  property_unit_id: string
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/property_unit_audit/get_audit_by_property_unit_id_for_last_7_days/${property_unit_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_audit_by_property_unit_id_for_last_365_days
export async function getAuditByPropertyUnitIdForLast365Days(
  property_unit_id: string
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/property_unit_audit/get_audit_by_property_unit_id_for_last_365_days/${property_unit_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
