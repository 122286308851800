import { Message, MessageMetadata } from "../../models/ai-chat/Message";
import config from "../../constants/config";
import { v4 as uuidv4 } from "uuid";

export interface WebSocketUpdate {
  type: "processing" | "ai_response" | "error";
  data: {
    message?: Message;
    status?: string;
    metadata?: MessageMetadata;
  };
}

export class AiWebSocketService {
  private static instance: AiWebSocketService;
  private ws: WebSocket | null = null;
  private sessionId: string | null = null;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectTimeout: NodeJS.Timeout | null = null;
  private callbacks: Map<string, (update: WebSocketUpdate) => void> = new Map();
  private intentionalClose = false;
  private connectionPromise: Promise<void> | null = null;

  // Add connection debounce timer
  private connectionDebounceTimer: NodeJS.Timeout | null = null;
  private readonly DEBOUNCE_TIME = 1000; // 1 second debounce

  private constructor() {}

  static getInstance(): AiWebSocketService {
    if (!AiWebSocketService.instance) {
      AiWebSocketService.instance = new AiWebSocketService();
    }
    return AiWebSocketService.instance;
  }

  private getWebSocketUrl(sessionId: string): string {
    const wsBaseUrl = config.wsEndpoint;

    return `${wsBaseUrl}/chat/ws/${sessionId}`;
  }

  connect(sessionId: string): Promise<void> {
    // If we're already trying to connect with the same session ID, return existing promise
    if (this.connectionPromise && this.sessionId === sessionId) {
      return this.connectionPromise;
    }

    // Clear any existing debounce timer
    if (this.connectionDebounceTimer) {
      clearTimeout(this.connectionDebounceTimer);
    }

    // Create new connection promise
    this.connectionPromise = new Promise((resolve, reject) => {
      // Debounce the connection attempt
      this.connectionDebounceTimer = setTimeout(() => {
        this._connect(sessionId).then(resolve).catch(reject);
      }, this.DEBOUNCE_TIME);
    });

    return this.connectionPromise;
  }

  private _connect(sessionId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        // If already connected to the same session, just resolve
        if (
          this.sessionId === sessionId &&
          this.ws?.readyState === WebSocket.OPEN
        ) {
          resolve();
          return;
        }

        // Close existing connection if any
        if (this.ws) {
          this.intentionalClose = true;
          this.ws.close();
        }

        this.sessionId = sessionId;
        this.intentionalClose = false;
        const wsUrl = this.getWebSocketUrl(sessionId);
        console.log("Creating new WebSocket connection:", wsUrl);

        this.ws = new WebSocket(wsUrl);

        this.ws.onopen = () => {
          console.log("WebSocket connected");
          this.reconnectAttempts = 0;
          resolve();
        };

        this.ws.onmessage = this.handleWebSocketMessage.bind(this);

        this.ws.onclose = (event) => {
          console.log("WebSocket closed", event.code, event.reason);
          this.connectionPromise = null;
          if (!this.intentionalClose) {
            // Notify UI about connection close if not intentional
            this.notifyCallbacks({
              type: "error",
              data: {
                message: {
                  id: uuidv4(),
                  type: "ai",
                  content: "Connection closed. Attempting to reconnect...",
                  timestamp: new Date().toISOString(),
                  status: "error",
                },
              },
            });
            this.handleReconnection();
          }
          this.intentionalClose = false;
        };

        this.ws.onerror = (error) => {
          console.error("WebSocket error:", error);
          this.connectionPromise = null;
          // Notify UI about connection error
          this.notifyCallbacks({
            type: "error",
            data: {
              message: {
                id: uuidv4(),
                type: "ai",
                content: "Connection error occurred. Please try again.",
                timestamp: new Date().toISOString(),
                status: "error",
              },
            },
          });
          reject(error);
        };
      } catch (error) {
        console.error("Error connecting to WebSocket:", error);
        this.connectionPromise = null;
        // Notify UI about connection setup error
        this.notifyCallbacks({
          type: "error",
          data: {
            message: {
              id: uuidv4(),
              type: "ai",
              content: "Failed to establish connection. Please try again.",
              timestamp: new Date().toISOString(),
              status: "error",
            },
          },
        });
        reject(error);
      }
    });
  }

  public disconnect(): void {
    if (this.connectionDebounceTimer) {
      clearTimeout(this.connectionDebounceTimer);
    }
    this.intentionalClose = true;
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.sessionId = null;
    this.callbacks.clear();
    this.connectionPromise = null;
  }

  private handleWebSocketMessage(event: MessageEvent): void {
    try {
      // Handle ping messages
      if (event.data === "ping") {
        this.ws?.send("pong");
        return;
      }

      const update: WebSocketUpdate = JSON.parse(event.data);
      this.notifyCallbacks(update);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
      // Send error to UI
      this.notifyCallbacks({
        type: "error",
        data: {
          message: {
            id: uuidv4(),
            type: "ai",
            content:
              "Sorry, I encountered an error processing the message. Please try again.",
            timestamp: new Date().toISOString(),
            status: "error",
          },
        },
      });
    }
  }

  private handleWebSocketClose(): void {
    console.log("WebSocket closed");
    if (!this.intentionalClose) {
      this.handleReconnection();
    }
    this.intentionalClose = false;
  }

  subscribeToSession(
    sessionId: string,
    callback: (update: WebSocketUpdate) => void
  ): () => void {
    const callbackId = `${sessionId}-${Date.now()}`;
    this.callbacks.set(callbackId, callback);
    return () => this.callbacks.delete(callbackId);
  }

  private notifyCallbacks(update: WebSocketUpdate): void {
    this.callbacks.forEach((callback) => {
      try {
        callback(update);
      } catch (error) {
        console.error("Error in WebSocket callback:", error);
      }
    });
  }

  private async handleReconnection(): Promise<void> {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error("Max reconnection attempts reached");
      // Notify UI about connection failure
      this.notifyCallbacks({
        type: "error",
        data: {
          message: {
            id: uuidv4(),
            type: "ai",
            content:
              "Lost connection to the server. Please refresh the page or try again later.",
            timestamp: new Date().toISOString(),
            status: "error",
          },
        },
      });
      return;
    }

    const backoffTime = Math.min(
      1000 * Math.pow(2, this.reconnectAttempts),
      10000
    );
    console.log(`Attempting to reconnect in ${backoffTime}ms`);

    await new Promise((resolve) => setTimeout(resolve, backoffTime));

    this.reconnectAttempts++;
    if (this.sessionId) {
      try {
        await this.connect(this.sessionId);
      } catch (error) {
        console.error("Reconnection failed:", error);
      }
    }
  }
}
