import React, { useState, useEffect } from "react";
import { format, parseISO, isSameDay, addDays } from "date-fns";
import {
  getAllAvailableViewingSlotsByCampaignId,
  updateUnitViewing,
} from "../../../../../../services/propertyUnits/advertisment/unitViewing/unitViewingManagement";
import LoaderView from "../../../../../reusable/loading/loaderView";
import { useParams } from "react-router-dom";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import config from "../../../../../../constants/config";

//RESTRICT: one person can only select one slot unless they cancel the slot first the try a new one
//show a widget on the dashboard to show the selected slot time slot and the tenant name (the logged in tenant)
//TODO: this logic (exact) is for the tenant portal, so it should be moved to the tenant portal
//Mine will just show who is booked and who is not
const ViewingScheduleDetail = ({ isDarkMode }) => {
  const { campaignId } = useParams();
  const [slots, setSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //user logged in user id and name from local storage
  const user_id = localStorage.getItem(config.user_id);
  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);

  useEffect(() => {
    fetchSlots();
  }, [campaignId]);

  const fetchSlots = async () => {
    setIsLoading(true);
    try {
      // This is a mock API call. Replace with your actual API call.
      const response = await getAllAvailableViewingSlotsByCampaignId(
        campaignId
      );
      if (response && Array.isArray(response)) {
        console.log(response);
        setSlots(response);
        findNextAvailableDate(response);
      } else {
        console.error("Invalid response format:", response);
        setSlots([]); // Ensure slots is an array
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      setSlots([]); // Ensure slots is an array in case of error
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const findNextAvailableDate = (slotsData) => {
    const availableSlot = slotsData.find((slot) => slot.status === "available");
    if (availableSlot) {
      setCurrentDate(parseISO(availableSlot.start_time));
    }
  };
  let availableSlotsForCurrentDate = [];

  if (Array.isArray(slots) && slots.length > 0) {
    availableSlotsForCurrentDate = slots.filter(
      (slot) =>
        isSameDay(parseISO(slot.start_time), currentDate) &&
        slot.status === "available"
    );
  }

  const handleDateChange = (increment) => {
    setCurrentDate((prevDate) => addDays(prevDate, increment));
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
    // Here you would typically call an API to update the slot's status
  };

  const submitSlotSelectionToServer = () => {
    // Here you would typically call an API to update the slot's status
    //add the user id and name to the selected slot

    //when deselecting a slot, set the user_id and tenant_name to null
    selectedSlot.tenant_id = user_id;
    selectedSlot.tenant_name = first_name + " " + last_name;
    //automated note
    selectedSlot.note =
      "Automated note: Slot selected by " + first_name + " " + last_name;
    //status
    selectedSlot.status = "booked";
    console.log("Selected slot:", selectedSlot);

    const response = updateUnitViewing(selectedSlot);
    console.log("Response:", response);

    if (response) {
      setSelectedSlot(null);
      fetchSlots(); // Refresh slots after successful update
    }
  };

  const cancelSlotSelection = () => {
    // Here you would typically call an API to update the slot's status
    //add the user id and name to the selected slot

    //when deselecting a slot, set the user_id and tenant_name to null
    selectedSlot.tenant_id = "";
    selectedSlot.tenant_name = "";
    //automated note
    selectedSlot.note = "";
    //status
    selectedSlot.status = "available";
    console.log("Selected slot:", selectedSlot);

    setSelectedSlot(null);
  };
  return (
    <>
      <div
        className="page-content"
        data-bs-theme={isDarkMode ? "dark" : "light"}
        style={{ paddingBottom: "10%" }}
      >
        <PageTitleBox
          pageTitle={"Viewing Schedule Detail"}
          previousPageTitle="Advertisement Campaign"
          previousPageLink={`/advertisementCampaign/${campaignId}`}
        />
        {isLoading && <LoaderView />}
        <div className="container mt-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h2 className="card-title mb-4">Select a Time Slot</h2>
              <div className="row mb-4 align-items-center">
                <div className="col-md-4 col-6">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => handleDateChange(-1)}
                  >
                    Previous Day
                  </button>
                </div>
                <div className="col-md-4 col-12 text-center my-2 my-md-0">
                  <h3 className="mb-0">
                    {format(currentDate, "MMMM d, yyyy")}
                  </h3>
                </div>
                <div className="col-md-4 col-6">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => handleDateChange(1)}
                  >
                    Next Day
                  </button>
                </div>
              </div>
              {availableSlotsForCurrentDate.length > 0 ? (
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                  {availableSlotsForCurrentDate.map((slot) => (
                    <div key={slot.start_time} className="col">
                      <button
                        onClick={() => handleSlotSelect(slot)}
                        className={`btn btn-outline-secondary w-100 ${
                          selectedSlot === slot ? "active" : ""
                        }`}
                      >
                        {format(parseISO(slot.start_time), "h:mm a")} -{" "}
                        {format(parseISO(slot.end_time), "h:mm a")}
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="alert alert-info" role="alert">
                  No available slots for this date. Please try another day.
                </div>
              )}
              {selectedSlot && (
                <div className="mt-4 p-3 bg-light rounded">
                  <h4>Selected Slot:</h4>
                  <p className="mb-2">
                    {format(
                      parseISO(selectedSlot.start_time),
                      "MMMM d, yyyy h:mm a"
                    )}{" "}
                    - {format(parseISO(selectedSlot.end_time), "h:mm a")}
                  </p>
                  <button
                    className="btn btn-danger"
                    onClick={() => cancelSlotSelection()}
                  >
                    Cancel Selection
                  </button>

                  <button
                    className="btn btn-success ms-2"
                    onClick={submitSlotSelectionToServer}
                  >
                    Confirm Selection
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewingScheduleDetail;
