import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserRentalContractTemplateById } from "../../../../services/rental_contract/rentalContractService";
import LoaderView from "../../../reusable/loading/loaderView";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import HtmlPreview from "../../../reusable/preview/HtmlPreview";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";

function ContractTemplateDetail({ isDarkMode }) {
  const pageTitle = "Contract Template";
  usePageTitle({ title: pageTitle });
  const { contractTemplateId } = useParams();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [contractTemplate, setContractTemplate] = useState({
    contract_template_id: "",
    user_id: "",
    portfolio_id: "",
    property_id: "",
    contract_name: "",
    contract_description: "",
    contract_template: "",
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
  });

  useEffect(() => {
    fetchRentalContractById();
  }, []);

  const fetchRentalContractById = async () => {
    const contractTemplate = await getUserRentalContractTemplateById(
      contractTemplateId
    );
    console.log("contractTemplate", contractTemplate);
    setContractTemplate(contractTemplate);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}{" "}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Contract Templates"
            previousPageLink={`/contractTemplates`}
          />

          <div className="card">
            <div className="row pt-3 px-4">
              <div className="col-lg-12 d-flex justify-content-between align-items-center">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  {contractTemplate?.contract_name}
                </label>

                <Link
                  to={`/updateContractTemplate/${contractTemplate?.contract_template_id}`}
                  className="btn btn-info btn-sm mt-2"
                >
                  <i className="ri-pencil-line"></i>
                  Edit
                </Link>
              </div>
            </div>
            {/*Description */}
            {contractTemplate?.contract_description && (
              <div className="row pt-3 px-4 ">
                <div className="col-lg-12">
                  <label
                    for="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Description
                  </label>
                  <p>{contractTemplate?.contract_description}</p>
                </div>
              </div>
            )}
            <div className="row pt-3 px-4 ">
              <div className="col-lg-12">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Preview
                </label>
                <HtmlPreview
                  htmlContent={contractTemplate?.contract_template}
                  isDarkMode={isDarkMode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContractTemplateDetail;
