import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveRentalContract } from "../../../../../services/rental_contract/rentalContractService";
import CurrentTenants from "./CurrentTenants";
import LoaderView from "../../../../reusable/loading/loaderView";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../services/utils/hooks/usePageTitle";
import { toast } from "react-toastify";

interface TenantDetailsPageProps {
  isDarkMode: boolean;
}

const TenantDetailsPage: React.FC<TenantDetailsPageProps> = ({
  isDarkMode,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const { propertyUnitId } = useParams<{ propertyUnitId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [activeRentalContract, setActiveRentalContract] = useState<any>(null);
  const pageTitle = "Current Tenants";
  usePageTitle({ title: pageTitle });

  useEffect(() => {
    const loadRentalContract = async () => {
      try {
        setIsLoading(true);
        if (!propertyUnitId) {
          toast.error("No property unit ID provided");
          return;
        }
        const contract = await getActiveRentalContract(propertyUnitId);
        if (contract) {
          setActiveRentalContract(contract);
        }
      } catch (error) {
        console.error("Error loading rental contract:", error);
        toast.error("Error loading tenant information");
      } finally {
        setIsLoading(false);
      }
    };

    loadRentalContract();
  }, [propertyUnitId]);

  return (
    <div
      className={`${
        isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
      } tw-min-h-screen tw-transition-colors tw-duration-150`}
    >
      {isLoading && <LoaderView />}

      <div className="tw-container-fluid tw-px-4">
        <PageTitleBox
          pageTitle={pageTitle}
          previousPageTitle="Property Unit"
          previousPageLink={`/propertyUnit/${propertyUnitId}`}
        />

        {activeRentalContract ? (
          <div className="tw-transition-all tw-duration-150">
            <CurrentTenants
              activeRentalContract={activeRentalContract}
              isDarkMode={isDarkMode}
            />
          </div>
        ) : (
          <div
            className={`tw-rounded-lg tw-shadow-md ${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-p-6 tw-transition-colors tw-duration-150`}
          >
            <div className="tw-text-center">
              <h5
                className={`tw-text-lg tw-font-medium ${
                  isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                } tw-mb-2`}
              >
                No Active Rental Contract Found
              </h5>
              <p
                className={`tw-mt-2 tw-text-sm ${
                  isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                }`}
              >
                There is no active rental contract for this property unit.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TenantDetailsPage;
