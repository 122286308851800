import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../constants/config";
import { AiContextTrackingService } from "../../../services/ai/AiContextTrackingService";

const LogOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        // Sync context before clearing data
        const contextService = AiContextTrackingService.getInstance();
        await contextService.syncOnLogout();

        // Clear localStorage items
        localStorage.removeItem(config.access_token);
        localStorage.removeItem(config.refresh_token);
        localStorage.removeItem(config.first_name);
        localStorage.removeItem(config.last_name);
        localStorage.removeItem(config.profile_img);
        localStorage.removeItem(config.email);
        localStorage.removeItem(config.is_dark_mode);
        localStorage.removeItem(config.number_of_free_trial_days_left);
        localStorage.removeItem(config.subscription_name);
        localStorage.removeItem(config.subscription_status);
        localStorage.removeItem(config.subscription_id);
        localStorage.removeItem(config.user_id);
        localStorage.removeItem(config.stripe_customer_id);
        localStorage.removeItem(config.is_paid);
        localStorage.removeItem(config.user_type);
        localStorage.removeItem(config.stripe_connect_account_id);

        // Navigate to login page
        navigate("/");
      } catch (error) {
        console.error("Error during logout:", error);
        navigate("/");
      }
    };

    handleLogout();
  }, [navigate]);

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center">
      <div className="tw-text-center">
        <h2 className="tw-text-xl tw-font-semibold tw-mb-2">Logging out...</h2>
        <p className="tw-text-gray-600">Please wait while we sign you out.</p>
      </div>
    </div>
  );
};

export default LogOut;
