import React from "react";
import { DialogAction } from "../../../../models/ai-chat/Message";

interface ActionDialogProps {
  title: string;
  message: string;
  actions: DialogAction[];
  onAction: (action: DialogAction) => void;
  isDarkMode: boolean;
}

export const ActionDialog: React.FC<ActionDialogProps> = ({
  title,
  message,
  actions,
  onAction,
  isDarkMode,
}) => {
  return (
    <div
      className={`tw-mt-4 tw-p-4 tw-rounded-lg tw-border ${
        isDarkMode
          ? "tw-bg-gray-800 tw-border-gray-700"
          : "tw-bg-white tw-border-gray-200"
      }`}
    >
      <h4
        className={`tw-text-sm tw-font-medium ${
          isDarkMode ? "tw-text-white" : "tw-text-gray-900"
        }`}
      >
        {title}
      </h4>
      <p
        className={`tw-mt-1 tw-text-sm ${
          isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
        }`}
      >
        {message}
      </p>
      <div className="tw-mt-4 tw-flex tw-gap-3">
        {actions.map((action) => (
          <button
            key={action.value}
            onClick={() => onAction(action)}
            className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg tw-transition-colors ${
              action.variant === "primary"
                ? "tw-bg-[#1e2124] tw-text-white hover:tw-bg-[#2a2d31]"
                : isDarkMode
                ? "tw-bg-gray-700 tw-text-gray-300 hover:tw-bg-gray-600"
                : "tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
            }`}
          >
            {action.label}
          </button>
        ))}
      </div>
    </div>
  );
};
