import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import type {
  Transaction,
  PlaidTransaction,
  TransactionStatus,
  RevenueCategory,
  ExpenseCategory,
} from "../types/transactionTypes";
import { getAllRevenuesForPropertyUnitYear } from "../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import { getAllExpensesForPropertyUnitYear } from "../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import {
  getTransactions,
  updatePlaidConnection,
} from "../../../../../../services/bank/bankManager";
import { getExpenseCategories } from "../../../../../../services/systemDefined/categories/expenses/sysExpenseCategoryManagement";
import { getRevenueCategories } from "../../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement";
import { handleError } from "../utils/transactionUtils";

export const useTransactions = (propertyUnitId: string) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [uncategorizedTransactions, setUncategorizedTransactions] = useState<
    PlaidTransaction[]
  >([]);
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
    {
      successful_accounts: [],
      items_requiring_attention: { items_requiring_update: [] },
      invalid_tokens: [],
      other_errors: [],
    }
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [sysRevenueCategories, setSysRevenueCategories] = useState<
    RevenueCategory[]
  >([]);
  const [sysExpenseCategories, setSysExpenseCategories] = useState<
    ExpenseCategory[]
  >([]);

  const fetchTransactions = useCallback(async () => {
    try {
      if (!propertyUnitId) {
        console.error(
          "[fetchTransactions] Error: Property unit ID is required"
        );
        return;
      }

      setIsLoading(true);

      // Fetch categories first
      const [expenseCategories, revenueCategories] = await Promise.all([
        getExpenseCategories(),
        getRevenueCategories(),
      ]);

      setSysExpenseCategories(expenseCategories);
      setSysRevenueCategories(revenueCategories);

      // Then fetch transactions
      const [revenueResponse, expenseResponse, plaidResponse] =
        await Promise.all([
          getAllRevenuesForPropertyUnitYear(propertyUnitId),
          getAllExpensesForPropertyUnitYear(propertyUnitId),
          getTransactions(propertyUnitId),
        ]);

      // Process and map transactions
      const mappedTransactions = [
        ...revenueResponse.map((transaction: any) => ({
          is_revenue_or_expense: "revenue",
          unit_revenue_expense_id: transaction.unit_revenue_id,
          property_unit_id: transaction.property_unit_id,
          category_id: transaction.revenue_category_id,
          amount: transaction.revenue_amount,
          date: new Date(transaction.revenue_date),
          is_active: transaction.is_active,
          is_received_from_tenant: transaction.is_received_from_tenant,
          payment_date: new Date(transaction.payment_date),
          is_capital_revenue: transaction.is_capital_revenue,
          title: transaction.revenue_title,
          description: transaction.revenue_description,
          receipt_link: transaction.receipt_link,
          day: new Date(transaction.revenue_date).getDate(),
          month: new Date(transaction.revenue_date).getMonth() + 1,
          year: new Date(transaction.revenue_date).getFullYear(),
          created_at: new Date(transaction.created_at),
          updated_at: new Date(transaction.updated_at),
          transaction_receipt_image_uploads:
            transaction.transaction_receipt_image_uploads,
        })),
        ...expenseResponse.map((transaction: any) => ({
          is_revenue_or_expense: "expense",
          unit_revenue_expense_id: transaction.unit_expense_id,
          property_unit_id: transaction.property_unit_id,
          category_id: transaction.expense_category_id,
          amount: transaction.expense_amount,
          date: new Date(transaction.expense_date),
          is_active: transaction.is_active,
          is_paid_by_tenant: transaction.is_paid_by_tenant,
          is_paid_by_landlord: transaction.is_paid_by_landlord,
          payment_date: new Date(transaction.payment_date),
          is_capital_expense: transaction.is_capital_expense,
          title: transaction.expense_title,
          description: transaction.expense_description,
          receipt_link: transaction.receipt_link,
          day: new Date(transaction.expense_date).getDate(),
          month: new Date(transaction.expense_date).getMonth() + 1,
          year: new Date(transaction.expense_date).getFullYear(),
          created_at: new Date(transaction.created_at),
          updated_at: new Date(transaction.updated_at),
          transaction_receipt_image_uploads:
            transaction.transaction_receipt_image_uploads,
        })),
      ];

      // Process uncategorized (Plaid) transactions
      const uncategorizedTxs =
        plaidResponse?.transactions?.filter(
          (tx: any) => tx.transaction_id && !tx.unit_revenue_expense_id
        ) || [];

      // Update states
      setTransactions(mappedTransactions);
      setUncategorizedTransactions(uncategorizedTxs);
      setTransactionStatus({
        successful_accounts: plaidResponse?.successful_accounts || [],
        items_requiring_attention:
          plaidResponse?.items_requiring_attention || {},
        invalid_tokens: plaidResponse?.invalid_tokens || [],
        other_errors: plaidResponse?.other_errors || [],
      });
    } catch (error) {
      console.error("[fetchTransactions] Error occurred:", error);
      toast.error("Failed to fetch transactions");
    } finally {
      setIsLoading(false);
    }
  }, [propertyUnitId]);

  const handleReauthenticate = useCallback(
    async (item: any) => {
      try {
        setIsUpdating(true);
        console.log("Starting re-authentication for item:", item);

        const plaidHandler = window.Plaid.create({
          token: item.link_token,
          onSuccess: async (public_token: string) => {
            try {
              console.log("Plaid Link success, updating connection...");
              const updateResponse = await updatePlaidConnection(
                public_token,
                item.item_id
              );

              if (updateResponse.error) {
                console.error("Update connection error:", updateResponse.error);
                toast.error(
                  `Failed to update connection: ${
                    updateResponse.error_message || updateResponse.error
                  }`
                );
                return;
              }

              toast.success(
                `Successfully updated connection to ${
                  updateResponse.institution_name || item.institution_name
                }`
              );

              // Wait a moment before fetching transactions to allow backend processing
              setTimeout(async () => {
                console.log("Refreshing transactions after update...");
                await fetchTransactions();
              }, 2000);
            } catch (error) {
              console.error("Error in onSuccess handler:", error);
              handleError(error);
            } finally {
              setIsUpdating(false);
            }
          },
          onExit: (err: any) => {
            console.log("Plaid Link exit:", err);
            setIsUpdating(false);
            if (err != null) {
              toast.error(
                `Error during re-authentication: ${
                  err.display_message || err.error_code
                }`
              );
            } else {
              toast.warning(
                `Please complete re-authentication for ${item.institution_name} to see latest transactions`
              );
            }
          },
          onEvent: (eventName: string, metadata: any) => {
            console.log("Plaid Link event:", eventName, metadata);
          },
        });

        plaidHandler.open();
      } catch (error) {
        console.error("Error initializing Plaid:", error);
        toast.error("Failed to start re-authentication process");
        setIsUpdating(false);
      }
    },
    [fetchTransactions]
  );

  return {
    transactions,
    uncategorizedTransactions,
    transactionStatus,
    isLoading,
    isUpdating,
    fetchTransactions,
    handleReauthenticate,
    sysRevenueCategories,
    sysExpenseCategories,
  };
};

export default useTransactions;
