import React from "react";
import { TransactionStatus } from "../types/transactionTypes";

interface StatusDashboardProps {
  transactionStatus: TransactionStatus;
  categorizedCount: number;
  uncategorizedCount: number;
}

export const StatusDashboard: React.FC<StatusDashboardProps> = ({
  transactionStatus,
  categorizedCount,
  uncategorizedCount,
}) => {
  const needsAttentionCount =
    ((transactionStatus.items_requiring_attention?.items_requiring_update || [])
      .length || 0) + (transactionStatus.invalid_tokens?.length || 0);

  return (
    <div className="row mb-4">
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <h5>Connected Accounts</h5>
            <h3>{transactionStatus.successful_accounts.length}</h3>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <h5>Total Transactions</h5>
            <h3>{categorizedCount}</h3>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <h5>Uncategorized</h5>
            <h3 className="text-warning">{uncategorizedCount}</h3>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-body">
            <h5>Needs Attention</h5>
            <h3 className="text-danger">{needsAttentionCount}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusDashboard;
