import React from "react";
import { Link } from "react-router-dom";
import loader from "../../../../styletheme/images/loading/loader.svg";

interface NoPropertiesFoundProps {
  isDarkMode: boolean;
  searchQuery: string;
}

const NoPropertiesFound: React.FC<NoPropertiesFoundProps> = ({
  isDarkMode,
  searchQuery,
}) => {
  return (
    <div className="tw-w-full tw-p-4">
      <div
        className={`tw-rounded-lg tw-shadow-lg tw-p-8 ${
          isDarkMode
            ? "tw-bg-gray-800 tw-border tw-border-gray-700"
            : "tw-bg-white"
        }`}
      >
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
          <div
            className={`tw-w-24 tw-h-24 tw-rounded-full tw-flex tw-items-center tw-justify-center ${
              isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-100"
            }`}
          >
            <img
              src={loader}
              alt="No Properties"
              className="tw-w-16 tw-h-16 tw-opacity-50"
            />
          </div>
          <h3
            className={`tw-text-xl tw-font-semibold ${
              isDarkMode ? "tw-text-gray-100" : "tw-text-gray-800"
            }`}
          >
            No Properties Found
          </h3>
          <p
            className={`tw-text-center tw-max-w-md ${
              isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
            }`}
          >
            {searchQuery
              ? "No properties match your search criteria. Try adjusting your search."
              : "You haven't added any properties to your portfolio yet. Start by adding your first property."}
          </p>
          <Link
            to="/newproperty"
            className="tw-bg-green-600 tw-text-white tw-px-6 tw-py-2.5 tw-rounded-lg tw-font-medium 
                    tw-hover:bg-green-700 tw-transition-colors tw-duration-200 tw-flex tw-items-center tw-gap-2"
          >
            <i className="ri-add-line"></i>
            Add Your First Property
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPropertiesFound;
