import React from "react";
import {
  Message,
  DialogAction,
  ActionType,
} from "../../../models/ai-chat/Message";
import { ActionDialog } from "./actions/ActionDialog";
import { PortfolioSelector } from "./actions/PortfolioSelector";
import { SkillFlowManager } from "../../../services/ai/SkillFlowManager";

interface SkillMessageProps {
  message: Message;
  isDarkMode: boolean;
  sessionId: string;
}

export const SkillMessage: React.FC<SkillMessageProps> = ({
  message,
  isDarkMode,
  sessionId,
}) => {
  const skillFlowManager = SkillFlowManager.getInstance();

  const handleAction = async (action: DialogAction) => {
    if (!message.metadata?.skill_action) return;

    try {
      if (action.value === "cancel") {
        await skillFlowManager.cancelSkillFlow(
          sessionId,
          message.metadata.skill_action.skill_type
        );
        return;
      }

      await skillFlowManager.handleStepTransition(
        sessionId,
        message.metadata.skill_action.current_step,
        action
      );
    } catch (error) {
      console.error("Error handling skill action:", error);
    }
  };

  const renderSkillComponent = () => {
    const skillAction = message.metadata?.skill_action;
    if (!skillAction) return null;

    const currentStep = skillAction.action_flow.steps[skillAction.current_step];

    // Render different components based on the step type
    switch (currentStep.type) {
      case ActionType.CREATE_TICKET:
        return (
          <ActionDialog
            title="Create Ticket"
            message="Would you like me to help you create a ticket?"
            actions={[
              { label: "Yes", value: "confirm", variant: "primary" },
              { label: "No", value: "cancel", variant: "secondary" },
            ]}
            onAction={handleAction}
            isDarkMode={isDarkMode}
          />
        );

      case ActionType.SELECT_PORTFOLIO:
      case ActionType.CREATE_PORTFOLIO:
        return (
          <PortfolioSelector
            description={currentStep.description}
            onAction={handleAction}
            isDarkMode={isDarkMode}
          />
        );

      // TODO: Add more components for other action types
      // case ActionType.SELECT_PROPERTY:
      // case ActionType.CREATE_PROPERTY:
      // case ActionType.SELECT_UNIT:
      // case ActionType.CREATE_UNIT:
      // case ActionType.ENTER_TICKET_DETAILS:

      default:
        // Fallback to basic action dialog
        return (
          <ActionDialog
            title={currentStep.description}
            message={message.content}
            actions={[
              { label: "Continue", value: "continue", variant: "primary" },
              { label: "Cancel", value: "cancel", variant: "secondary" },
            ]}
            onAction={handleAction}
            isDarkMode={isDarkMode}
          />
        );
    }
  };

  return (
    <div className="tw-space-y-4">
      <div
        className={`tw-text-sm ${
          isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
        }`}
      >
        {message.content}
      </div>
      {message.status === "action_required" && renderSkillComponent()}
      {message.metadata?.skill_action && (
        <div className="tw-mt-4">
          <div
            className={`tw-flex tw-items-center tw-gap-2 tw-text-xs ${
              isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
            }`}
          >
            <svg
              className="tw-w-4 tw-h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <span>
              {skillFlowManager.getStepLabel(
                message.metadata.skill_action.action_flow.steps[
                  message.metadata.skill_action.current_step
                ].type
              )}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
