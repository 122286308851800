import http from "../../../../services/general/httpService";
import api from "../../../../constants/config";
//This week task is to get the tenant screening manual done and hosted
//TODO: need to be able to attach screening pack to a advertisement
//TODO need a create, update list and detail pages for screening pack
//THEN screen to see the submissions, the analytics, automated emails based on the decision made at the step
//screening_pack/create_screening_pack
export async function createScreeningPack(screeningPack) {
  const fullApiEndpoint =
    api.apiEndpoint + `/screening_pack/create_screening_pack`;
  const { data: result } = await http.post(fullApiEndpoint, screeningPack);

  return result;
}

//get_screening_packs_by_user_creator_id
export async function getScreeningPacksByUserCreatorId(userId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/screening_pack/get_screening_packs_by_user_creator_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    user_creator_id: userId,
  });

  return result;
}

//get_screening_packs_by_ownership_group_id
export async function getScreeningPacksByOwnershipGroupId(ownershipGroupId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/screening_pack/get_screening_packs_by_ownership_group_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    ownership_group_id: ownershipGroupId,
  });

  return result;
}

//get_screening_pack_by_id
export async function getScreeningPackById(screeningPackId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/screening_pack/get_screening_pack_by_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    screening_pack_id: screeningPackId,
  });

  return result;
}
