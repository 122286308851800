import http from "../../services/general/httpService";
import config from "../../constants/config";

//get_new_tickets_by_property_unit_id
export async function getNewTicketCountByPropertyUnitId(property_unit_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_ticket/get_new_ticket_count_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}

//get_in_progress_tickets_by_property_unit_id
export async function getInProgressTicketCountByPropertyUnitId(
  property_unit_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_ticket/get_in_progress_ticket_count_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}

//get_pending_tickets_by_property_unit_id
export async function getPendingTicketCountByPropertyUnitId(property_unit_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_ticket/get_pending_ticket_count_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}

//get_completed_tickets_by_property_unit_id
export async function getCompletedTicketCountByPropertyUnitId(
  property_unit_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_ticket/get_completed_ticket_count_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
  });

  return result;
}
