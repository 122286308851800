import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUniversalList } from "../../../../services/task/universalListManagement";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";
import { Card, ListGroup, Badge, Button, Nav } from "react-bootstrap";
import { getPropertyUnitAdvertListingByCampaignId } from "../../../../services/propertyUnits/advertisment/advertismentManagement";
import { actionTypeMap } from "../../../../services/utils/actionTypes";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";
import {
  FiClock,
  FiCalendar,
  FiInfo,
  FiCheckCircle,
  FiList,
} from "react-icons/fi";

const UniversalListDetail = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const navigate = useNavigate();
  const { universalListId } = useParams();
  const pageTitle = "Universal List Details";
  usePageTitle({ title: pageTitle });
  const [isLoading, setIsLoading] = useState(false);
  const [universalList, setUniversalList] = useState({
    universal_list: {
      universal_list_id: "",
      name: "",
      description: "",
      created_by: null,
      created_at: "",
      updated_at: "",
      is_deleted: false,
      list_type: "",
      campaign_ids: [],
      user_id: "",
    },
    universal_list_items: [],
  });
  const [error, setError] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [firstPropertyId, setFirstPropertyId] = useState("");
  const [activeTab, setActiveTab] = useState("details");

  const listTypeMap = {
    general: "General",
    tenant_screening: "Tenant Screening",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    move_in: "Move-In",
    move_out: "Move-Out",
    maintenance: "Maintenance",
    seasonal: "Seasonal",
    emergency: "Emergency",
    legal_compliance: "Legal Compliance",
    financial: "Financial",
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUniversalList();
  }, [universalListId]);

  const fetchUniversalList = async () => {
    try {
      const result = await getUniversalList(universalListId);
      setUniversalList(result);

      if (result.universal_list && result.universal_list.campaign_ids) {
        fetchCampaigns(result.universal_list.campaign_ids);
      }
    } catch (error) {
      console.error("Error fetching universal list:", error);
      setError("Failed to load universal list details.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCampaigns = async (campaignIds) => {
    try {
      const campaignPromises = campaignIds.map((id) =>
        getPropertyUnitAdvertListingByCampaignId(id)
      );
      const results = await Promise.all(campaignPromises);
      const flattenedResults = results.flat();
      setCampaigns(flattenedResults);

      const firstPropertyId = flattenedResults.reduce((acc, campaign) => {
        if (acc) return acc;
        if (campaign.property_unit && campaign.property_unit.property_id) {
          return campaign.property_unit.property_id;
        }
        return null;
      }, null);

      if (firstPropertyId) {
        setFirstPropertyId(firstPropertyId);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setError("Failed to load campaigns.");
    }
  };

  const renderEmptyState = () => (
    <div className="text-center py-5">
      <div className="avatar-lg mx-auto mb-4">
        <div className="avatar-title bg-light text-primary display-5 rounded-circle">
          <FiList />
        </div>
      </div>
      <h5>No Items Found</h5>
      <p className="text-muted mb-4">
        There are no items in this list yet. Items will appear here once they
        are added.
      </p>
      <Button
        variant="primary"
        size="sm"
        className="rounded-pill px-3"
        onClick={() => navigate(`/createUniversalListItem/${universalListId}`)}
      >
        Add New Item
      </Button>
    </div>
  );

  const renderListItems = () => (
    <div className="py-2">
      {universalList.universal_list_items.length > 0 ? (
        <div className="d-flex flex-column gap-3">
          {universalList.universal_list_items.map((item, index) => (
            <div
              key={item.universal_list_item_id}
              className="p-4 rounded-3"
              style={{ backgroundColor: "rgb(247, 249, 252)" }}
            >
              <div className="d-flex gap-3">
                <div className="d-flex align-items-start">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "24px",
                      height: "24px",
                      backgroundColor: "rgba(47, 85, 212, 0.1)",
                      color: "#2f55d4",
                      fontSize: "14px",
                    }}
                  >
                    {index + 1}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start">
                    <h6 className="mb-2 text-dark">{item.name}</h6>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="rounded-pill px-3 py-1"
                      style={{
                        fontSize: "12px",
                        backgroundColor: "white",
                        borderColor: "#2f55d4",
                        color: "#2f55d4",
                      }}
                    >
                      {actionTypeMap[item.action_type] || item.action_type}
                    </Button>
                  </div>
                  <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        renderEmptyState()
      )}
    </div>
  );

  if (isLoading) return <LoaderView />;
  if (error) return <div className="alert alert-danger">{error}</div>;
  if (!universalList.universal_list)
    return <div className="alert alert-info">No universal list found.</div>;

  const { universal_list, universal_list_items } = universalList;

  return (
    <div className={`${getThemeClasses()}`}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={universal_list.name}
          previousPageTitle={firstPropertyId ? "Property Details" : null}
          previousPageLink={
            firstPropertyId ? `/universalList/${firstPropertyId}` : null
          }
        />
        <Card className="shadow-sm">
          <Card.Header className="bg-white border-bottom-0 pt-4 px-4">
            <Nav
              variant="tabs"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="border-0"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="details"
                  className={`border-0 px-4 ${
                    activeTab === "details"
                      ? "text-primary border-primary border-bottom"
                      : "text-muted"
                  }`}
                >
                  <FiInfo className="me-2" />
                  List Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="items"
                  className={`border-0 px-4 ${
                    activeTab === "items"
                      ? "text-primary border-primary border-bottom"
                      : "text-muted"
                  }`}
                >
                  List Items
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="px-4">
            {activeTab === "details" ? (
              <div className="row g-4">
                <div className="col-md-4">
                  <Card className="h-100 shadow-sm">
                    <Card.Body className="p-4">
                      <div className="d-flex align-items-center mb-4">
                        <FiInfo className="me-2" size={20} />
                        <Card.Title className="mb-0 h5">
                          List Details
                        </Card.Title>
                      </div>
                      <ListGroup variant="flush" className="border-0">
                        <ListGroup.Item className="border-0 px-0 py-3">
                          <div className="d-flex align-items-center mb-2">
                            <strong className="text-secondary">
                              Description
                            </strong>
                          </div>
                          <p className="mb-0 text-muted">
                            {universal_list.description}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0 px-0 py-3">
                          <div className="d-flex align-items-center mb-2">
                            <strong className="text-secondary">Type</strong>
                          </div>
                          <Badge
                            bg="primary"
                            className="px-3 py-2 rounded-pill"
                          >
                            {listTypeMap[universal_list.list_type] ||
                              universal_list.list_type}
                          </Badge>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0 px-0 py-3">
                          <div className="d-flex align-items-center mb-2">
                            <FiCalendar
                              className="me-2 text-primary"
                              size={16}
                            />
                            <strong className="text-secondary">Created</strong>
                          </div>
                          <p className="mb-0 text-muted">
                            {new Date(
                              universal_list.created_at
                            ).toLocaleString()}
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0 px-0 py-3">
                          <div className="d-flex align-items-center mb-2">
                            <FiClock className="me-2 text-primary" size={16} />
                            <strong className="text-secondary">
                              Last Updated
                            </strong>
                          </div>
                          <p className="mb-0 text-muted">
                            {new Date(
                              universal_list.updated_at
                            ).toLocaleString()}
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-8">{renderListItems()}</div>
              </div>
            ) : (
              renderListItems()
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default UniversalListDetail;
