import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "../../../../common/table/DataTable";
import { Column } from "../../../../common/table/types/TableTypes";
import { Ticket, TicketsListProps } from "../../../tickets/types/ticket";

const TicketsList: React.FC<TicketsListProps> = ({
  unitTickets,
  isLoading = false,
  isDarkMode = false,
}) => {
  const navigate = useNavigate();

  const columns: Column<Ticket>[] = [
    {
      field: "ticket_number",
      headerName: "Number",
      flex: 1.2,
      renderCell: (row) => (
        <span className="tw-font-medium">{row.ticket_number}</span>
      ),
    },
    {
      field: "ticket_title",
      headerName: "Title",
      flex: 3,
      renderCell: (row) => (
        <Link
          to={`/ticket/${row.ticket_id}`}
          className="tw-text-blue-500 hover:tw-text-blue-600 tw-font-medium"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          {row.ticket_title}
        </Link>
      ),
    },
    {
      field: "ticket_status",
      headerName: "Status",
      flex: 1.2,
      renderCell: (row) => (
        <span
          className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${getStatusClass(
            row.ticket_status,
            isDarkMode
          )}`}
        >
          {row.ticket_status}
        </span>
      ),
    },
    {
      field: "priority_info.priority",
      headerName: "Priority",
      flex: 1.2,
      renderCell: (row) => (
        <span
          className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${getPriorityClass(
            row.priority_info?.priority,
            isDarkMode
          )}`}
        >
          {row.priority_info?.priority}
        </span>
      ),
    },
    {
      field: "ticket_type",
      headerName: "Type",
      flex: 1.5,
      renderCell: (row) => (
        <span className="tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-100 tw-text-blue-700 dark:tw-bg-blue-900/50 dark:tw-text-blue-200">
          {row.ticket_type}
        </span>
      ),
    },
    {
      field: "ticket_description",
      headerName: "Description",
      flex: 4,
      renderCell: (row) => (
        <span className="tw-line-clamp-2">{row.ticket_description}</span>
      ),
    },
  ];

  return (
    <div className="tw-card" id="ticketsList">
      <div className="tw-card-body">
        <DataTable<Ticket>
          data={unitTickets || []}
          columns={columns}
          isDarkMode={isDarkMode}
          onRowClick={(row) => navigate(`/ticket/${row.ticket_id}`)}
          searchPlaceholder="Search tickets..."
          searchFields={[
            "ticket_number",
            "ticket_title",
            "ticket_status",
            "ticket_type",
            "ticket_description",
            "priority_info.priority",
          ]}
          filterOptions={[
            {
              field: "ticket_status",
              options: [
                { label: "All Status", value: "all" },
                { label: "Open", value: "Open" },
                { label: "In Progress", value: "In Progress" },
                { label: "Pending", value: "Pending" },
                { label: "Resolved", value: "Resolved" },
                { label: "Closed", value: "Closed" },
              ],
            },
            {
              field: "priority_info.priority" as keyof Ticket,
              options: [
                { label: "All Priorities", value: "all" },
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" },
              ],
            },
          ]}
          noDataMessage="No tickets found"
          pagination={{
            currentPage: 1,
            pageSize: 10,
            totalItems: unitTickets?.length || 0,
            totalPages: Math.ceil((unitTickets?.length || 0) / 10),
            onPageChange: () => {},
            onPageSizeChange: () => {},
            pageSizeOptions: [10, 25, 50, 100],
          }}
          showPagination={true}
        />
      </div>
    </div>
  );
};

// Helper functions for status and priority styling
const getStatusClass = (status: string, isDarkMode: boolean): string => {
  const statusMap: Record<string, string> = {
    Open: isDarkMode
      ? "tw-bg-yellow-900/50 tw-text-yellow-200"
      : "tw-bg-yellow-100 tw-text-yellow-800",
    "In Progress": isDarkMode
      ? "tw-bg-blue-900/50 tw-text-blue-200"
      : "tw-bg-blue-100 tw-text-blue-800",
    Pending: isDarkMode
      ? "tw-bg-orange-900/50 tw-text-orange-200"
      : "tw-bg-orange-100 tw-text-orange-800",
    Resolved: isDarkMode
      ? "tw-bg-green-900/50 tw-text-green-200"
      : "tw-bg-green-100 tw-text-green-800",
    Closed: isDarkMode
      ? "tw-bg-gray-900/50 tw-text-gray-200"
      : "tw-bg-gray-100 tw-text-gray-800",
  };
  return (
    statusMap[status] ||
    (isDarkMode
      ? "tw-bg-gray-900/50 tw-text-gray-200"
      : "tw-bg-gray-100 tw-text-gray-800")
  );
};

const getPriorityClass = (
  priority: string | undefined,
  isDarkMode: boolean
): string => {
  const priorityMap: Record<string, string> = {
    High: isDarkMode
      ? "tw-bg-red-900/50 tw-text-red-200"
      : "tw-bg-red-100 tw-text-red-800",
    Medium: isDarkMode
      ? "tw-bg-yellow-900/50 tw-text-yellow-200"
      : "tw-bg-yellow-100 tw-text-yellow-800",
    Low: isDarkMode
      ? "tw-bg-green-900/50 tw-text-green-200"
      : "tw-bg-green-100 tw-text-green-800",
  };
  return (
    (priority && priorityMap[priority]) ||
    (isDarkMode
      ? "tw-bg-gray-900/50 tw-text-gray-200"
      : "tw-bg-gray-100 tw-text-gray-800")
  );
};

export default TicketsList;
