import React from 'react';
import { Link } from 'react-router-dom';

const TicketCard = ({ ticket }) => {
  // Format date in a cleaner way
  const formattedDate = new Date(ticket.updated_at).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Truncate description with proper handling
  const truncatedDescription = ticket?.ticket_description 
    ? ticket.ticket_description.length > 80 
      ? ticket.ticket_description.substring(0, 80) + '...' 
      : ticket.ticket_description
    : '';

  return (
    <div className='card tasks-box shadow-sm'>
      <div className='card-body p-3'>
        <div className='d-flex align-items-center mb-2'>
          <h6 className='fs-14 mb-0 text-truncate'>
            <Link
              to={`/ticket/${ticket.ticket_id}`}
              className='text-muted fw-medium'
            >
              #{ticket.ticket_number}
            </Link>
          </h6>
          <div className='ms-auto'>
            <span className={`badge ${getPriorityBadgeClass(ticket.priority_info?.priority)}`}>
              {ticket.priority_info?.priority}
            </span>
          </div>
        </div>
        
        <h6 className='fs-15 text-truncate mb-2'>
          <Link
            to={`/ticket/${ticket.ticket_id}`}
            className='text-body fw-medium'
          >
            {ticket.ticket_title}
          </Link>
        </h6>
        
        <p className='text-muted fs-13 mb-3'>
          {truncatedDescription}
        </p>
        
        <div className='d-flex gap-2 mb-2'>
          <span className={`badge ${getStatusBadgeClass(ticket.ticket_status)}`}>
            {ticket.ticket_status}
          </span>
          <span className='badge bg-info-subtle text-info'>
            {ticket.ticket_type}
          </span>
        </div>
      </div>
      
      <div className='card-footer bg-light border-top-dashed py-2'>
        <div className='d-flex align-items-center'>
          <div className='flex-grow-1'>
            <span className='text-muted fs-13'>
              <i className='ri-time-line align-bottom me-1'></i>
              {formattedDate}
            </span>
          </div>
          <div className='d-flex gap-3'>
            <Link to={`/ticket/${ticket.ticket_id}`} className='text-muted fs-13'>
              <i className='ri-eye-line align-bottom me-1'></i>View
            </Link>
            <Link to={`/ticket/${ticket.ticket_id}`} className='text-muted fs-13'>
              <i className='ri-question-answer-line align-bottom me-1'></i>Reply
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper functions to determine badge classes based on status and priority
const getStatusBadgeClass = (status) => {
  switch (status?.toLowerCase()) {
    case 'open':
      return 'bg-warning-subtle text-warning';
    case 'in progress':
      return 'bg-info-subtle text-info';
    case 'resolved':
      return 'bg-success-subtle text-success';
    case 'closed':
      return 'bg-secondary-subtle text-secondary';
    default:
      return 'bg-primary-subtle text-primary';
  }
};

const getPriorityBadgeClass = (priority) => {
  switch (priority?.toLowerCase()) {
    case 'high':
      return 'bg-danger-subtle text-danger';
    case 'medium':
      return 'bg-warning-subtle text-warning';
    case 'low':
      return 'bg-success-subtle text-success';
    default:
      return 'bg-primary-subtle text-primary';
  }
};

export default TicketCard;
