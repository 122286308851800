import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Guide,
  getGuideById,
  deleteGuide,
  updateGuideAccess,
  incrementGuideStats,
} from "../../../../services/guidebook/guidebookService";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import { toast } from "react-toastify";
import { usePageTitle } from "services/utils/hooks/usePageTitle";
import "./GuideDetail.css";
import { PDFDocument, rgb } from "pdf-lib";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { getPropertiesOwnedByUserId } from "services/portfolio/ownership/portfolioOwnershipManagement";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getPropertyUnitsByPropertyId } from "../../../../services/propertyUnits/propertyUnitManagement";

//TODO: ability to link to a ticket (reference in a ticket if the unit has access to the guide), also seeing the guide in the tenant portal
//TODO: ability to edit existing guides and use AI to generate new guides, deleting a guide
//TODO: Other items: multi unit advertisment screen with waiting list and custom branding
//TODO: ACH support and showing Stripe transaction history related to the rent payment with rent specific verification screen



interface GuideDetailProps {
  isDarkMode: boolean;
}

interface Property {
  property: {
    property_id: string;
    property_alias_name: string;
    portfolio_id: string;
    units: Array<{
      property_unit_id: string;
      unit_alias_name: string;
      unit_name: string;
    }>;
  };
  portfolio: {
    portfolio_id: string;
    portfolio_name: string;
  };
}

interface ShareModalProps {
  show: boolean;
  onHide: () => void;
  onShare: (selectedItems: {
    portfolios: string[];
    properties: string[];
    units: string[];
  }) => void;
  properties: Property[];
  isSharing: boolean;
  isLoadingProperties: boolean;
  guide: Guide | null;
}

const ShareModal: React.FC<ShareModalProps> = ({
  show,
  onHide,
  onShare,
  properties,
  isSharing,
  isLoadingProperties,
  guide,
}) => {
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [expandedProperties, setExpandedProperties] = useState<string[]>([]);

  // Load initial selections when modal opens
  useEffect(() => {
    if (show && guide) {
      setSelectedPortfolios(guide.portfolio_ids || []);
      setSelectedProperties(guide.property_ids || []);
      setSelectedUnits(guide.property_unit_ids || []);
    }
  }, [show, guide]);

  const portfolioOptions = Array.from(
    new Set(properties.map((p) => p.portfolio.portfolio_id))
  ).map((id) => {
    const portfolio = properties.find(
      (p) => p.portfolio.portfolio_id === id
    )?.portfolio;
    return {
      value: id,
      label: portfolio?.portfolio_name,
      properties: properties.filter((p) => p.portfolio.portfolio_id === id),
    };
  });

  const handlePortfolioSelect = (portfolioId: string) => {
    const portfolio = portfolioOptions.find((p) => p.value === portfolioId);
    if (!portfolio) return;

    setSelectedPortfolios((prev) => {
      const isSelected = prev.includes(portfolioId);
      const newPortfolios = isSelected
        ? prev.filter((id) => id !== portfolioId)
        : [...prev, portfolioId];

      // Update properties and units based on portfolio selection
      const portfolioProperties = portfolio.properties.map(
        (p) => p.property.property_id
      );
      const portfolioUnits = portfolio.properties.flatMap((p) =>
        (p.property.units || []).map((u) => u.property_unit_id)
      );

      setSelectedProperties((prevProps) => {
        if (isSelected) {
          return prevProps.filter((id) => !portfolioProperties.includes(id));
        }
        return [...new Set([...prevProps, ...portfolioProperties])];
      });

      setSelectedUnits((prevUnits) => {
        if (isSelected) {
          return prevUnits.filter((id) => !portfolioUnits.includes(id));
        }
        return [...new Set([...prevUnits, ...portfolioUnits])];
      });

      return newPortfolios;
    });
  };

  const handlePropertySelect = (
    propertyId: string,
    units: Array<{ property_unit_id: string }> = []
  ) => {
    setSelectedProperties((prev) => {
      const isSelected = prev.includes(propertyId);
      const newProperties = isSelected
        ? prev.filter((id) => id !== propertyId)
        : [...prev, propertyId];

      // Update units based on property selection
      const propertyUnits = units.map((u) => u.property_unit_id);
      setSelectedUnits((prevUnits) => {
        if (isSelected) {
          return prevUnits.filter((id) => !propertyUnits.includes(id));
        }
        return [...new Set([...prevUnits, ...propertyUnits])];
      });

      return newProperties;
    });
  };

  const handleUnitSelect = (unitId: string) => {
    setSelectedUnits((prev) => {
      if (prev.includes(unitId)) {
        return prev.filter((id) => id !== unitId);
      }
      return [...prev, unitId];
    });
  };

  const togglePropertyExpand = (propertyId: string) => {
    setExpandedProperties((prev) => {
      if (prev.includes(propertyId)) {
        return prev.filter((id) => id !== propertyId);
      }
      return [...prev, propertyId];
    });
  };

  // Calculate changes from initial state
  const getAccessChanges = () => {
    const initialPortfolios = guide?.portfolio_ids || [];
    const initialProperties = guide?.property_ids || [];
    const initialUnits = guide?.property_unit_ids || [];

    const addedPortfolios = selectedPortfolios.filter(
      (id) => !initialPortfolios.includes(id)
    );
    const removedPortfolios = initialPortfolios.filter(
      (id) => !selectedPortfolios.includes(id)
    );
    const addedProperties = selectedProperties.filter(
      (id) => !initialProperties.includes(id)
    );
    const removedProperties = initialProperties.filter(
      (id) => !selectedProperties.includes(id)
    );
    const addedUnits = selectedUnits.filter((id) => !initialUnits.includes(id));
    const removedUnits = initialUnits.filter(
      (id) => !selectedUnits.includes(id)
    );

    return {
      added: {
        portfolios: addedPortfolios.length,
        properties: addedProperties.length,
        units: addedUnits.length,
      },
      removed: {
        portfolios: removedPortfolios.length,
        properties: removedProperties.length,
        units: removedUnits.length,
      },
    };
  };

  const changes = getAccessChanges();
  const hasChanges =
    Object.values(changes.added).some((v) => v > 0) ||
    Object.values(changes.removed).some((v) => v > 0);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton={!isSharing}>
        <Modal.Title>Share Guide</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="mb-4">
          <h6 className="mb-2">How Sharing Works</h6>
          <p className="text-muted small mb-0">
            1. Select portfolios, properties, or individual units
          </p>
          <p className="text-muted small mb-0">
            2. Selected items will have access to this guide
          </p>
          <p className="text-muted small">
            3. Property managers and tenants can view the guide
          </p>
        </div>

        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">Select Items</h6>
            <div className="text-muted small">
              {hasChanges ? (
                <>
                  {changes.added.portfolios > 0 &&
                    `+${changes.added.portfolios} portfolios, `}
                  {changes.removed.portfolios > 0 &&
                    `-${changes.removed.portfolios} portfolios, `}
                  {changes.added.properties > 0 &&
                    `+${changes.added.properties} properties, `}
                  {changes.removed.properties > 0 &&
                    `-${changes.removed.properties} properties, `}
                  {changes.added.units > 0 && `+${changes.added.units} units, `}
                  {changes.removed.units > 0 &&
                    `-${changes.removed.units} units`}
                </>
              ) : (
                "No changes"
              )}
            </div>
          </div>
        </div>

        {isLoadingProperties ? (
          <div className="d-flex justify-content-center align-items-center py-5">
            <div className="d-flex flex-column align-items-center">
              <div className="spinner-border text-primary mb-2" role="status">
                <span className="visually-hidden">Loading properties...</span>
              </div>
              <div className="text-muted">Loading properties...</div>
            </div>
          </div>
        ) : (
          <div className="portfolio-list">
            {portfolioOptions.map((portfolio) => {
              const portfolioProperties = portfolio.properties;
              const allPropertiesSelected = portfolioProperties.every((p) =>
                selectedProperties.includes(p.property.property_id)
              );
              const somePropertiesSelected = portfolioProperties.some((p) =>
                selectedProperties.includes(p.property.property_id)
              );

              return (
                <div key={portfolio.value} className="portfolio-group mb-3">
                  <div
                    className="portfolio-header d-flex align-items-center p-2 bg-light rounded cursor-pointer"
                    onClick={() => handlePortfolioSelect(portfolio.value)}
                  >
                    <div className="form-check mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={allPropertiesSelected}
                        onChange={() => {}}
                        ref={(input) => {
                          if (input) {
                            input.indeterminate =
                              somePropertiesSelected && !allPropertiesSelected;
                          }
                        }}
                      />
                    </div>
                    <span className="ms-2 fw-medium">{portfolio.label}</span>
                    <span className="ms-auto text-muted small">
                      {portfolioProperties.length}{" "}
                      {portfolioProperties.length === 1
                        ? "property"
                        : "properties"}
                    </span>
                  </div>

                  <div className="properties-list ms-4 mt-2">
                    {portfolioProperties.map((property) => {
                      const propertyUnits = property.property.units || [];
                      const allUnitsSelected = propertyUnits.every((u) =>
                        selectedUnits.includes(u.property_unit_id)
                      );
                      const someUnitsSelected = propertyUnits.some((u) =>
                        selectedUnits.includes(u.property_unit_id)
                      );
                      const isExpanded = expandedProperties.includes(
                        property.property.property_id
                      );

                      return (
                        <div key={property.property.property_id}>
                          <div className="property-item d-flex align-items-center p-2">
                            <div className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedProperties.includes(
                                  property.property.property_id
                                )}
                                onChange={() =>
                                  handlePropertySelect(
                                    property.property.property_id,
                                    propertyUnits
                                  )
                                }
                              />
                            </div>
                            <span
                              className="ms-2 cursor-pointer"
                              onClick={() =>
                                togglePropertyExpand(
                                  property.property.property_id
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {property.property.property_alias_name}
                              <span className="text-muted small ms-2">
                                ({propertyUnits.length}{" "}
                                {propertyUnits.length === 1 ? "unit" : "units"})
                              </span>
                              <i
                                className={`ms-2 ri-arrow-${
                                  isExpanded ? "down" : "right"
                                }-s-line`}
                              ></i>
                            </span>
                          </div>

                          {isExpanded && propertyUnits.length > 0 && (
                            <div className="units-list ms-4 mt-1 mb-2">
                              <div className="row g-2">
                                {propertyUnits.map((unit) => (
                                  <div
                                    key={unit.property_unit_id}
                                    className="col-12"
                                  >
                                    <div className="d-flex align-items-center p-1">
                                      <div className="form-check mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={selectedUnits.includes(
                                            unit.property_unit_id
                                          )}
                                          onChange={() =>
                                            handleUnitSelect(
                                              unit.property_unit_id
                                            )
                                          }
                                        />
                                        <label className="form-check-label ms-2 small">
                                          {unit.unit_name ||
                                            unit.unit_alias_name}
                                          {unit.unit_name &&
                                            unit.unit_alias_name &&
                                            unit.unit_name !==
                                              unit.unit_alias_name && (
                                              <span className="text-muted">
                                                ({unit.unit_alias_name})
                                              </span>
                                            )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={onHide} disabled={isSharing}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() =>
            onShare({
              portfolios: selectedPortfolios,
              properties: selectedProperties,
              units: selectedUnits,
            })
          }
          disabled={!hasChanges || isSharing}
        >
          {isSharing ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Updating access...
            </>
          ) : (
            <>Update access</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

// Add custom CSS for the wider modal
const styles = `
.modal-90w {
  max-width: 90%;
  width: 1000px;
}

.portfolio-list {
  max-height: 60vh;
  overflow-y: auto;
}

.unit-item {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 4px 8px;
}
`;

// Add styles to head
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const GuideDetail: React.FC<GuideDetailProps> = ({ isDarkMode }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [guide, setGuide] = useState<Guide | null>(null);
  usePageTitle({ title: guide?.title || "Guidebook" });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  useEffect(() => {
    if (id) {
      loadGuide(id);
    }
  }, [id]);

  // Add scroll handler
  useEffect(() => {
    const handleScroll = async () => {
      const params = new URLSearchParams(window.location.search);
      const shouldScroll = params.get("scrollTo") === "true";
      const hash = window.location.hash;

      if (shouldScroll && hash && guide) {
        // Remove the 'scrollTo' parameter from URL without reloading
        const newUrl = window.location.pathname + window.location.hash;
        window.history.replaceState({}, "", newUrl);

        // Wait a bit for content to be fully rendered
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      }
    };

    handleScroll();
  }, [guide]); // Run when guide data is loaded

  useEffect(() => {
    if (showShareModal) {
      loadProperties();
    }
  }, [showShareModal]);

  const loadGuide = async (guideId: string) => {
    try {
      const data = await getGuideById(guideId);
      setGuide(data || null);
    } catch (error) {
      console.error("Error loading guide:", error);
      toast.error("Failed to load guide details");
    } finally {
      setIsLoading(false);
    }
  };

  const loadProperties = async () => {
    setIsLoadingProperties(true);
    try {
      const data = await getPropertiesOwnedByUserId();
      // Fetch units for each property
      const propertiesWithUnits = await Promise.all(
        data.map(async (property: Property) => {
          const units = await getPropertyUnitsByPropertyId(
            property.property.property_id
          );
          return {
            ...property,
            property: {
              ...property.property,
              units: units || [],
            },
          };
        })
      );
      setProperties(propertiesWithUnits);
    } catch (error) {
      console.error("Error loading properties:", error);
      toast.error("Failed to load properties");
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const handleDelete = async () => {
    if (!guide) return;

    try {
      await deleteGuide(guide.guide_id);
      toast.success("Guide deleted successfully");
      navigate("/guidebook");
    } catch (error) {
      console.error("Error deleting guide:", error);
      toast.error("Failed to delete guide");
    }
  };

  const handleShare = async (selectedItems: {
    portfolios: string[];
    properties: string[];
    units: string[];
  }) => {
    if (!guide || !id) return;

    setIsSharing(true);
    try {
      await updateGuideAccess(
        id,
        guide.author_id,
        selectedItems.portfolios,
        selectedItems.properties,
        selectedItems.units
      );
      toast.success("Guide shared successfully");
      setShowShareModal(false);
    } catch (error) {
      console.error("Error sharing guide:", error);
      toast.error("Failed to share guide");
    } finally {
      setIsSharing(false);
    }
  };

  const handleDownload = async () => {
    if (!guide || !contentRef.current) return;

    setIsDownloading(true);
    try {
      const pdfContent = document.createElement("div");
      pdfContent.innerHTML = `
        <div style="padding: 20px; font-family: Arial, sans-serif; color: #333; max-width: 800px; margin: 0 auto;">
          <!-- Header -->
          <div style="margin-bottom: 20px; border-bottom: 2px solid #0d6efd; padding-bottom: 15px;">
            <h1 style="font-size: 24px; color: #0d6efd; margin: 0 0 8px 0;">${
              guide.title
            }</h1>
            <div style="color: #666; font-size: 12px;">
              Last updated: ${new Date(guide.updated_at).toLocaleDateString()}
            </div>
          </div>

          <!-- Category and Tags -->
          <div style="margin-bottom: 20px;">
            <span style="background: #0d6efd; color: white; padding: 4px 12px; border-radius: 15px; font-size: 12px; margin-right: 8px; display: inline-block;">
              ${guide.category}
            </span>
            ${guide.tags
              .map(
                (tag) => `
              <span style="background: #6c757d; color: white; padding: 4px 12px; border-radius: 15px; font-size: 12px; margin-right: 8px; display: inline-block;">
                ${tag}
              </span>
            `
              )
              .join("")}
          </div>

          <!-- Overview -->
          <div style="margin-bottom: 30px; color: #666; font-size: 14px; line-height: 1.5;">
            A comprehensive guide to help enhance your experience and streamline communication.
          </div>

          <!-- Table of Contents -->
          <div style="margin-bottom: 30px; background: #f8f9fa; padding: 15px; border-radius: 8px;">
            <h2 style="font-size: 18px; color: #0d6efd; margin: 0 0 12px 0;">Table of Contents</h2>
            ${guide.sections
              .map(
                (section, index) => `
              <div style="margin: 6px 0;">
                <span style="color: #0d6efd; font-weight: bold; margin-right: 8px;">${
                  index + 1
                }.</span>
                <span style="color: #333;">${section.title}</span>
              </div>
            `
              )
              .join("")}
          </div>

          <!-- Sections -->
          ${guide.sections
            .map(
              (section, index) => `
            <div style="margin-bottom: 40px;">
              <div style="display: flex; align-items: center; margin-bottom: 15px;">
                <div style="background: #0d6efd; color: white; width: 28px; height: 28px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-right: 12px; font-weight: bold; font-size: 14px;">
                  ${index + 1}
                </div>
                <h2 style="font-size: 20px; color: #0d6efd; margin: 0;">${
                  section.title
                }</h2>
              </div>
              <div style="margin-left: 40px; line-height: 1.5; color: #333; font-size: 14px;">
                ${section.content.replace(/\n/g, "<br>")}
              </div>
            </div>
          `
            )
            .join("")}
        </div>
      `;

      document.body.appendChild(pdfContent);

      const pdf = new jsPDF({
        format: "a4",
        unit: "mm",
        orientation: "portrait",
      });

      const margin = 10;
      const pageWidth = 210;
      const pageHeight = 297;
      const contentWidth = pageWidth - margin * 2;
      const footerHeight = 20;

      const canvas = await html2canvas(pdfContent, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
        windowWidth: 800,
        onclone: (clonedDoc) => {
          const style = clonedDoc.createElement("style");
          style.innerHTML = `
            @page { margin: ${margin}mm; }
            * { -webkit-print-color-adjust: exact !important; }
            div { page-break-inside: avoid; }
            h2 { page-break-after: avoid; }
          `;
          clonedDoc.head.appendChild(style);
        },
      });

      const contentHeight = (canvas.height * contentWidth) / canvas.width;
      const pageContentHeight = pageHeight - margin * 2 - footerHeight;
      const totalPages = Math.ceil(contentHeight / pageContentHeight);

      let remainingHeight = contentHeight;
      let currentPage = 1;
      let sourceY = 0;

      while (remainingHeight > 0) {
        if (currentPage > 1) {
          pdf.addPage();
        }

        // Calculate visible content height for this page
        const visibleHeight = Math.min(remainingHeight, pageContentHeight);
        const scaleFactor = canvas.width / contentWidth;

        // Create a temporary canvas for the current page
        const pageCanvas = document.createElement("canvas");
        const ctx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = visibleHeight * scaleFactor;

        // Draw the portion of content for this page
        ctx?.drawImage(
          canvas,
          0,
          sourceY * scaleFactor,
          canvas.width,
          visibleHeight * scaleFactor,
          0,
          0,
          canvas.width,
          visibleHeight * scaleFactor
        );

        // Add content for current page
        pdf.addImage(
          pageCanvas,
          "PNG",
          margin,
          margin,
          contentWidth,
          visibleHeight
        );

        // Add footer with proper spacing
        const footerY = pageHeight - footerHeight - margin;
        pdf.setFillColor(249, 250, 251);
        pdf.rect(margin, footerY, contentWidth, footerHeight, "F");

        pdf.setFontSize(8);
        pdf.setTextColor(128, 128, 128);

        // Stats
        pdf.text(`${guide.views} views`, margin + 5, footerY + 8);
        pdf.text(`${guide.downloads} downloads`, pageWidth / 2, footerY + 8, {
          align: "center",
        });
        pdf.text(
          `★ ${guide.rating.toFixed(1)}`,
          pageWidth - margin - 5,
          footerY + 8,
          { align: "right" }
        );

        // Page numbers
        pdf.text(
          `Page ${currentPage} of ${totalPages}`,
          pageWidth / 2,
          footerY + 15,
          { align: "center" }
        );

        remainingHeight -= visibleHeight;
        sourceY += visibleHeight;
        currentPage++;
      }

      document.body.removeChild(pdfContent);
      pdf.save(`${guide.title.replace(/\s+/g, "_")}.pdf`);
      toast.success("Guide downloaded successfully");

      if (id) {
        await incrementGuideStats(id, "downloads");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF");
    } finally {
      setIsDownloading(false);
    }
  };

  const renderContent = (content: string) => {
    // Convert markdown-style bold text to HTML
    const processedContent = content
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\n/g, "<br />");

    return processedContent;
  };

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!guide) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <h2>Guide Not Found</h2>
            <button
              className="btn btn-primary mt-3"
              onClick={() => navigate("/guidebook")}
            >
              Back to Guides
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={guide.title}
          previousPageLink="/guidebook"
          previousPageTitle="Guidebook"
        />

        <div className="row">
          <div className="col-lg-9">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="text-muted mb-0">
                      Last updated:{" "}
                      {new Date(guide.updated_at).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="d-flex gap-2">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        id="guideOptionsDropdown"
                      >
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            navigate(`/guidebook/edit/${guide.guide_id}`)
                          }
                        >
                          <i className="ri-edit-line me-2"></i>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowShareModal(true)}>
                          <i className="ri-share-line me-2"></i>
                          Share
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleDownload}>
                          <i className="ri-download-line me-2"></i>
                          Download
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => setShowDeleteModal(true)}
                          className="text-danger"
                        >
                          <i className="ri-delete-bin-line me-2"></i>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="card-body" ref={contentRef}>
                <div className="mb-4">
                  <span className="badge bg-primary me-2">
                    {guide.category}
                  </span>
                  {guide.tags.map((tag) => (
                    <span key={tag} className="badge bg-secondary me-2">
                      {tag}
                    </span>
                  ))}
                </div>

                <div className="guide-content">
                  {/* Overview section */}
                  <div className="mb-5">
                    <p className="lead text-muted">
                      A comprehensive guide to help enhance your experience and
                      streamline communication.
                    </p>
                  </div>

                  {/* Table of Contents */}
                  <div className="mb-5">
                    <h4 className="mb-3">Table of Contents</h4>
                    <div className="list-group">
                      {guide.sections.map((section, index) => (
                        <a
                          key={section.section_id}
                          href={`#section-${section.section_id}`}
                          className="list-group-item list-group-item-action"
                        >
                          <strong>
                            {index + 1}. {section.title}
                          </strong>
                        </a>
                      ))}
                    </div>
                  </div>

                  {/* Sections */}
                  {guide.sections.map((section, index) => (
                    <div
                      key={section.section_id}
                      id={`section-${section.section_id}`}
                      className="mb-5"
                    >
                      <div className="d-flex align-items-center mb-4">
                        <div className="section-number me-3">
                          <span className="badge bg-primary rounded-circle p-2">
                            {index + 1}
                          </span>
                        </div>
                        <h3 className="mb-0">{section.title}</h3>
                      </div>
                      <div className="ps-5">
                        <div
                          className="guide-section-content"
                          dangerouslySetInnerHTML={{
                            __html: renderContent(section.content),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-between text-muted mt-4 pt-4 border-top">
                  <span>{guide.views} views</span>
                  <span>{guide.downloads} downloads</span>
                  <span>★ {guide.rating.toFixed(1)}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="col-lg-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-3">Quick Actions</h5>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowShareModal(true)}
                    disabled={isSharing}
                  >
                    {isSharing ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <i className="ri-share-line me-2"></i>
                    )}
                    Share Guide
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={handleDownload}
                    disabled={isDownloading}
                  >
                    {isDownloading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <i className="ri-download-line me-2"></i>
                    )}
                    Download PDF
                  </button>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-body">
                <h5 className="card-title mb-3">Guide Info</h5>
                <div className="mb-3">
                  <small className="text-muted d-block">Category</small>
                  <span className="badge bg-primary">{guide.category}</span>
                </div>
                <div className="mb-3">
                  <small className="text-muted d-block">Tags</small>
                  <div className="d-flex flex-wrap gap-2">
                    {guide.tags.map((tag) => (
                      <span key={tag} className="badge bg-secondary">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <small className="text-muted d-block">Last Updated</small>
                  <span>{new Date(guide.updated_at).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <div
        className={`modal fade ${showDeleteModal ? "show" : ""}`}
        id="deleteModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
        style={{ display: showDeleteModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Delete Guide
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowDeleteModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this guide? This action cannot be
              undone.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleDelete();
                  setShowDeleteModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && <div className="modal-backdrop fade show"></div>}

      {/* Share Modal */}
      <ShareModal
        show={showShareModal}
        onHide={() => !isSharing && setShowShareModal(false)}
        onShare={handleShare}
        properties={properties}
        isSharing={isSharing}
        isLoadingProperties={isLoadingProperties}
        guide={guide}
      />
    </div>
  );
};

export default GuideDetail;
