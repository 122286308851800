import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserTasksByLoggedInUser } from "../../services/task/userTaskManagement";
import { getNewTicketsByUserId } from "../../services/tickets/unitTicketsManagement";
import { getLatestRentalContractByLandlordId } from "../../services/rent/tenant/invite/tenantRentalContractService";
import config from "../../constants/config";

interface NotificationItem {
  id: string;
  title: string;
  description: string;
  type: "task" | "ticket" | "contract" | "other";
  timestamp: Date;
  read: boolean;
  href: string;
  icon?: React.ComponentType<any>;
}

interface NotificationContextType {
  notifications: NotificationItem[];
  unreadCount: number;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  fetchNotifications: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    // Check authentication status
    const checkAuth = () => {
      const token = localStorage.getItem(config.access_token);
      setIsAuthenticated(!!token);
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);
    return () => window.removeEventListener("storage", checkAuth);
  }, []);

  const fetchNotifications = async () => {
    // Only fetch notifications if user is authenticated
    if (!isAuthenticated) {
      setNotifications([]);
      return;
    }

    try {
      //TODO: instead of three separate calls set up a single call to get all the notifications (that is processed on the server then cached)
      // Fetch tasks
      //WOnt re-imple until I ahve the API to handle notifications so we dont do heavy network calls
      /*
      const tasks = await getUserTasksByLoggedInUser();
      const taskNotifications = tasks.flatMap((taskGroup: any) =>
        taskGroup.tasks.map((task: any) => ({
          id: task.task_id,
          title: task.task_name,
          description: task.task_description,
          type: "task" as const,
          timestamp: new Date(task.created_at),
          read: false,
          href: `/tasks/${task.task_id}`,
        }))
      );

      // Fetch tickets
      const tickets = await getNewTicketsByUserId();
      const ticketNotifications = tickets.map((ticket: any) => ({
        id: ticket.ticket_id,
        title: ticket.ticket_title,
        description: ticket.ticket_description,
        type: "ticket" as const,
        timestamp: new Date(ticket.created_at),
        read: false,
        href: `/ticket/${ticket.ticket_id}`,
      }));

      // Fetch rental contracts
      const contract = await getLatestRentalContractByLandlordId();
      const contractNotifications = contract
        ? [
            {
              id: contract.rental_contract_id,
              title: "New Rental Contract",
              description: `Contract for ${contract.property_unit_name}`,
              type: "contract" as const,
              timestamp: new Date(),
              read: false,
              href: `/rentalContractSignature/${contract.rental_contract_id}`,
            },
          ]
        : [];

      // Combine and sort all notifications by timestamp
      const allNotifications = [
        ...taskNotifications,
        ...ticketNotifications,
        ...contractNotifications,
      ].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

      setNotifications(allNotifications);

      */
    } catch (error) {
      console.error("Error fetching notifications:", error);
      // If there's an error, clear notifications
      setNotifications([]);
    }
  };

  const markAsRead = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  };

  const markAllAsRead = () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  useEffect(() => {
    if (isAuthenticated) {
      //fetchNotifications();
      // Set up polling interval only when authenticated
      //Re-enable this when I have the API to handle notifications from database instead of dynamically
      //const interval = setInterval(fetchNotifications, 5 * 60 * 1000); // Poll every 5 minutes
      //return () => clearInterval(interval);
    } else {
      // Clear notifications when not authenticated
      setNotifications([]);
    }
  }, [isAuthenticated]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        markAsRead,
        markAllAsRead,
        fetchNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
