import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridToolbar,
  GridRowSelectionModel,
  GridColDef,
  GridValueOptionsParams,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  PlaidTransaction,
  CurrencyCode,
  RevenueCategory,
  ExpenseCategory,
} from "../types/transactionTypes";
import ShimmerEffect from "../../../../../reusable/loading/ShimmerEffect";
import { LinearProgress } from "@mui/material";
import newDocumentImg from "../../../../../../styletheme/images/new-document.png";
import { createOrUpdateUnitRevenue } from "../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import { createOrUpdateUnitExpense } from "../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import {
  createRevenueModelFromPlaid,
  createExpenseModelFromPlaid,
} from "../types/revenueAndExpenseModels";
import { useNavigate } from "react-router-dom";

//WIll continue to enhance this feature so it becomes easier and more robust to use
//TODO: need to update the page to handle bank accounts and be able to remove bank accounts not being used

interface ExtendedPlaidTransaction
  extends Omit<PlaidTransaction, "transaction_type"> {
  transaction_type?:
    | "revenue"
    | "expense"
    | "place"
    | "digital"
    | "special"
    | "unresolved";
}

interface UncategorizedTransactionsTableProps {
  transactions: ExtendedPlaidTransaction[];
  onSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  loading?: boolean;
  isDarkMode?: boolean;
  onTransactionUpdate?: (
    newRow: ExtendedPlaidTransaction,
    oldRow: ExtendedPlaidTransaction
  ) => Promise<ExtendedPlaidTransaction>;
  revenueCategories: RevenueCategory[];
  expenseCategories: ExpenseCategory[];
  propertyUnitId: string;
  onTransactionsProcessed?: () => void;
}

interface UncategorizedFiltersProps {
  onDateChange: (range: string) => void;
  onStatusChange: (status: string) => void;
  onAmountChange: (range: string) => void;
  onClear: () => void;
}

interface FilteredTransactions {
  transactions: PlaidTransaction[];
  dateFilter: string;
  statusFilter: string;
  amountFilter: string;
}

interface BulkActionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: { type?: string; category?: string }) => void;
  selectedField: string;
  revenueCategories: RevenueCategory[];
  expenseCategories: ExpenseCategory[];
  transactionType?: string;
  isLoading?: boolean;
}

const LoadingOverlay: React.FC = () => (
  <LinearProgress sx={{ width: "100%", position: "absolute", top: 0 }} />
);

const ShimmerLoadingState: React.FC<{ isDarkMode?: boolean }> = ({
  isDarkMode = false,
}) => (
  <div className="d-flex flex-column gap-3">
    {[...Array(10)].map((_, index) => (
      <ShimmerEffect
        key={index}
        type="line"
        height="52px"
        width="100%"
        darkMode={isDarkMode}
      />
    ))}
  </div>
);

const createUncategorizedColumns = (
  revenueCategories: RevenueCategory[],
  expenseCategories: ExpenseCategory[]
): GridColDef<ExtendedPlaidTransaction>[] => [
  {
    field: "date",
    headerName: "Date",
    width: 150,
    type: "date",
    editable: false,
    valueGetter: ({ row }: { row: ExtendedPlaidTransaction | undefined }) => {
      if (!row?.date) return null;
      return new Date(row.date);
    },
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => {
      if (!params.row?.date) return null;
      return (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {new Date(params.row.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
      );
    },
  },
  {
    field: "merchant_name",
    headerName: "Merchant",
    width: 200,
    editable: true,
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {params.row?.merchant_name || params.row?.name || ""}
        </div>
      </div>
    ),
  },
  {
    field: "logo_url",
    headerName: "",
    width: 90,
    editable: false,
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => (
      <img
        src={
          params.row?.logo_url ||
          params.row?.personal_finance_category_icon_url ||
          newDocumentImg
        }
        style={{ width: "80%", height: "80%" }}
        alt="transaction"
      />
    ),
  },
  {
    field: "name",
    headerName: "Title",
    width: 250,
    type: "string",
    editable: true,
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
    width: 110,
    editable: true,
    valueGetter: ({ row }: { row: ExtendedPlaidTransaction | undefined }) => {
      if (!row?.amount) return null;
      return Math.abs(row.amount);
    },
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => {
      if (!params.row?.amount || !params.row?.iso_currency_code) return null;
      return (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: params.row.iso_currency_code,
            }).format(Math.abs(params.row.amount))}
          </div>
        </div>
      );
    },
  },
  {
    field: "iso_currency_code",
    headerName: "Currency",
    width: 110,
    editable: false,
  },
  {
    field: "transaction_type",
    headerName: "Type",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: [
      { value: "revenue", label: "Revenue" },
      { value: "expense", label: "Expense" },
    ],
  },
  {
    field: "category_id",
    headerName: "Category",
    width: 200,
    editable: true,
    type: "singleSelect",
    valueOptions: (
      params: GridValueOptionsParams<ExtendedPlaidTransaction>
    ) => {
      const transactionType = params.row?.transaction_type;

      if (transactionType === "revenue") {
        return revenueCategories.map((category) => ({
          value: category.revenue_category_id,
          label: category.revenue_category,
        }));
      } else if (transactionType === "expense") {
        return expenseCategories.map((category) => ({
          value: category.expense_category_id,
          label: category.expense_category,
        }));
      }
      return [];
    },
    valueFormatter: ({
      value,
      row,
    }: {
      value: any;
      row: ExtendedPlaidTransaction;
    }) => {
      const categoryId = value as string;
      const transactionType = row?.transaction_type;

      if (transactionType === "revenue") {
        const category = revenueCategories.find(
          (cat) => cat.revenue_category_id === categoryId
        );
        return category?.revenue_category || "";
      } else if (transactionType === "expense") {
        const category = expenseCategories.find(
          (cat) => cat.expense_category_id === categoryId
        );
        return category?.expense_category || "";
      }
      return "";
    },
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => {
      const categoryId = params.value as string;
      const transactionType = params.row?.transaction_type;
      let displayValue = "";

      if (transactionType === "revenue") {
        const category = revenueCategories.find(
          (cat) => cat.revenue_category_id === categoryId
        );
        displayValue = category?.revenue_category || "";
      } else if (transactionType === "expense") {
        const category = expenseCategories.find(
          (cat) => cat.expense_category_id === categoryId
        );
        displayValue = category?.expense_category || "";
      }

      return (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">{displayValue}</div>
        </div>
      );
    },
  },
  {
    field: "personal_finance_category",
    headerName: "Plaid Category",
    width: 200,
    editable: false,
    renderCell: (params: GridCellParams<ExtendedPlaidTransaction>) => (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 ms-2 name">
          {params.row?.personal_finance_category?.detailed?.replace(
            /_/g,
            " "
          ) || ""}
        </div>
      </div>
    ),
  },
];

const CustomToolbar: React.FC<{
  selectedRows: GridRowSelectionModel;
  onBulkUpdate: (field: string) => void;
  onPushToDatabase: () => void;
  onRemoveSelected: () => void;
  isSaving?: boolean;
  isBulkActionsLoading?: boolean;
}> = ({
  selectedRows,
  onBulkUpdate,
  onPushToDatabase,
  onRemoveSelected,
  isSaving = false,
  isBulkActionsLoading = false,
}) => {
  const hasSelection = selectedRows.length > 0;

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <GridToolbar />
        {hasSelection && (
          <>
            <Button
              variant="outlined"
              onClick={() => onBulkUpdate("transaction_type")}
              size="small"
            >
              Update Type
            </Button>
            <Button
              variant="outlined"
              onClick={() => onBulkUpdate("category_id")}
              size="small"
            >
              Update Category
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={onRemoveSelected}
              size="small"
            >
              Remove Selected
            </Button>
          </>
        )}
      </Box>
      {hasSelection && (
        <Button
          variant="contained"
          color="primary"
          onClick={onPushToDatabase}
          size="small"
          disabled={isSaving || isBulkActionsLoading}
          endIcon={
            isSaving || isBulkActionsLoading ? (
              <CircularProgress size={20} />
            ) : null
          }
        >
          {isSaving || isBulkActionsLoading
            ? "Saving Transactions..."
            : "Save as Revenue/Expense"}
        </Button>
      )}
    </Box>
  );
};

const BulkActionDialog: React.FC<BulkActionDialogProps> = ({
  open,
  onClose,
  onConfirm,
  selectedField,
  revenueCategories,
  expenseCategories,
  transactionType,
  isLoading = false,
}) => {
  const [type, setType] = useState<string>("");
  const [category, setCategory] = useState<string>("");

  const handleConfirm = () => {
    onConfirm({
      type: selectedField === "transaction_type" ? type : undefined,
      category: selectedField === "category_id" ? category : undefined,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Bulk Update{" "}
        {selectedField === "transaction_type" ? "Transaction Type" : "Category"}
      </DialogTitle>
      <DialogContent>
        {selectedField === "transaction_type" && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Type</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="revenue">Revenue</MenuItem>
              <MenuItem value="expense">Expense</MenuItem>
            </Select>
          </FormControl>
        )}
        {selectedField === "category_id" && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {transactionType === "revenue"
                ? revenueCategories.map((cat) => (
                    <MenuItem
                      key={cat.revenue_category_id}
                      value={cat.revenue_category_id}
                    >
                      {cat.revenue_category}
                    </MenuItem>
                  ))
                : expenseCategories.map((cat) => (
                    <MenuItem
                      key={cat.expense_category_id}
                      value={cat.expense_category_id}
                    >
                      {cat.expense_category}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UncategorizedFilters: React.FC<UncategorizedFiltersProps> = ({
  onDateChange,
  onStatusChange,
  onAmountChange,
  onClear,
}) => {
  return (
    <Box sx={{ mb: 3, pt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          alignItems: { xs: "stretch", sm: "center" },
          mb: 2,
        }}
      >
        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Date Range</InputLabel>
          <Select
            label="Date Range"
            defaultValue="all"
            onChange={(e) => onDateChange(e.target.value)}
          >
            <MenuItem value="all">All Dates</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="quarter">This Quarter</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            defaultValue="all"
            onChange={(e) => onStatusChange(e.target.value)}
          >
            <MenuItem value="all">All Status</MenuItem>
            <MenuItem value="pending">Pending Review</MenuItem>
            <MenuItem value="ready">Ready to Categorize</MenuItem>
            <MenuItem value="needs_info">Needs More Info</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: { xs: "100%", sm: 150 } }}>
          <InputLabel>Amount Range</InputLabel>
          <Select
            label="Amount Range"
            defaultValue="all"
            onChange={(e) => onAmountChange(e.target.value)}
          >
            <MenuItem value="all">All Amounts</MenuItem>
            <MenuItem value="0-100">$0 - $100</MenuItem>
            <MenuItem value="100-500">$100 - $500</MenuItem>
            <MenuItem value="500-1000">$500 - $1000</MenuItem>
            <MenuItem value="1000-5000">$1000 - $5000</MenuItem>
            <MenuItem value="5000+">$5000+</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            mt: { xs: 2, sm: 0 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Button variant="outlined" size="small" onClick={onClear} fullWidth>
            Clear Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const UncategorizedTransactionsTable: React.FC<
  UncategorizedTransactionsTableProps
> = ({
  transactions: initialTransactions,
  onSelectionModelChange,
  loading = false,
  isDarkMode = false,
  onTransactionUpdate,
  revenueCategories,
  expenseCategories,
  propertyUnitId,
  onTransactionsProcessed,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [dateFilter, setDateFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [amountFilter, setAmountFilter] = useState("all");
  const [filteredTransactions, setFilteredTransactions] = useState<
    ExtendedPlaidTransaction[]
  >(initialTransactions as ExtendedPlaidTransaction[]);
  const [bulkActionDialogOpen, setBulkActionDialogOpen] = useState(false);
  const [selectedField, setSelectedField] = useState<string>("");
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isBulkActionsLoading, setIsBulkActionsLoading] = useState(false);
  const navigate = useNavigate();

  // Apply all active filters
  const applyFilters = useCallback(
    (
      transactions: ExtendedPlaidTransaction[],
      {
        dateFilter,
        statusFilter,
        amountFilter,
      }: Omit<FilteredTransactions, "transactions">
    ) => {
      let filtered = [...transactions];

      // Apply date filter
      if (dateFilter !== "all") {
        const today = new Date();
        let startDate: Date | null = null;

        switch (dateFilter) {
          case "today":
            startDate = new Date(today.setHours(0, 0, 0, 0));
            break;
          case "week":
            startDate = new Date(today.setDate(today.getDate() - 7));
            break;
          case "month":
            startDate = new Date(today.setMonth(today.getMonth() - 1));
            break;
          case "quarter":
            startDate = new Date(today.setMonth(today.getMonth() - 3));
            break;
        }

        if (startDate) {
          filtered = filtered.filter((t) => new Date(t.date) >= startDate!);
        }
      }

      // Apply status filter
      if (statusFilter !== "all") {
        filtered = filtered.filter((t) => {
          switch (statusFilter) {
            case "pending":
              return !t.category_id;
            case "ready":
              return (
                t.personal_finance_category?.confidence_level === "VERY_HIGH"
              );
            case "needs_info":
              return t.personal_finance_category?.confidence_level === "LOW";
            default:
              return true;
          }
        });
      }

      // Apply amount filter
      if (amountFilter !== "all") {
        filtered = filtered.filter((t) => {
          const amount = Math.abs(t.amount);
          const [min, max] =
            amountFilter === "5000+"
              ? [5000, Infinity]
              : amountFilter.split("-").map(Number);
          return amount >= min && amount <= max;
        });
      }

      return filtered;
    },
    []
  );

  // Update filtered transactions when filters or initial transactions change
  useEffect(() => {
    const newFilteredTransactions = applyFilters(initialTransactions, {
      dateFilter,
      statusFilter,
      amountFilter,
    });
    setFilteredTransactions(newFilteredTransactions);
  }, [
    initialTransactions,
    dateFilter,
    statusFilter,
    amountFilter,
    applyFilters,
  ]);

  const handleDateChange = (range: string) => {
    setIsFiltersLoading(true);
    try {
      setDateFilter(range);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleStatusChange = (status: string) => {
    setIsFiltersLoading(true);
    try {
      setStatusFilter(status);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleAmountChange = (range: string) => {
    setIsFiltersLoading(true);
    try {
      setAmountFilter(range);
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const clearFilters = () => {
    setIsFiltersLoading(true);
    try {
      setDateFilter("all");
      setStatusFilter("all");
      setAmountFilter("all");
    } finally {
      setIsFiltersLoading(false);
    }
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    setSelectionModel(newSelectionModel);
    onSelectionModelChange(newSelectionModel);
  };

  const handleProcessRowUpdate = async (
    newRow: ExtendedPlaidTransaction,
    oldRow: ExtendedPlaidTransaction
  ) => {
    try {
      if (onTransactionUpdate) {
        return await onTransactionUpdate(newRow, oldRow);
      }
      return newRow;
    } catch (error) {
      toast.error("Failed to update transaction");
      return oldRow;
    }
  };

  const handleProcessRowUpdateError = (error: Error) => {
    toast.error(error.message);
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  // Handle bulk update dialog
  const handleBulkUpdate = (field: string) => {
    setIsBulkActionsLoading(true);
    try {
      setSelectedField(field);
      if (field === "category_id") {
        const firstSelectedRow = filteredTransactions.find((t) =>
          selectionModel.includes(t.transaction_id)
        );
        setSelectedTransactionType(
          firstSelectedRow?.transaction_type || "expense"
        );
      }
      setBulkActionDialogOpen(true);
    } finally {
      setIsBulkActionsLoading(false);
    }
  };

  // Handle bulk update confirmation
  const handleBulkUpdateConfirm = ({
    type,
    category,
  }: {
    type?: string;
    category?: string;
  }) => {
    const updatedTransactions = filteredTransactions.map((transaction) => {
      if (selectionModel.includes(transaction.transaction_id)) {
        return {
          ...transaction,
          ...(type && { transaction_type: type as "revenue" | "expense" }),
          ...(category && { category_id: category }),
        };
      }
      return transaction;
    });

    setFilteredTransactions(updatedTransactions);
    setBulkActionDialogOpen(false);
  };

  const handleRowClick = (params: any) => {
    const transactionType = params.row.transaction_type;
    if (transactionType === "expense") {
      navigate(`/expenseDetail/${params.row.unit_revenue_expense_id}`);
    } else {
      navigate(`/revenueDetail/${params.row.unit_revenue_expense_id}`);
    }
  };

  const handlePushToDatabase = async () => {
    try {
      setIsSaving(true);
      setIsTableLoading(true);
      const selectedTransactions = filteredTransactions.filter((t) =>
        selectionModel.includes(t.transaction_id)
      );

      // Validate selected transactions
      const invalidTransactions = selectedTransactions.filter(
        (t) => !t.transaction_type || !t.category_id
      );

      if (invalidTransactions.length > 0) {
        toast.error(
          "All selected transactions must have a type and category assigned"
        );
        return;
      }

      // Transform transactions using our helper functions
      const revenueTransactions = selectedTransactions
        .filter((t) => t.transaction_type === "revenue")
        .map((transaction) =>
          createRevenueModelFromPlaid(transaction, propertyUnitId)
        );

      const expenseTransactions = selectedTransactions
        .filter((t) => t.transaction_type === "expense")
        .map((transaction) =>
          createExpenseModelFromPlaid(transaction, propertyUnitId)
        );

      // Call the API endpoints
      if (revenueTransactions.length > 0) {
        await Promise.all(
          revenueTransactions.map((revenue) =>
            createOrUpdateUnitRevenue(revenue)
          )
        );
      }

      if (expenseTransactions.length > 0) {
        await Promise.all(
          expenseTransactions.map((expense) =>
            createOrUpdateUnitExpense(expense)
          )
        );
      }

      toast.success("Transactions successfully saved to database");

      // Remove saved transactions from the list
      const remainingTransactions = filteredTransactions.filter(
        (t) => !selectionModel.includes(t.transaction_id)
      );
      setFilteredTransactions(remainingTransactions);
      setSelectionModel([]);

      // Notify parent component to update counts
      if (onTransactionsProcessed) {
        onTransactionsProcessed();
      }
    } catch (error) {
      console.error("Error saving transactions:", error);
      toast.error("Failed to save transactions to database");
    } finally {
      setIsSaving(false);
      setIsTableLoading(false);
    }
  };

  // Handle removing selected transactions
  const handleRemoveSelected = () => {
    const remainingTransactions = filteredTransactions.filter(
      (t) => !selectionModel.includes(t.transaction_id)
    );
    setFilteredTransactions(remainingTransactions);
    setSelectionModel([]);
    toast.success("Selected transactions removed");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {isFiltersLoading ? (
          <Box sx={{ p: 2 }}>
            <LinearProgress />
          </Box>
        ) : (
          <UncategorizedFilters
            onDateChange={handleDateChange}
            onStatusChange={handleStatusChange}
            onAmountChange={handleAmountChange}
            onClear={clearFilters}
          />
        )}

        {loading || isTableLoading ? (
          <ShimmerLoadingState isDarkMode={isDarkMode} />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 120px)",
              minHeight: 400,
              position: "relative",
            }}
          >
            <DataGrid
              rows={filteredTransactions}
              columns={createUncategorizedColumns(
                revenueCategories,
                expenseCategories
              )}
              getRowId={(row) => row.transaction_id}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25 },
                },
                sorting: {
                  sortModel: [{ field: "date", sort: "desc" }],
                },
              }}
              slots={{
                toolbar: (props) => (
                  <CustomToolbar
                    {...props}
                    selectedRows={selectionModel}
                    onBulkUpdate={handleBulkUpdate}
                    onPushToDatabase={handlePushToDatabase}
                    onRemoveSelected={handleRemoveSelected}
                    isSaving={isSaving}
                    isBulkActionsLoading={isBulkActionsLoading}
                  />
                ),
                loadingOverlay: LoadingOverlay,
              }}
              loading={isTableLoading}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              sx={{
                flexGrow: 1,
                width: "100%",
                height: "100%",
                "& .MuiDataGrid-root": {
                  minWidth: { xs: "800px", md: "100%" },
                },
                "& .MuiDataGrid-cell": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                },
                "& .MuiDataGrid-main": {
                  width: "100%",
                },
                "& .MuiDataGrid-virtualScroller": {
                  width: "100%",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid rgba(224, 224, 224, 0.2)",
                },
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "1px solid rgba(224, 224, 224, 0.2)",
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                onSelectionModelChange(newSelectionModel);
              }}
              disableRowSelectionOnClick
              processRowUpdate={handleProcessRowUpdate}
              //onRowClick={handleRowClick}
            />
          </Box>
        )}

        <BulkActionDialog
          open={bulkActionDialogOpen}
          onClose={() => setBulkActionDialogOpen(false)}
          onConfirm={handleBulkUpdateConfirm}
          selectedField={selectedField}
          revenueCategories={revenueCategories}
          expenseCategories={expenseCategories}
          transactionType={selectedTransactionType}
          isLoading={isBulkActionsLoading}
        />
      </Box>
    </ThemeProvider>
  );
};

export default UncategorizedTransactionsTable;
