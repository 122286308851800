import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { PaginationProps } from "./types/TableTypes";

export const Pagination: React.FC<
  PaginationProps & { isDarkMode: boolean }
> = ({
  currentPage,
  totalPages,
  pageSize,
  totalItems,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = [10, 25, 50, 100],
  isDarkMode,
}) => {
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) pages.push(i);
        pages.push(-1); // Separator
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1);
        pages.push(-1); // Separator
        for (let i = totalPages - 3; i <= totalPages; i++) pages.push(i);
      } else {
        pages.push(1);
        pages.push(-1); // Separator
        for (let i = currentPage - 1; i <= currentPage + 1; i++) pages.push(i);
        pages.push(-1); // Separator
        pages.push(totalPages);
      }
    }
    return pages;
  };

  return (
    <div
      className={`tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-between tw-gap-4 tw-px-6 tw-py-3 tw-border-t ${
        isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
      }`}
    >
      <div className="tw-flex tw-items-center tw-gap-4">
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className={`tw-px-2 tw-py-1 tw-rounded-lg tw-border ${
            isDarkMode
              ? "tw-bg-gray-700 tw-border-gray-600 tw-text-white"
              : "tw-bg-white tw-border-gray-300 tw-text-gray-900"
          } focus:tw-ring-2 focus:tw-ring-blue-500`}
        >
          {pageSizeOptions.map((size) => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </select>
        <span
          className={`tw-text-sm ${
            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
          }`}
        >
          Showing {startItem}-{endItem} of {totalItems} items
        </span>
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`tw-p-2 tw-rounded-lg ${
            isDarkMode
              ? "tw-text-gray-400 hover:tw-bg-gray-700 disabled:tw-text-gray-600"
              : "tw-text-gray-600 hover:tw-bg-gray-100 disabled:tw-text-gray-300"
          } disabled:tw-cursor-not-allowed`}
        >
          <FiChevronLeft className="tw-w-5 tw-h-5" />
        </button>

        <div className="tw-flex tw-items-center tw-gap-1">
          {getPageNumbers().map((pageNum, index) =>
            pageNum === -1 ? (
              <span
                key={`separator-${index}`}
                className={`tw-px-2 ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                }`}
              >
                ...
              </span>
            ) : (
              <button
                key={pageNum}
                onClick={() => onPageChange(pageNum)}
                className={`tw-min-w-[2.5rem] tw-h-10 tw-rounded-lg tw-text-sm ${
                  currentPage === pageNum
                    ? isDarkMode
                      ? "tw-bg-blue-600 tw-text-white"
                      : "tw-bg-blue-50 tw-text-blue-600"
                    : isDarkMode
                    ? "tw-text-gray-400 hover:tw-bg-gray-700"
                    : "tw-text-gray-600 hover:tw-bg-gray-100"
                }`}
              >
                {pageNum}
              </button>
            )
          )}
        </div>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`tw-p-2 tw-rounded-lg ${
            isDarkMode
              ? "tw-text-gray-400 hover:tw-bg-gray-700 disabled:tw-text-gray-600"
              : "tw-text-gray-600 hover:tw-bg-gray-100 disabled:tw-text-gray-300"
          } disabled:tw-cursor-not-allowed`}
        >
          <FiChevronRight className="tw-w-5 tw-h-5" />
        </button>
      </div>
    </div>
  );
};
