// Receipt Image Upload Model Interface
export interface ReceiptImageUploadModel {
  base64_data?: string;
  url?: string;
  file_name: string;
  file_size: number;
  file_size_formatted: string;
  file_type?: string;
  upload_time_stamp?: Date;
  unique_file_name?: string;
}

// Generate UUID v4
function generateUUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Unit Revenue Model Interface
export interface UnitRevenueModel {
  unit_revenue_id?: string | null;
  property_unit_id: string;
  revenue_category_id: string;
  revenue_amount: number;
  revenue_date: Date;
  is_active: boolean;
  is_received_from_tenant: boolean;
  payment_date: Date;
  is_capital_revenue: boolean;
  revenue_title?: string | null;
  revenue_description?: string | null;
  receipt_link?: string | null;
  revenue_day: number;
  revenue_month: number;
  revenue_year: number;
  created_at: Date;
  updated_at: Date;
  property_bank_account_id?: string | null;
  bank_account_id?: string | null;
  bank_id?: string | null;
  bank_name?: string | null;
  contractor_id?: string | null;
  contractor_name?: string | null;
  transaction_receipt_image_uploads: ReceiptImageUploadModel[];
  plaid_transaction_id?: string | null;
}

// Unit Expense Model Interface
export interface UnitExpenseModel {
  unit_expense_id?: string | null;
  property_unit_id: string;
  expense_category_id: string;
  expense_amount: number;
  expense_date: Date;
  is_active: boolean;
  is_paid_by_tenant: boolean;
  is_paid_by_landlord: boolean;
  payment_date: Date;
  is_capital_expense: boolean;
  expense_title?: string | null;
  expense_description?: string | null;
  receipt_link?: string | null;
  expense_day: number;
  expense_month: number;
  expense_year: number;
  created_at: Date;
  updated_at: Date;
  property_bank_account_id?: string | null;
  bank_account_id?: string | null;
  bank_id?: string | null;
  bank_name?: string | null;
  contractor_id?: string | null;
  contractor_name?: string | null;
  transaction_receipt_image_uploads: ReceiptImageUploadModel[];
  plaid_transaction_id?: string | null;
}

// Helper function to create a revenue model from Plaid transaction
export function createRevenueModelFromPlaid(
  transaction: any,
  propertyUnitId: string
): UnitRevenueModel {
  const date = new Date(transaction.date);
  return {
    unit_revenue_id: generateUUID(),
    property_unit_id: propertyUnitId,
    revenue_category_id: transaction.category_id || "",
    revenue_amount: Math.abs(transaction.amount),
    revenue_date: date,
    is_active: true,
    is_received_from_tenant: false,
    payment_date: date,
    is_capital_revenue: false,
    revenue_title: transaction.name || "",
    revenue_description: transaction.merchant_name || transaction.name || "",
    receipt_link: transaction.logo_url || "",
    revenue_day: date.getDate(),
    revenue_month: date.getMonth() + 1,
    revenue_year: date.getFullYear(),
    created_at: new Date(),
    updated_at: new Date(),
    property_bank_account_id: null,
    bank_account_id: transaction.account_id || null,
    bank_id: null,
    bank_name: null,
    contractor_id: null,
    contractor_name: null,
    transaction_receipt_image_uploads: [],
    plaid_transaction_id: transaction.transaction_id,
  };
}

// Helper function to create an expense model from Plaid transaction
export function createExpenseModelFromPlaid(
  transaction: any,
  propertyUnitId: string
): UnitExpenseModel {
  const date = new Date(transaction.date);
  return {
    unit_expense_id: generateUUID(),
    property_unit_id: propertyUnitId,
    expense_category_id: transaction.category_id || "",
    expense_amount: Math.abs(transaction.amount),
    expense_date: date,
    is_active: true,
    is_paid_by_tenant: false,
    is_paid_by_landlord: true,
    payment_date: date,
    is_capital_expense: false,
    expense_title: transaction.name || "",
    expense_description: transaction.merchant_name || transaction.name || "",
    receipt_link: transaction.logo_url || "",
    expense_day: date.getDate(),
    expense_month: date.getMonth() + 1,
    expense_year: date.getFullYear(),
    created_at: new Date(),
    updated_at: new Date(),
    property_bank_account_id: null,
    bank_account_id: transaction.account_id || null,
    bank_id: null,
    bank_name: null,
    contractor_id: null,
    contractor_name: null,
    transaction_receipt_image_uploads: [],
    plaid_transaction_id: transaction.transaction_id,
  };
}
