import React, { createContext, useState, useContext, useEffect } from "react";
import config from "../../../../constants/config";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const initialSettings = {
    ...config.settings,
    isDarkMode: localStorage.getItem(config.is_dark_mode) === "true" || false,
    isAiChatEnabled:
      localStorage.getItem(config.ai_chat_enabled) === "true" || false,
  };

  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    localStorage.setItem(config.is_dark_mode, settings.isDarkMode);
  }, [settings.isDarkMode]);

  useEffect(() => {
    localStorage.setItem(config.ai_chat_enabled, settings.isAiChatEnabled);
  }, [settings.isAiChatEnabled]);

  // Listen for localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === config.ai_chat_enabled) {
        setSettings((prev) => ({
          ...prev,
          isAiChatEnabled: e.newValue === "true",
        }));
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  return useContext(SettingsContext);
}
