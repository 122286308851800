import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { getPropertyRentRolls as getPropertyRentRoll } from "../../../services/reports/reportService";
import { PropertyFinancialReportModel } from "types/reports";
import { toast } from "react-toastify";

interface PropertyReportsProps {
  isDarkMode: boolean;
}

const PropertyReports: React.FC<PropertyReportsProps> = ({ isDarkMode }) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [reports, setReports] = useState<PropertyFinancialReportModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (propertyId) {
      loadReports();
    }
  }, [propertyId]);

  const loadReports = async () => {
    try {
      const data = await getPropertyRentRoll(propertyId!);
      setReports(data);
    } catch (error) {
      console.error("Error loading property reports:", error);
      toast.error("Failed to load property reports");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Property Reports"
          previousPageLink="/reports"
          previousPageTitle="Reports Dashboard"
        />

        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-3">Financial Overview</h5>
                {/* Financial metrics will go here */}
                <p className="text-muted">Coming soon...</p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-3">Unit Reports</h5>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Unit</th>
                        <th>Status</th>
                        <th>Last Report</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports.map((report) => (
                        <tr key={report.report_id}>
                          <td>{report.property_unit_id}</td>
                          <td>
                            <span
                              className={`badge bg-${
                                report.status === "completed"
                                  ? "success"
                                  : "warning"
                              }`}
                            >
                              {report.status}
                            </span>
                          </td>
                          <td>
                            {new Date(report.report_date).toLocaleDateString()}
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary me-2"
                              onClick={() =>
                                navigate(
                                  `/reports/unit/${report.property_unit_id}`
                                )
                              }
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyReports;
