import { Message, ChatHistory } from "../../models/ai-chat/Message";
import config from "../../constants/config";
import { devLog } from "services/utils/logger";
import http from "../../services/general/httpService";

export class AiChatHistoryService {
  private static instance: AiChatHistoryService;
  private readonly apiEndpoint: string = config.apiEndpoint;

  private constructor() {}

  public static getInstance(): AiChatHistoryService {
    if (!AiChatHistoryService.instance) {
      AiChatHistoryService.instance = new AiChatHistoryService();
    }
    return AiChatHistoryService.instance;
  }

  public async sendMessageToServer(
    sessionId: string,
    message: Message
  ): Promise<ChatHistory> {
    const userId = localStorage.getItem(config.user_id);

    try {
      const { data } = await http.post(
        `${this.apiEndpoint}/chat/history/${sessionId}/messages`,
        {
          user_id: userId || "",
          message,
        }
      );
      return data as ChatHistory;
    } catch (error) {
      devLog("Error saving message to server:", error);
      throw error;
    }
  }

  public async getChatHistory(sessionId: string): Promise<ChatHistory> {
    const userId = localStorage.getItem(config.user_id);
    try {
      const { data } = await http.get(
        `${this.apiEndpoint}/chat/history/${sessionId}`
      );
      return data;
    } catch (error) {
      devLog("Error fetching chat history:", error);
      // Return empty history if server fails
      return {
        messages: [],
        lastUpdated: new Date().toISOString(),
        session_id: sessionId,
        user_id: userId || "",
      };
    }
  }

  public async clearHistory(sessionId: string): Promise<void> {
    try {
      await http.delete(`${this.apiEndpoint}/chat/history/${sessionId}`);
    } catch (error) {
      devLog("Error clearing chat history from server:", error);
      throw error;
    }
  }
}
