import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  LinearProgress,
  Box,
  Chip,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAllTenantsForCampaignId } from "../../../../../../../../services/propertyUnits/tenantScreening/prospectives/tenantRentalApplicationManagement";
import { getScreeningPackById } from "../../../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement";
import { getAdvertisementListingByCampaignId } from "../../../../../../../../services/propertyUnits/advertisment/advertismentManagement";

import NoRecordsFound from "../../../../../../tickets/list/sub/noRecordsFound";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PageTitleBox from "../../../../../../../reusable/title/pageTitleBox";
import { getStatusColor } from "../../../../../../../../services/utils/actionTypes";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { getThemeColors } from "../../../../../../../../services/utils/colors";
import { usePageTitle } from "../../../../../../../../services/utils/hooks/usePageTitle";
const screeningPhases = [
  { name: "Pre-screening", field: "has_passed_pre_screening" },
  { name: "Viewing", field: "has_passed_viewing_screening" },
  { name: "Application", field: "has_passed_application_screening" },
  { name: "Background Check", field: "has_passed_background_check" },
  { name: "Final Steps", field: "has_been_selected_for_the_rental" },
];

const TenantApplicantPool = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Applicant Pool";
  usePageTitle({ title: pageTitle });
  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [screeningPack, setScreeningPack] = useState(null);
  const [rentAmount, setRentAmount] = useState(null);
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#121212" : "#ffffff",
        paper: isDarkMode ? "#1e1e1e" : "#ffffff",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#000000",
        secondary: isDarkMode ? "#b3b3b3" : "#666666",
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderColor: isDarkMode
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)",
          },
          row: {
            "&:nth-of-type(odd)": {
              backgroundColor: isDarkMode
                ? "rgba(255,255,255,0.02)"
                : "rgba(0,0,0,0.02)",
            },
          },
        },
      },
    },
  });

  const themeColors = getThemeColors(isDarkMode);

  const checkIncomeCriteria = (income, rentAmount, screeningPack) => {
    // Return false if any required values are missing
    if (!income || !rentAmount || !screeningPack?.minimum_income_multiple) {
      return false;
    }

    // Calculate required income based on rent amount and minimum income multiple
    const requiredIncome = rentAmount * screeningPack.minimum_income_multiple;

    // Check if actual income meets or exceeds required income
    return income >= requiredIncome;
  };

  /*
   const incomeValue =
          typeof income === "number" ? `$${income.toLocaleString()}` : income;

        let meetsIncomeCriteria = false;
        if (screeningPack && rentAmount && typeof income === "number") {
          const requiredIncome =
            rentAmount * screeningPack.minimum_income_multiple;
          meetsIncomeCriteria = income >= requiredIncome;
        }


*/

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch tenants
        const tenantsData = await getAllTenantsForCampaignId(campaignId);

        // Fetch advertisement
        const advertisement = await getAdvertisementListingByCampaignId(
          campaignId
        );

        if (advertisement) {
          setRentAmount(advertisement.rental_unit_price);
          if (advertisement.screening_pack_id) {
            const screeningPackData = await getScreeningPackById(
              advertisement.screening_pack_id
            );
            setScreeningPack(screeningPackData);
          }
        }

        console.log("advertisement", advertisement);

        // Fetch screening pack
        let screeningPackData = null;
        if (advertisement && advertisement.screening_pack_id) {
          screeningPackData = await getScreeningPackById(
            advertisement.screening_pack_id
          );
          console.log("screeningPackData", screeningPackData);
          setScreeningPack(screeningPackData);
        }

        const formattedData = tenantsData.map((tenant, index) => ({
          id: tenant.profile?.tenant_id || `unknown-${index}`,
          fullName:
            `${tenant.profile?.first_name || ""} ${
              tenant.profile?.last_name || ""
            }`.trim() || "N/A",
          applicationDate: tenant.application?.application_date
            ? new Date(tenant.application.application_date).toLocaleDateString()
            : "N/A",
          screeningProgress: calculateScreeningProgress(tenant.application),
          status: getApplicationStatus(tenant.application),
          income:
            tenant.application?.prescreening_info?.total_monthly_income ||
            "N/A",
          ...tenant,
        }));
        setTenants(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenants([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [campaignId]);

  const calculateScreeningProgress = (application) => {
    if (!application) return 0;
    return screeningPhases.findIndex((phase) => !application[phase.field]) * 20;
  };

  const getApplicationStatus = (application) => {
    if (!application) return "Not Started";
    if (application.application_status === "rejected") return "Rejected";
    if (application.has_been_selected_for_the_rental) return "Approved";
    return "In Progress";
  };

  const searchInField = (fieldValue, searchTerm) => {
    if (fieldValue === null || fieldValue === undefined) return false;

    // Handle numbers (like income)
    if (typeof fieldValue === "number") {
      return fieldValue.toString().includes(searchTerm);
    }

    // Handle strings
    return String(fieldValue).toLowerCase().includes(searchTerm);
  };

  const filteredTenants = tenants.filter((tenant) => {
    const searchTerm = searchQuery.toLowerCase().trim();

    if (!searchTerm) return true; // Show all results when search is empty

    return (
      // Search by name
      searchInField(tenant.fullName, searchTerm) ||
      // Search by email
      searchInField(tenant.profile?.email_address, searchTerm) ||
      // Search by income
      searchInField(tenant.income, searchTerm) ||
      // Search by status
      searchInField(tenant.status, searchTerm) ||
      // Search by application date
      searchInField(tenant.applicationDate, searchTerm)
    );
  });

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Advertisement Campaign"
            previousPageLink={`/advertisementCampaign/${campaignId}`}
          />

          <div className="tw-px-4 tw-sm:px-6 tw-lg:px-8">
            <div className="tw-sm:flex tw-sm:items-center">
              <div className="tw-sm:flex-auto">
                <div className="tw-relative tw-max-w-sm">
                  <input
                    type="search"
                    placeholder="Search by name, email, income, status..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={`tw-w-full tw-rounded-lg tw-border tw-py-2.5 tw-pl-11 tw-pr-4 tw-text-sm
    tw-transition-colors tw-duration-200
    ${themeColors.background.table.ring}
                    focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-border-transparent`}
                  />
                  <div className="tw-absolute tw-inset-y-0 tw-left-3 tw-flex tw-items-center tw-pointer-events-none">
                    <MagnifyingGlassIcon
                      className={`tw-h-5 tw-w-5 ${themeColors.text.tertiary}`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-mt-8 tw-flow-root">
              <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto tw-sm:-mx-6 tw-lg:-mx-8">
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle tw-sm:px-6 tw-lg:px-8">
                  <div
                    className={`tw-overflow-hidden tw-shadow tw-ring-1 tw-rounded-lg ${themeColors.background.table.ring}`}
                  >
                    <table
                      className={`tw-min-w-full tw-divide-y ${themeColors.background.table.divider}`}
                    >
                      <thead className={themeColors.background.table.header}>
                        <tr>
                          <th
                            className={`tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold ${themeColors.text.primary}`}
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className={`tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold ${themeColors.text.primary}`}
                          >
                            Application Date
                          </th>
                          <th
                            scope="col"
                            className={`tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold ${themeColors.text.primary}`}
                          >
                            Monthly Income
                          </th>
                          <th
                            scope="col"
                            className={`tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold ${themeColors.text.primary}`}
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 tw-sm:tw-pr-6"
                          >
                            <span className="tw-sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className={`tw-divide-y ${themeColors.background.table.divider} ${themeColors.background.table.body}`}
                      >
                        {isLoading ? (
                          <tr>
                            <td
                              className={`tw-text-center tw-py-4 ${themeColors.text.tertiary}`}
                            >
                              Loading...
                            </td>
                          </tr>
                        ) : (
                          filteredTenants.map((tenant) => (
                            <tr key={tenant.id}>
                              <td
                                className={`tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm ${themeColors.text.primary}`}
                              >
                                <div className="tw-flex tw-items-center">
                                  <div className="tw-h-9 tw-w-9 tw-flex-shrink-0 tw-rounded-full tw-bg-gray-700 tw-flex tw-items-center tw-justify-center">
                                    <PersonIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
                                  </div>
                                  <div className="tw-ml-4 tw-font-medium">
                                    {tenant.fullName}
                                  </div>
                                </div>
                              </td>
                              <td
                                className={`tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm ${themeColors.text.tertiary}`}
                              >
                                {tenant.applicationDate}
                              </td>
                              <td
                                className={`tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm ${themeColors.text.tertiary}`}
                              >
                                <div className="tw-flex tw-items-center tw-gap-2">
                                  {typeof tenant.income === "number"
                                    ? `$${tenant.income.toLocaleString()}`
                                    : tenant.income}
                                  {typeof tenant.income === "number" &&
                                  checkIncomeCriteria(
                                    tenant.income,
                                    rentAmount,
                                    screeningPack
                                  ) ? (
                                    <CheckCircleIcon className="tw-h-5 tw-w-5 tw-text-green-500" />
                                  ) : (
                                    <CancelIcon className="tw-h-5 tw-w-5 tw-text-red-500" />
                                  )}
                                </div>
                              </td>
                              <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm">
                                <span
                                  className={`tw-inline-flex tw-rounded-full tw-px-2 tw-py-1 tw-text-xs tw-font-medium ${
                                    tenant.status === "Approved"
                                      ? `${themeColors.status.approved.background} ${themeColors.status.approved.text}`
                                      : tenant.status === "Rejected"
                                      ? `${themeColors.status.rejected.background} ${themeColors.status.rejected.text}`
                                      : `${themeColors.status.pending.background} ${themeColors.status.pending.text}`
                                  }`}
                                >
                                  {tenant.status}
                                </span>
                              </td>
                              <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium tw-sm:tw-pr-6">
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/applicantPoolDetail/${tenant.profile.tenant_id}`
                                    )
                                  }
                                  className={`tw-text-sm tw-font-medium ${themeColors.button.primary.background} ${themeColors.button.primary.hover}`}
                                >
                                  View Application
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TenantApplicantPool;
