import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import Multistep from "react-multistep";
import Select from "react-select";

import CreatableSelect from "react-select/creatable";
import DropzoneComponent from "./uploader/DropzoneComponent";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import MultiLineInput from "../../../reusable/form/multilineinput";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import {
  fetchAllPropertyTypes,
  fetchAllSubPropertyTypes,
} from "../../../../services/systemDefined/property/sysPropertyTypeManagement";
import { fetchAllCurrencies } from "../../../../services/systemDefined/currency/sysCurrencyManagement";
import {
  createNewUserDefinedCountry,
  fetchAllCountries,
} from "../../../../services/systemDefined/country/countryManagement";

import config from "../../../../constants/config";
import { getCode } from "country-list";
import {
  createOrUpdatePortfolio,
  getPortfolioByOwnershipId,
} from "../../../../services/portfolio/portfolioManagement";

import PropertyStep from "./sections/propertyStep/propertyStep";
import PropertyUnitStep from "./sections/propertyStep/propertyUnitStep";
import PropertyBankAccounts from "./sections/bankAccount/propertyBankAccounts";
import PropertyOwnership from "./sections/ownership/propertyOwnership";
import { createOrUpdateProperty } from "../../../../services/property/create/propertyCreationService";
import { set } from "react-hook-form";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";
import "../form/sections/css/prop-track.css";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";

//if country (isUserDefined) or portfolio doesnt exist then create it then link it to the property
//when you are finished creating the property, create the units, bank accounts and ownership it should navigate to the property page for the new property

//before the ownership and the final submission ensure the property and u its at min has been set, setting the bank account is optional
const CreateNewProperty = ({ isDarkMode }) => {
  usePageTitle({ title: "Create New Property" });
  const pageTitle = "Create New Property";
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [propertyData, setPropertyData] = useState({
    property_id: "",
    portfolio_id: "",
    currency_id: "",
    property_type_id: "",
    sub_property_type_id: "",
    property_alias_name: "", // Changed from null to ""
    street_address_1: "", // Changed from null to ""
    street_address_2: "",
    city_town: "", // Changed from null to ""
    state_parish: "", // Changed from null to ""
    zip_postal_code: "", // Changed from null to ""
    country_id: "",
    country: "",
  });

  //propertyUnitData
  const [propertyUnitsData, setPropertyUnitsData] = useState([]);

  //existingPortfolio
  const [existingPortfolio, setExistingPortfolio] = useState([
    {
      portfolio_id: "",
      ownership_id: "",
      portfolio_name: "",
    },
  ]);

  const [errors, setErrors] = useState({});
  //set the property_id on save
  const schema = {
    property_id: Joi.string().allow("").optional().label("Property ID"),
    portfolio_id: Joi.string().allow("").optional().label("Portfolio"),
    currency_id: Joi.string().allow("").optional().label("Currency"),
    property_type_id: Joi.string().optional().allow("").label("Property Type"),
    sub_property_type_id: Joi.string().required().label("Sub Property Type"),
    property_alias_name: Joi.string().required().label("Property Alias Name"),
    street_address_1: Joi.string().required().label("Street Address 1"),
    street_address_2: Joi.string()
      .optional()
      .label("Street Address 2")
      .allow(""),
    city_town: Joi.string().required().label("City/Town"),
    state_parish: Joi.string().required().label("State/Parish"),
    zip_postal_code: Joi.string().required().label("Zip/Postal Code"),
    country: Joi.string().required().label("Country"),
    country_id: Joi.string().optional().allow("").label("Country ID"),
  };

  //portfolio options
  //will be created from the api here as I am adding new properteries
  const [existingPortfolioOptions, setExistingPortfolioOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  //currency options
  //currencyOptions
  const [currencyOptions, setCurrencyOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const handleCurrencyChange = (option) => {
    //if (!option) return;
    setSelectedCurrencyOption(option);
    propertyData.currency_id = option.value;
  };

  const getCurrencyOptions = async () => {
    const response = await fetchAllCurrencies();
    const options = response.map((currency) => ({
      value: currency.currency_id,
      label: currency.currency_name,
      data: currency,
    }));
    setCurrencyOptions(options);

    // Set selectedCurrencyOption to the option where currency_code is "USD"
    const usdOption = options.find(
      (option) => option.data.currency_code === "USD"
    );
    if (usdOption) {
      setSelectedCurrencyOption(usdOption);
      setPropertyData((prevState) => ({
        ...prevState,
        currency_id: usdOption.value,
      }));
    }
  };

  //selected portfolio options
  const [selectedPortfolioOption, setSelectedPortfolioOption] = useState(null);
  //country options
  const [countryOptions, setCountryOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  const handleCountryChange = async (option) => {
    //ensure it is not empty
    if (!option) return;

    setSelectedCountryOption(option);
    propertyData.country = option.value;

    if (option.__isNew__) {
      console.log("User created a new option with value:", option.value);
      //call the api to create the country and tag it as user defined

      //then update the countryOptions
      //createNewUserDefinedCountry
      const countryCode = getCode(option.value);
      if (countryCode) {
        createCountry(option, countryCode);
      } else {
        console.log(
          "Could not find the country code for the specified country. Saving without country code."
        );
        createCountry(option, "");
      }
    } else {
      console.log("User selected the option:", option.value);
    }
  };

  async function createCountry(option, countryCode) {
    const userId = localStorage.getItem(config.user_id);
    const countryName = option.value;

    var country = {
      country_id: uuidv4(),
      country_name: countryName,
      country_code: countryCode, // This will be undefined if the country code is not found
      is_user_defined: true,
      created_at: new Date(),
      updated_at: new Date(),
      created_by_user_id: userId,
    };

    console.log("Country:", country);

    const updatedCountryList = await createNewUserDefinedCountry(country);

    // If the country was created successfully
    if (updatedCountryList) {
      // Update the countryOptions
      const options = updatedCountryList.map((country) => ({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      }));
      setCountryOptions(options);

      // Select this new country
      setSelectedCountryOption({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      });

      toast.success("New country saved successfully");
    } else {
      // Handle the case where the country could not be saved
      toast.error(
        "New country could not be saved successfully, please try again."
      );
    }
  }

  const getCountryOptions = async () => {
    const response = await fetchAllCountries();
    const options = response.map((country) => ({
      value: country.country_id,
      label: country.is_user_defined
        ? `${country.country_name} `
        : country.country_name,
      data: country,
    }));
    setCountryOptions(options);
  };

  const formattedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: option.data.is_user_defined
      ? `${option.label} (User Defined)`
      : option.label,
  }));

  //property type options
  //propertyTypeOptions
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  const [selectedPropertyTypeOption, setSelectedPropertyTypeOption] =
    useState(null);

  const handlePropertyTypeChange = (option) => {
    if (!option) return;
    console.log("Selected option:", option);
    console.log(
      "Original sub property type options:",
      originalSubPropertyTypeOptions
    );

    setSelectedPropertyTypeOption(option);
    setPropertyData((prevState) => ({
      ...prevState,
      property_type_id: option.value,
    }));

    // Filter the sub property types based on the selected option
    const filtered = originalSubPropertyTypeOptions.filter(
      (subPropertyType) => {
        console.log(
          "Comparing:",
          subPropertyType.data.property_type_id,
          "and",
          option.value
        );
        return subPropertyType.data.property_type_id === option.value;
      }
    );
    console.log("Filtered sub property type options:", filtered);
    setSubPropertyTypeOptions(filtered);
    setSelectedSubPropertyTypeOption(null);
  };
  //sub property type options
  //subPropertyTypeOptions
  // Original list of sub property types
  const [originalSubPropertyTypeOptions, setOriginalSubPropertyTypeOptions] =
    useState([{ value: "", label: "", data: {} }]);

  const [subPropertyTypeOptions, setSubPropertyTypeOptions] = useState([
    { value: "", label: "", data: {} },
  ]);

  const [selectedSubPropertyTypeOption, setSelectedSubPropertyTypeOption] =
    useState(null);

  const handleSubPropertyTypeChange = (option) => {
    if (!option) return;
    setSelectedSubPropertyTypeOption(option);
    propertyData.sub_property_type_id = option.value;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(propertyData, schema, options);
    if (!error) return null;

    console.log("Joi Error:", error);
    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    if (!schema[name]) {
      console.error(`No schema defined for ${name}`);
      return `No schema defined for ${name}`;
    }

    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        toast.error(errors[error]);
      }
    }
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = (input) => {
    if (input && input.currentTarget) {
      const { name, value, checked, type } = input.currentTarget;
      const errorMessage = validateProperty({ name, value });

      setPropertyData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));

      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (errorMessage) {
          newErrors[name] = errorMessage;
        } else {
          delete newErrors[name];
        }
        return newErrors;
      });
    } else if (input && input.value) {
      // This is an option from the CreatableSelect component for expense_name
      // Add your specific logic here
    } else if (input && input.label === "portfolio_id") {
      //alert('Portfolio ID');
      // This is an option from the Select component for expense_category
      // Add your specific logic here
    } else if (input && input.label === "budget_category_selected_id") {
      // This is an option from the Select component for budget_category_selected_id
      // Add your specific logic here
    }
  };

  const handlePortfolioChange = (option) => {
    if (!option) {
      setPropertyData((prevState) => ({
        ...prevState,
        portfolio_id: "",
      }));
      setSelectedPortfolioOption(null);
      return;
    }

    console.log("Portfolio changed:", option);

    setPropertyData((prevState) => ({
      ...prevState,
      portfolio_id: option.value,
    }));

    setSelectedPortfolioOption(option);

    if (option.__isNew__) {
      console.log("Creating new portfolio group");
      createNewPortfolio(option.label);
    }
  };

  const createNewPortfolio = async (portfolio_name) => {
    const userId = localStorage.getItem(config.user_id);
    const portfolio_id = uuidv4();

    const response = await createOrUpdatePortfolio({
      portfolio_id: portfolio_id,
      ownership_id: userId,
      portfolio_name: portfolio_name,
    });

    if (response) {
      const options = response.map((portfolio) => ({
        value: portfolio.portfolio_id,
        label: portfolio.portfolio_name,
        data: portfolio,
      }));
      setExistingPortfolioOptions(options);

      const newPortfolio = options.find(
        (portfolio) => portfolio.label === portfolio_name
      );

      if (newPortfolio) {
        setSelectedPortfolioOption(newPortfolio);
        setPropertyData((prevState) => ({
          ...prevState,
          portfolio_id: newPortfolio.value,
        }));
      }

      toast.success("New portfolio saved successfully");
    } else {
      toast.error(
        "New portfolio could not be saved successfully, please try again."
      );
    }
  };

  const doSubmit = async () => {
    propertyData.property_id = property_id;
    propertyData.property_type_id = selectedPropertyTypeOption.value;
    propertyData.country_id = selectedCountryOption.value;
    propertyData.country = selectedCountryOption.label;

    console.log("Property Data:", propertyData);

    const updatedPropertyData = await createOrUpdateProperty(propertyData);

    if (updatedPropertyData) {
      console.log("Property saved successfully:", updatedPropertyData);
      toast.success("Property saved successfully, next create your Units");
      setIsPropertyAlreadyCreated(true);
      return true;
    } else {
      console.log("Property could not be saved successfully");
      toast.error("Property could not be saved successfully");
      console.log("Property failed to save:", updatedPropertyData);
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getPropertyTypes();
    getSubPropertyTypes();
    getCurrencyOptions();
    //next fetch all property types then filter it by the selected property type
    //get the existing portfolios
    getExistingPortfolios();
    getCountryOptions();
  }, []);

  //set the property id on load
  const [property_id, setPropertyId] = useState(uuidv4());
  //get all property types

  const getPropertyTypes = async () => {
    //set propertyTypeOptions

    const response = await fetchAllPropertyTypes();
    //set propertyTypeOptions
    const options = response.map((propertyType) => ({
      value: propertyType.property_type_id,
      label: propertyType.property_type_name,
      data: propertyType,
    }));
    setPropertyTypeOptions(options);
  };

  const getSubPropertyTypes = async () => {
    //set propertyTypeOptions

    const response = await fetchAllSubPropertyTypes();
    //set propertyTypeOptions
    const options = response.map((subPropertyType) => ({
      value: subPropertyType.sub_property_type_id,
      label: subPropertyType.sub_property_type_name,
      data: subPropertyType,
    }));
    setSubPropertyTypeOptions(options);
    setOriginalSubPropertyTypeOptions(options);

    console.log("Sub Property Types:", options);
  };

  //get the existing portfolios
  //need to make this an api call
  const getExistingPortfolios = async () => {
    //set existingPortfolio

    const userId = localStorage.getItem(config.user_id);
    const response = await getPortfolioByOwnershipId(userId);

    if (!response) {
      console.log("No portfolios found for this user");
      return;
    }

    //set placeholder values for setExistingPortfolioOptions
    const options = response.map((portfolio) => ({
      value: portfolio.portfolio_id,
      label: portfolio.portfolio_name,
      data: portfolio,
    }));
    setExistingPortfolioOptions(options);
    //call the api to get the portfolios
  };

  //const [currentStep, setCurrentStep] = useState(steps[0]);

  //could handle the the validation inside each component
  // Define your save functions
  const saveProperty = async () => {
    const success = await doSubmit();
    if (success) {
      setIsPropertyAlreadyCreated(true);
    }
    return success;
  };

  const saveUnits = async () => {
    // This function is now handled within the PropertyUnitStep component
    return true;
  };

  const saveBankAccounts = async () => {
    // This function is now handled within the PropertyBankAccounts component
    return true;
  };

  const saveOwnership = async () => {
    // Implement the logic to save ownership
    // Return true if successful, false otherwise
    return true; // Placeholder
  };

  const [isPropertyAlreadyCreated, setIsPropertyAlreadyCreated] =
    useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = async (stepIndex) => {
    let success = false;
    switch (stepIndex) {
      case 0: // Property step
        success = await saveProperty();
        break;
      case 1: // Units step
        success = await saveUnits();
        break;
      case 2: // Bank Accounts step
        success = await saveBankAccounts();
        break;
      case 3: // Ownership step
        success = await saveOwnership();
        if (success) {
          navigate(`/property/${property_id}`); // Navigate to property profile page
        }
        break;
    }
    if (success) {
      setCurrentStep(stepIndex + 1);
    }
    return success;
  };

  const steps = ["Property Details", "Units", "Bank Accounts", "Ownership"];

  return (
    <React.Fragment>
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Properties"
            previousPageLink="/myproperties"
          />
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    New Property Form
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="card-body">
                  <div className="stepper-wrapper mb-4">
                    {steps.map((step, index) => (
                      <div
                        key={index}
                        className={`stepper-item ${
                          index < currentStep
                            ? "completed"
                            : index === currentStep
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="step-counter">{index + 1}</div>
                        <div className="step-name">{step}</div>
                      </div>
                    ))}
                  </div>
                  {currentStep === 0 && (
                    <PropertyStep
                      title="Property"
                      isDarkMode={isDarkMode}
                      pageTitle={pageTitle}
                      existingPortfolioOptions={existingPortfolioOptions}
                      handlePortfolioChange={handlePortfolioChange}
                      selectedCurrencyOption={selectedCurrencyOption}
                      handleCurrencyChange={handleCurrencyChange}
                      currencyOptions={currencyOptions}
                      selectedPropertyTypeOption={selectedPropertyTypeOption}
                      handlePropertyTypeChange={handlePropertyTypeChange}
                      propertyTypeOptions={propertyTypeOptions}
                      selectedSubPropertyTypeOption={
                        selectedSubPropertyTypeOption
                      }
                      handleSubPropertyTypeChange={handleSubPropertyTypeChange}
                      subPropertyTypeOptions={subPropertyTypeOptions}
                      propertyData={propertyData}
                      handleChange={handleChange}
                      errors={errors}
                      formattedCountryOptions={formattedCountryOptions}
                      handleCountryChange={handleCountryChange}
                      validate={validate}
                      handleSubmit={() => handleNext(0)}
                    />
                  )}
                  {currentStep === 1 && (
                    <PropertyUnitStep
                      title="Units"
                      isDarkMode={isDarkMode}
                      property_id={property_id}
                      propertyUnitsData={propertyUnitsData}
                      setPropertyUnitsData={setPropertyUnitsData}
                      isPropertyAlreadyCreated={isPropertyAlreadyCreated}
                      selectedCurrencyOption={selectedCurrencyOption}
                      handleSubmit={() => handleNext(1)}
                    />
                  )}
                  {currentStep === 2 && (
                    <PropertyBankAccounts
                      title="Bank Accounts"
                      isDarkMode={isDarkMode}
                      property_id={property_id}
                      isPropertyAlreadyCreated={isPropertyAlreadyCreated}
                      handleSubmit={() => handleNext(2)}
                    />
                  )}
                  {currentStep === 3 && (
                    <PropertyOwnership
                      title="Ownership"
                      isDarkMode={isDarkMode}
                      portfolio_id={selectedPortfolioOption?.value}
                      handleSubmit={() => handleNext(3)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewProperty;
