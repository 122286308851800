import { v4 as uuidv4 } from "uuid";
import http from "../general/httpService";
import config from "../../constants/config";

export interface GuideSection {
  section_id: string;
  title: string;
  content: string;
  order: number;
  created_at: Date;
  updated_at: Date;
}

export interface Guide {
  guide_id: string;
  title: string;
  sections: GuideSection[];
  category: string;
  tags: string[];
  author_id: string;
  portfolio_ids: string[];
  property_ids: string[];
  property_unit_ids: string[];
  views: number;
  downloads: number;
  rating: number;
  is_public: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface GuideId {
  guide_id: string;
}

export interface AuthorId {
  author_id: string;
}

export interface GuideCategory {
  category: string;
}

export interface GuideAccess {
  guide_id: string;
  portfolio_id?: string;
  property_id?: string;
  property_unit_id?: string;
}

export interface GuideAccessCheck {
  author_id?: string;
  portfolio_id?: string;
  property_id?: string;
  property_unit_id?: string;
}

// Mock categories
export const categories = [
  "House Rules",
  "Emergency Information",
  "Local Guide",
  "Maintenance",
  "Check-in/Check-out",
  "Amenities",
  "General",
];

//let guides = [...mockGuides];

/**
 * Gets the access check data with the current user's ID from localStorage
 * @param portfolioId Optional portfolio ID to check access for
 * @param propertyId Optional property ID to check access for
 * @param propertyUnitId Optional property unit ID to check access for
 */
export const getAccessCheck = (
  portfolioId?: string,
  propertyId?: string,
  propertyUnitId?: string
): GuideAccessCheck => {
  return {
    author_id: localStorage.getItem(config.user_id) || undefined,
    portfolio_id: portfolioId,
    property_id: propertyId,
    property_unit_id: propertyUnitId,
  };
};

// Service functions
export const getGuides = async (
  accessCheck?: GuideAccessCheck
): Promise<Guide[]> => {
  const fullApiEndpoint = config.apiEndpoint + `/guides/guides`;
  const params = accessCheck || getAccessCheck();
  const { data: results } = await http.get(fullApiEndpoint, {
    params: {
      access_check_author_id: params.author_id,
      access_check_portfolio_id: params.portfolio_id,
      access_check_property_id: params.property_id,
      access_check_property_unit_id: params.property_unit_id,
    },
  });
  return results;
};

export const getGuideById = async (
  guide_id: string,
  accessCheck?: GuideAccessCheck
): Promise<Guide> => {
  const fullApiEndpoint = config.apiEndpoint + `/guides/guide/${guide_id}`;
  const params = accessCheck || getAccessCheck();
  const { data: result } = await http.get(fullApiEndpoint, {
    params: {
      access_check_author_id: params.author_id,
      access_check_portfolio_id: params.portfolio_id,
      access_check_property_id: params.property_id,
      access_check_property_unit_id: params.property_unit_id,
    },
  });
  return result;
};

export const createGuide = async (
  guideData: Partial<Guide>
): Promise<Guide> => {
  const fullApiEndpoint = config.apiEndpoint + `/guides/create_guide`;
  const { data: result } = await http.post(fullApiEndpoint, guideData);
  return result;
};

export const updateGuide = async (
  guide_id: string,
  guideData: Partial<Guide>,
  author_id: string
): Promise<Guide> => {
  const fullApiEndpoint =
    config.apiEndpoint + `/guides/update_guide/${guide_id}`;
  const { data: result } = await http.put(fullApiEndpoint, guideData, {
    params: { author_id },
  });
  return result;
};

export const deleteGuide = async (guide_id: string): Promise<boolean> => {
  const author_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/guides/delete_guide/${guide_id}`;
  await http.delete(fullApiEndpoint, { params: { author_id } });
  return true;
};

export const searchGuides = async (
  query: string,
  accessCheck?: GuideAccessCheck
): Promise<Guide[]> => {
  const params = accessCheck || getAccessCheck();
  const fullApiEndpoint = config.apiEndpoint + `/guides/guides/search`;
  const { data: results } = await http.get(fullApiEndpoint, {
    params: {
      search_term: query,
      access_check_author_id: params.author_id,
      access_check_portfolio_id: params.portfolio_id,
      access_check_property_id: params.property_id,
      access_check_property_unit_id: params.property_unit_id,
    },
  });
  return results;
};

export const incrementGuideStats = async (
  guide_id: string,
  field: "views" | "downloads",
  accessCheck?: GuideAccessCheck
): Promise<Guide> => {
  const params = accessCheck || getAccessCheck();
  const fullApiEndpoint =
    config.apiEndpoint + `/guides/guide/${guide_id}/increment/${field}`;
  const { data: result } = await http.post(fullApiEndpoint, null, {
    params: {
      access_check_author_id: params.author_id,
      access_check_portfolio_id: params.portfolio_id,
      access_check_property_id: params.property_id,
      access_check_property_unit_id: params.property_unit_id,
    },
  });
  return result;
};

export const updateGuideRating = async (
  guide_id: string,
  rating: number,
  accessCheck?: GuideAccessCheck
): Promise<Guide> => {
  const fullApiEndpoint =
    config.apiEndpoint + `/guides/guide/${guide_id}/rating`;
  const { data: result } = await http.post(fullApiEndpoint, {
    rating,
    access_check: accessCheck || getAccessCheck(),
  });
  return result;
};

export const updateGuideAccess = async (
  guide_id: string,
  author_id: string,
  portfolio_ids?: string[],
  property_ids?: string[],
  property_unit_ids?: string[]
): Promise<Guide> => {
  console.log(
    "updateGuideAccess",
    guide_id,
    author_id,
    portfolio_ids,
    property_ids,
    property_unit_ids
  );
  const fullApiEndpoint =
    config.apiEndpoint + `/guides/guide/${guide_id}/access`;
  const { data: result } = await http.post(fullApiEndpoint, {
    author_id,
    portfolio_ids,
    property_ids,
    property_unit_ids,
  });
  return result;
};
