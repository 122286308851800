import {
  Transaction,
  PlaidTransaction,
  FilterState,
} from "../types/transactionTypes";

export const getFilteredTransactions = <
  T extends Transaction | PlaidTransaction
>(
  transactions: T[],
  selectedFilters: FilterState
): T[] => {
  return transactions.filter((t) => {
    if (selectedFilters.dateRange) {
      const txDate = new Date(t.date);
      if (
        txDate < selectedFilters.dateRange.start ||
        txDate > selectedFilters.dateRange.end
      ) {
        return false;
      }
    }

    if (selectedFilters.amountRange) {
      const amount = Math.abs(Number(t.amount));
      if (
        amount < selectedFilters.amountRange.min ||
        amount > selectedFilters.amountRange.max
      ) {
        return false;
      }
    }

    return true;
  });
};

export const handleError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  if (typeof error === "object" && error && "response" in error) {
    const response = (error as any).response;
    return response?.data?.detail || "An unexpected error occurred";
  }
  return "An unexpected error occurred";
};

export const currencySymbols = {
  USD: "$",
  EUR: "€",
  JPY: "¥",
  GBP: "£",
  AUD: "A$",
  CAD: "C$",
  CHF: "CHF",
  CNY: "¥",
  SEK: "kr",
  NZD: "NZ$",
};
