import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuditByPropertyUnitIdForLast365Days } from "../../../../../services/propertyUnits/audit/propertyUnitAuditManagement";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../services/utils/hooks/usePageTitle";
import ShimmerEffect from "../../../../reusable/loading/ShimmerEffect";
import DataTable from "../../../../reusable/table/DataTable";

interface PropertyUnitAudit {
  property_unit_audit_id: string;
  property_unit_id: string;
  audit_date: string;
  audit_type: string;
  description: string;
  icon_details: {
    icon_name: string;
    icon_color: string;
  };
  action_name: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

const PropertyUnitAuditView = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const { propertyUnitId } = useParams();
  const pageTitle = "Property Unit Audit History";
  usePageTitle({ title: pageTitle });

  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState<PropertyUnitAudit[]>([]);

  useEffect(() => {
    fetchAudits();
  }, [propertyUnitId]);

  const fetchAudits = async () => {
    if (!propertyUnitId) return;

    try {
      setIsLoading(true);
      const response = await getAuditByPropertyUnitIdForLast365Days(
        propertyUnitId
      );
      if (response) {
        // Sort audits by date in descending order (newest first)
        const sortedAudits = response.sort(
          (a: PropertyUnitAudit, b: PropertyUnitAudit) =>
            new Date(b.audit_date).getTime() - new Date(a.audit_date).getTime()
        );
        setAudits(sortedAudits);
      }
    } catch (error) {
      console.error("Error fetching audits:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function getAvatarClass(iconColor: string) {
    switch (iconColor) {
      case "red":
        return "bg-danger-subtle text-danger";
      case "blue":
        return "bg-primary-subtle text-primary";
      case "green":
        return "bg-success-subtle text-success";
      case "orange":
        return "bg-warning-subtle text-warning";
      default:
        return "bg-success-subtle text-success";
    }
  }

  const columns = [
    {
      header: "Icon",
      accessor: "icon_details",
      cell: (row: PropertyUnitAudit) => (
        <div className="avatar-xs">
          <div
            className={`avatar-title ${getAvatarClass(
              row.icon_details?.icon_color
            )} rounded-circle`}
          >
            <i className={row.icon_details?.icon_name}></i>
          </div>
        </div>
      ),
    },
    {
      header: "Action",
      accessor: "action_name",
      sortable: true,
    },
    {
      header: "Type",
      accessor: "audit_type",
      cell: (row: PropertyUnitAudit) => (
        <span className="badge bg-primary-subtle text-primary">
          {row.audit_type}
        </span>
      ),
      sortable: true,
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Date",
      accessor: "audit_date",
      cell: (row: PropertyUnitAudit) =>
        new Date(row.audit_date).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      sortable: true,
    },
    {
      header: "Created By",
      accessor: "created_by",
      sortable: true,
    },
  ];

  return (
    <div className={`${getThemeClasses()}`}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={pageTitle}
          previousPageTitle="Property Unit"
          previousPageLink={`/propertyUnit/${propertyUnitId}`}
        />

        <DataTable
          columns={columns}
          data={audits}
          isLoading={isLoading}
          LoadingComponent={() => (
            <ShimmerEffect
              type="table"
              height="500px"
              width="100%"
              darkMode={isDarkMode}
            />
          )}
          searchPlaceholder="Search audits..."
          darkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default PropertyUnitAuditView;
