import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HtmlPreview from "../../../../reusable/preview/HtmlPreview";

interface ContractTemplate {
  contract_template_id: string;
  user_id: string;
  portfolio_id: string;
  property_id: string;
  contract_name: string;
  contract_description: string;
  contract_template: string;
  created_at: number;
  updated_at: number;
  is_active: boolean;
  is_deleted: boolean;
}

interface KanbanViewProps {
  data: ContractTemplate[];
  isDarkMode: boolean;
  onRowClick: (template: ContractTemplate) => void;
}

export const ContractTemplateKanbanView: React.FC<KanbanViewProps> = ({
  data,
  isDarkMode,
  onRowClick,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [filteredData, setFilteredData] = useState<ContractTemplate[]>(data);

  // Filter data when search term changes
  useEffect(() => {
    const results = data.filter((item) => {
      const nameMatch = item.contract_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const descMatch = item.contract_description
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return nameMatch || descMatch;
    });
    setFilteredData(results);
    setCurrentPage(1); // Reset to first page after search
  }, [searchTerm, data]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="card tw-mb-0">
      <div className="card-body tw-p-4">
        <div className="tw-mb-4">
          <div className="row">
            <div className="col-lg-6 col-sm-8">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search contracts..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </div>
            <div className="col-lg-6 col-sm-4">
              <div className="tw-flex tw-justify-end tw-items-center">
                <select
                  className="form-select tw-w-auto"
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="4">4 per page</option>
                  <option value="8">8 per page</option>
                  <option value="12">12 per page</option>
                  <option value="16">16 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-overflow-auto tw-h-[calc(100vh-280px)] tw-pb-10">
          <div className="row mx-0">
            {currentItems.length > 0 ? (
              currentItems.map((template, index) => (
                <div
                  className="col-sm-6 col-lg-4 col-xl-3 mb-4"
                  key={index}
                  onClick={() => onRowClick(template)}
                >
                  <div className="card tasks-box h-100 tw-cursor-pointer hover:tw-shadow-md tw-transition-shadow">
                    <div className="card-body">
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <h6 className="fs-15 mb-0 text-truncate task-title">
                            <Link
                              to={`/contractTemplate/${template.contract_template_id}`}
                              className="text-primary fw-medium fs-16 flex-grow-1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {template.contract_name}
                            </Link>
                          </h6>
                        </div>
                      </div>

                      <div className="tw-flex tw-flex-col tw-h-full">
                        <p className="text-muted tw-mb-2 tw-line-clamp-2">
                          {template.contract_description}
                        </p>

                        <div className="tw-relative tw-h-[160px] tw-overflow-hidden tw-rounded-lg tw-mt-auto">
                          <HtmlPreview
                            isDarkMode={isDarkMode}
                            htmlContent={template.contract_template}
                            height="160px"
                            padding="10px"
                            className="tw-absolute tw-inset-0"
                          />
                          <div
                            className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-12 tw-bg-gradient-to-t ${
                              isDarkMode ? "tw-from-gray-800" : "tw-from-white"
                            } tw-to-transparent`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer border-top-dashed">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted">
                          <i className="ri-time-line align-bottom"></i>{" "}
                          {new Date(template.updated_at).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </span>
                        <span
                          className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium ${
                            template.is_active
                              ? "tw-bg-green-100 tw-text-green-800 dark:tw-bg-green-900/30 dark:tw-text-green-400"
                              : "tw-bg-gray-100 tw-text-gray-800 dark:tw-bg-gray-700/30 dark:tw-text-gray-400"
                          }`}
                        >
                          {template.is_active ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 tw-text-center tw-py-8">
                <div className="tw-text-lg tw-font-medium tw-text-gray-500 dark:tw-text-gray-400">
                  No contracts found
                </div>
                <p className="tw-text-gray-400 dark:tw-text-gray-500 tw-mt-2">
                  Try adjusting your search or filters
                </p>
              </div>
            )}
          </div>
        </div>

        {totalPages > 1 && (
          <div className="tw-mt-4 tw-flex tw-justify-center">
            <nav>
              <ul className="pagination pagination-rounded mb-0">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i className="ri-arrow-left-s-line"></i>
                  </button>
                </li>

                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <li
                      key={page}
                      className={`page-item ${
                        page === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  )
                )}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <i className="ri-arrow-right-s-line"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};
