import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import config from "../../../../constants/config";

const ProtectedRoute = ({ user, redirectPath = "/", children }) => {
  //alert(user);
  if (!localStorage.getItem(config.access_token)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
