import http from "../../../general/httpService";
import config from "../../../../constants/config";

///unit_revenue/create_or_update_unit_revenue

export async function createOrUpdateUnitRevenue(data) {
  const fullApiEndpoint =
    config.apiEndpoint + `/unit_revenue/create_or_update_unit_revenue`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//upload_attachment_to_unit_revenue
export async function uploadAttachmentToUnitRevenue(data) {
  const fullApiEndpoint =
    config.apiEndpoint + `/unit_revenue/upload_attachment_to_unit_revenue`;
  const { data: result } = await http.post(fullApiEndpoint, data);

  return result;
}

//delete_revenue_attachment
export async function deleteRevenueAttachment(attachmentToDelete) {
  const fullApiEndpoint =
    config.apiEndpoint + `/unit_revenue/delete_revenue_attachment`;
  const { data: result } = await http.post(fullApiEndpoint, attachmentToDelete);

  return result;
}

//get_unit_revenue_by_id
export async function getUnitRevenueById(unit_revenue_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_unit_revenue_by_id/${unit_revenue_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_all_revenues_for_property_unit_year
export async function getAllRevenuesForPropertyUnitYear(property_unit_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_all_revenues_for_property_unit_year`;
  //get the current year
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
    revenue_year: new Date().getFullYear(),
  });

  return result;
}

//getAllRevenueForPropertyUnitBankAccount
//get_all_revenues_for_property_unit_bank_account
export async function getAllRevenueForPropertyUnitBankAccount(
  property_unit_id,
  property_bank_account_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_all_revenues_for_property_unit_bank_account`;
  //get the current year
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: property_unit_id,
    property_bank_account_id: property_bank_account_id,
  });

  return result;
}

//get_top_5_units_with_highest_revenue_return
//post and takes user_id
export async function getTop5UnitsWithHighestRevenueReturn() {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_top_5_units_with_highest_revenue_return`;
  //get the current year
  const { data: result } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });

  return result;
}

//new api endpoints for the revenue

//This is meant to be recent summaries start
//get_5_recent_revenue_for_property_unit_month_year
export async function get5RecentRevenueForPropertyUnitMonthYear(property_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_5_recent_revenue_for_property_unit_month_year`;
  //get the current year
  const { data: result } = await http.post(fullApiEndpoint, {
    property_id: property_id,
  });

  return result;
}

//This is meant to be recent summaries end

//get_5_recent_revenue_for_property_unit_month_year_individual
export async function get5RecentRevenueForPropertyUnitMonthYearIndividual(
  property_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/unit_revenue/get_5_recent_revenue_for_property_unit_month_year_individual`;
  //get the current year
  const { data: result } = await http.post(fullApiEndpoint, {
    property_id: property_id,
  });

  return result;
}
