import http from "../general/httpService";
import config from "../../constants/config";
import { toast } from "react-toastify";
import { devLog } from "services/utils/logger";

// Types and Interfaces
interface PlaidLocation {
  address: string | null;
  city: string | null;
  region: string | null;
  postal_code: string | null;
  country: string | null;
  lat: number | null;
  lon: number | null;
  store_number: string | null;
}

interface PlaidPaymentMeta {
  reference_number: string | null;
  ppd_id: string | null;
  payee: string | null;
  by_order_of: string | null;
  payer: string | null;
  payment_method: string | null;
  payment_processor: string | null;
  reason: string | null;
}

interface PlaidPersonalFinanceCategory {
  primary: string;
  detailed: string;
  confidence_level: "VERY_HIGH" | "HIGH" | "MEDIUM" | "LOW" | "UNKNOWN";
}

interface PlaidCounterparty {
  name: string | null;
  type: string | null;
  logo_url: string | null;
  website: string | null;
}

interface PlaidTransaction {
  account_id: string;
  account_owner: string | null;
  amount: number;
  authorized_date: string | null;
  authorized_datetime: string | null;
  category: string[];
  category_id: string;
  check_number: string | null;
  counterparties: PlaidCounterparty[];
  date: string;
  datetime: string | null;
  iso_currency_code: string;
  location: PlaidLocation;
  logo_url: string | null;
  merchant_entity_id: string | null;
  merchant_name: string | null;
  name: string;
  payment_channel: "online" | "in store" | "other";
  payment_meta: PlaidPaymentMeta;
  pending: boolean;
  pending_transaction_id: string | null;
  personal_finance_category: PlaidPersonalFinanceCategory;
  personal_finance_category_icon_url: string | null;
  transaction_code: string | null;
  transaction_id: string;
  transaction_type: "place" | "digital" | "special" | "unresolved";
  unofficial_currency_code: string | null;
  website: string | null;
}

interface TransactionResponse {
  transactions: PlaidTransaction[];
  successful_accounts: any[];
  items_requiring_attention: {
    items_requiring_update: any[];
  };
  invalid_tokens: any[];
  other_errors: any[];
}

interface BankAccountData {
  bank_name: string;
  account_name: string;
  account_type: string;
  account_number: string;
  routing_number: string;
  balance: number;
  currency: string;
  is_active: boolean;
}

interface PropertyBankAccount {
  property_unit_id: string;
  bank_account_id: string;
  is_active: boolean;
}

interface ApiError {
  response?: {
    status?: number;
    data?: any;
  };
  message?: string;
}

//need to be able to delete bank accounts that arent linked to a property unit and also remove the bank account from the property unit
//also need to be able to refresh bank tokens if they expire or now invalid
//TODO: revamp the notifcation system to be more efficient so it will every few mins process and upset then cache so it doesnt run every time
//bank_account/create_link_token
export async function createLinkToken(): Promise<{ link_token: string }> {
  try {
    const user_id = localStorage.getItem(config.user_id);
    const fullApiEndpoint =
      config.apiEndpoint + `/bank_account/create_link_token`;
    const { data: result } = await http.post(fullApiEndpoint, { user_id });

    if (!result || !result.link_token) {
      console.error("Invalid response from create_link_token:", result);
      throw new Error("Failed to create link token");
    }

    return result;
  } catch (error) {
    console.error("Error in createLinkToken:", error);
    throw error;
  }
}

// Function to handle the success of the Plaid Link flow
export async function exchangePublicToken(public_token: string): Promise<any> {
  try {
    const user_id = localStorage.getItem(config.user_id);
    if (!user_id) {
      throw new Error("User ID not found in local storage");
    }

    const ownership_id = user_id;
    const fullApiEndpoint = `${config.apiEndpoint}/bank_account/exchange_public_token`;

    const requestBody = {
      public_token,
      user_id,
      ownership_id,
    };

    console.log("Sending exchange request with:", requestBody);

    const { data: result } = await http.post(fullApiEndpoint, requestBody);

    if (!result) {
      throw new Error("No result received from server");
    }

    console.log("Exchange token response:", result);
    return result;
  } catch (error: unknown) {
    const apiError = error as ApiError;
    console.error("Error in exchangePublicToken:", apiError);
    if (apiError.response?.data) {
      console.error("Server error details:", apiError.response.data);
    }
    throw error;
  }
}

//bank_account/get_bank_accounts
export async function getBankAccounts(): Promise<any[]> {
  //user_id
  const user_id = localStorage.getItem(config.user_id);

  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/get_bank_accounts`;
  const { data: result } = await http.post(fullApiEndpoint, {
    ownership_id: user_id,
  });

  return result;
}

//bank_account/create_bank_and_bank_account
export async function createBankAndBankAccountManually(
  bank_data: BankAccountData
): Promise<any> {
  const ownership_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_bank_and_bank_account`;
  const { data: result } = await http.post(fullApiEndpoint, {
    bank_account: bank_data,
    ownership_id: ownership_id,
  });

  return result;
}

//bank_account/create_or_update_property_bank_account
export async function createOrUpdatePropertyBankAccount(
  property_bank_account: PropertyBankAccount
): Promise<any> {
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_or_update_property_bank_account`;
  const { data: result } = await http.post(
    fullApiEndpoint,
    property_bank_account
  );

  return result;
}

//get_transactions
export async function getTransactions(
  property_unit_id: string,
  start_date?: string,
  end_date?: string
): Promise<TransactionResponse> {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint = config.apiEndpoint + `/bank_account/get_transactions`;

  const requestData = {
    property_unit_id,
    user_id,
    start_date,
    end_date,
  };

  console.log(
    "[getTransactions] Step 1: Sending request with data:",
    requestData
  );
  console.log("[getTransactions] Step 1: Using endpoint:", fullApiEndpoint);

  try {
    console.log("[getTransactions] Step 2: About to make HTTP request");
    const response = await http.post(fullApiEndpoint, requestData);
    console.log("[getTransactions] Step 3: Received HTTP response:", response);

    const result = response.data;
    console.log(
      "[getTransactions] Step 4: Extracted data from response:",
      result
    );

    if (!result) {
      console.error(
        "[getTransactions] Step 4a: No result received from server"
      );
      throw new Error("No response received from server");
    }

    if (!result.transactions) {
      console.error(
        "[getTransactions] Step 4b: No transactions array in result:",
        result
      );
      throw new Error("No transactions array in response");
    }

    console.log(
      "[getTransactions] Step 5: About to return result with",
      result.transactions.length,
      "transactions"
    );
    return result;
  } catch (error: unknown) {
    console.error("[getTransactions] Error occurred:", error);

    // Check if it's an axios error
    if ((error as any).isAxiosError) {
      const axiosError = error as any;
      console.error("[getTransactions] Axios error details:", {
        status: axiosError.response?.status,
        statusText: axiosError.response?.statusText,
        data: axiosError.response?.data,
        headers: axiosError.response?.headers,
      });
    }

    // Re-throw the error to be handled by the caller
    throw error;
  }
}

//get_property_unit_bank_accounts
export async function getPropertyUnitBankAccounts(
  property_unit_id: string
): Promise<any[]> {
  try {
    const fullApiEndpoint =
      config.apiEndpoint +
      `/bank_account/get_property_unit_bank_accounts/${property_unit_id}`;
    const { data: result } = await http.get(fullApiEndpoint);

    if (!result || result.length === 0) {
      toast.info("Please link a bank account to this unit");
    }

    return result;
  } catch (error: unknown) {
    const apiError = error as ApiError;
    console.error("Error fetching property unit bank accounts:", apiError);

    if (!apiError.response) {
      toast.error(
        "Unable to connect to the server. Please check your internet connection."
      );
    }

    if (apiError.response?.status === 500) {
      toast.error("Server encountered an error. Please try again later.");
    }

    toast.error("Failed to fetch bank accounts. Please try again later.");
    throw error;
  }
}

//get_property_bank_account_details
export async function getPropertyBankAccountByPropBankId(
  property_bank_account_id: string
): Promise<any> {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/bank_account/get_property_bank_account_details_by_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_bank_account_id: property_bank_account_id,
  });

  return result;
}

//get_bank_account_details_by_id
export async function getBankAccountDetailsById(
  bank_account_id: string
): Promise<any> {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/bank_account/get_bank_account_details_by_id/${bank_account_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

export async function updatePlaidAccountDetails(
  public_token: string
): Promise<any> {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_or_update_plaid_account_details`;
  const { data: result } = await http.post(fullApiEndpoint, {
    public_token,
    user_id,
  });
  return result;
}

// Function to handle Plaid re-authentication
export async function updatePlaidConnection(
  public_token: string,
  item_id: string
): Promise<any> {
  try {
    const fullApiEndpoint =
      config.apiEndpoint + `/bank_account/update_plaid_connection`;

    const requestBody = {
      public_token,
      item_id,
    };

    console.log("Updating Plaid connection:", requestBody);

    const { data: result } = await http.post(fullApiEndpoint, requestBody);

    if (!result) {
      throw new Error("No response received from server");
    }

    console.log("Update Plaid connection response:", result);
    return result;
  } catch (error: unknown) {
    const apiError = error as ApiError;
    console.error("Error updating Plaid connection:", apiError);
    if (apiError.response?.data) {
      console.error("Server error details:", apiError.response.data);
    }
    throw error;
  }
}
