import React, { useEffect, useState } from "react";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";

import { PlaidLink } from "react-plaid-link";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Input from "../../../../../reusable/form/Input";
import { usePlaidLink } from "react-plaid-link";
import {
  createBankAndBankAccountManually,
  createLinkToken,
  createOrUpdatePropertyBankAccount,
  exchangePublicToken,
  getBankAccounts,
  getPropertyUnitBankAccounts,
} from "../../../../../../services/bank/bankManager";
import Joi from "joi-browser";
import LoaderView from "../../../../../reusable/loading/loaderView";
import { set } from "react-hook-form";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Modal, Badge, Button } from "react-bootstrap";
import BankAccount from "../../../../../../services/model/bankAccountModel";
import {
  createNewUserDefinedCountry,
  fetchAllCountries,
} from "../../../../../../services/systemDefined/country/countryManagement";
import { getCode } from "country-list";
import config from "../../../../../../constants/config";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import BankAccountCard from "./sub/bankAccountCard";
import { usePageTitle } from "../../../../../../services/utils/hooks/usePageTitle";
//to go to the create bank account page
//createPropertyUnitBankAccounts
//fetch all the bank accounts for the property unit
const PropertyUnitBankAccounts = ({ isDarkMode }) => {
  const pageTitle = "Bank Accounts";
  usePageTitle({ title: pageTitle });
  const { propertyUnitId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [bankAccounts, setBankAccounts] = useState([
    {
      property_bank_account_id: "",
      bank_account_id: "",
      property_id: "",
      property_unit_id: "",
      bank_alias_name: "",
      account_balance: 0,
      is_reserve_account: false,
      reserve_amount: 0,
      bank_account: {
        account_id: "",
        account_name: "",
        account_mask: "",
        account_subtype: "",
        account_type: "",
        available_balance: 0,
        bank_account_id: "",
        bank_id: "",
        bank_name: "",
        created_at: "",
        currency_id: "",
        current_balance: 0,
        institution_id: "",
        is_default_account: false,
        logo: "",
        routing_number: "",
        routing_numbers: [""],
        updated_at: "",
        url: "",
      },
      bank: {
        bank_country: "",
        bank_name: "",
        ownership_id: "",
        bank_id: "",
        created_at: "",
        institution_id: "",
        is_default_bank: false,
        logo: "",
        routing_number: "",
        routing_numbers: [""],
        swift_code: "",
        updated_at: "",
        url: "",
      },
    },
  ]);

  //search for bank accounts
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("Account Name");

  const filteredBankAccounts = bankAccounts
    .filter(
      (bankAccount) =>
        bankAccount?.bank_account?.account_name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        bankAccount?.bank?.bank_name
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        bankAccount?.bank_account?.routing_numbers.some((routing_number) =>
          routing_number?.toLowerCase().includes(searchTerm?.toLowerCase())
        ) ||
        (bankAccount?.bank_account?.url &&
          bankAccount?.bank_account?.url
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase()))
    )
    .sort((a, b) => {
      switch (sortOption) {
        case "Account Name":
          return a.bank_account.account_name.localeCompare(
            b.bank_account.account_name
          );
        case "Balance":
          return (
            a.bank_account.current_balance - b.bank_account.current_balance
          );
        default:
          return 0;
      }
    });

  useEffect(() => {
    findAllBankAccountForUnit();
    getCountryOptions();
  }, []);

  //the bank accounts that are linked to the logged in user
  const findAllBankAccountForUnit = async () => {
    const bankAccounts = await getPropertyUnitBankAccounts(propertyUnitId);
    console.log("Bank Accounts for the unit");
    console.log(bankAccounts);

    if (
      !bankAccounts ||
      bankAccounts.length === 0 ||
      bankAccounts === null ||
      bankAccounts === undefined
    ) {
      toast.error("Could not find bank accounts");
      return;
    } else {
      setBankAccounts(bankAccounts);
    }
  };

  //TODO: next be able to select a bank account and press confirm to let it be account associated with the property
  //TODO: ability to add a bank account manually
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedBankAccountIndex, setSlectedBankAccountIndex] = useState(null);

  //manually creating a bank account (one at a time)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // Define your schema for validation
  const schema = Joi.object({
    account_id: Joi.string().required(),
    account_type: Joi.string().required(),
    routing_number: Joi.string().required(),
    bank_name: Joi.string().required(),
    account_number: Joi.string().required(),
  });

  const [accountTypes, setAccountTypes] = useState([
    { value: "Checking", label: "Checking" },
    { value: "Savings", label: "Savings" },
  ]);

  // Define your state
  const [newBankAccount, setNewBankAccount] = useState({
    account_id: "",
    account_name: "",
    account_type: "",
    routing_number: "",
    bank_name: "",
    account_number: "",
    bank_country: "",
  });

  //country options
  //need to refactor this to be a reusable component
  //country options
  const [countryOptions, setCountryOptions] = useState([
    { value: "", label: "", data: {} },
  ]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  const handleCountryChange = async (option) => {
    //ensure it is not empty
    if (!option) return;

    setSelectedCountryOption(option);
    newBankAccount.bank_country = option.label;

    if (option.__isNew__) {
      console.log("User created a new option with value:", option.value);
      //call the api to create the country and tag it as user defined

      //then update the countryOptions
      //createNewUserDefinedCountry
      const countryCode = getCode(option.value);
      if (countryCode) {
        createCountry(option, countryCode);
      } else {
        console.log(
          "Could not find the country code for the specified country. Saving without country code."
        );
        createCountry(option, "");
      }
    } else {
      console.log("User selected the option:", option.value);
    }
  };
  const getCountryOptions = async () => {
    const response = await fetchAllCountries();
    const options = response.map((country) => ({
      value: country.country_id,
      label: country.is_user_defined
        ? `${country.country_name} `
        : country.country_name,
      data: country,
    }));
    setCountryOptions(options);
  };

  const formattedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: option.data.is_user_defined
      ? `${option.label} (User Defined)`
      : option.label,
  }));

  async function createCountry(option, countryCode) {
    const userId = localStorage.getItem(config.user_id);
    const countryName = option.value;

    var country = {
      country_id: uuidv4(),
      country_name: countryName,
      country_code: countryCode, // This will be undefined if the country code is not found
      is_user_defined: true,
      created_at: new Date(),
      updated_at: new Date(),
      created_by_user_id: userId,
    };

    console.log("Country:", country);

    const updatedCountryList = await createNewUserDefinedCountry(country);

    // If the country was created successfully
    if (updatedCountryList) {
      // Update the countryOptions
      const options = updatedCountryList.map((country) => ({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      }));
      setCountryOptions(options);

      // Select this new country
      setSelectedCountryOption({
        value: country.country_id,
        label: country.is_user_defined
          ? `${country.country_name} (User Defined)`
          : country.country_name,
        data: country,
      });

      toast.success("New country saved successfully");
    } else {
      // Handle the case where the country could not be saved
      toast.error(
        "New country could not be saved successfully, please try again."
      );
    }
  }

  //assign the bank account to the property_id
  const assignBankAccountToProperty = async () => {
    //assign the bank account to the property_id
    const bankAccount = selectedBankAccount;
    /*
    property_bank_account_id: str
    bank_account_id: str
    property_unit_id: str
    bank_alias_name: Optional[str] = None
    account_balance: Optional[float] = None
    is_reserve_account: Optional[bool] = None
    reserve_amount: Optional[float] = None

    */
    const propertyBankAccount = {
      property_bank_account_id: uuidv4(),
      bank_account_id: bankAccount.bank_account_id,
      property_id: "", //TODO: get the property_id or leave bank
      property_unit_id: propertyUnitId,
      bank_alias_name: bankAccount.account_name,
      account_balance: bankAccount.current_balance,
      is_reserve_account: false,
      reserve_amount: 0.0,
    };
    console.log(propertyBankAccount);

    //create or update the property bank account
    const response = await createOrUpdatePropertyBankAccount(
      propertyBankAccount
    );
    console.log(response);

    //if successful
    if (response) {
      //update the UI
      //clear the selected bank account
      setSelectedBankAccount(null);
      setSlectedBankAccountIndex(null);
      //show a success message
      toast.success("Bank account assigned to property successfully");
    } else {
      //show an error message
      toast.error("Bank account could not be assigned to property");
      return;
    }
  };

  const handleBankAccountClick = (bankAccount, index) => {
    // setSelectedBankAccountIndex(index);
    // setSelectedBankAccount(bankAccount);
    // alert('Go to a screen that shows the full information about this bank and bank account');

    // alert('Id: ' + bankAccount.property_bank_account_id);

    navigate(`/bankAccountDetail/${bankAccount.property_bank_account_id}`);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      {/* Have a mode switcher this is how you add a new account to the unit so move this to the add or assoicate new account then this only show the ones linked to the unit*/}
      <div
        className="page-content"
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Unit Transactions"
            previousPageLink={`/unitTransactions/${propertyUnitId}`}
          />
          <div className="card">
            {/*For adding new accounts to the list of banks */}
            <div className="row pt-4 px-5">
              <div className="col-xl-2 p-3 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Assign or Add Bank Account</h5>
                    <p className="card-text">
                      Assign an existing bank account or add a new bank account
                    </p>
                    <Link
                      to={`/createPropertyUnitBankAccounts/${propertyUnitId}`}
                      style={{ width: "250px" }}
                      className="btn btn-info border"
                    >
                      Add Bank Account
                    </Link>
                  </div>
                </div>
              </div>

              {selectedBankAccount && selectedBankAccount.bank_id !== "" && (
                <div className="col-xl-2 p-3">
                  <div className="card card-height-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h5 className="contact-name fs-13 mb-1">
                          <a href="#" className="link text-body">
                            {selectedBankAccount?.account_name}
                          </a>
                        </h5>
                        <button
                          style={{ border: "none", background: "transparent" }}
                          onClick={() => {
                            setSelectedBankAccount(null);
                            setSlectedBankAccountIndex(null);
                          }}
                        >
                          <i className="mdi mdi-close-thick"></i>
                        </button>
                      </div>
                      <p className="contact-born text-muted mb-0">
                        {selectedBankAccount?.routing_number}
                      </p>
                      <div className="fs-11 text-muted">
                        {selectedBankAccount?.bank_name}
                      </div>
                      <div className="row pt-4 px-5">
                        <button
                          style={{ width: "250px" }}
                          className="btn btn-primary border"
                          onClick={() => {
                            assignBankAccountToProperty();
                          }}
                        >
                          Assign Bank Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/*For selecting the bank account to be used  from the list of existing banks and the default bank at the top*/}
            <div className="row pt-4 px-5">
              {/* the selectedBankAccount bank account */}

              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                  <div className="col-md-3">
                    <h4 className="mb-sm-0">Existing Bank Accounts</h4>
                  </div>

                  <div className="col-md-6">
                    <div id="contact-existing-list">
                      <div className="row mb-2">
                        <div className="col">
                          <input
                            className="search form-control"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 text-end">
                    <NavDropdown
                      title={`Sort by: ${sortOption}`}
                      id="nav-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => setSortOption("Account Name")}
                      >
                        Account Name
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => setSortOption("Routing Number")}
                      >
                        Routing Number
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => setSortOption("Bank Name")}
                      >
                        Bank Name
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row px-4"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              {filteredBankAccounts.map((bankAccount, index) => (
                <BankAccountCard
                  key={index}
                  bankAccount={bankAccount}
                  index={index}
                  selectedBankAccountIndex={selectedBankAccountIndex}
                  onClick={handleBankAccountClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyUnitBankAccounts;
