import React from "react";

interface AiChatButtonProps {
  onClick: () => void;
  isOpen: boolean;
  isDarkMode: boolean;
}

export const AiChatButton: React.FC<AiChatButtonProps> = ({
  onClick,
  isOpen,
  isDarkMode,
}) => {
  return (
    <button
      onClick={onClick}
      className={`
        tw-w-14 tw-h-14 
        tw-rounded-full 
        tw-border
        tw-flex 
        tw-items-center 
        tw-justify-center 
        tw-transition-all 
        tw-duration-300 
        tw-ease-in-out
        tw-shadow-lg
        ${
          isDarkMode
            ? "tw-bg-[#1e2124] tw-border-gray-700 hover:tw-bg-[#2a2d31] hover:tw-border-gray-600"
            : "tw-bg-[#1e2124] tw-border-gray-700 hover:tw-bg-[#2a2d31] hover:tw-border-gray-600"
        }
        focus:tw-outline-none 
        focus:tw-ring-2 
        focus:tw-ring-[#1e2124]
        focus:tw-ring-offset-2
        ${isOpen ? "tw-rotate-180" : ""}
        ${
          isDarkMode
            ? "focus:tw-ring-offset-gray-900"
            : "focus:tw-ring-offset-white"
        }
      `}
      aria-label="Toggle AI Chat"
    >
      {isOpen ? (
        <svg
          className="tw-w-5 tw-h-5 tw-text-gray-100"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center">
          <span className="tw-text-lg tw-text-gray-100">✨</span>
        </div>
      )}
    </button>
  );
};
