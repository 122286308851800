import React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../../services/utils/currencyUtils';

const TopRevenueCard = ({ unit, defaultHouseImg }) => {
  return (
    <div className='card card-body border mb-3 shadow-sm'>
      <div className='d-flex align-items-start'>
        <div className='flex-shrink-0'>
          <Link to={`/propertyunit/${unit.property_unit_id}`}>
            <img
              src={defaultHouseImg}
              alt={unit.unit_name}
              className='avatar-sm rounded material-shadow'
            />
          </Link>
        </div>
        <div className='flex-grow-1 ms-3'>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <Link to={`/propertyunit/${unit.property_unit_id}`}>
              <h6 className='fs-15 mb-0 text-truncate task-title fw-semibold'>{unit.unit_name}</h6>
            </Link>
            <span className='badge bg-success-subtle text-success rounded-pill'>
              {unit.revenue_category}
            </span>
          </div>
          
          <div className='row g-2 mb-2'>
            <div className='col-6'>
              <div className='d-flex align-items-center'>
                <i className='bx bx-bed text-muted me-1'></i>
                <span className='text-muted fs-13'>{unit.number_of_bedrooms} Beds</span>
              </div>
            </div>
            <div className='col-6'>
              <div className='d-flex align-items-center'>
                <i className='bx bx-bath text-muted me-1'></i>
                <span className='text-muted fs-13'>{unit.number_of_bathrooms} Baths</span>
              </div>
            </div>
            <div className='col-6'>
              <div className='d-flex align-items-center'>
                <i className='bx bx-ruler text-muted me-1'></i>
                <span className='text-muted fs-13'>{unit.square_footage} sq.ft</span>
              </div>
            </div>
          </div>
          
          <div className='d-flex justify-content-between align-items-center pt-2 border-top'>
            <span className='text-muted fs-13'>YTD Revenue</span>
            <h5 className='mb-0 text-success fw-semibold'>
              {formatCurrency(unit.revenue)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRevenueCard;
