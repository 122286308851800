import http from "../../general/httpService";
import config from "../../../constants/config";

//sys_priority/priorities
export async function fetchAllPriorities() {
  const fullApiEndpoint = config.apiEndpoint + `/sys_priority/priorities`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
