import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  getCurrentUserProfileByEmail,
  updateLandlordPropertyManagerProfile,
} from "../../../services/authentication/auth";
import { updateProfilePhoto } from "../../../services/landlords/landlordProfileService";
import SingleImageUploader from "../../reusable/uploader/singleImageUploader";
import profileImg from "../../../styletheme/images/users/avatar-1.jpg";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import config from "../../../constants/config";
import { devLog } from "../../../services/utils/logger";
import LoaderView from "../../reusable/loading/loaderView";
import UserProfileForm from "./UserProfileForm";
import { usePageTitle } from "../../../services/utils/hooks/usePageTitle";

const LandlordPropManagerProfile = ({ isDarkMode }) => {
  const pageTitle = "Profile";
  usePageTitle({ title: pageTitle });
  const location = useLocation();
  const [showStripeInstructions, setShowStripeInstructions] = useState(false);
  const [stripeInstructionsMessage, setStripeInstructionsMessage] =
    useState("");
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Data, setBase64Data] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (location.state?.showStripeInstructions) {
      setShowStripeInstructions(true);
      setStripeInstructionsMessage(location.state.message);
    }
  }, [location]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userData = await getCurrentUserProfileByEmail();
        if (userData) {
          setProfile(userData);
          setUserId(localStorage.getItem(config.user_id));
        }
      } catch (error) {
        toast.error("Failed to load profile");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleImageSelect = async (imageData) => {
    if (!imageData) return;

    setIsImageUploading(true);
    try {
      await updateProfilePhoto({
        file_name: imageData.file_name,
        base64_data: imageData.base64_data,
      });

      toast.success("Profile photo updated successfully");
      const userData = await getCurrentUserProfileByEmail();
      setProfile(userData);

      setSelectedFile(null);
      setBase64Data(null);
      setImageToUpload(null);

      if (userData.profileImg) {
        devLog("userData.profileImg", userData.profileImg);
        localStorage.setItem(config.profile_img, userData.profileImg);
      }
    } catch (error) {
      toast.error("Failed to update profile photo");
    } finally {
      setIsImageUploading(false);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      devLog("formData", formData);
      //Add the user id to the form data
      formData.user_id = userId ?? localStorage.getItem(config.user_id);
      const response = await updateLandlordPropertyManagerProfile(formData);
      if (response.isError) {
        toast.error(response.message);
        return;
      }
      toast.success("Profile updated successfully");

      // Refresh profile data
      const userData = await getCurrentUserProfileByEmail();
      setProfile(userData);
    } catch (error) {
      toast.error("Failed to update profile");
    }
  };

  const renderProfileImage = (profileImg) => {
    const isFullBase64 =
      typeof profileImg === "string" && profileImg.startsWith("data:image");
    const isRawBase64 =
      typeof profileImg === "string" &&
      (profileImg.startsWith("/9j/") || /^[A-Za-z0-9+/=]+$/.test(profileImg));
    const isUrl =
      typeof profileImg === "string" &&
      (profileImg.startsWith("http") || profileImg.startsWith("https"));

    let imageSource;
    if (isFullBase64) {
      imageSource = profileImg;
    } else if (isRawBase64) {
      imageSource = `data:image/jpeg;base64,${profileImg}`;
    } else if (isUrl) {
      imageSource = profileImg;
    } else {
      imageSource = profileImg;
    }

    return (
      <div className="relative group">
        <img
          src={imageSource}
          alt="Profile"
          className="w-32 h-32 rounded-full mx-auto object-cover border-4 border-gray-200 shadow-lg"
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    );
  };

  if (loading) {
    return <LoaderView isDark={isDarkMode} />;
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <PageTitleBox pageTitle="Profile" previousPageTitle="" />

          {showStripeInstructions && (
            <div
              className={`tw-mb-6 tw-p-4 tw-rounded-lg ${
                isDarkMode
                  ? "tw-bg-yellow-900/20 tw-text-yellow-200"
                  : "tw-bg-yellow-50 tw-text-yellow-800"
              } tw-border ${
                isDarkMode ? "tw-border-yellow-900/30" : "tw-border-yellow-200"
              }`}
            >
              <div className="tw-flex tw-items-start tw-gap-3">
                <div
                  className={`tw-p-2 tw-rounded-full ${
                    isDarkMode ? "tw-bg-yellow-900/30" : "tw-bg-yellow-100"
                  }`}
                >
                  <svg
                    className="tw-w-5 tw-h-5 tw-text-yellow-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="tw-text-sm tw-mb-0">
                  {stripeInstructionsMessage}
                </p>
              </div>
            </div>
          )}

          <div
            className={`tw-min-h-screen ${
              isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
            } tw-py-8`}
          >
            <div className="tw-max-w-6xl tw-mx-auto">
              <div
                className={`${
                  isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
                } tw-shadow-xl tw-rounded-lg tw-overflow-hidden`}
              >
                <div
                  className={`tw-w-full tw-px-6 tw-py-5 tw-border-b ${
                    isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
                  }`}
                >
                  <h3
                    className={`tw-text-lg tw-leading-6 tw-font-medium ${
                      isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                    }`}
                  >
                    Profile Information
                  </h3>
                  <p
                    className={`tw-mt-1 tw-max-w-2xl tw-text-sm ${
                      isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                    }`}
                  >
                    Personal details and profile photo
                  </p>
                </div>

                <div className="tw-px-6 tw-py-8">
                  {/* Profile Image Section */}
                  <div className="tw-flex tw-items-start tw-gap-8 tw-mb-12">
                    <div className="tw-flex-shrink-0 tw-w-32">
                      {profile?.profileImg
                        ? renderProfileImage(profile.profileImg)
                        : renderProfileImage(profileImg)}
                    </div>

                    <div className="tw-flex-grow tw-max-w-sm">
                      <div className="tw-card-body">
                        <div className="tw-p-4 tw-border tw-rounded tw-border-secondary hover:tw-border-primary tw-transition-colors">
                          {isImageUploading ? (
                            <div className="tw-flex tw-justify-center tw-items-center tw-py-4">
                              <div className="tw-animate-spin tw-rounded-full tw-h-8 tw-w-8 tw-border-b-2 tw-border-gray-900"></div>
                            </div>
                          ) : (
                            <SingleImageUploader
                              selectedFile={selectedFile}
                              setSelectedFile={setSelectedFile}
                              base64Data={base64Data}
                              setBase64Data={setBase64Data}
                              imageToUpload={imageToUpload}
                              setImageToUpload={setImageToUpload}
                              handleUpload={handleImageSelect}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <UserProfileForm
                    profile={profile}
                    isDarkMode={isDarkMode}
                    onSubmit={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandlordPropManagerProfile;
