import { Message, ChatHistory } from "../../models/ai-chat/Message";
import { AiApiService } from "./AiApiService";
import config from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import http from "../../services/general/httpService";

export class AiChatStateService {
  private static instance: AiChatStateService;
  private apiService: AiApiService;
  private activeSessionId: string | null = null;

  private constructor() {
    this.apiService = AiApiService.getInstance();
  }

  static getInstance(): AiChatStateService {
    if (!AiChatStateService.instance) {
      AiChatStateService.instance = new AiChatStateService();
    }
    return AiChatStateService.instance;
  }

  async createNewChat(): Promise<string> {
    try {
      const userId = localStorage.getItem(config.user_id);
      const sessionId = uuidv4();

      // Create new chat session on the server
      await http.post(`${config.apiEndpoint}/chat/sessions`, {
        session_id: sessionId,
        user_id: userId || "",
        messages: [],
        lastUpdated: new Date().toISOString(),
      });

      // Set up the connection
      await this.apiService.setSessionId(sessionId);
      this.activeSessionId = sessionId;

      return sessionId;
    } catch (error) {
      console.error("Error creating new chat:", error);
      throw error;
    }
  }

  async setActiveSession(sessionId: string): Promise<void> {
    try {
      this.activeSessionId = sessionId;
      await this.apiService.setSessionId(sessionId);
    } catch (error) {
      console.error("Error setting active session:", error);
      throw error;
    }
  }

  async getMessages(): Promise<Message[]> {
    if (!this.activeSessionId) {
      return [];
    }

    try {
      const { data } = await http.get(
        `${config.apiEndpoint}/chat/history/${this.activeSessionId}`
      );
      // Handle both direct messages array and ChatHistory format
      if (Array.isArray(data)) {
        return data;
      } else if (data.messages && Array.isArray(data.messages)) {
        return data.messages;
      }
      console.error("Unexpected message response format:", data);
      return [];
    } catch (error) {
      console.error("Error fetching messages:", error);
      return [];
    }
  }
  //TODO: test and fix next
  async getAllSessions(): Promise<ChatHistory[]> {
    try {
      const userId = localStorage.getItem(config.user_id);
      const { data: sessions } = await http.get(
        `${config.apiEndpoint}/chat/sessions/${userId}`
      );

      const mapSession = (session: Partial<ChatHistory>): ChatHistory => ({
        session_id: session.session_id || uuidv4(),
        user_id: userId || "",
        messages: session.messages || [],
        lastUpdated: session.lastUpdated || new Date().toISOString(),
        metadata: session.metadata || {},
      });

      // Handle both array and object response formats
      if (Array.isArray(sessions)) {
        return sessions.map(mapSession);
      } else if (sessions && Array.isArray(sessions)) {
        return sessions.map(mapSession);
      }

      console.error("Unexpected sessions response format:", sessions);
      return [];
    } catch (error) {
      console.error("Error fetching sessions:", error);
      return [];
    }
  }

  subscribeToUpdates(callback: (update: any) => void): () => void {
    if (!this.activeSessionId) {
      return () => {};
    }
    return this.apiService.subscribeToUpdates(callback);
  }
}
