import { Message, ChatHistory } from "../../models/ai-chat/Message";
import { v4 as uuidv4 } from "uuid";
import config from "../../constants/config";
import { devLog } from "services/utils/logger";
import { AiChatHistoryService } from "./AiChatHistoryService";
import http from "../../services/general/httpService";
import { AiWebSocketService } from "./AiWebSocketService";

//TODO: next need to work to link this screen and the AiContextTrackingService to link to the real API

interface AiApiRequest {
  prompt: string;
  metadata: {
    user_id: string;
    session_id: string;
  };
}

interface AiApiResponse {
  prompt: string;
  response: string;
  model_used: string;
  request_type: string;
  embedding_id: string | null;
  metadata: {
    user_id: string;
    session_id: string;
    citations: any[];
    tutorial?: {
      source: {
        data: string;
      };
      title: string;
      context: string;
      tutorial_id?: string;
    };
  };
  created_at: string;
}

export class AiApiService {
  private static instance: AiApiService;
  private sessionId: string | null = null;
  private readonly apiEndpoint: string = config.apiEndpoint;
  private historyService: AiChatHistoryService;
  private wsService: AiWebSocketService;

  private constructor() {
    this.historyService = AiChatHistoryService.getInstance();
    this.wsService = AiWebSocketService.getInstance();
  }

  public static getInstance(): AiApiService {
    if (!AiApiService.instance) {
      AiApiService.instance = new AiApiService();
    }
    return AiApiService.instance;
  }

  private parseTutorialContext(
    tutorial?: AiApiResponse["metadata"]["tutorial"]
  ): Message["metadata"] {
    if (!tutorial) return undefined;

    try {
      const contextData = tutorial.context ? JSON.parse(tutorial.context) : {};
      return {
        tutorial_id: contextData.tutorial_id || tutorial.tutorial_id,
        tutorial_title: tutorial.title,
        tutorial_url: `https://gurenter.com/resources/tutorial/${
          contextData.tutorial_id || tutorial.tutorial_id
        }`,
        tutorial_context: {
          category: contextData.category,
          difficulty: contextData.difficulty,
          platform: contextData.platform,
        },
      };
    } catch (error) {
      devLog("Error parsing tutorial context:", error);
      return {
        tutorial_id: tutorial.tutorial_id,
        tutorial_title: tutorial.title,
      };
    }
  }

  public setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  public getWebSocketUrl(sessionId: string): string {
    const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const baseUrl = process.env.REACT_APP_WS_URL || window.location.host;
    return `${wsProtocol}//${baseUrl}/ws/chat/${sessionId}`;
  }

  public getSessionId(): string {
    return this.sessionId || "";
  }

  public async loadChatHistory(): Promise<ChatHistory> {
    return this.historyService.getChatHistory(this.sessionId || "");
  }

  public async clearHistory(): Promise<void> {
    return this.historyService.clearHistory(this.sessionId || "");
  }

  subscribeToUpdates(callback: (update: any) => void): () => void {
    return this.wsService.subscribeToSession(this.sessionId || "", callback);
  }

  disconnect(): void {
    this.wsService.disconnect();
  }

  public async submitSkillAction(
    sessionId: string,
    currentStep: string,
    action: any
  ): Promise<void> {
    const userId = localStorage.getItem(config.user_id);

    try {
      await http.post(
        `${this.apiEndpoint}/chat/sessions/${sessionId}/skill-action`,
        {
          user_id: userId,
          current_step: currentStep,
          action_data: action,
        }
      );
    } catch (error) {
      devLog("Error submitting skill action:", error);
      throw error;
    }
  }

  public async cancelSkillFlow(
    sessionId: string,
    skillType: string
  ): Promise<void> {
    const userId = localStorage.getItem(config.user_id);

    try {
      await http.post(
        `${this.apiEndpoint}/chat/sessions/${sessionId}/cancel-skill`,
        {
          user_id: userId,
          skill_type: skillType,
        }
      );
    } catch (error) {
      devLog("Error canceling skill flow:", error);
      throw error;
    }
  }
}
