import React, { useState, useEffect } from "react";
import { createCustomerSession } from "../../../../services/authentication/subscription/subscriptionManagementService";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";
const Paywall = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Subscription Management";
  usePageTitle({ title: pageTitle });
  const [clientDetail, setClientDetail] = useState({
    client_secret: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    startCustomerSession();
  }, []);

  const startCustomerSession = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const customerSession = await createCustomerSession();

      if (customerSession && customerSession.client_secret) {
        setClientDetail(customerSession);
      } else {
        throw new Error("Invalid customer session data");
      }
    } catch (error) {
      console.error("Error loading subscription details:", error);
      setError(
        "We encountered an issue while loading your subscription details. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="mt-2">Loading your subscription details...</p>
        </div>
      );
    }

    if (error) {
      return (
        <Alert variant="warning" className="my-3">
          <Alert.Heading>Oops! Something went wrong</Alert.Heading>
          <p>{error}</p>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <Button onClick={startCustomerSession} variant="outline-warning">
              Try Again
            </Button>
            <a
              href="https://gurenter.com/contactus"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link"
            >
              Contact Support
            </a>
          </div>
        </Alert>
      );
    }

    if (!clientDetail.client_secret) {
      return (
        <Alert variant="info" className="my-3">
          <Alert.Heading>No Subscription Details Available</Alert.Heading>
          <p>
            We couldn't find any subscription information for your account. If
            you believe this is an error, please try again or contact our
            support team.
          </p>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <Button onClick={startCustomerSession} variant="outline-info">
              Refresh
            </Button>
            <a
              href="https://gurenter.com/contactus"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link"
            >
              Contact Support
            </a>
          </div>
        </Alert>
      );
    }

    return (
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        customer-session-client-secret={clientDetail.client_secret}
        theme="dark" // Apply the dark theme
      ></stripe-pricing-table>
    );
  };

  return (
    <div className={`${getThemeClasses()}`}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={pageTitle}
          previousPageTitle="Dashboard"
          previousPageLink="/dashboard"
        />
        <div className="row">
          <div className="col-12">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Paywall;
