import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";
import { DataTable } from "../../../common/table/DataTable";
import NoRecordsFound from "../../tickets/list/sub/noRecordsFound";
import config from "../../../../constants/config";
import { getUniversalListAndItemsByUserId } from "../../../../services/task/universalListManagement";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";

const UniversalList = ({ isDarkMode }) => {
  const { propertyId } = useParams();
  const pageTitle = "Universal Lists";
  usePageTitle({ title: pageTitle });
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isLoading, setIsLoading] = useState(false);
  const [universalLists, setUniversalLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const user_id = localStorage.getItem(config.user_id);

  const listTypeMap = {
    general: "General",
    tenant_screening: "Tenant Screening",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    move_in: "Move-In",
    move_out: "Move-Out",
    maintenance: "Maintenance",
    seasonal: "Seasonal",
    emergency: "Emergency",
    legal_compliance: "Legal Compliance",
    financial: "Financial",
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 2.5 },
    { field: "description", headerName: "Description", flex: 3.5 },
    {
      field: "list_type",
      headerName: "List Type",
      flex: 1.8,
      renderCell: (row) => {
        const listType = row.list_type?.trim().toLowerCase();
        return listTypeMap[listType] || row.list_type || "Unknown";
      },
    },
    { field: "items_count", headerName: "Items Count", flex: 1.2 },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchUniversalLists();
  }, [user_id]);

  const fetchUniversalLists = async () => {
    try {
      const result = await getUniversalListAndItemsByUserId(user_id);
      const formattedLists = result.map(
        ({ universal_list, universal_list_items }) => ({
          ...universal_list,
          items_count: universal_list_items.length,
          id: universal_list.universal_list_id,
          campaign_ids: universal_list.campaign_ids.join(", "),
          list_type: universal_list.list_type || "Unknown",
        })
      );
      setUniversalLists(formattedLists);
    } catch (error) {
      console.error("Error fetching universal lists:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="Universal Lists"
            previousPageLink={`/property/${propertyId}`}
            previousPageTitle="Property"
          />

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="universalListsCard">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Universal Lists
                    </h5>
                    <div className="d-flex flex-wrap gap-2">
                      <Link
                        to={`/createUniversalList/${propertyId}`}
                        className="btn btn-success add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Universal List
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive table-card mb-4 px-3">
                    {universalLists.length === 0 ? (
                      <NoRecordsFound />
                    ) : (
                      <DataTable
                        data={universalLists}
                        columns={columns}
                        isDarkMode={isDarkMode}
                        onRowClick={(row) => {
                          navigate(
                            `/universalListDetail/${row.universal_list_id}`
                          );
                        }}
                        searchFields={["name", "description", "list_type"]}
                        searchPlaceholder="Search lists..."
                        pagination={{
                          currentPage,
                          pageSize,
                          totalItems: universalLists.length,
                          onPageChange: setCurrentPage,
                          onPageSizeChange: setPageSize,
                        }}
                        noDataMessage="No lists found"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UniversalList;
