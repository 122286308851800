import { useSettings } from "services/authentication/contexts/settings/settingsContext";

const LoaderView = () => {
  const { settings } = useSettings();
  const isDarkMode = settings?.isDarkMode;

  return (
    <div
      className={`tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center ${
        isDarkMode ? "tw-bg-gray-900/80" : "tw-bg-white/80"
      } tw-transition-all tw-duration-300 tw-ease-in-out tw-backdrop-blur-sm`}
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-animate-fade-in tw-z-50">
        <div className="tw-relative">
          <div
            className={`tw-w-12 tw-h-12 tw-border-4 tw-rounded-full tw-animate-[spin_1.5s_linear_infinite] tw-transition-colors tw-duration-300 ${
              isDarkMode
                ? "tw-border-gray-600 tw-border-t-white"
                : "tw-border-gray-200 tw-border-t-primary"
            }`}
            role="status"
          >
            <span className="tw-sr-only">Loading...</span>
          </div>
          <div
            className={`tw-absolute tw-top-3 tw-left-3 tw-w-6 tw-h-6 tw-border-2 tw-rounded-full tw-animate-[spin_2s_ease-in-out_infinite] tw-transition-colors tw-duration-300 ${
              isDarkMode
                ? "tw-border-gray-500 tw-border-t-white"
                : "tw-border-gray-100 tw-border-t-primary"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LoaderView;
