import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import NoRecordsFound from "../../../../tickets/list/sub/noRecordsFound";

import LoaderView from "../../../../../reusable/loading/loaderView";
import { getAllPropertyUnitAdvertListingByPropertyUnitId } from "../../../../../../services/propertyUnits/advertisment/advertismentManagement";
import { formatDateTime } from "../../../../../../services/utils/dateUtils";
import { formatCurrency } from "../../../../../../services/utils/currencyUtils";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { devError, devLog } from "../../../../../../services/utils/logger";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../../services/utils/hooks/usePageTitle";

const MyAdvertisements = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const navigate = useNavigate();
  const pageTitle = "My Advertisements";
  usePageTitle({ title: pageTitle });
  const { propertyUnitId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [currentAdvertisements, setCurrentAdvertisements] = useState([
    {
      advert_id: "",
      landlord_id: "",
      property_unit_id: "",
      date_advert_ends: "",
      campaign_id: "",
      property_unit: {
        property_unit_id: "",
        property_id: "",
        market_rent_amount: 0,
        number_of_bathrooms: 0,
        number_of_bedrooms: 0,
        square_footage: 0,
        unit_name: "",
      },
      security_deposit: 0,
      screening_pack_id: "",
      currency_id: "",
      currency_name: "",
      is_advert_expired: false,
      rental_unit_price: 0,
      rental_unit_amt_currency: "",
      unit_advert_image_urls: [],
    },
  ]);

  const advertColumns = [
    {
      //show the first of unit_advert_image_urls as the image and it can be empty
      field: "unit_advert_image_urls",
      headerName: "Image",

      renderCell: (params) => {
        return params.row.unit_advert_image_urls?.length > 0 ? (
          <img
            src={params.row.unit_advert_image_urls[0].url}
            alt="unit"
            style={{ width: "100px", height: "80%" }}
          />
        ) : (
          <span>No Image</span>
        );
      },

      flex: 1,
    },
    {
      field: "property_unit.unit_name",
      headerName: "Unit Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/propertyUnit/${params.row.property_unit.property_unit_id}`}
          target="_blank"
        >
          {params.row.property_unit.unit_name}
        </Link>
      ),
    },
    {
      field: "rental_unit_price",
      headerName: "Rental Price",
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
    },
    {
      field: "rental_unit_amt_currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "date_advert_ends",
      headerName: "Advertisement Ends",
      flex: 1,
      renderCell: (params) => formatDateTime(params.value),
    },
    {
      field: "is_advert_expired",
      headerName: "Expired",
      flex: 1,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "security_deposit",
      headerName: "Security Deposit",
      flex: 1,
      renderCell: (params) => formatCurrency(params.value),
    },
  ];

  const fetchAdvertisements = async () => {
    try {
      setIsLoading(true);

      //filtered by property_unit_id
      const result = await getAllPropertyUnitAdvertListingByPropertyUnitId(
        propertyUnitId
      );

      if (result) {
        setCurrentAdvertisements(result);

        devLog("Advertisements:", result);
      }
    } catch (error) {
      devError("Error fetching advertisements", error);
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchAdvertisements();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={"My Advertisements"}
            previousPageTitle="Property Unit"
            previousPageLink={`/propertyUnit/${propertyUnitId}`}
          />

          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="ticketsList">
                <div className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      My Advertisements
                    </h5>
                  </div>
                  {/* All Rental Fee Pack Button */}
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <Link
                      to={`/allRentalFeePacks`}
                      className="btn btn-info btn-sm"
                    >
                      All Rental Fee Packs
                    </Link>
                    <Link
                      to={`/newAdvertisement/${propertyUnitId}`}
                      className="btn btn-primary btn-sm"
                    >
                      <i className="ri-add-line align-middle me-1"></i> New
                      Advertisement
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <ThemeProvider theme={theme}>
                      <Box sx={{ height: 650, width: "100%" }}>
                        <DataGrid
                          rows={currentAdvertisements || []}
                          getRowId={(row) => row.advert_id || 0}
                          columns={advertColumns}
                          pageSize={5}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          onRowClick={(row) => {
                            //Define this next
                            //advertisementCampaign
                            navigate(
                              `/advertisementCampaign/${row.row.campaign_id}`
                            );
                          }}
                          slots={{
                            toolbar: GridToolbar,
                            noResultsOverlay: NoRecordsFound,
                          }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                        />
                      </Box>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyAdvertisements;
