/**
 * Formats a string by splitting it by the specified delimiter(s) and capitalizing each word
 * @param text - The text to format
 * @param delimiter - A string or RegExp to split by. Defaults to /[_-]/ (underscores and hyphens)
 * @returns The formatted string with each word capitalized and joined with spaces
 * @example
 * formatReportType('monthly_noi') // returns 'Monthly Noi'
 * formatReportType('property-report', '-') // returns 'Property Report'
 * formatReportType('some_other_type', '_') // returns 'Some Other Type'
 */
export const formatReportType = (
  text: string,
  delimiter: string | RegExp = /[_-]/
): string => {
  if (!text) return "";

  // Split by the provided delimiter or default pattern
  const words = text.split(delimiter);

  // Capitalize first letter of each word and join with space
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
