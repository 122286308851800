export const formatPaymentMethod = (paymentMethod: string): string => {
  const paymentMethodMap: { [key: string]: string } = {
    CASH: "Cash Payment",
    BANK_TRANSFER: "Bank Transfer",
    CREDIT_CARD: "Credit Card",
    DEBIT_CARD: "Debit Card",
    CHEQUE: "Cheque",
  };

  return paymentMethodMap[paymentMethod] || paymentMethod || "Unknown";
};

export const formatPaymentStatus = (paymentStatus: string): string => {
  const paymentStatusMap: { [key: string]: string } = {
    PAID: "Paid",
    UNPAID: "Unpaid",
    PARTIALLY_PAID: "Partially Paid",
  };
  return paymentStatusMap[paymentStatus] || paymentStatus || "Unknown";
};
