import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "../../../../../common/table/DataTable";
import { Column } from "../../../../../common/table/types/TableTypes";
import { ImageWithFallback } from "../../../../../common/image/ImageWithFallback";
import LoaderView from "../../../../../reusable/loading/loaderView";
import { getAllAdvertisementListingsByLandlordId } from "../../../../../../services/propertyUnits/advertisment/advertismentManagement";
import { formatDateTime } from "../../../../../../services/utils/dateUtils";
import { formatCurrency } from "../../../../../../services/utils/currencyUtils";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../../services/utils/hooks/usePageTitle";

interface Advertisement {
  advert_id: string;
  landlord_id: string;
  property_unit_id: string;
  date_advert_ends: string;
  campaign_id: string;
  property_unit: {
    property_unit_id: string;
    property_id: string;
    market_rent_amount: number;
    number_of_bathrooms: number;
    number_of_bedrooms: number;
    square_footage: number;
    unit_name: string;
  };
  security_deposit: number;
  screening_pack_id: string;
  currency_id: string;
  currency_name: string;
  is_advert_expired: boolean;
  rental_unit_price: number;
  rental_unit_amt_currency: string;
  unit_advert_image_urls: Array<{ url: string }>;
  contract_template_id: string;
  rental_fee_pack_id: string;
  rental_fee_pack_template_id: string;
}

// Define valid search fields
type SearchableFields = "rental_unit_amt_currency" | "property_unit.unit_name";

const AllAdvertisements: React.FC<{ isDarkMode: boolean }> = ({
  isDarkMode,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const navigate = useNavigate();
  const pageTitle = "All Advertisements";
  usePageTitle({ title: pageTitle });
  const [isLoading, setIsLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns: Column<Advertisement>[] = [
    {
      field: "unit_advert_image_urls",
      headerName: "Image",
      renderCell: (row) => (
        <ImageWithFallback
          src={row.unit_advert_image_urls?.[0]?.url}
          alt={`${row.property_unit.unit_name} image`}
          className="tw-w-20 tw-h-20 tw-object-cover tw-rounded-lg tw-shadow-sm"
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      field: "property_unit.unit_name",
      headerName: "Unit Name",
      renderCell: (row) => (
        <Link
          to={`/propertyUnit/${row.property_unit.property_unit_id}`}
          className="tw-text-blue-500 hover:tw-text-blue-600 tw-font-medium"
          onClick={(e) => e.stopPropagation()}
        >
          {row.property_unit.unit_name}
        </Link>
      ),
    },
    {
      field: "rental_unit_price",
      headerName: "Rental Price",
      renderCell: (row) => formatCurrency(row.rental_unit_price),
    },
    {
      field: "rental_unit_amt_currency",
      headerName: "Currency",
    },
    {
      field: "date_advert_ends",
      headerName: "Advertisement Ends",
      renderCell: (row) => formatDateTime(row.date_advert_ends),
    },
    {
      field: "is_advert_expired",
      headerName: "Status",
      renderCell: (row) => (
        <span
          className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${
            row.is_advert_expired
              ? isDarkMode
                ? "tw-bg-red-900/50 tw-text-red-200"
                : "tw-bg-red-100 tw-text-red-800"
              : isDarkMode
              ? "tw-bg-green-900/50 tw-text-green-200"
              : "tw-bg-green-100 tw-text-green-800"
          }`}
        >
          {row.is_advert_expired ? "Expired" : "Active"}
        </span>
      ),
    },
    {
      field: "security_deposit",
      headerName: "Security Deposit",
      renderCell: (row) => formatCurrency(row.security_deposit),
    },
  ];

  const fetchAdvertisements = async () => {
    try {
      setIsLoading(true);
      const result = await getAllAdvertisementListingsByLandlordId();
      if (result) {
        setAdvertisements(result);
      }
    } catch (error) {
      console.error("Error fetching advertisements:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAdvertisements();
  }, []);

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()} tw-min-h-screen`}>
        <div className="tw-w-full tw-p-4">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle=""
            previousPageLink="/"
            isDarkMode={isDarkMode}
          />

          <DataTable<Advertisement>
            data={advertisements}
            columns={columns}
            isDarkMode={isDarkMode}
            onRowClick={(row) =>
              navigate(`/advertisementCampaign/${row.campaign_id}`)
            }
            searchPlaceholder="Search advertisements..."
            searchFields={[
              "rental_unit_amt_currency",
              "property_unit.unit_name",
            ]}
            filterOptions={[
              {
                field: "is_advert_expired",
                options: [
                  { label: "All Status", value: "all" },
                  { label: "Expired", value: "true" },
                  { label: "Active", value: "false" },
                ],
              },
            ]}
            headerActions={
              <Link
                to="/allRentalFeePacks"
                className="tw-px-4 tw-py-2 tw-rounded-lg tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 tw-transition-colors tw-duration-200"
              >
                All Rental Fee Packs
              </Link>
            }
            pagination={{
              currentPage,
              pageSize,
              totalItems: advertisements.length,
              totalPages: Math.ceil(advertisements.length / pageSize),
              onPageChange: setCurrentPage,
              onPageSizeChange: setPageSize,
              pageSizeOptions: [10, 25, 50, 100],
            }}
            showPagination={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllAdvertisements;
