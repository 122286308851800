import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { getReportByMonth as getMonthlyReports } from "../../../services/reports/reportService";
import {
  PropertyFinancialReportModel,
  RevenueBreakdownItemModel,
  ExpenseBreakdownItemModel,
} from "types/reports";
import { toast } from "react-toastify";
import config from "../../../constants/config";

interface UnitReportsProps {
  isDarkMode: boolean;
}

const UnitReports: React.FC<UnitReportsProps> = ({ isDarkMode }) => {
  const { unitId } = useParams<{ unitId: string }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [report, setReport] = useState<PropertyFinancialReportModel | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (unitId) {
      loadReport();
    }
  }, [unitId]);

  const loadReport = async () => {
    if (!unitId) {
      toast.error("Unit ID is required");
      return;
    }

    setIsLoading(true);
    try {
      const userId = localStorage.getItem(config.user_id);
      if (!userId) {
        throw new Error("User ID not found");
      }
      const reports = await getMonthlyReports(
        userId,
        unitId,
        new Date().getFullYear(),
        new Date().getMonth() + 1
      );
      setReport(reports);
    } catch (error) {
      console.error("Error loading unit report:", error);
      toast.error("Failed to load unit report");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Unit Reports"
          previousPageLink="/reports"
          previousPageTitle="Reports Dashboard"
        />

        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-3">Monthly Overview</h5>
                {report?.monthly_noi_metrics && (
                  <div className="row g-4">
                    <div className="col-md-3">
                      <div className="p-3 border rounded">
                        <h6 className="mb-2">Total Revenue</h6>
                        <h3 className="mb-0">
                          ${report.monthly_noi_metrics.total_revenue.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="p-3 border rounded">
                        <h6 className="mb-2">Total Expenses</h6>
                        <h3 className="mb-0">
                          $
                          {report.monthly_noi_metrics.total_expenses.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="p-3 border rounded">
                        <h6 className="mb-2">Net Operating Income</h6>
                        <h3 className="mb-0">
                          $
                          {report.monthly_noi_metrics.net_operating_income.toFixed(
                            2
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="p-3 border rounded">
                        <h6 className="mb-2">Occupancy Rate</h6>
                        <h3 className="mb-0">
                          {(
                            report.monthly_noi_metrics.occupancy_rate * 100
                          ).toFixed(1)}
                          %
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {report?.monthly_noi_metrics && (
            <>
              <div className="col-md-6 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title mb-3">Revenue Breakdown</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {report.monthly_noi_metrics.revenue_breakdown.map(
                            (
                              item: RevenueBreakdownItemModel,
                              index: number
                            ) => (
                              <tr key={index}>
                                <td>{item.category}</td>
                                <td>${item.amount.toFixed(2)}</td>
                                <td>{item.percentage.toFixed(1)}%</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title mb-3">Expense Breakdown</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {report.monthly_noi_metrics.expense_breakdown.map(
                            (
                              item: ExpenseBreakdownItemModel,
                              index: number
                            ) => (
                              <tr key={index}>
                                <td>{item.category}</td>
                                <td>${item.amount.toFixed(2)}</td>
                                <td>{item.percentage.toFixed(1)}%</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnitReports;
