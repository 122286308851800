import React, { useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import default_avatar_img from "../../../../../styletheme/logos/minimal/gurenter-logo-196.png";
import { formatCurrency } from "services/utils/currencyUtils";
import { getTenantForTenantId } from "../../../../../services/propertyUnits/tenantScreening/prospectives/tenantRentalApplicationManagement";
import { useThemeClasses } from "../../../../../services/utils/hooks/useThemeClasses";
import { getTenantById } from "services/rent/tenant/invite/tenantRentalContractService";
import { devLog } from "services/utils/logger";
import Modal from "react-bootstrap/Modal";
import { Badge, Tab, Nav } from "react-bootstrap";
import { replaceTemplateVariables } from "../../../../../services/utils/templateReplacer";
import { toast } from "react-toastify";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";

//TODO: this needs some work to look refined
interface TenantProfile {
  tenant_id: string;
  profile: {
    first_name: string;
    last_name: string;
    email_address: string;
    phone_number: string | null;
    profile_img_url?: string;
    is_active: boolean;
    prescreening_info?: {
      total_monthly_income: number;
      sources_of_income: Array<{
        job_category: string;
      }>;
      number_of_occupants: number;
    };
  };
  application: {
    phone_number: string;
    email_address: string;
    application_status: string;
    co_applicant_connections: Array<{
      first_name: string;
      last_name: string;
      relationship: string;
      is_over_18: boolean;
      phone_number: string;
      email: string;
    }>;
    employment_income_info: Array<{
      employer_name: string;
      annual_income: number;
      is_current_employer: boolean;
      employer_address?: string;
      employer_phone_number?: string;
    }>;
    personal_references: Array<{
      name: string;
      phone_number: string;
      email: string;
      relationship: string;
    }>;
    current_addresses: Array<{
      address: string;
      city: string;
      state: string;
      zip_code: string;
      landlord_name: string;
      landlord_phone_number: string;
      monthly_rent: number;
    }>;
    attachments: Array<{
      url: string;
      file_name: string;
      file_size_formatted: string;
      file_type: string;
    }>;
  };
  is_active: boolean;
}

interface RentalContract {
  rental_contract_id: string;
  tenant_id: string;
  property_unit_id: string;
  start_date: string;
  end_date: string;
  monthly_rent: number;
  security_deposit: number;
  contract_template_id: string;
  contract_detail: string;
  portfolio_ownership_id: string;
  property_name: string;
  property_unit_name: string;
  portfolio_name: string;
  owner_manager_user_id: string;
  owner_manager_name: string;
  owner_manager_email: string;
  end_of_lease_action: string;
  is_active: boolean;
  is_deleted: boolean;
  rental_fee_pack_template_id: string;
  rental_fee_pack_id: string;
  screening_pack_id: string;
  campaign_id: string;
  landlord_property_manager_signatures: Array<{
    signature_id: string;
    rental_contract_id: string;
    signature_url: string;
    signature_base64: string | null;
    signed_at: string;
    signed_by: string;
    is_signed: boolean;
    user_id: string;
    user_type: string;
    email_address: string;
  }>;
  tenant_signatures: Array<{
    signature_id: string;
    rental_contract_id: string;
    signature_url: string;
    signature_base64: string | null;
    signed_at: string;
    signed_by: string;
    is_signed: boolean;
    user_type: string;
    tenant_id: string;
    email_address: string;
  }>;
  pet_deposit: number;
  pet_rent: number;
  portfolio_id: string;
  acceptance_date: string;
  lease_is_active: boolean;
}

interface CurrentTenantsProps {
  activeRentalContract: RentalContract;
  isDarkMode: boolean;
}

const TenantDetailModal: React.FC<{
  show: boolean;
  onHide: () => void;
  tenant: TenantProfile | null;
  isDarkMode: boolean;
}> = ({ show, onHide, tenant, isDarkMode }) => {
  const [activeTab, setActiveTab] = useState("overview");

  if (!tenant) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      className={`${isDarkMode ? "modal-dark" : ""}`}
      contentClassName={`${
        isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
      } tw-rounded-lg tw-shadow-xl`}
    >
      <Modal.Header
        closeButton
        className={`tw-border-b ${
          isDarkMode
            ? "tw-border-gray-700 tw-bg-gray-800"
            : "tw-border-gray-200"
        }`}
      >
        <Modal.Title
          className={`tw-text-lg tw-font-medium ${
            isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
          }`}
        >
          Tenant Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`${isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"} tw-p-6`}
      >
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-6">
          {/* Left Column - Profile Info */}
          <div className="tw-text-center tw-mb-4 md:tw-mb-0">
            <img
              src={tenant.profile.profile_img_url || default_avatar_img}
              alt={`${tenant.profile.first_name} ${tenant.profile.last_name}`}
              className="tw-w-24 tw-h-24 tw-rounded-full tw-mx-auto tw-mb-4 tw-object-cover tw-shadow-md"
            />
            <h5
              className={`tw-text-lg tw-font-medium tw-mb-2 ${
                isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
              }`}
            >
              {tenant.profile.first_name} {tenant.profile.last_name}
            </h5>
            <p
              className={`tw-text-sm tw-mb-2 ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
              }`}
            >
              {tenant.application.email_address}
            </p>
            <p
              className={`tw-text-sm tw-mb-3 ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
              }`}
            >
              {tenant.application.phone_number}
            </p>
            <span
              className={`tw-px-3 tw-py-1 tw-text-xs tw-font-medium tw-rounded-full ${
                tenant.is_active
                  ? isDarkMode
                    ? "tw-bg-green-900 tw-text-green-200"
                    : "tw-bg-green-100 tw-text-green-800"
                  : isDarkMode
                  ? "tw-bg-red-900 tw-text-red-200"
                  : "tw-bg-red-100 tw-text-red-800"
              }`}
            >
              {tenant.is_active ? "Active" : "Inactive"}
            </span>
          </div>

          {/* Right Column - Content */}
          <div className="md:tw-col-span-3">
            <div className="tw-border-b tw-mb-4 ${isDarkMode ? 'tw-border-gray-700' : 'tw-border-gray-200'}">
              <nav className="tw-flex tw-space-x-4">
                {["overview", "employment", "references", "documents"].map(
                  (tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors tw-duration-150 ${
                        activeTab === tab
                          ? isDarkMode
                            ? "tw-text-blue-400 tw-border-b-2 tw-border-blue-400"
                            : "tw-text-blue-600 tw-border-b-2 tw-border-blue-600"
                          : isDarkMode
                          ? "tw-text-gray-400 hover:tw-text-gray-300"
                          : "tw-text-gray-500 hover:tw-text-gray-700"
                      }`}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  )
                )}
              </nav>
            </div>

            <div className="tw-mt-4">
              {activeTab === "overview" && (
                <>
                  <h6
                    className={`tw-text-sm tw-font-medium tw-mb-3 ${
                      isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                    }`}
                  >
                    Co-applicants
                  </h6>
                  <div className="tw-space-y-2">
                    {tenant.application.co_applicant_connections.map(
                      (co, index) => (
                        <div
                          key={index}
                          className={`tw-rounded-lg tw-p-3 ${
                            isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                          }`}
                        >
                          <div className="tw-flex tw-justify-between tw-items-center">
                            <div>
                              <h6
                                className={`tw-text-sm tw-font-medium tw-mb-1 ${
                                  isDarkMode
                                    ? "tw-text-gray-100"
                                    : "tw-text-gray-900"
                                }`}
                              >
                                {co.first_name} {co.last_name}
                              </h6>
                              <p
                                className={`tw-text-xs ${
                                  isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                }`}
                              >
                                {co.relationship} •{" "}
                                {co.is_over_18 ? "Adult" : "Minor"}
                              </p>
                            </div>
                            {co.phone_number && (
                              <span
                                className={`tw-text-xs ${
                                  isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                }`}
                              >
                                {co.phone_number}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <h6
                    className={`tw-text-sm tw-font-medium tw-mb-3 tw-mt-6 ${
                      isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                    }`}
                  >
                    Current Address
                  </h6>
                  {tenant.application.current_addresses.map((addr, index) => (
                    <div
                      key={index}
                      className={`tw-rounded-lg tw-p-4 ${
                        isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                      }`}
                    >
                      <p
                        className={`tw-text-sm tw-mb-2 ${
                          isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                        }`}
                      >
                        {addr.address}, {addr.city}, {addr.state}{" "}
                        {addr.zip_code}
                      </p>
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <p
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          }`}
                        >
                          Landlord: {addr.landlord_name} •{" "}
                          {addr.landlord_phone_number}
                        </p>
                        <p
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          }`}
                        >
                          Rent: {formatCurrency(addr.monthly_rent)}/month
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {activeTab === "employment" && (
                <div className="tw-space-y-4">
                  {tenant.application.employment_income_info.map(
                    (emp, index) => (
                      <div
                        key={index}
                        className={`tw-rounded-lg tw-p-4 ${
                          isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                        }`}
                      >
                        <div className="tw-flex tw-justify-between tw-items-start tw-mb-2">
                          <h6
                            className={`tw-text-sm tw-font-medium ${
                              isDarkMode
                                ? "tw-text-gray-100"
                                : "tw-text-gray-900"
                            }`}
                          >
                            {emp.employer_name}
                          </h6>
                          <span
                            className={`tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-rounded-full ${
                              emp.is_current_employer
                                ? isDarkMode
                                  ? "tw-bg-green-900 tw-text-green-200"
                                  : "tw-bg-green-100 tw-text-green-800"
                                : isDarkMode
                                ? "tw-bg-gray-600 tw-text-gray-200"
                                : "tw-bg-gray-100 tw-text-gray-800"
                            }`}
                          >
                            {emp.is_current_employer ? "Current" : "Previous"}
                          </span>
                        </div>
                        {emp.employer_address && (
                          <p
                            className={`tw-text-xs tw-mb-2 ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            }`}
                          >
                            {emp.employer_address}
                          </p>
                        )}
                        <p
                          className={`tw-text-sm ${
                            isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
                          }`}
                        >
                          Annual Income: {formatCurrency(emp.annual_income)}
                        </p>
                        {emp.employer_phone_number && (
                          <p
                            className={`tw-text-xs ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            }`}
                          >
                            Contact: {emp.employer_phone_number}
                          </p>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}

              {activeTab === "references" && (
                <div className="tw-space-y-4">
                  {tenant.application.personal_references.map((ref, index) => (
                    <div
                      key={index}
                      className={`tw-rounded-lg tw-p-4 ${
                        isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                      }`}
                    >
                      <h6
                        className={`tw-text-sm tw-font-medium tw-mb-2 ${
                          isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                        }`}
                      >
                        {ref.name}
                      </h6>
                      <p
                        className={`tw-text-xs tw-mb-2 ${
                          isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                        }`}
                      >
                        {ref.relationship}
                      </p>
                      <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                        <p
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          }`}
                        >
                          Phone: {ref.phone_number}
                        </p>
                        <p
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          }`}
                        >
                          Email: {ref.email}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === "documents" && (
                <div className="tw-space-y-2">
                  {tenant.application.attachments.map((file, index) => (
                    <div
                      key={index}
                      className={`tw-flex tw-items-center tw-justify-between tw-p-3 tw-rounded-lg ${
                        isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                      }`}
                    >
                      <div>
                        <p
                          className={`tw-text-sm tw-font-medium tw-mb-1 ${
                            isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                          }`}
                        >
                          {file.file_name}
                        </p>
                        <p
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          }`}
                        >
                          {file.file_size_formatted}
                        </p>
                      </div>
                      <div className="tw-flex tw-space-x-3">
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`tw-text-lg ${
                            isDarkMode
                              ? "tw-text-gray-300 hover:tw-text-gray-100"
                              : "tw-text-gray-600 hover:tw-text-gray-800"
                          }`}
                        >
                          <i className="ri-eye-line"></i>
                        </a>
                        <a
                          href={file.url}
                          target="_blank"
                          download={file.file_name}
                          className={`tw-text-lg ${
                            isDarkMode
                              ? "tw-text-gray-300 hover:tw-text-gray-100"
                              : "tw-text-gray-600 hover:tw-text-gray-800"
                          }`}
                        >
                          <i className="ri-download-2-line"></i>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Define PDF styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 40,
  },
  header: {
    marginBottom: 30,
    borderBottom: "1 solid #e5e7eb",
    paddingBottom: 20,
  },
  title: {
    fontSize: 28,
    textAlign: "center",
    color: "#1f2937",
    marginBottom: 15,
    fontWeight: "bold",
  },
  date: {
    fontSize: 12,
    textAlign: "right",
    color: "#4b5563",
    marginBottom: 10,
  },
  section: {
    margin: "15 0",
    lineHeight: 1.6,
  },
  text: {
    fontSize: 11,
    color: "#374151",
    textAlign: "justify",
  },
  signatureSection: {
    marginTop: 40,
    borderTop: "1 solid #e5e7eb",
    paddingTop: 20,
  },
  signatureRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  signatureBox: {
    width: "45%",
  },
  signatureImage: {
    width: "100%",
    height: 60,
    marginBottom: 10,
    objectFit: "contain",
  },
  signatureText: {
    fontSize: 10,
    color: "#4b5563",
    textAlign: "center",
  },
  signatureName: {
    fontSize: 11,
    color: "#1f2937",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 5,
  },
  signatureDate: {
    fontSize: 10,
    color: "#6b7280",
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#9ca3af",
    fontSize: 9,
    paddingHorizontal: 40,
  },
  pageNumber: {
    position: "absolute",
    bottom: 10,
    right: 30,
    fontSize: 9,
    color: "#9ca3af",
  },
});

interface ContractPDFProps {
  contract: RentalContract;
  contractHtml: string;
}

// Contract PDF Document Component
const ContractPDF: React.FC<ContractPDFProps> = ({
  contract,
  contractHtml,
}) => {
  // Clean and format the contract text
  const cleanContractText = contractHtml
    .replace(/<[^>]+>/g, "")
    .replace(/\s+/g, " ")
    .trim();

  // Split text into pages (approximately 3000 characters per page)
  const textChunks = cleanContractText.match(/.{1,3000}/g) || [
    cleanContractText,
  ];

  return (
    <Document>
      {textChunks.map((chunk, index) => (
        <Page key={index} size="A4" style={styles.page}>
          {index === 0 && (
            <View style={styles.header}>
              <Text style={styles.title}>Rental Contract</Text>
              <Text style={styles.date}>
                {format(new Date(), "MMMM dd, yyyy")}
              </Text>
            </View>
          )}

          <View style={styles.section}>
            <Text style={styles.text}>{chunk}</Text>
          </View>

          {index === textChunks.length - 1 && (
            <>
              <View style={styles.signatureSection}>
                <View style={styles.signatureRow}>
                  <View style={styles.signatureBox}>
                    <Text style={styles.signatureName}>
                      {contract.owner_manager_name}
                    </Text>
                    <Text style={styles.signatureText}>Landlord</Text>
                    <Text style={styles.signatureDate}>
                      {format(
                        new Date(
                          contract.landlord_property_manager_signatures[0]
                            ?.signed_at || new Date()
                        ),
                        "MMMM dd, yyyy"
                      )}
                    </Text>
                  </View>

                  <View style={styles.signatureBox}>
                    <Text style={styles.signatureName}>
                      {contract.tenant_signatures[0]?.signed_by}
                    </Text>
                    <Text style={styles.signatureText}>Tenant</Text>
                    <Text style={styles.signatureDate}>
                      {format(
                        new Date(
                          contract.tenant_signatures[0]?.signed_at || new Date()
                        ),
                        "MMMM dd, yyyy"
                      )}
                    </Text>
                  </View>
                </View>
              </View>

              <Text style={styles.footer}>
                Generated by Gurenter •{" "}
                {format(new Date(), "MMMM dd, yyyy, h:mm a")}
              </Text>
            </>
          )}

          <Text style={styles.pageNumber}>
            {`Page ${index + 1} of ${textChunks.length}`}
          </Text>
        </Page>
      ))}
    </Document>
  );
};

const ContractDownloadButton: React.FC<{ contract: RentalContract }> = ({
  contract,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  // Create the data object with the actual values
  const contractData = {
    property_name: contract.property_name,
    portfolio_name: contract.portfolio_name,
    start_date: contract.start_date,
    end_date: contract.end_date,
    monthly_rent: contract.monthly_rent,
    security_deposit: contract.security_deposit,
    tenant_name: contract.tenant_signatures[0]?.signed_by || "Tenant",
    additional_charges: `Pet Deposit: ${formatCurrency(
      contract.pet_deposit
    )}, Pet Rent: ${formatCurrency(contract.pet_rent)}`,
    notice_period: "30 days",
  };

  // Define custom mappings for special formatting
  const customMappings = {
    "@TenantName": contractData.tenant_name,
    "@PropertyName": contractData.property_name,
    "@Portfolio": contractData.portfolio_name,
    "@StartDate": format(new Date(contractData.start_date), "MMMM dd, yyyy"),
    "@EndDate": format(new Date(contractData.end_date), "MMMM dd, yyyy"),
    "@RentAmount": formatCurrency(contractData.monthly_rent),
    "@SecurityDepositAmount": formatCurrency(contractData.security_deposit),
    "@AdditionalCharges": contractData.additional_charges,
    "@NoticePeriod": contractData.notice_period,
  };

  // Replace template variables in the contract text using both the data and custom mappings
  const processedContractText = replaceTemplateVariables(
    contract.contract_detail,
    contractData,
    customMappings
  );

  const generateFileName = () => {
    const timestamp = format(new Date(), "yyyyMMdd_HHmmss");
    const propertyName = contract.property_name.replace(/[^a-zA-Z0-9]/g, "");
    const tenantName = (
      contract.tenant_signatures[0]?.signed_by || "Tenant"
    ).replace(/[^a-zA-Z0-9]/g, "");
    return `lease_${propertyName}_${tenantName}_${timestamp}.pdf`;
  };

  return (
    <BlobProvider
      document={
        <ContractPDF contract={contract} contractHtml={processedContractText} />
      }
    >
      {({ url, loading, error }) => (
        <button
          onClick={() => {
            if (url) {
              const link = document.createElement("a");
              link.href = url;
              link.download = generateFileName();
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }}
          disabled={loading || !url || isGenerating}
          className={`tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-blue-600 tw-rounded-md tw-shadow-sm hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition-all tw-duration-150 ${
            loading || !url ? "tw-opacity-75 tw-cursor-not-allowed" : ""
          }`}
        >
          {loading ? (
            <>
              <i className="ri-loader-4-line tw-animate-spin tw-mr-2"></i>
              Generating PDF...
            </>
          ) : error ? (
            <>
              <i className="ri-error-warning-line tw-mr-2"></i>
              Error generating PDF
            </>
          ) : (
            <>
              <i className="ri-download-2-line tw-mr-2"></i>
              Download Contract
            </>
          )}
        </button>
      )}
    </BlobProvider>
  );
};

const CurrentTenants: React.FC<CurrentTenantsProps> = ({
  activeRentalContract,
  isDarkMode,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [tenantProfiles, setTenantProfiles] = useState<TenantProfile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTenant, setSelectedTenant] = useState<TenantProfile | null>(
    null
  );
  const [showTenantModal, setShowTenantModal] = useState(false);

  useEffect(() => {
    const fetchTenantProfiles = async () => {
      try {
        setIsLoading(true);
        setError(null);
        devLog("Active Rental Contract", activeRentalContract);

        if (!activeRentalContract || !activeRentalContract.tenant_signatures) {
          setError("No rental contract or tenant information available");
          return;
        }

        const profiles = await Promise.all(
          activeRentalContract.tenant_signatures.map((signature) =>
            getTenantForTenantId(signature.tenant_id)
          )
        );

        const validProfiles = profiles.filter((profile) => profile !== null);
        devLog("Valid Tenant Profiles", validProfiles);

        setTenantProfiles(
          validProfiles.map((tenant) => ({
            tenant_id: tenant.profile.tenant_id,
            profile: tenant.profile,
            application: tenant.application,
            is_active: activeRentalContract.lease_is_active,
          }))
        );
      } catch (error) {
        console.error("Error fetching tenant profiles:", error);
        setError("Failed to load tenant profiles. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTenantProfiles();
  }, [activeRentalContract]);

  const formatDate = (dateString: string): string => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return isValid(date) ? format(date, "MMM dd, yyyy") : "Invalid Date";
  };

  if (!activeRentalContract) {
    return (
      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h5 className="card-title mb-2">
                      No Rental Contract Available
                    </h5>
                    <p className="text-muted">
                      There is no active rental contract for this property unit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${
        isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
      } tw-min-h-screen tw-transition-colors tw-duration-150`}
    >
      <div className="tw-container-fluid tw-px-4">
        {/* Lease Details Card */}
        <div className="tw-mb-6">
          <div
            className={`tw-rounded-lg tw-shadow-md ${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-transition-colors tw-duration-150`}
          >
            <div
              className={`tw-p-4 tw-border-b ${
                isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
              } tw-flex tw-justify-between tw-items-center`}
            >
              <h4
                className={`tw-text-lg tw-font-medium tw-m-0 ${
                  isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                }`}
              >
                Lease Details
              </h4>
              <div className="tw-flex tw-items-center tw-space-x-2">
                <ContractDownloadButton contract={activeRentalContract} />
              </div>
            </div>

            <div className="tw-p-4">
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                {/* Start Date */}
                <div
                  className={`tw-rounded-lg tw-p-4 ${
                    isDarkMode ? "tw-bg-gray-700" : "tw-bg-blue-50"
                  } tw-transition-colors tw-duration-150`}
                >
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <p
                      className={`tw-text-sm tw-font-medium ${
                        isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                      } tw-m-0`}
                    >
                      Start Date
                    </p>
                    <i className="ri-calendar-line tw-text-blue-500 tw-text-xl"></i>
                  </div>
                  <h4
                    className={`tw-text-lg tw-font-semibold tw-mt-2 ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    {formatDate(activeRentalContract.start_date)}
                  </h4>
                </div>

                {/* End Date */}
                <div
                  className={`tw-rounded-lg tw-p-4 ${
                    isDarkMode ? "tw-bg-gray-700" : "tw-bg-purple-50"
                  } tw-transition-colors tw-duration-150`}
                >
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <p
                      className={`tw-text-sm tw-font-medium ${
                        isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                      } tw-m-0`}
                    >
                      End Date
                    </p>
                    <i className="ri-calendar-line tw-text-purple-500 tw-text-xl"></i>
                  </div>
                  <h4
                    className={`tw-text-lg tw-font-semibold tw-mt-2 ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    {formatDate(activeRentalContract.end_date)}
                  </h4>
                </div>

                {/* Monthly Rent */}
                <div
                  className={`tw-rounded-lg tw-p-4 ${
                    isDarkMode ? "tw-bg-gray-700" : "tw-bg-green-50"
                  } tw-transition-colors tw-duration-150`}
                >
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <p
                      className={`tw-text-sm tw-font-medium ${
                        isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                      } tw-m-0`}
                    >
                      Monthly Rent
                    </p>
                    <i className="ri-money-dollar-circle-line tw-text-green-500 tw-text-xl"></i>
                  </div>
                  <h4
                    className={`tw-text-lg tw-font-semibold tw-mt-2 ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    {formatCurrency(activeRentalContract.monthly_rent)}
                  </h4>
                </div>

                {/* Security Deposit */}
                <div
                  className={`tw-rounded-lg tw-p-4 ${
                    isDarkMode ? "tw-bg-gray-700" : "tw-bg-yellow-50"
                  } tw-transition-colors tw-duration-150`}
                >
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <p
                      className={`tw-text-sm tw-font-medium ${
                        isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                      } tw-m-0`}
                    >
                      Security Deposit
                    </p>
                    <i className="ri-shield-keyhole-line tw-text-yellow-500 tw-text-xl"></i>
                  </div>
                  <h4
                    className={`tw-text-lg tw-font-semibold tw-mt-2 ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    {formatCurrency(activeRentalContract.security_deposit)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Current Tenants Section */}
        <div className="tw-mb-6">
          <div
            className={`tw-rounded-lg tw-shadow-md ${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-transition-colors tw-duration-150`}
          >
            <div
              className={`tw-p-4 tw-border-b ${
                isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
              }`}
            >
              <h4
                className={`tw-text-lg tw-font-medium tw-m-0 ${
                  isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                }`}
              >
                Current Tenants
              </h4>
            </div>

            <div className="tw-p-4">
              {error ? (
                <div className="tw-rounded-md tw-bg-red-50 tw-p-4 tw-mb-4">
                  <div className="tw-flex">
                    <div className="tw-flex-shrink-0">
                      <i className="ri-error-warning-line tw-text-red-400"></i>
                    </div>
                    <div className="tw-ml-3">
                      <h3 className="tw-text-sm tw-font-medium tw-text-red-800">
                        {error}
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
                  {isLoading
                    ? // Loading skeletons
                      Array(3)
                        .fill(0)
                        .map((_, index) => (
                          <div
                            key={index}
                            className={`tw-rounded-lg tw-shadow ${
                              isDarkMode ? "tw-bg-gray-700" : "tw-bg-white"
                            } tw-p-4`}
                          >
                            <div className="tw-animate-pulse tw-flex tw-space-x-4">
                              <div className="tw-rounded-full tw-bg-gray-400 tw-h-12 tw-w-12"></div>
                              <div className="tw-flex-1 tw-space-y-4 tw-py-1">
                                <div className="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-3/4"></div>
                                <div className="tw-space-y-2">
                                  <div className="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-5/6"></div>
                                  <div className="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-2/3"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    : tenantProfiles.map((tenant) => (
                        <div
                          key={tenant.tenant_id}
                          className={`tw-rounded-lg tw-shadow-sm ${
                            isDarkMode ? "tw-bg-gray-700" : "tw-bg-white"
                          } tw-transition-all tw-duration-150 tw-transform hover:tw-shadow-md`}
                        >
                          <div className="tw-p-4">
                            <div className="tw-flex tw-items-center tw-space-x-4 tw-mb-4">
                              <img
                                src={
                                  tenant.profile.profile_img_url ||
                                  default_avatar_img
                                }
                                alt={`${tenant.profile.first_name} ${tenant.profile.last_name}`}
                                className="tw-h-12 tw-w-12 tw-rounded-full tw-object-cover"
                              />
                              <div className="tw-flex-1 tw-min-w-0">
                                <p
                                  className={`tw-text-sm tw-font-medium ${
                                    isDarkMode
                                      ? "tw-text-gray-100"
                                      : "tw-text-gray-900"
                                  } tw-truncate`}
                                >
                                  {tenant.profile.first_name}{" "}
                                  {tenant.profile.last_name}
                                </p>
                                <p
                                  className={`tw-text-sm ${
                                    isDarkMode
                                      ? "tw-text-gray-300"
                                      : "tw-text-gray-500"
                                  } tw-truncate`}
                                >
                                  {tenant.profile.email_address}
                                </p>
                                <p
                                  className={`tw-text-sm ${
                                    isDarkMode
                                      ? "tw-text-gray-300"
                                      : "tw-text-gray-500"
                                  } tw-truncate`}
                                >
                                  {tenant.application.phone_number ||
                                    "No phone number"}
                                </p>
                              </div>
                            </div>

                            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
                              <div className="tw-flex tw-flex-wrap tw-gap-2">
                                <span
                                  className={`tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-rounded-full ${
                                    isDarkMode
                                      ? "tw-bg-blue-900 tw-text-blue-200"
                                      : "tw-bg-blue-100 tw-text-blue-800"
                                  }`}
                                >
                                  {tenant.application.application_status}
                                </span>
                                <span
                                  className={`tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-rounded-full ${
                                    tenant.is_active
                                      ? isDarkMode
                                        ? "tw-bg-green-900 tw-text-green-200"
                                        : "tw-bg-green-100 tw-text-green-800"
                                      : isDarkMode
                                      ? "tw-bg-red-900 tw-text-red-200"
                                      : "tw-bg-red-100 tw-text-red-800"
                                  }`}
                                >
                                  {tenant.is_active ? "Active" : "Inactive"}
                                </span>
                              </div>
                              <button
                                onClick={() => {
                                  setSelectedTenant(tenant);
                                  setShowTenantModal(true);
                                }}
                                className={`tw-inline-flex tw-items-center tw-px-3 tw-py-1.5 tw-text-sm tw-font-medium tw-rounded-md ${
                                  isDarkMode
                                    ? "tw-bg-gray-600 tw-text-gray-100 hover:tw-bg-gray-500"
                                    : "tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
                                } tw-transition-colors tw-duration-150`}
                              >
                                View Details
                              </button>
                            </div>

                            <div
                              className={`tw-pt-3 tw-border-t ${
                                isDarkMode
                                  ? "tw-border-gray-600"
                                  : "tw-border-gray-200"
                              }`}
                            >
                              <div className="tw-flex tw-justify-between">
                                <span
                                  className={`tw-text-xs ${
                                    isDarkMode
                                      ? "tw-text-gray-400"
                                      : "tw-text-gray-500"
                                  }`}
                                >
                                  Co-applicants:{" "}
                                  {
                                    tenant.application.co_applicant_connections
                                      .length
                                  }
                                </span>
                                <span
                                  className={`tw-text-xs ${
                                    isDarkMode
                                      ? "tw-text-gray-400"
                                      : "tw-text-gray-500"
                                  }`}
                                >
                                  Documents:{" "}
                                  {tenant.application.attachments.length}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <TenantDetailModal
        show={showTenantModal}
        onHide={() => setShowTenantModal(false)}
        tenant={selectedTenant}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default CurrentTenants;
