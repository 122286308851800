import httpService from "../../services/general/httpService";
import { PropertyFinancialReportModel } from "../../types/reports";
import config from "../../constants/config";
//API work
//Also the reports need to auto generate at mid night each night which it didnt

const API_URL = `${config.apiEndpoint}/reports/property_financial`;

interface SearchReportRequest {
  user_id?: string;
  portfolio_id?: string;
  property_id?: string;
  property_unit_id?: string;
  report_type?: string;
  year?: number;
  month?: number;
  start_date?: string;
  end_date?: string;
  is_latest?: boolean;
}

export const getLatestReport = async (
  userId: string,
  propertyUnitId: string,
  reportType: string
): Promise<PropertyFinancialReportModel> => {
  try {
    const response = await httpService.get(
      `${API_URL}/latest/${userId}/${propertyUnitId}/${reportType}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching latest report:", error);
    throw error;
  }
};

export const searchReports = async (
  searchParams: SearchReportRequest
): Promise<PropertyFinancialReportModel[]> => {
  try {
    const response = await httpService.post(`${API_URL}/search`, searchParams);
    return response.data;
  } catch (error) {
    console.error("Error searching reports:", error);
    throw error;
  }
};

export const getReportByMonth = async (
  userId: string,
  propertyUnitId: string,
  year: number,
  month: number
): Promise<PropertyFinancialReportModel> => {
  try {
    const response = await httpService.get(
      `${API_URL}/by_month/${userId}/${propertyUnitId}/${year}/${month}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching monthly report:", error);
    throw error;
  }
};

export const getReportsByDateRange = async (
  userId: string,
  startDate: string,
  endDate: string,
  options?: {
    propertyUnitId?: string;
    propertyId?: string;
    portfolioId?: string;
  }
): Promise<PropertyFinancialReportModel[]> => {
  try {
    let url = `${API_URL}/by_date_range/${userId}/${startDate}/${endDate}`;

    // Add optional query parameters
    const queryParams = new URLSearchParams();
    if (options?.propertyUnitId) {
      queryParams.append("property_unit_id", options.propertyUnitId);
    }
    if (options?.propertyId) {
      queryParams.append("property_id", options.propertyId);
    }
    if (options?.portfolioId) {
      queryParams.append("portfolio_id", options.portfolioId);
    }

    const queryString = queryParams.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await httpService.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching reports by date range:", error);
    throw error;
  }
};

// Rent Roll specific endpoints
export const getLatestRentRoll = async (
  propertyUnitId: string
): Promise<PropertyFinancialReportModel> => {
  try {
    const response = await httpService.get(
      `${API_URL}/latest_rent_roll/${propertyUnitId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching latest rent roll:", error);
    throw error;
  }
};

export const getRentRollByMonth = async (
  propertyUnitId: string,
  year: number,
  month: number
): Promise<PropertyFinancialReportModel> => {
  try {
    const response = await httpService.get(
      `${API_URL}/rent_roll/by_month/${propertyUnitId}/${year}/${month}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching monthly rent roll:", error);
    throw error;
  }
};

export const getPropertyRentRolls = async (
  propertyId: string
): Promise<PropertyFinancialReportModel[]> => {
  try {
    const response = await httpService.get(
      `${API_URL}/rent_roll/property/${propertyId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching property rent rolls:", error);
    throw error;
  }
};

export const getPortfolioRentRolls = async (
  portfolioId: string
): Promise<PropertyFinancialReportModel[]> => {
  try {
    const response = await httpService.get(
      `${API_URL}/rent_roll/portfolio/${portfolioId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching portfolio rent rolls:", error);
    throw error;
  }
};

//"/by_id/{report_id}
export const getReportById = async (
  reportId: string
): Promise<PropertyFinancialReportModel> => {
  try {
    const response = await httpService.get(`${API_URL}/by_id/${reportId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching report by ID:", error);
    throw error;
  }
};

// Alias for backward compatibility
export const getMonthlyReports = getReportByMonth;
export const getPropertyRentRoll = getPropertyRentRolls;
