import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderView from "../../reusable/loading/loaderView";
//import loader from "../../../styletheme/assets/img/svgicons/loader.svg";
import config from "../../../constants/config";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import {
  getNewTicketsByPropertyUnitId,
  getNewTicketsByUserId,
} from "../../../services/tickets/unitTicketsManagement";

//for the table
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import NoRecordsFound from "../tickets/list/sub/noRecordsFound";
import { getTop5UnitsWithHighestRevenueReturn } from "../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import defaultHouseImg from "../../../styletheme/images/buildings/house.png";
import { formatCurrency } from "../../../services/utils/currencyUtils";
import {
  completeTask,
  getUserTasksByLoggedInUser,
} from "../../../services/task/userTaskManagement";
import { v4 as uuidv4 } from "uuid";
//animation
import Lottie from "react-lottie";
import empty_list_animation from "../../reusable/animations/lottie/empty_people_list.json";
import { createPropertyUnitAudit } from "../../../services/propertyUnits/audit/propertyUnitAuditManagement";
import TopRevenueCard from "./reusable/topRevenueCard";
import TicketCard from "../tickets/list/kanban/reusable/ticketCard";
import { getTheRentedVsVacantUnitsByOwnership } from "../../../services/portfolio/ownership/portfolioOwnershipManagement";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import SubscriptionStatusCard from "./reusable/SubscriptionStatusCard";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../services/helpers/color/colorService";
import { useSubscriptionStatus } from "../../../context/subscription/SubscriptionStatusContext";

// Import the ShimmerEffect component and CSS
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import "../../../styletheme/css/shimmer.css";
import { getLatestRentalContractByLandlordId } from "services/rent/tenant/invite/tenantRentalContractService";
import { RentalContractSignatureWidget } from "components/reusable/signature/widgets/RentalContractSignatureWidget";
import { devLog } from "services/utils/logger";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { updateRentalContractWithLandlordSignature } from "../../../services/rent/tenant/invite/tenantRentalContractService";
import KYCBanner from "../../stripe-connect/KYCBanner";
import { useStripeConnect } from "../../../hooks/useStripeConnect";
import { getSubscriptionStatusFromStripe } from "../../../services/authentication/subscription/subscriptionManagementService";
import { usePageTitle } from "../../../services/utils/hooks/usePageTitle";
//TODO: next after adding the ability to tenants to sign, need to let the landlord be presented a notification
// that the tenant has signed, then show a control that allow them to verify when all the payments have been received
// once the payments are verified, then the lease contract will start and the tenant will be able to move in and do onboarding
//WHen the connect with stripe comes up take them to the profile page and let them know they need to fill out the information in the
//Personal Information section

//TODO: Need to add the payments payouts for units for landlords, the guidebooks

//TODO: add the user page title to set the page title in the tab

const Dashboard = ({ isDarkMode }) => {
  usePageTitle({ title: "Dashboard" });
  const { getThemeClasses } = useThemeClasses(isDarkMode);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: "Relevant news about Gurenter and its offerings",
  });
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();

  const { subscriptionStatus, setSubscriptionStatusState } =
    useSubscriptionStatus();

  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);
  const profile_img = localStorage.getItem(config.profile_img);
  const email = localStorage.getItem(config.email);
  const subscriptionPlanName = subscriptionStatus?.subscription_name;
  const numberOfUnitsUsed = subscriptionStatus?.amt_of_units_used;
  const maxNumberOfUnits = subscriptionStatus?.max_allowed_units;
  const numberOfDaysLeftInTrial =
    subscriptionStatus?.number_of_free_trial_days_left;
  const stripeConnectAccountId = localStorage.getItem(
    config.stripe_connect_account_id
  );
  const { state: stripeState, checkStatus } = useStripeConnect(
    stripeConnectAccountId || ""
  );

  const [userId, setUserId] = useState(localStorage.getItem(config.user_id));

  //put the components on another component
  const [newUnitTickets, setNewUnitTickets] = useState([
    {
      ticket_id: "",
      property_unit_id: "",
      ticket_type: "",
      ticket_title: "",
      ticket_description: "",
      ticket_priority_id: "",
      ticket_status_id: "",
      ticket_number: "",
      ticket_status: "",
      priority_info: {
        created_at: "",
        is_active: true,
        priority: "",
        priority_id: "",
        updated_at: "",
      },
    },
  ]);

  const [topRevenueUnitPeriod, setTopRevenueUnitPeriod] = useState({
    top_revenue_unit_period_id: "",
    period: "",
    date_reported: "",
    units: [
      {
        top_revenue_unit_id: "",
        property_unit_id: "",
        unit_name: "",
        unit_image_url: "",
        revenue: 0,
        date_reported: "",
        market_rent_amount: 0,
        number_of_bathrooms: 0,
        number_of_bedrooms: 0,
        square_footage: 0,
      },
    ],
  });

  //tasks groups start
  const [taskGroups, setTaskGroups] = useState([
    {
      task_group_id: "",
      property_unit_id: "",
      created_by: "",
      created_at: "",
      updated_at: "",
      is_deleted: false,
      tasks: [
        {
          task_name: "",
          task_description: "",
          status_id: "",
          priority_id: "",
          task_due_date: "",
          is_done: false,
          created_by: "",
          task_id: "",
          task_group_id: "",
          is_deleted: false,
          priority: {
            priority: "",
            created_at: "",
            is_active: false,
            priority_id: "",
            updated_at: "",
          },
          status: {
            status: "",
            created_at: "",
            is_active: false,
            status_id: "",
            updated_at: "",
          },
          payment_intent_id: "",
          is_payment_verification: false,
          rental_contract_id: "",
          rental_fee_pack_id: "",
        },
      ],
    },
  ]);

  //flatten version of the task
  const [tasks, setTasks] = useState([
    {
      task_name: "",
      task_description: "",
      status_id: "",
      priority_id: "",
      task_due_date: "",
      is_done: false,
      created_by: "",
      task_id: "",
      task_group_id: "",
      is_deleted: false,
      priority: {
        priority: "",
        created_at: "",
        is_active: false,
        priority_id: "",
        updated_at: "",
      },
      status: {
        status: "",
        created_at: "",
        is_active: false,
        status_id: "",
        updated_at: "",
      },
    },
  ]);

  //tasks groups end

  //chart data
  const [vacancyChartData, setVacancyChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  //table components start

  const topRevenueColumns = [
    {
      field: "unit_image_url",
      headerName: "",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <img
          src={defaultHouseImg}
          className="rounded-circle avatar-xs"
          alt={`Unit ${params.row.unit_name} image`}
        />
      ),
    },
    {
      field: "unit_name",
      headerName: "Unit Name",
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.unit_name}</strong>,
    },
    {
      field: "revenue_category",
      headerName: "Category",
      width: 200,
      editable: false,
      renderCell: (params) => <strong>{params.row.revenue_category}</strong>,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <strong>{formatCurrency(params.row.revenue)}</strong>
      ),
    },
  ];

  //table components end

  //table components start

  const ticketColumns = [
    {
      field: "ticket_number",
      headerName: "Number",
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_number}</strong>,
    },
    {
      field: "ticket_title",
      headerName: "Title",
      width: 300,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_title}</strong>,
    },
    {
      field: "ticket_status",
      headerName: "Status",
      width: 120,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_status}</strong>,
    },

    {
      field: "ticket_type",
      headerName: "Type",
      width: 150,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_type}</strong>,
    },
  ];

  //table components end

  const [isLoadingTopRevenue, setIsLoadingTopRevenue] = useState(true);

  const [isLoadingVacancyChart, setIsLoadingVacancyChart] = useState(true);

  const [isLoadingTickets, setIsLoadingTickets] = useState(true);

  const [isLoadingTasks, setIsLoadingTasks] = useState(true);

  const [isPendingContractLoading, setIsPendingContractLoading] =
    useState(true);

  const [rentalContract, setRentalContract] = useState({
    rental_contract_id: "",
    tenant_id: "",
    property_unit_id: "",
    start_date: "",
    end_date: "",
    monthly_rent: 0,
    security_deposit: 0,
    contract_template_id: "",
    contract_detail: "",
    portfolio_ownership_id: "",
    property_name: "",
    property_unit_name: "",
    portfolio_name: "",
    owner_manager_user_id: "",
    owner_manager_name: "",
    owner_manager_email: "",
    end_of_lease_action: "",
    is_active: true,
    is_deleted: false,
    rental_fee_pack_template_id: "",
    rental_fee_pack_id: "",
    screening_pack_id: "",
    campaign_id: "",
    //Need to prefill who needs to sign the contract
    landlord_property_manager_signatures: [
      //TODO: next saving the signature for the logged in user and disable the others for the logged in user
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "", //aws s3 url
        signature_base64: "", //base64 string (not for storage )
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_id: "",
        user_type: "",
        landlord_id: "",
        email_address: "",
      },
    ],
    tenant_signatures: [
      {
        signature_id: "",
        rental_contract_id: "",
        signature_url: "",
        signed_at: Date(),
        signed_by: "",
        is_signed: false,
        user_id: "",
        user_type: "",
        tenant_id: "",
        email_address: "",
      },
    ],
  });

  const [error, setError] = useState("");

  const fetchLatestRentalContractByLandlordId = async () => {
    setIsPendingContractLoading(true);
    try {
      const response = await getLatestRentalContractByLandlordId();
      devLog(response);
      setRentalContract(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPendingContractLoading(false);
    }
  };

  const fetchAllNewTickets = async () => {
    setIsLoadingTickets(true);
    try {
      const response = await getNewTicketsByUserId();
      console.log("new tickets");
      console.log(response);
      setNewUnitTickets(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTickets(false);
    }
  };

  const fetchTopFiveUnitsWithHighestRevenueReturn = async () => {
    setIsLoadingTopRevenue(true);
    try {
      const response = await getTop5UnitsWithHighestRevenueReturn();
      //console.log('Top 5 units with highest revenue return');
      // console.log(response);
      setTopRevenueUnitPeriod(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTopRevenue(false);
    }
  };

  //getUserTasksByLoggedInUser

  const emptyTaskLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_list_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchAllUserTasks = async () => {
    setIsLoadingTasks(true);
    try {
      const response = await getUserTasksByLoggedInUser();
      if (response.length > 0) {
        devLog(`Tasks Assigned: `);
        devLog(response);
        setTaskGroups(response);
        let allTasks = [];
        response.forEach((taskGroup) => {
          taskGroup.tasks.forEach((task) => {
            allTasks.push(task);
          });
        });
        setTasks(allTasks);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTasks(false);
    }
  };

  //task is done checkbox
  const handleCheckboxChange = async (event, _unitTask) => {
    console.log(_unitTask);
    const { name, checked } = event.currentTarget;
    //const updatedTaskData = { ...taskData, [name]: checked };
    //setTaskData(updatedTaskData);

    // Update the task in the list immediately for UI feedback
    const updatedTasks = tasks.map((task) =>
      task.task_id === _unitTask.task_id ? { ...task, is_done: checked } : task
    );
    setTasks({ ...tasks, tasks: updatedTasks });

    //alert(`Task is done via checked : ${checked}`);
    //alert(`Task is done via unit: ${_unitTask.is_done}`);
    // Use _unitTask.task_id directly for accurate task identification
    if (checked) {
      console.log("Task is done");
      const response = await completeTask(_unitTask.task_id, true);
      console.log(response);

      if (response) {
        fetchAllUserTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Completed",
          description: `Task ${_unitTask.task_name} has been completed.`,
          _task_id: _unitTask.task_id,
        });
      }
    } else {
      console.log("Task is not done");
      const response = await completeTask(_unitTask.task_id, false);
      console.log(response);

      if (response) {
        fetchAllUserTasks();

        //log the property unit audit
        logPropertyUnitAudit({
          audit_type: "Task",
          action_name: "Task Uncompleted",
          description: `Task ${_unitTask.task_name} has been uncompleted.`,
          _task_id: _unitTask.task_id,
        });
      }
    }
  };

  const logPropertyUnitAudit = async ({
    audit_type,
    action_name,
    description,
    _task_id,
    icon_name = "ri-edit-box-line",
    icon_color = "blue",
  }) => {
    //alert(`Task ID: ${_task_id}`);
    const first_name = localStorage.getItem(config.first_name);
    const last_name = localStorage.getItem(config.last_name);
    const created_by = `${first_name} ${last_name}`;
    //use the _task_id to get the property_unit_id which is found at the task_group level
    const propertyUnit = taskGroups.find((taskGroup) =>
      taskGroup.tasks.find((task) => task.task_id === _task_id)
    );
    const property_unit_id = propertyUnit.property_unit_id;

    //alert(`Property Unit ID: ${property_unit_id}`);

    const audit = {
      property_unit_audit_id: uuidv4(),
      property_unit_id: property_unit_id,
      audit_date: new Date(),
      audit_type: audit_type,
      description: description,
      icon_details: {
        icon_name: icon_name,
        icon_color: icon_color,
      },
      action_name: action_name,
      created_by: created_by,
    };

    const response = await createPropertyUnitAudit(audit);

    console.log(response);
  };

  const getRentedVsVacantUnits = async () => {
    setIsLoadingVacancyChart(true);
    try {
      const response = await getTheRentedVsVacantUnitsByOwnership();

      if (response) {
        console.log(response);

        const rentedCount = response.rented_units;
        const vacantCount = response.vacant_units;

        const labels = [
          `(${rentedCount}) Rented Units`,
          `(${vacantCount}) Vacant Units`,
        ];

        // Define the colors for rented and vacant units
        const predefinedColors = ["#28a745", "#dc3545"]; // Green for rented, Red for vacant

        const borderColor = predefinedColors.map(
          (color) => color.replace("0.2", "1") // Set alpha channel to 1 for solid color
        );

        const hoverColors = predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ); // darken by 30%

        setVacancyChartData({
          ...vacancyChartData,
          labels: labels,
          datasets: [
            {
              label: "Rented vs Vacant Units",
              data: [response.rented_units, response.vacant_units],
              backgroundColor: predefinedColors, // Use the predefined colors
              borderColor: isDarkMode ? "#6c757d" : "white",
              borderWidth: 3,
              hoverBackgroundColor: hoverColors,
            },
          ],
        });
      }
    } catch (error) {
      //setIsLoading(false);
      console.error(error);
    } finally {
      setIsLoadingVacancyChart(false);
    }
  };

  //guage start
  const getProgressBarColor = (usagePercentage) => {
    if (usagePercentage < 50) {
      return "bg-success"; // Green
    } else if (usagePercentage < 75) {
      return "bg-warning"; // Yellow
    } else {
      return "bg-danger"; // Red
    }
  };

  const usagePercentage = (numberOfUnitsUsed / maxNumberOfUnits) * 100;
  const progressBarColor = getProgressBarColor(usagePercentage);
  //guage end
  useEffect(() => {
    checkStatus();
  }, [checkStatus]);
  useEffect(() => {
    setIsLoading(true);

    //stop animation after 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    window.scrollTo(0, 0);

    fetchAllNewTickets();

    fetchTopFiveUnitsWithHighestRevenueReturn();

    fetchAllUserTasks();

    getRentedVsVacantUnits();

    fetchLatestRentalContractByLandlordId();

    // Simulate loading user info
    setTimeout(() => {
      setIsLoadingUserInfo(false);
    }, 2000);
    //after loading
  }, []);

  useEffect(() => {
    const loadSubscriptionStatus = async () => {
      try {
        const status = await getSubscriptionStatusFromStripe();
        setSubscriptionStatusState(status);
      } catch (error) {
        console.error("Error loading subscription status:", error);
      } finally {
        setIsLoadingUserInfo(false);
      }
    };

    loadSubscriptionStatus();
  }, [setSubscriptionStatusState]);

  // Add these transformations before passing data to the component
  const transformSignatures = (contract) => {
    const signatures = [];

    // Add landlord signatures
    contract.landlord_property_manager_signatures.forEach((sig) => {
      signatures.push({
        name:
          contract.landlord_details.first_name +
          " " +
          contract.landlord_details.last_name,
        status: sig.is_signed ? "signed" : "required",
        signedAt: sig.signed_at,
      });
    });

    // Add tenant signatures
    contract.tenant_signatures.forEach((sig) => {
      signatures.push({
        name:
          contract.tenant_details.first_name +
          " " +
          contract.tenant_details.last_name,
        status: sig.is_signed ? "signed" : "required",
        signedAt: sig.signed_at,
      });
    });

    return signatures;
  };

  const handleSignatureComplete = async (signature) => {
    if (!signature) {
      setError("Please provide a signature first");
      return;
    }

    try {
      // Find the current user's signature entry
      const currentUserId = localStorage.getItem(config.user_id);
      const currentUserSignature =
        rentalContract.landlord_property_manager_signatures.find(
          (sig) => sig.user_id === currentUserId
        );

      if (!currentUserSignature) {
        setError("User signature entry not found");
        return;
      }

      // Prepare signature data matching the working implementation
      const signatureData = {
        signature_id: currentUserSignature.signature_id || undefined,
        signature_base64: signature,
        rental_contract_id: rentalContract.rental_contract_id,
        signed_at: new Date().toISOString(),
        signed_by: `${localStorage.getItem(
          config.first_name
        )} ${localStorage.getItem(config.last_name)}`,
        is_signed: true,
        user_type: "landlord",
        landlord_id: currentUserId,
        email_address:
          currentUserSignature.email_address ||
          localStorage.getItem(config.email),
        user_id: currentUserId, // Add this field to match the working implementation
      };

      // Save signature to backend
      const response = await updateRentalContractWithLandlordSignature(
        signatureData
      );

      if (response) {
        // Update local state to reflect the signature
        const updatedContract = {
          ...rentalContract,
          landlord_property_manager_signatures:
            rentalContract.landlord_property_manager_signatures.map((sig) =>
              sig.user_id === currentUserId
                ? {
                    ...sig,
                    is_signed: true,
                    signature_base64: signature,
                    signed_at: new Date().toISOString(),
                  }
                : sig
            ),
        };
        setRentalContract(updatedContract);

        // Refresh the contract data from server
        await fetchLatestRentalContractByLandlordId();
      }
    } catch (error) {
      devLog("Error saving signature:", error);
      setError("Failed to save signature. Please try again.");
    }
  };

  const handleTaskClick = (task) => {
    if (
      task.is_payment_verification &&
      task.payment_intent_id !== "" &&
      task.rental_contract_id !== "" &&
      task.rental_fee_pack_id !== ""
    ) {
      navigate(`/paymentVerification/${task.payment_intent_id}`);
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      <div className={` ${getThemeClasses()}`}>
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox pageTitle="Dashboard" previousPageTitle="" />
          {/* end page title */}

          <div className="mb-3">
            <KYCBanner
              userId={userId}
              stripeAccountId={stripeConnectAccountId}
              isDarkMode={isDarkMode}
              state={stripeState}
              onComplete={() => {
                checkStatus();
              }}
            />
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="d-flex flex-column h-100">
                <div className="row h-100">
                  <div className="col-12">
                    <SubscriptionStatusCard
                      isLoadingUserInfo={isLoadingUserInfo}
                      subscriptionStatus={subscriptionStatus}
                      isDarkMode={isDarkMode}
                      firstName={first_name}
                    />
                  </div>
                </div>
                {/* end row*/}

                <div className="col-xl-12">
                  <div className={`card card-height-100 ${getThemeClasses()}`}>
                    <div className="card-header">
                      <h4 className="card-title mb-0">
                        Rented VS Vacant Units
                      </h4>
                    </div>
                    <div className="card-body">
                      {isLoadingVacancyChart ? (
                        <ShimmerEffect
                          type="chart"
                          height="250px"
                          darkMode={isDarkMode}
                        />
                      ) : (
                        <div
                          className="chart-container-1"
                          style={{
                            width: "100%",
                            height: "100%",
                            minHeight: "250px",
                          }}
                        >
                          <Doughnut
                            data={vacancyChartData}
                            options={{
                              maintainAspectRatio: false,
                              responsive: true,
                              plugins: {
                                legend: {
                                  display: true,
                                  labels: {
                                    color: isDarkMode ? "white" : "#6c757d",
                                  },
                                },
                                tooltip: {
                                  enabled: false,
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end col*/}
            <div className="col-xl-3">
              <div className={`card card-height-100 ${getThemeClasses()}`}>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Top Revenue Units YTD
                  </h4>
                  <div className="flex-shrink-0">
                    <div hidden className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="text-muted">
                          Report<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Download Report
                        </a>
                        <a className="dropdown-item" href="#">
                          Export
                        </a>
                        <a className="dropdown-item" href="#">
                          Import
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive table-card"
                    style={{
                      height: "450px",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    {isLoadingTopRevenue
                      ? Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="card"
                              className="mb-3"
                              darkMode={isDarkMode}
                            />
                          ))
                      : topRevenueUnitPeriod &&
                        topRevenueUnitPeriod.units &&
                        topRevenueUnitPeriod.units.map((unit, index) => (
                          <TopRevenueCard
                            key={index}
                            unit={unit}
                            defaultHouseImg={defaultHouseImg}
                          />
                        ))}
                    {topRevenueUnitPeriod &&
                      topRevenueUnitPeriod.units &&
                      topRevenueUnitPeriod.units.length > 3 && (
                        <div
                          className="scroll-indicator"
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: "30px",
                            background: `linear-gradient(to bottom, transparent, ${
                              isDarkMode ? "#2a3042" : "#ffffff"
                            })`,
                            pointerEvents: "none",
                            textAlign: "center",
                          }}
                        >
                          <i
                            className="bx bx-chevron-down"
                            style={{
                              fontSize: "20px",
                              color: isDarkMode ? "#adb5bd" : "#6c757d",
                            }}
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* end col*/}
            <div className="col-xl-3">
              <div className={`card card-height-100 ${getThemeClasses()}`}>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Ticket Requests
                  </h4>
                  <div className="flex-shrink-0">
                    <div hidden className="dropdown card-header-dropdown">
                      {/* ... (dropdown menu code remains unchanged) ... */}
                    </div>
                  </div>
                </div>
                {/*end card header */}
                <div className="card-body">
                  <div
                    className="table-responsive table-card p-3"
                    style={{ height: "450px", overflowY: "auto" }}
                  >
                    {isLoadingTickets
                      ? Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="card"
                              className="mb-3"
                              darkMode={isDarkMode}
                            />
                          ))
                      : newUnitTickets &&
                        newUnitTickets.map((ticket, index) => (
                          <TicketCard ticket={ticket} key={index} />
                        ))}
                  </div>
                </div>
                {/* end card-body */}
              </div>
              {/* end card */}
            </div>
            {/* end col */}
            {/* end col*/}
            <div className="col-xl-3">
              <div className={`card card-height-100 ${getThemeClasses()}`}>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">My Tasks</h4>
                  <div className="flex-shrink-0">
                    <div className="dropdown card-header-dropdown">
                      <a
                        className="text-reset dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        hidden
                      >
                        <span className="text-muted">
                          <i className="ri-list-check-2"></i>
                          Show More{" "}
                          {/* Open option to duplicate to other units and portfolios*/}
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end card header */}

                <div className="card-body p-0">
                  <div className="align-items-center p-3 justify-content-between d-flex">
                    {tasks && tasks.length > 0 && tasks[0].task_name && (
                      <div className="p-0 mt-auto">
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                          className="text-muted text-decoration-underline"
                        >
                          <i className="ri-list-check-2"></i>
                          Show more...
                        </a>
                      </div>
                    )}
                    <button
                      type="button"
                      hidden
                      className="btn btn-sm btn-success"
                      disabled
                      onClick={() => {
                        //setShowModal(true);
                        //TODO: create a add task or bulk tasks view so this navigates to it
                      }}
                    >
                      <i className="ri-add-line align-middle me-1"></i> Add Task
                    </button>
                  </div>
                  {/*end card header */}
                  <div className="card-body p-0">
                    <div
                      style={{ height: "450px", overflowY: "auto" }}
                      className="p-3"
                    >
                      {isLoadingTasks ? (
                        // Shimmer effect while loading
                        Array(5)
                          .fill()
                          .map((_, index) => (
                            <ShimmerEffect
                              key={index}
                              type="task-card"
                              className="mb-3"
                              darkMode={isDarkMode}
                            />
                          ))
                      ) : tasks && tasks.length > 0 && tasks[0].task_name ? (
                        <ul className="list-group list-group-flush border-dashed">
                          {tasks.map((unitTask, index) => (
                            <li
                              className="list-group-item ps-2 card card-body border"
                              key={index}
                            >
                              <div className="d-flex align-items-center">
                                <div className="form-check ps-0 flex-sharink-0">
                                  <input
                                    type="checkbox"
                                    className="form-check-input ms-0"
                                    id={`task_${unitTask.task_id}`}
                                    name="is_done"
                                    checked={unitTask.is_done}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, unitTask)
                                    }
                                  />
                                </div>
                                <div
                                  className="flex-grow-1"
                                  style={
                                    unitTask.is_done
                                      ? { textDecoration: "line-through" }
                                      : {}
                                  }
                                  onClick={() => handleTaskClick(unitTask)}
                                  role="button"
                                >
                                  <label className="form-check-label mb-0 ps-2">
                                    {unitTask.task_name}
                                  </label>

                                  <p className="text-muted fs-12 mb-0 ps-2">
                                    {unitTask.task_description.length > 150
                                      ? `${unitTask.task_description.slice(
                                          0,
                                          150
                                        )}...`
                                      : unitTask.task_description}
                                  </p>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <p className="text-muted fs-12 mb-0">
                                    {new Date(
                                      unitTask.task_due_date
                                    ).toLocaleDateString("en-US", {
                                      day: "numeric",
                                      month: "short",
                                    })}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="text-center">
                          <Lottie
                            options={emptyTaskLottieOptions}
                            height={200}
                            width={300}
                          />
                          <p className="text-muted">
                            You don't have any tasks assigned yet. Tasks will
                            appear here when they are created or assigned to
                            you.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*end card body */}
              </div>
              {/*end card */}
            </div>
            {/*end col */}
            {/* Add this to its own componene tto clean it up*/}
            {rentalContract && (
              <div className="col-xl-3">
                <div className={`card card-height-100 ${getThemeClasses()}`}>
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Pending Rental Contract
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive table-card">
                      {isPendingContractLoading ? (
                        // Shimmer effect while loading
                        <ShimmerEffect
                          type="contract-card"
                          height="200px"
                          className="mb-3"
                          darkMode={isDarkMode}
                        />
                      ) : (
                        <RentalContractSignatureWidget
                          contractId={rentalContract.rental_contract_id}
                          unitName={rentalContract.property_unit_name}
                          startDate={rentalContract.start_date}
                          endDate={rentalContract.end_date}
                          landlordSignatures={
                            rentalContract.landlord_property_manager_signatures
                          }
                          tenantSignatures={rentalContract.tenant_signatures}
                          currentUserId={localStorage.getItem(config.user_id)}
                          landlordProfile={rentalContract.landlord_details}
                          tenantProfile={rentalContract.tenant_details}
                          onViewClick={() => {
                            window.open(
                              `/rentalContractSignature/${rentalContract.rental_contract_id}`,
                              "_blank"
                            );
                          }}
                          onSignClick={handleSignatureComplete}
                          isDarkMode={isDarkMode}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* end row*/}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
