import React, { useEffect, useState } from "react";
import {
  Guide,
  categories,
  getGuides,
  searchGuides,
} from "../../../../services/guidebook/guidebookService";
import { useNavigate } from "react-router-dom";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../../reusable/title/pageTitleBox";

// Helper function to create a preview from HTML content
const createContentPreview = (
  htmlContent: string,
  maxLength: number = 200
): string => {
  if (!htmlContent) return "No content available";

  // Create a temporary div to parse HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Get text content and trim it
  let textContent = tempDiv.textContent || tempDiv.innerText || "";
  textContent = textContent.trim();

  // Truncate to maxLength and add ellipsis if needed
  if (textContent.length > maxLength) {
    return textContent.substring(0, maxLength) + "...";
  }

  return textContent;
};

interface GuideListProps {
  isDarkMode: boolean;
}

const GuideList: React.FC<GuideListProps> = ({ isDarkMode }) => {
  const [guides, setGuides] = useState<Guide[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);

  useEffect(() => {
    loadGuides();
  }, []);

  const loadGuides = async () => {
    try {
      const data = await getGuides();
      setGuides(data);
    } catch (error) {
      console.error("Error loading guides:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    if (query.trim()) {
      const results = await searchGuides(query);
      setGuides(results);
    } else {
      loadGuides();
    }
  };

  const filteredGuides =
    selectedCategory === "all"
      ? guides
      : guides.filter((guide) => guide.category === selectedCategory);

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Guidebook"
          previousPageLink="/dashboard"
          previousPageTitle="Dashboard"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Guides</h4>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => navigate("/guidebook/create")}
                  >
                    <i className="ri-add-line align-middle me-1"></i>
                    Create New Guide
                  </button>
                </div>
              </div>

              <div className="card-body">
                <div className="row g-3">
                  <div className="col-lg-4">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search guides..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <ul className="nav nav-pills nav-custom-pills justify-content-end">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${
                            selectedCategory === "all" ? "active" : ""
                          }`}
                          onClick={() => setSelectedCategory("all")}
                        >
                          All
                        </button>
                      </li>
                      {categories.map((category) => (
                        <li className="nav-item" key={category}>
                          <button
                            className={`nav-link ${
                              selectedCategory === category ? "active" : ""
                            }`}
                            onClick={() => setSelectedCategory(category)}
                          >
                            {category}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="row mt-4">
                  {isLoading ? (
                    <div className="col-12">
                      <div className="text-center">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    filteredGuides.map((guide) => (
                      <div
                        key={guide.guide_id}
                        className="col-xl-4 col-md-6"
                        onClick={() => navigate(`/guidebook/${guide.guide_id}`)}
                      >
                        <div className="card cursor-pointer">
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-3">
                              <div className="flex-grow-1">
                                <h5 className="card-title mb-1">
                                  {guide.title}
                                </h5>
                                <p className="text-muted mb-0">
                                  Last updated:{" "}
                                  {new Date(
                                    guide.updated_at
                                  ).toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                            <p className="text-muted line-clamp-3">
                              {createContentPreview(
                                guide.sections[0]?.content || ""
                              )}
                            </p>
                            <div className="d-flex flex-wrap gap-2 mb-3">
                              <span className="badge bg-primary">
                                {guide.category}
                              </span>
                              {guide.tags.map((tag) => (
                                <span key={tag} className="badge bg-secondary">
                                  {tag}
                                </span>
                              ))}
                            </div>
                            <div className="d-flex justify-content-between text-muted small">
                              <span>{guide.views} views</span>
                              <span>{guide.downloads} downloads</span>
                              <span>★ {guide.rating.toFixed(1)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideList;
