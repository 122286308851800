interface Config {
  apiEndpoint: string;
  wsEndpoint: string;
  access_token: string;
  refresh_token: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  login_code: string;
  profile_img: string;
  stripe_customer_id: string;
  is_paid: string;
  user_type: string;
  is_dark_mode: string;
  number_of_free_trial_days_left: string;
  subscription_name: string;
  subscription_status: string;
  subscription_id: string;
  stripe_connect_account_id: string;
  ai_chat_enabled: string;
}

const config: Config = {
  apiEndpoint:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION || ""
      : process.env.REACT_APP_API_URL_DEVELOPMENT || "",
  wsEndpoint:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_WS_URL_PRODUCTION || ""
      : process.env.REACT_APP_WS_URL_DEVELOPMENT || "",
  access_token: "access_token",
  refresh_token: "refresh_token",
  user_id: "user_id",
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
  login_code: "login_code",
  profile_img: "profile_img",
  stripe_customer_id: "stripe_customer_id",
  is_paid: "is_paid",
  user_type: "user_type",
  is_dark_mode: "is_dark_mode",
  number_of_free_trial_days_left: "number_of_free_trial_days_left",
  subscription_name: "subscription_name",
  subscription_status: "subscription_status",
  subscription_id: "subscription_id",
  stripe_connect_account_id: "stripe_connect_account_id",
  ai_chat_enabled: "ai_chat_enabled",
};

export default config;
