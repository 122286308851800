import React, { useState, useEffect } from "react";
import { DialogAction } from "../../../../models/ai-chat/Message";

interface Portfolio {
  id: string;
  name: string;
  description?: string;
  property_count: number;
}

interface PortfolioSelectorProps {
  description: string;
  onAction: (action: DialogAction) => void;
  isDarkMode: boolean;
}

export const PortfolioSelector: React.FC<PortfolioSelectorProps> = ({
  description,
  onAction,
  isDarkMode,
}) => {
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // TODO: Replace with actual API call to fetch portfolios
    const fetchPortfolios = async () => {
      try {
        setIsLoading(true);
        // Simulated API call
        const response = await fetch("/api/portfolios");
        const data = await response.json();
        setPortfolios(data);
      } catch (error) {
        console.error("Error fetching portfolios:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPortfolios();
  }, []);

  const handlePortfolioSelect = (portfolio: Portfolio) => {
    onAction({
      label: portfolio.name,
      value: portfolio.id,
      variant: "primary",
    });
  };

  const handleCreateNew = () => {
    onAction({
      label: "Create New Portfolio",
      value: "create_new",
      variant: "primary",
    });
  };

  if (isLoading) {
    return (
      <div
        className={`tw-p-4 tw-rounded-lg tw-border ${
          isDarkMode
            ? "tw-bg-gray-800 tw-border-gray-700"
            : "tw-bg-white tw-border-gray-200"
        }`}
      >
        <div className="tw-animate-pulse tw-space-y-4">
          <div className="tw-h-4 tw-bg-gray-300 tw-rounded tw-w-3/4"></div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-4">
            <div className="tw-h-24 tw-bg-gray-300 tw-rounded"></div>
            <div className="tw-h-24 tw-bg-gray-300 tw-rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`tw-p-4 tw-rounded-lg tw-border ${
        isDarkMode
          ? "tw-bg-gray-800 tw-border-gray-700"
          : "tw-bg-white tw-border-gray-200"
      }`}
    >
      <p
        className={`tw-text-sm ${
          isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
        } tw-mb-4`}
      >
        {description}
      </p>

      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        {portfolios.map((portfolio) => (
          <button
            key={portfolio.id}
            onClick={() => handlePortfolioSelect(portfolio)}
            className={`tw-p-4 tw-rounded-lg tw-border tw-text-left tw-transition-colors ${
              isDarkMode
                ? "tw-border-gray-700 hover:tw-bg-gray-700"
                : "tw-border-gray-200 hover:tw-bg-gray-50"
            }`}
          >
            <h4
              className={`tw-font-medium ${
                isDarkMode ? "tw-text-white" : "tw-text-gray-900"
              }`}
            >
              {portfolio.name}
            </h4>
            {portfolio.description && (
              <p
                className={`tw-text-sm tw-mt-1 ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                }`}
              >
                {portfolio.description}
              </p>
            )}
            <p
              className={`tw-text-sm tw-mt-2 ${
                isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
              }`}
            >
              {portfolio.property_count} properties
            </p>
          </button>
        ))}

        <button
          onClick={handleCreateNew}
          className={`tw-p-4 tw-rounded-lg tw-border tw-border-dashed tw-flex tw-items-center tw-justify-center tw-transition-colors ${
            isDarkMode
              ? "tw-border-gray-700 hover:tw-bg-gray-700"
              : "tw-border-gray-300 hover:tw-bg-gray-50"
          }`}
        >
          <div className="tw-text-center">
            <div
              className={`tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mx-auto tw-mb-2 ${
                isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-100"
              }`}
            >
              <svg
                className={`tw-w-5 tw-h-5 ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </div>
            <span
              className={`tw-text-sm tw-font-medium ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-900"
              }`}
            >
              Create New Portfolio
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};
