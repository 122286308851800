import {
  TabContext,
  UserActivityContext,
  DataContext,
  ContextState,
  AiAssistantContext,
} from "../../models/ai-chat/Context";
import config from "../../constants/config";
import { debounce } from "lodash";
import { devLog } from "services/utils/logger";

interface ContextualSuggestion {
  question: string;
  relevance: number;
  category: string;
}

export class AiContextTrackingService {
  private static instance: AiContextTrackingService;
  private contextState: ContextState;
  private broadcastChannel: BroadcastChannel;
  private syncInterval: NodeJS.Timeout | null = null;
  private db: IDBDatabase | null = null;

  private constructor() {
    this.contextState = this.initializeContextState();
    this.broadcastChannel = new BroadcastChannel("ai_assistant_channel");
    this.setupEventListeners();
    this.initializeIndexedDB();
    this.startPeriodicSync();
  }

  public static getInstance(): AiContextTrackingService {
    if (!AiContextTrackingService.instance) {
      AiContextTrackingService.instance = new AiContextTrackingService();
    }
    return AiContextTrackingService.instance;
  }

  private initializeContextState(): ContextState {
    const tabId = this.generateTabId();
    const userId = localStorage.getItem(config.user_id) || "";

    return {
      activeTabId: tabId,
      tabs: new Map([
        [
          tabId,
          {
            tabId,
            isActive: true,
            lastActive: new Date(),
            pageContext: {
              route: window.location.pathname,
              params: {},
              title: document.title,
              timestamp: new Date(),
            },
          },
        ],
      ]),
      globalContext: {
        userId,
        recentSearches: [],
        commonActions: [],
        activeProcesses: [],
      },
      lastSynced: new Date(),
    };
  }

  private generateTabId(): string {
    return `tab_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }

  private setupEventListeners(): void {
    // Track tab visibility
    document.addEventListener("visibilitychange", () => {
      this.updateTabActivity(!document.hidden);
    });

    // Track window focus
    window.addEventListener("focus", () => {
      this.updateTabActivity(true);
    });

    window.addEventListener("blur", () => {
      this.updateTabActivity(false);
    });

    // Listen for messages from other tabs
    this.broadcastChannel.addEventListener("message", (event) => {
      this.handleBroadcastMessage(event.data);
    });

    // Track route changes
    this.setupRouteTracking();
  }

  private updateTabActivity(isActive: boolean): void {
    const currentTab = this.contextState.tabs.get(
      this.contextState.activeTabId
    );
    if (currentTab) {
      currentTab.isActive = isActive;
      currentTab.lastActive = new Date();
      this.contextState.tabs.set(this.contextState.activeTabId, currentTab);

      // Notify other tabs
      this.broadcastChannel.postMessage({
        type: "TAB_ACTIVITY_CHANGED",
        tabId: this.contextState.activeTabId,
        isActive,
      });

      // Trigger context sync if tab becomes active
      if (isActive) {
        this.debouncedSync();
      }
    }
  }

  private setupRouteTracking(): void {
    // Basic route change detection
    const originalPushState = window.history.pushState;
    window.history.pushState = (...args) => {
      originalPushState.apply(window.history, args);
      this.handleRouteChange();
      // Notify other tabs about route change
      this.broadcastChannel.postMessage({
        type: "ROUTE_CHANGED",
        tabId: this.contextState.activeTabId,
        route: window.location.pathname,
        timestamp: new Date(),
      });
    };

    window.addEventListener("popstate", () => {
      this.handleRouteChange();
      // Notify other tabs about route change
      this.broadcastChannel.postMessage({
        type: "ROUTE_CHANGED",
        tabId: this.contextState.activeTabId,
        route: window.location.pathname,
        timestamp: new Date(),
      });
    });

    // Listen for route changes from React Router
    document.addEventListener("routeChange", (event: Event) => {
      this.handleRouteChange();
    });
  }

  private handleRouteChange(): void {
    const currentTab = this.contextState.tabs.get(
      this.contextState.activeTabId
    );
    if (currentTab) {
      const newPageContext = {
        route: window.location.pathname,
        params: this.extractRouteParams(),
        title: document.title,
        timestamp: new Date(),
      };

      // Check if the route actually changed
      if (currentTab.pageContext.route !== newPageContext.route) {
        currentTab.pageContext = newPageContext;
        this.contextState.tabs.set(this.contextState.activeTabId, currentTab);

        // Clear any stale context when route changes
        this.contextState.globalContext.recentSearches = [];
        this.contextState.globalContext.activeProcesses = [];

        // Save context immediately on route change
        this.saveToIndexedDB();
        this.debouncedSync();

        // Dispatch a custom event that components can listen to
        window.dispatchEvent(
          new CustomEvent("contextChanged", {
            detail: {
              route: newPageContext.route,
              timestamp: newPageContext.timestamp,
            },
          })
        );
      }
    }
  }

  private extractRouteParams(): Record<string, string> {
    const params: Record<string, string> = {};
    const pathParts = window.location.pathname.split("/");
    // Extract basic ID parameters
    pathParts.forEach((part, index) => {
      if (part.match(/^[0-9a-fA-F-]+$/)) {
        params[`param${index}`] = part;
      }
    });
    return params;
  }

  private handleBroadcastMessage(message: any): void {
    switch (message.type) {
      case "TAB_ACTIVITY_CHANGED":
        if (message.tabId !== this.contextState.activeTabId) {
          const tab = this.contextState.tabs.get(message.tabId);
          if (tab) {
            tab.isActive = message.isActive;
            tab.lastActive = new Date();
            this.contextState.tabs.set(message.tabId, tab);
          }
        }
        break;
      case "ROUTE_CHANGED":
        if (message.tabId !== this.contextState.activeTabId) {
          const tab = this.contextState.tabs.get(message.tabId);
          if (tab) {
            tab.pageContext = {
              route: message.route,
              params: this.extractRouteParams(),
              title: document.title,
              timestamp: new Date(message.timestamp),
            };
            this.contextState.tabs.set(message.tabId, tab);
          }
        }
        break;
    }
  }

  private startPeriodicSync(): void {
    // Sync every 5 minutes
    this.syncInterval = setInterval(() => {
      this.syncContext();
    }, 5 * 60 * 1000);
  }

  private debouncedSync = debounce(() => {
    this.syncContext();
  }, 1000);

  private initializeIndexedDB(): void {
    const request = indexedDB.open("AiAssistantDB", 1);

    request.onerror = (event) => {
      console.error("IndexedDB error:", event);
    };

    request.onsuccess = (event) => {
      this.db = (event.target as IDBOpenDBRequest).result;
      this.loadFromIndexedDB();
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Create stores
      if (!db.objectStoreNames.contains("contextState")) {
        db.createObjectStore("contextState", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("suggestions")) {
        db.createObjectStore("suggestions", { keyPath: "id" });
      }
    };
  }

  private async loadFromIndexedDB(): Promise<void> {
    if (!this.db) return;

    try {
      const transaction = this.db.transaction(["contextState"], "readonly");
      const store = transaction.objectStore("contextState");
      const request = store.get("current");

      request.onsuccess = () => {
        if (request.result) {
          // Convert stored dates back to Date objects
          const storedState = request.result.state;
          this.contextState = this.deserializeContextState(storedState);
        }
      };
    } catch (error) {
      console.error("Error loading from IndexedDB:", error);
    }
  }

  private saveToIndexedDB(): void {
    if (!this.db) return;

    try {
      const transaction = this.db.transaction(["contextState"], "readwrite");
      const store = transaction.objectStore("contextState");

      // Serialize the state before storing
      const serializedState = this.serializeContextState(this.contextState);

      store.put({
        id: "current",
        state: serializedState,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error("Error saving to IndexedDB:", error);
    }
  }

  private serializeContextState(state: ContextState): any {
    return {
      ...state,
      tabs: Array.from(state.tabs.entries()),
      lastSynced: state.lastSynced.toISOString(),
    };
  }

  private deserializeContextState(serialized: any): ContextState {
    return {
      ...serialized,
      tabs: new Map(serialized.tabs),
      lastSynced: new Date(serialized.lastSynced),
    };
  }

  private async syncContext(): Promise<void> {
    try {
      const context = this.prepareContextForSync();

      // Save to IndexedDB first
      this.saveToIndexedDB();

      // Then sync to API
      await this.mockSyncToApi(context);
      this.contextState.lastSynced = new Date();
    } catch (error) {
      console.error("Failed to sync context:", error);
    }
  }

  private prepareContextForSync(): AiAssistantContext {
    const currentTab = this.contextState.tabs.get(
      this.contextState.activeTabId
    );
    return {
      tabId: this.contextState.activeTabId,
      userId: this.contextState.globalContext.userId,
      pageContext: currentTab!,
      userActivity: {
        recentViews: [],
        currentAction: undefined,
      },
      dataContext: {},
      lastUpdated: new Date(),
    };
  }

  private async mockSyncToApi(context: AiAssistantContext): Promise<void> {
    // Mock API call - replace with real API call later
    return new Promise((resolve) => {
      setTimeout(() => {
        devLog("Context synced to API:", context);
        resolve();
      }, 500);
    });
  }

  public async syncOnLogout(): Promise<void> {
    try {
      await this.syncContext();
      this.cleanup();
    } catch (error) {
      console.error("Failed to sync context on logout:", error);
    }
  }

  private cleanup(): void {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }
    if (this.db) {
      this.db.close();
    }
    this.broadcastChannel.close();
  }

  // Public methods for updating context
  public updateDataContext(data: Partial<DataContext>): void {
    const currentTab = this.contextState.tabs.get(
      this.contextState.activeTabId
    );
    if (currentTab) {
      // Update data context
      this.debouncedSync();
    }
  }

  public addUserActivity(activity: Partial<UserActivityContext>): void {
    // Update user activity
    this.debouncedSync();
  }

  public getCurrentContext(): AiAssistantContext {
    return this.prepareContextForSync();
  }

  //This will be replaced with a real contextual suggestion engine from the API
  private getContextualSuggestions(): ContextualSuggestion[] {
    const currentTab = this.contextState.tabs.get(
      this.contextState.activeTabId
    );
    if (!currentTab) return [];

    const route = currentTab.pageContext.route;
    const suggestions: ContextualSuggestion[] = [];

    // Property detail page suggestions
    if (route.includes("/property/")) {
      suggestions.push(
        {
          question: "What's the current occupancy rate for this property?",
          relevance: 0.9,
          category: "property",
        },
        {
          question: "Show me recent maintenance requests for this property",
          relevance: 0.8,
          category: "maintenance",
        },
        {
          question: "What's the rental income trend for this property?",
          relevance: 0.7,
          category: "financial",
        }
      );
    }

    // Tenant-related suggestions
    if (route.includes("/tenant/")) {
      suggestions.push(
        {
          question: "Is this tenant's rent up to date?",
          relevance: 0.9,
          category: "financial",
        },
        {
          question: "Show me the tenant's payment history",
          relevance: 0.8,
          category: "financial",
        },
        {
          question:
            "Are there any active maintenance requests from this tenant?",
          relevance: 0.7,
          category: "maintenance",
        }
      );
    }

    // Maintenance/tickets page suggestions
    if (route.includes("/ticket/")) {
      suggestions.push(
        {
          question: "What's the status of this maintenance request?",
          relevance: 0.9,
          category: "maintenance",
        },
        {
          question: "Find similar past maintenance issues",
          relevance: 0.8,
          category: "maintenance",
        },
        {
          question: "Which contractor should I assign for this type of issue?",
          relevance: 0.7,
          category: "contractor",
        }
      );
    }

    // Dashboard suggestions
    if (route.includes("/dashboard")) {
      suggestions.push(
        {
          question: "Show me properties with pending maintenance issues",
          relevance: 0.9,
          category: "maintenance",
        },
        {
          question: "What's my total rental income this month?",
          relevance: 0.8,
          category: "financial",
        },
        {
          question: "Which properties have upcoming lease renewals?",
          relevance: 0.7,
          category: "property",
        }
      );
    }

    // Add data-aware suggestions based on current context
    const dataContext = this.contextState.globalContext;
    if (dataContext.recentSearches.length > 0) {
      suggestions.push({
        question: `Tell me more about ${dataContext.recentSearches[0]}`,
        relevance: 0.6,
        category: "recent",
      });
    }

    return suggestions.sort((a, b) => b.relevance - a.relevance);
  }

  public getCurrentSuggestions(): ContextualSuggestion[] {
    return this.getContextualSuggestions();
  }
}
