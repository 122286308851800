import React from "react";

interface LoadingDotsProps {
  isDarkMode: boolean;
}

export const LoadingDots: React.FC<LoadingDotsProps> = ({ isDarkMode }) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <div
        className={`tw-w-2 tw-h-2 tw-rounded-full ${
          isDarkMode ? "tw-bg-gray-400" : "tw-bg-gray-600"
        } tw-animate-bounce [animation-delay:-0.3s]`}
      ></div>
      <div
        className={`tw-w-2 tw-h-2 tw-rounded-full ${
          isDarkMode ? "tw-bg-gray-400" : "tw-bg-gray-600"
        } tw-animate-bounce [animation-delay:-0.15s]`}
      ></div>
      <div
        className={`tw-w-2 tw-h-2 tw-rounded-full ${
          isDarkMode ? "tw-bg-gray-400" : "tw-bg-gray-600"
        } tw-animate-bounce`}
      ></div>
    </div>
  );
};
