import React from "react";
import { FeeConfirmation } from "../../types/payment/payment-verification";
import { formatCurrency } from "../../services/utils/currencyUtils";
import { cn } from "../../lib/utils";
import { usePageTitle } from "../../services/utils/hooks/usePageTitle";
interface FeeVerificationItemProps {
  fee: FeeConfirmation;
  onConfirm: (feeName: string, notes?: string) => void;
  isDarkMode?: boolean;
}

export const FeeVerificationItem: React.FC<FeeVerificationItemProps> = ({
  fee,
  onConfirm,
  isDarkMode = false,
}) => {
  const [notes, setNotes] = React.useState("");
  const [isExpanded, setIsExpanded] = React.useState(false);
  const pageTitle = "Fee Verification";
  usePageTitle({ title: pageTitle });

  const getStatusColor = (status?: string) => {
    switch (status?.toLowerCase()) {
      case "succeeded":
        return "text-green-500";
      case "pending":
        return "text-yellow-500";
      case "failed":
        return "text-red-500";
      default:
        return "text-gray-500";
    }
  };

  return (
    <div
      className={cn(
        "tw-border tw-rounded-lg tw-p-4 tw-mb-4",
        isDarkMode
          ? "tw-border-gray-700 tw-bg-gray-800"
          : "tw-border-gray-200 tw-bg-white",
        fee.is_confirmed_by_landlord && "tw-opacity-75"
      )}
    >
      <div className="tw-flex tw-justify-between tw-items-start">
        <div className="tw-flex-1">
          <h3
            className={cn(
              "tw-text-lg tw-font-medium",
              isDarkMode ? "tw-text-white" : "tw-text-gray-900"
            )}
          >
            {fee.fee_name}
          </h3>
          <div className="tw-mt-1 tw-space-y-1">
            <p
              className={cn(
                "tw-text-sm",
                isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
              )}
            >
              Expected Amount: {formatCurrency(fee.amount)}
            </p>
            {fee.stripe_amount_received && (
              <p
                className={cn(
                  "tw-text-sm",
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                )}
              >
                Received: {formatCurrency(fee.stripe_amount_received)}
              </p>
            )}
            {fee.stripe_payment_status && (
              <p
                className={cn(
                  "tw-text-sm",
                  getStatusColor(fee.stripe_payment_status)
                )}
              >
                Status: {fee.stripe_payment_status}
              </p>
            )}
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-items-end tw-space-y-2">
          {!fee.is_confirmed_by_landlord ? (
            <>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={cn(
                  "tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md",
                  isDarkMode
                    ? "tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white"
                    : "tw-bg-blue-50 hover:tw-bg-blue-100 tw-text-blue-700"
                )}
              >
                Confirm Payment
              </button>
              {isExpanded && (
                <div className="tw-mt-4 tw-w-full">
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add verification notes (optional)"
                    className={cn(
                      "tw-w-full tw-p-2 tw-rounded-md tw-border",
                      isDarkMode
                        ? "tw-bg-gray-700 tw-border-gray-600 tw-text-white"
                        : "tw-bg-white tw-border-gray-300"
                    )}
                    rows={3}
                  />
                  <div className="tw-mt-2 tw-flex tw-justify-end tw-space-x-2">
                    <button
                      onClick={() => {
                        onConfirm(fee.fee_name, notes);
                        setIsExpanded(false);
                      }}
                      className={cn(
                        "tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-bg-green-600 hover:tw-bg-green-700 tw-text-white"
                      )}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => setIsExpanded(false)}
                      className={cn(
                        "tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md",
                        isDarkMode
                          ? "tw-bg-gray-700 hover:tw-bg-gray-600 tw-text-white"
                          : "tw-bg-gray-100 hover:tw-bg-gray-200 tw-text-gray-700"
                      )}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="tw-text-sm tw-text-green-500">
              <span className="tw-flex tw-items-center">
                <svg
                  className="tw-w-5 tw-h-5 tw-mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Confirmed
              </span>
              {fee.landlord_confirmation_date && (
                <p className="tw-text-xs tw-mt-1 tw-text-gray-500">
                  {new Date(
                    fee.landlord_confirmation_date
                  ).toLocaleDateString()}
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      {fee.landlord_notes && (
        <div
          className={cn(
            "tw-mt-4 tw-p-3 tw-rounded-md tw-text-sm",
            isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
          )}
        >
          <p
            className={cn(
              "tw-font-medium tw-mb-1",
              isDarkMode ? "tw-text-gray-300" : "tw-text-gray-700"
            )}
          >
            Verification Notes:
          </p>
          <p
            className={cn(isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600")}
          >
            {fee.landlord_notes}
          </p>
        </div>
      )}
    </div>
  );
};
