import http from "../../general/httpService";
import config from "../../../constants/config";

///portfolio/create_or_update_property
export async function createOrUpdateProperty(property) {
  const fullApiEndpoint =
    config.apiEndpoint + `/portfolio/create_or_update_property`;
  const { data: result } = await http.post(fullApiEndpoint, property);

  return result;
}
