import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Modal, Badge, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../reusable/form/Input";
import { getExpenseCategories } from "../../../../../../services/systemDefined/categories/expenses/sysExpenseCategoryManagement";
import { getRevenueCategories } from "../../../../../../services/systemDefined/categories/revenue/sysRevenueCategoryManagement";
import { createOrUpdateUnitExpense } from "../../../../../../services/propertyUnits/revenueAndExpenses/expenses/propertyUnitExpenseManagement";
import { createOrUpdateUnitRevenue } from "../../../../../../services/propertyUnits/revenueAndExpenses/revenue/propertyUnitRevenueManagement";
import { getPropertyUnitBankAccounts } from "../../../../../../services/bank/bankManager";
import { set } from "date-fns";
import { getPropertyDetailByPropertyUnitId } from "../../../../../../services/property/retrieve/propertyRetrievalService";
import { getContractorsByPortfolioId } from "../../../../../../services/contractor/contractorManagement";
import MultiImageUploader from "../../../../../reusable/uploader/multiImageUploader";
import { selectCustomStyle } from "../../../../../../styletheme/custom/selectStyle";
import { useThemeClasses } from "../../../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../../../services/utils/hooks/usePageTitle";
import FilePDFImagePreview from "../../../../../portal/tickets/detail/sub/filePdfImagePreview";

//create the revnue or expense then work on show transactions
const CreateNewUnitTransaction = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Create New Unit Transaction";
  usePageTitle({ title: "Create New Unit Transaction" });
  const navigate = useNavigate();

  // Add this to get URL parameters
  const [searchParams] = useState(new URLSearchParams(window.location.search));
  const transactionType = searchParams.get("type");

  //upload receipt proof
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [base64Data, setBase64Data] = useState([]);

  //formData
  const [showContractorOptions, setShowContractorOptions] = useState(false);

  const [formData, setFormData] = useState({
    is_revenue_or_expense: transactionType || "", // Set initial value from URL parameter
    unit_revenue_expense_id: "",
    property_unit_id: "",
    category_id: "",
    amount: "",
    date: Date(),
    is_active: "",
    is_received_from_tenant: "",
    payment_date: "",
    is_capital_revenue: "",
    title: "",
    description: "",
    receipt_link: "",
    day: 0,
    month: 0,
    year: 0,
    created_at: new Date(),
    updated_at: new Date(),
    //new fields to store
    property_bank_account_id: "",
    bank_account_id: "",
    bank_id: "",
    bank_name: "",
    contractor_id: "",
    contractor_name: "",
  });

  const [bankAccounts, setBankAccounts] = useState([
    {
      property_bank_account_id: "",
      bank_account_id: "",
      property_id: "",
      property_unit_id: "",
      bank_alias_name: "",
      account_balance: 0,
      is_reserve_account: false,
      reserve_amount: 0,
      bank_account: {
        account_id: "",
        account_name: "",
        account_mask: "",
        account_subtype: "",
        account_type: "",
        available_balance: 0,
        bank_account_id: "",
        bank_id: "",
        bank_name: "",
        created_at: "",
        currency_id: "",
        current_balance: 0,
        institution_id: "",
        is_default_account: false,
        logo: "",
        routing_number: "",
        routing_numbers: [""],
        updated_at: "",
        url: "",
      },
      bank: {
        bank_country: "",
        bank_name: "",
        ownership_id: "",
        bank_id: "",
        created_at: "",
        institution_id: "",
        is_default_bank: false,
        logo: "",
        routing_number: "",
        routing_numbers: [""],
        swift_code: "",
        updated_at: "",
        url: "",
      },
    },
  ]);

  const [sysExpenseCategories, setSysExpenseCategories] = useState([
    {
      revenue_category_id: "",
      created_at: "",
      is_active: true,
      revenue_category: "",
      updated_at: "",
    },
  ]);

  const [sysRevenueCategories, setSysRevenueCategories] = useState([
    {
      expense_category_id: "",
      created_at: "",
      expense_category: "",
      is_active: true,
      updated_at: "",
    },
  ]);

  const [propertyPortfolioUnit, setPropertyPortfolioUnit] = useState({
    property_unit: {
      property_unit_id: "",
      property_id: "",
      unit_name: "",
      number_of_bedrooms: "",
      number_of_bathrooms: "",
      square_footage: "",
      market_rent_amount: "",
    },
    property: {
      city_town: "",
      country: "",
      country_id: "",
      portfolio_id: "",
      property_id: "",
      state_parish: "",
      street_address_1: "",
      zip_postal_code: "",
      currency_id: "",
      property_alias_name: "",
      property_type_id: "",
      street_address_2: "",
      sub_property_type_id: "",
    },
    portfolio: {
      ownership_id: "",
      portfolio_id: "",
      portfolio_name: "",
    },
  });

  const [contractors, setContractors] = useState([
    {
      contractor_id: "",
      portfolio_id: "",
      property_id: "",
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: "",
      company_name: "",
      company_address: "",
      company_address_2: "",
      company_city: "",
      company_state: "",
      company_zip: "",
      company_country: "",
      company_logo: "",
      company_website: "",
      company_phone: "",
      company_email: "",
      company_description: "",
      company_license: "",
      company_insurance: "",
      company_insurance_expiration: new Date(),
      company_first_licensure_date: new Date(),
      company_license_expiration: new Date(),
      company_license_status: "", //nt used
      company_tax_rate: 0,
      company_tax_type: "", //not used
      payment_rate_type: "", //not used
      payment_rate_other: "", //not used
      payment_rate: 0, //not used need to use the payment rate type
      payment_rate_frequency: "",
      company_tax_id: "",
      payment_rate_method: "",
      created_at: new Date(),
      updated_at: new Date(),
      is_active: true,
      is_verified: false,
      is_deleted: false,
      is_suspended: false,
      is_paused: false,
      job_count: 0,
      job_count_completed: 0,
      company_license_state: "",
    },
  ]);

  const [contractorOptions, setContractorOptions] = useState([
    {
      value: "",
      label: "",
      data: {},
    },
  ]);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const [revenueOrExpenseData, setRevenueOrExpenseData] = useState([
    { value: "revenue", label: "Revenue" },
    { value: "expense", label: "Expense" },
  ]);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [itemToPreview, setItemToPreview] = useState({
    file_name: "",
    file_size: 0,
    file_size_formatted: "",
    file_type: "",
    url: "",
  });
  const [attachmentsConfirmed, setAttachmentsConfirmed] = useState(false);
  const handleClosePreviewModal = () => setShowPreviewModal(false);

  // Add these state declarations near the top with other states
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const [isBankAccountsLoading, setIsBankAccountsLoading] = useState(true);

  //schema
  const schema = {
    is_revenue_or_expense: Joi.string()
      .optional()
      .allow("")
      .label("Revenue or Expense"),
    unit_revenue_expense_id: Joi.string()
      .optional()
      .allow("")
      .label("Revenue or Expense ID"),
    property_unit_id: Joi.string()
      .optional()
      .allow("")
      .label("Property Unit ID"),
    category_id: Joi.string().optional().allow("").label("Category ID"), //need to add the dropdownlist
    amount: Joi.number().required().label("Amount"),
    date: Joi.date().required().label("Date"),
    is_active: Joi.string().optional().allow("").label("Active"),
    is_received_from_tenant: Joi.string()
      .optional()
      .allow("")
      .label("Received from Tenant"),
    payment_date: Joi.string().optional().allow("").label("Payment Date"),
    is_capital_revenue: Joi.string()
      .optional()
      .allow("")
      .label("Capital Revenue"),
    title: Joi.string().required().label("Title"),
    description: Joi.string().optional().allow("").label("Description"),
    receipt_link: Joi.string().optional().allow("").label("Receipt Link"),
    day: Joi.number().required().label("Day"),
    month: Joi.number().required().label("Month"),
    year: Joi.number().required().label("Year"),
    created_at: Joi.date().optional().label("Created At"),
    updated_at: Joi.date().optional().label("Updated At"),
    //new fields to store
    property_bank_account_id: Joi.string().required().label("Bank Account"),
    bank_account_id: Joi.string().optional().allow("").label("Bank Account ID"),
    bank_id: Joi.string().optional().allow("").label("Bank ID"),
    bank_name: Joi.string().optional().allow("").label("Bank Name"),
    contractor_id: Joi.string().optional().allow("").label("Contractor ID"),
    contractor_name: Joi.string().optional().allow("").label("Contractor Name"),
    //new
    transaction_receipt_image_uploads: Joi.array()
      .optional()
      .label("Transaction Receipt Image Uploads"),
  };
  const [errors, setErrors] = useState({});

  //validate the form
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(formData, schema, options);
    const errors = {};

    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    // Only validate attachments if files are selected but not confirmed
    if (selectedFiles.length > 0 && !attachmentsConfirmed) {
      errors.attachments = "Please confirm your attachments before submitting";
    }

    console.log("Validation state:", {
      hasErrors: Object.keys(errors).length > 0,
      selectedFiles: selectedFiles.length,
      attachmentsConfirmed,
      errors,
    });

    // Return null if no errors and attachments are either confirmed or not required
    if (Object.keys(errors).length === 0) {
      if (selectedFiles.length === 0 || attachmentsConfirmed) {
        console.log("Validation passed - returning null");
        return null;
      }
    }

    console.log("Validation failed - returning errors:", errors);
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    const { error } = Joi.validate(obj, schemaLocal, options);

    return error ? error.details[0].message : null;
  };

  // Function to check if a year is a leap year
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  // Function to get the number of days in a given month and year
  const getDaysInMonth = (month, year) => {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
      case 2:
        return isLeapYear(year) ? 29 : 28;
      default:
        return 0; // Invalid month
    }
  };

  // Validate and print the day, month, year
  const validateDate = (day, month, year) => {
    const daysInMonth = getDaysInMonth(month, year);

    if (day < 1 || day > daysInMonth) {
      console.error(
        `Invalid day: ${day} for month: ${month} and year: ${year}`
      );
      return false;
    } else {
      console.log(`Day: ${day}`);
    }

    if (month < 1 || month > 12) {
      console.error(`Invalid month: ${month}`);
      return false;
    } else {
      console.log(`Month: ${month}`);
    }

    if (year < 1900 || year > 2100) {
      console.error(`Invalid year: ${year}`);
      return false;
    } else {
      console.log(`Year: ${year}`);
    }

    return true;
  };

  //handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newErrors = validate();
      setErrors(newErrors || {});
      if (newErrors) {
        setLoading(false);
        return;
      }

      //submit the formData
      await doSubmit();
    } catch (error) {
      console.error("Error creating transaction:", error);
      toast.error("Failed to create transaction. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const doSubmit = async () => {
    //call the server to save the changes

    console.log("submitting the form data");
    console.log(formData.is_revenue_or_expense);
    console.log(formData);

    // Parse the date field to extract day, month, and year
    const [year, month, day] = formData.date.split("-").map(Number);

    // Validate the date
    if (!validateDate(day, month, year)) {
      toast.error("Invalid date selected");
      return;
    }

    // Update formData with parsed day, month, and year
    formData.day = day;
    formData.month = month;
    formData.year = year;

    //if is an expense
    if (formData.is_revenue_or_expense === "expense") {
      //put the in own function

      //Set the unitExpenseModel
      const unitExpenseModel = {
        unit_expense_id: uuidv4(),
        property_unit_id: propertyUnitId,
        expense_category_id: formData.category_id,
        expense_amount: formData.amount,
        expense_date: formData.date,
        is_active: true,
        is_paid_by_tenant: false,
        is_paid_by_landlord: true,
        payment_date: formData.date,
        is_capital_expense: false,
        expense_title: formData.title,
        expense_description: formData.description,
        receipt_link: "", //to do to upload the receipt image
        expense_day: formData.day,
        expense_month: formData.month,
        expense_year: formData.year,
        created_at: new Date(),
        updated_at: new Date(),
        property_bank_account_id: formData.property_bank_account_id,
        bank_account_id: formData.bank_account_id,
        bank_id: formData.bank_id,
        bank_name: formData.bank_name,
        //optional
        contractor_id: formData.contractor_id,
        contractor_name: formData.contractor_name,
        //new fields
        transaction_receipt_image_uploads: imagesToUpload,
      };

      console.log("unitExpenseModel");
      console.log(unitExpenseModel);
      //call the createOrUpdateUnitExpense
      const result = await createOrUpdateUnitExpense(unitExpenseModel);

      if (result) {
        if (result.error) {
          toast.error(result.error);
          return;
        }

        toast.success("Expense created successfully");
        // Redirect to the unit transactions
        navigate(`/unitTransactions/${propertyUnitId}`);
      } else {
        toast.error("Error creating the expense");
      }
    } else if (formData.is_revenue_or_expense === "revenue") {
      // Set the unitRevenueModel
      const unitRevenueModel = {
        unit_revenue_id: uuidv4(),
        property_unit_id: propertyUnitId,
        revenue_category_id: formData.category_id,
        revenue_amount: formData.amount,
        revenue_date: formData.date,
        is_active: true,
        is_received_from_tenant: false,
        payment_date: formData.date,
        is_capital_revenue: false,
        revenue_title: formData.title,
        revenue_description: formData.description,
        receipt_link: "", // to do to upload the receipt image
        revenue_day: formData.day,
        revenue_month: formData.month,
        revenue_year: formData.year,
        created_at: new Date(),
        updated_at: new Date(),
        property_bank_account_id: formData.property_bank_account_id,
        bank_account_id: formData.bank_account_id,
        bank_id: formData.bank_id,
        bank_name: formData.bank_name,
        // optional
        contractor_id: formData.contractor_id,
        contractor_name: formData.contractor_name,
        //new fields
        transaction_receipt_image_uploads: imagesToUpload,
      };

      console.log("unitRevenueModel");
      console.log(unitRevenueModel);

      // Call the createOrUpdateUnitRevenue function
      const result = await createOrUpdateUnitRevenue(unitRevenueModel);

      if (result) {
        if (result.error) {
          toast.error(result.error);
          return;
        }
        toast.success("Revenue created successfully");

        // Redirect to the unit transactions
        navigate(`/unitTransactions/${propertyUnitId}`);
      } else {
        toast.error("Error creating the revenue");
      }
    }
  };
  //handle the form input changes
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else {
      // If not, assume it's a date from DatePicker
      name = "date";
      value = input;
    }
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...formData };
    if (value !== undefined) {
      data[name] = value;
    }

    if (name === "date") {
      const dateValue = new Date(value);
      data.day = dateValue.getDate() + 1;
      data.month = dateValue.getMonth() + 1; // JavaScript months are 0-indexed
      data.year = dateValue.getFullYear();
    }

    if (name === "category_id") {
      //if the label contains or is contractor (ignore case) then show the contractor options
      //search the
      categories.forEach((category) => {
        if (category.value === value) {
          if (category.label.toLowerCase().includes("contractor")) {
            setShowContractorOptions(true);
          } else {
            setShowContractorOptions(false);
          }
        }
      });
    }
    setFormData(data);
    setErrors(currentErrors);
  };
  //handle the select changes

  const handleSelectChange = (selectedOption) => {
    console.log("handleSelectChange", selectedOption);
    const value = selectedOption.value;

    // Only proceed with resetting if the value is actually different
    if (value !== formData.is_revenue_or_expense) {
      // Update URL without reloading the page
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set("type", value);
      window.history.pushState({}, "", newUrl);

      // Reset category_id and contractor options when changing transaction type
      setFormData((prevState) => ({
        ...prevState,
        is_revenue_or_expense: value,
        category_id: null, // Reset category selection only when type changes
      }));

      // Reset contractor options since they're tied to categories
      setShowContractorOptions(false);
    } else {
      // If it's the same type, just update the URL without resetting anything
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set("type", value);
      window.history.pushState({}, "", newUrl);
    }
  };

  // Add this effect to handle category changes when revenue/expense type changes
  useEffect(() => {
    if (
      formData.is_revenue_or_expense &&
      (sysExpenseCategories.length > 0 || sysRevenueCategories.length > 0)
    ) {
      setCategoryDropDown();
    }
  }, [
    formData.is_revenue_or_expense,
    sysExpenseCategories,
    sysRevenueCategories,
  ]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        // Fetch all necessary data
        await Promise.all([
          fetchExpenseCategories(),
          fetchRevenueCategories(),
          fetchBankAccounts(),
          getPropertyPortfolioByUnitId(),
        ]);

        // If we have a transaction type from URL, set it after data is loaded
        if (transactionType) {
          const formattedType = transactionType.toLowerCase();
          if (formattedType === "revenue" || formattedType === "expense") {
            setFormData((prev) => ({
              ...prev,
              is_revenue_or_expense: formattedType,
            }));
          }
        }
      } catch (error) {
        console.error("Error initializing data:", error);
        toast.error("Error loading form data. Please try again.");
      }
    };

    window.scrollTo(0, 0);
    initializeData();
  }, []);

  // Update the fetchExpenseCategories function
  const fetchExpenseCategories = async () => {
    try {
      setIsCategoriesLoading(true);
      const sysExpenseCategories = await getExpenseCategories();
      if (sysExpenseCategories) {
        setSysExpenseCategories(sysExpenseCategories);
      }
      return sysExpenseCategories;
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  // Update the fetchRevenueCategories function
  const fetchRevenueCategories = async () => {
    try {
      setIsCategoriesLoading(true);
      const sysRevenueCategories = await getRevenueCategories();
      if (sysRevenueCategories) {
        setSysRevenueCategories(sysRevenueCategories);
      }
      return sysRevenueCategories;
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  const setCategoryDropDown = () => {
    if (formData.is_revenue_or_expense === "expense") {
      console.log("Expense");
      //map the sysExpenseCategories to categories
      const categories = sysExpenseCategories.map((category) => {
        return {
          value: category.expense_category_id,
          label: category.expense_category,
        };
      });

      setCategories(categories);
    } else if (formData.is_revenue_or_expense === "revenue") {
      console.log("Revenue");
      //map the sysRevenueCategories to categories
      const categories = sysRevenueCategories.map((category) => {
        return {
          value: category.revenue_category_id,
          label: category.revenue_category,
        };
      });

      setCategories(categories);
    }
  };

  const [bankAccountOptions, setBankAccountOptions] = useState([
    {
      value: "",
      label: "",
      data: {},
    },
  ]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    value: "",
    label: "",
    data: {},
  });
  // Update the fetchBankAccounts function
  const fetchBankAccounts = async () => {
    try {
      setIsBankAccountsLoading(true);
      const bankAccounts = await getPropertyUnitBankAccounts(propertyUnitId);

      if (bankAccounts) {
        setBankAccounts(bankAccounts);

        if (bankAccounts.length > 0) {
          const bankAccountOptions = bankAccounts?.map((bankAccount) => ({
            value: bankAccount.property_bank_account_id,
            label:
              bankAccount.bank_account.account_name +
              " - " +
              bankAccount.bank_account.bank_name,
            data: bankAccount,
          }));

          setBankAccountOptions(bankAccountOptions);
        }
      }
    } finally {
      setIsBankAccountsLoading(false);
    }
  };

  const handleBankAccountChange = (selectedOption) => {
    console.log("handleBankAccountChange");
    console.log(selectedOption);
    setSelectedBankAccount(selectedOption);

    if (selectedOption?.__isNew__) {
      toast.info("Creating New Bank Account, refresh the page when done.");
      window.open(
        `/createPropertyUnitBankAccounts/${propertyUnitId}`,
        "_blank"
      );
      return;
    }

    // Safely access nested properties
    const bankData = selectedOption?.data?.bank_account?.bank || {};

    setFormData((prevState) => ({
      ...prevState,
      property_bank_account_id: selectedOption?.value || "",
      bank_account_id: selectedOption?.data?.bank_account_id || "",
      bank_id: bankData.bank_id || "",
      bank_name: bankData.bank_name || "",
    }));
  };

  //propertyPortfolioUnit
  const getPropertyPortfolioByUnitId = async () => {
    const propertyPortfolioUnit = await getPropertyDetailByPropertyUnitId(
      propertyUnitId
    );

    console.log("propertyPortfolioUnit");
    console.log(propertyPortfolioUnit);
    if (propertyPortfolioUnit) {
      setPropertyPortfolioUnit(propertyPortfolioUnit);

      //find the contractors for the portfolioId
      fetchContractorsByPortfolioId(
        propertyPortfolioUnit.portfolio.portfolio_id
      );
    }
  };

  const fetchContractorsByPortfolioId = async (_portfolioId) => {
    //get the contractors for the portfolioId
    const contractors = await getContractorsByPortfolioId(_portfolioId);

    console.log("contractors");
    console.log(contractors);

    if (contractors) {
      setContractors(contractors);

      //map the contractors to the options
      const contractorOptions = contractors.map((contractor) => {
        return {
          value: contractor.contractor_id,
          label:
            contractor.first_name +
            " " +
            contractor.last_name +
            " - " +
            contractor.company_name,
          data: contractor,
        };
      });

      setContractorOptions(contractorOptions);
    }
  };

  const handleContractorChange = (selectedOption) => {
    console.log("handleContractorChange");
    console.log(selectedOption);

    //set the contractor_id
    setFormData((prevState) => ({
      ...prevState,
      contractor_id: selectedOption?.value,
      contractor_name:
        selectedOption?.data?.first_name +
        " " +
        selectedOption?.data?.last_name,
    }));

    //if new
    if (selectedOption.__isNew__) {
      toast.info("Creating New Contractor, refresh the page when done.");
      //create a new contractor in a new tab
      console.log("New Contractor");
      console.log(selectedOption);

      //navigate to the create contractor page on a new tab
      window.open(`/createContractor`, "_blank");
    }
  };

  // Add this function to handle attachment preview
  const handlePreviewAttachment = (attachment) => {
    setItemToPreview(attachment);
    setShowPreviewModal(true);
  };

  // Modify the handleSelectionConfirm function
  const handleSelectionConfirm = async () => {
    console.log("handleSelectionConfirm called");
    console.log("Current state - selectedFiles:", selectedFiles);
    console.log("Current state - attachmentsConfirmed:", attachmentsConfirmed);

    if (selectedFiles.length > 0) {
      console.log("Setting attachmentsConfirmed to true");

      // First update imagesToUpload with the base64 data
      const uploads = base64Data.map((item, index) => ({
        base64_data: item.data,
        file_name: selectedFiles[index].name,
      }));

      // Then set the states in order
      await setImagesToUpload(uploads);
      await setAttachmentsConfirmed(true);

      console.log("State after update - attachmentsConfirmed:", true);
      console.log("State after update - imagesToUpload:", uploads);
    }
  };

  // Add an effect to log state changes
  useEffect(() => {
    console.log("attachmentsConfirmed changed:", attachmentsConfirmed);
  }, [attachmentsConfirmed]);

  return (
    <React.Fragment>
      {/* Add the preview modal */}
      <Modal
        show={showPreviewModal}
        onHide={handleClosePreviewModal}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <FilePDFImagePreview
            itemToPreview={itemToPreview}
            showDeleteBtn={false}
            onClose={handleClosePreviewModal}
          />
        </Modal.Body>
      </Modal>

      <div className={`${getThemeClasses()}`}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Property Unit Transactions"
            previousPageLink={`/unitTransactions/${propertyUnitId}`}
          />

          {/* Add bank account warning message */}
          {(!bankAccounts || bankAccounts.length === 0) && (
            <div className="alert alert-warning d-flex align-items-center justify-content-between mb-4">
              <div>
                <i className="ri-error-warning-line me-2"></i>
                Please link a bank account to this unit, then refresh the page.
              </div>
              <a
                className="btn btn-warning btn-sm"
                href={`/propertyUnitBankAccounts/${propertyUnitId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-bank-line me-1"></i>
                Link Bank Account
              </a>
            </div>
          )}

          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    New Transaction
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="title"
                              label="Transaction Title"
                              type={"text"}
                              value={formData.title}
                              onChange={handleChange}
                              error={errors.title}
                            />
                          </div>
                        </div>
                        {/* Bank Account*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label>Bank Account</label>
                            <CreatableSelect
                              name="bank_id"
                              options={bankAccountOptions}
                              value={selectedBankAccount}
                              onChange={handleBankAccountChange}
                              placeholder={
                                isBankAccountsLoading
                                  ? "Loading bank accounts..."
                                  : "Select Bank Account"
                              }
                              isLoading={isBankAccountsLoading}
                              isDisabled={isBankAccountsLoading}
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>

                        {/* is Revenue or Expense*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label>Revenue or Expense</label>
                            <Select
                              name="is_revenue_or_expense"
                              options={revenueOrExpenseData}
                              value={revenueOrExpenseData.find(
                                (option) =>
                                  option.value ===
                                  formData.is_revenue_or_expense
                              )}
                              onChange={handleSelectChange}
                              placeholder="Select Revenue or Expense"
                              styles={selectCustomStyle(isDarkMode)}
                            />
                          </div>
                        </div>
                        {/* TODO: show the revenue or expense catergory option beased on is_revenue_or_expense*/}
                        {formData.is_revenue_or_expense && (
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <label>Category</label>
                              <Select
                                name="category_id"
                                options={categories}
                                value={
                                  categories.find(
                                    (option) =>
                                      option.value === formData.category_id
                                  ) || null
                                }
                                onChange={(selectedOption) =>
                                  handleChange({
                                    currentTarget: {
                                      name: "category_id",
                                      value: selectedOption
                                        ? selectedOption.value
                                        : null,
                                    },
                                  })
                                }
                                placeholder={
                                  isCategoriesLoading
                                    ? "Loading categories..."
                                    : "Select Category"
                                }
                                isLoading={isCategoriesLoading}
                                isDisabled={isCategoriesLoading}
                                styles={selectCustomStyle(isDarkMode)}
                                isClearable={true}
                              />
                            </div>
                          </div>
                        )}

                        {showContractorOptions && (
                          <>
                            <div className="col-xxl-3 col-md-6">
                              <div>
                                <label>Contractor</label>
                                <CreatableSelect
                                  name="contractor_id"
                                  options={contractorOptions}
                                  value={contractorOptions.find(
                                    (option) =>
                                      option.value === formData.contractor_id
                                  )} // find the corresponding object in the options array
                                  onChange={(selectedOption) =>
                                    handleContractorChange(selectedOption)
                                  }
                                  placeholder="Select Contractor"
                                  styles={selectCustomStyle(isDarkMode)}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="amount"
                              label="Amount"
                              type={"number"}
                              value={formData.amount}
                              onChange={handleChange}
                              error={errors.amount}
                            />
                          </div>
                        </div>
                        {/* revenue_date*/}

                        <div className="col-xxl-3 col-md-6 ">
                          <div>
                            {/*
                            <Input
                              name='date'
                              label='Date'
                              type={'date'}
                              value={formData.date}
                              onChange={handleChange} // <-- handleChange will be called with an event object
                              error={errors.date}
                            />*/}

                            <Input
                              name="date"
                              className="form-control"
                              type="date"
                              label={" Select Transaction Date"}
                              selected={formData.date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {/* description */}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="description"
                              label="Description"
                              type={"text"}
                              value={formData.description}
                              onChange={handleChange}
                              error={errors.description}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row gy-3 pt-4">
                        <div className="col-xxl-3 col-md-6">
                          <label htmlFor="form-grid-category">
                            Transaction Receipt(s)
                          </label>
                          <MultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload || []}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText="Confirm Selection"
                            onConfirm={handleSelectionConfirm}
                          />
                          {attachmentsConfirmed &&
                            imagesToUpload?.length > 0 && (
                              <div className="mt-3">
                                <div className="d-flex flex-wrap gap-2">
                                  {(imagesToUpload || []).map((file, index) => (
                                    <Badge
                                      key={index}
                                      bg="success"
                                      className="d-flex align-items-center gap-2 p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handlePreviewAttachment(file)
                                      }
                                    >
                                      <i className="ri-file-line"></i>
                                      {file.file_name.length > 20
                                        ? `${file.file_name.substring(
                                            0,
                                            20
                                          )}...`
                                        : file.file_name}
                                    </Badge>
                                  ))}
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => {
                                      setAttachmentsConfirmed(false);
                                      setImagesToUpload([]);
                                    }}
                                  >
                                    <i className="ri-refresh-line me-1"></i>
                                    Change Selection
                                  </Button>
                                </div>
                              </div>
                            )}
                          {!attachmentsConfirmed &&
                            selectedFiles.length > 0 && (
                              <div className="text-danger mt-1">
                                <small>
                                  Please confirm your selection using the
                                  "Confirm Selection" button above
                                </small>
                              </div>
                            )}
                        </div>
                      </div>

                      {/* Add to the list*/}
                      <div className="row gy-4 mt-3">
                        <div className="col-12 d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() =>
                              navigate(`/unitTransactions/${propertyUnitId}`)
                            }
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className={`btn ${
                              loading ? "btn-dark" : "btn-success"
                            }`}
                            disabled={validate() || loading}
                          >
                            {loading ? (
                              <>
                                <i className="ri-loader-4-line ri-spin me-2"></i>
                                Creating Transaction...
                              </>
                            ) : (
                              "Add Transaction"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewUnitTransaction;
