import React, { useState, useEffect } from "react";
import { AiChatWindow } from "./AiChatWindow";
import { AiChatButton } from "./AiChatButton";
import { LoadingSpinner } from "./LoadingSpinner";
import { Message, ChatHistory } from "../../../models/ai-chat/Message";
import { AiApiService } from "../../../services/ai/AiApiService";
import { AiContextTrackingService } from "../../../services/ai/AiContextTrackingService";
import { useAiChat } from "../../../context/AiChatContext";
import { v4 as uuidv4 } from "uuid";
import { AiChatStateService } from "../../../services/ai/AiChatStateService";
import { AiChatHistoryService } from "../../../services/ai/AiChatHistoryService";
import config from "../../../constants/config";

interface AiChatProps {
  isDarkMode: boolean;
}

export const AiChat: React.FC<AiChatProps> = ({ isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const aiService = AiApiService.getInstance();
  const contextService = AiContextTrackingService.getInstance();
  const {
    messages,
    updateMessages,
    addMessage,
    replaceMessage,
    setActiveSessionId,
    clearMessages,
  } = useAiChat();
  const chatStateService = AiChatStateService.getInstance();
  const chatHistoryService = AiChatHistoryService.getInstance();
  const [chatSessions, setChatSessions] = useState<ChatHistory[]>([]);
  const [activeSessionId, setLocalActiveSessionId] = useState<string | null>(
    null
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Separate useEffect for loading history to avoid dependency issues
  useEffect(() => {
    // Load chat history when component mounts or when feature is enabled
    const loadHistory = async () => {
      try {
        setIsLoading(true);
        // Get all sessions first
        const sessions = await chatStateService.getAllSessions();
        setChatSessions(sessions);

        if (sessions.length > 0) {
          // Use the most recent session
          const mostRecentSession = sessions[0];
          setLocalActiveSessionId(mostRecentSession.session_id);
          aiService.setSessionId(mostRecentSession.session_id);
          updateMessages(mostRecentSession.messages);
        } else {
          // If no sessions exist, create a new one
          await handleNewChat();
        }
      } catch (error) {
        console.error("Error loading chat history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadHistory();
  }, [updateMessages]);

  const toggleChat = () => {
    setIsOpen(!isOpen);

    // When opening chat, add it as a user activity
    if (!isOpen) {
      contextService.addUserActivity({
        currentAction: {
          type: "OPEN_AI_CHAT",
          data: {
            currentRoute: window.location.pathname,
            timestamp: new Date(),
          },
          timestamp: new Date(),
        },
      });
    }
  };

  const handleNewChat = async () => {
    try {
      // Create new chat session using the state service
      const newSessionId = await chatStateService.createNewChat();

      // Update active session IDs
      setLocalActiveSessionId(newSessionId);
      setActiveSessionId(newSessionId);
      aiService.setSessionId(newSessionId);

      // Clear existing messages immediately
      updateMessages([]);

      // Create new session object and add it to the list immediately
      const newSession: ChatHistory = {
        session_id: newSessionId,
        user_id: localStorage.getItem(config.user_id) || "",
        messages: [],
        lastUpdated: new Date().toISOString(),
        isActive: true,
      };

      // Add new session to the top of the list and mark others as inactive
      setChatSessions((prevSessions) => [
        newSession,
        ...prevSessions.map((session) => ({
          ...session,
          isActive: false,
        })),
      ]);

      // Close mobile sidebar if open
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }

      // Optionally reload sessions in the background to ensure sync
      const sessions = await chatStateService.getAllSessions();
      if (Array.isArray(sessions)) {
        const sortedSessions = [...sessions]
          .sort((a, b) => {
            const dateA = new Date(a.lastUpdated).getTime();
            const dateB = new Date(b.lastUpdated).getTime();
            return dateB - dateA;
          })
          .map((session) => ({
            ...session,
            isActive: session.session_id === newSessionId,
          }));
        setChatSessions(sortedSessions);
      }
    } catch (error) {
      console.error("Error creating new chat:", error);
      // Show error in UI but don't block interaction
      updateMessages([
        {
          id: uuidv4(),
          type: "ai",
          content:
            "Failed to create chat session. You can still type your message and try again.",
          timestamp: new Date().toISOString(),
          status: "error",
        },
      ]);
    }
  };

  const handleNewMessage = async (content: string) => {
    try {
      // Add loading message immediately to UI for better UX
      const loadingMessage: Message = {
        id: uuidv4(),
        type: "ai",
        content: "Thinking...",
        timestamp: new Date().toISOString(),
        status: "loading",
      };

      // Create temporary messages array with user message and loading state
      const userMessage: Message = {
        id: uuidv4(),
        type: "user",
        content,
        timestamp: new Date().toISOString(),
        status: "sent",
      };

      const tempMessages = [...messages, userMessage, loadingMessage];
      updateMessages(tempMessages);

      // Send message and get response
      const chatHistory = await chatHistoryService.sendMessageToServer(
        activeSessionId || "",
        userMessage
      );

      // After a brief delay, update with the server state
      setTimeout(() => {
        updateMessages(chatHistory.messages);
      }, 300);

      // Track the interaction in context
      const aiResponseMessage =
        chatHistory.messages[chatHistory.messages.length - 1];
      contextService.addUserActivity({
        currentAction: {
          type: "SEND_MESSAGE",
          data: {
            messageId: aiResponseMessage.id,
            context: contextService.getCurrentContext(),
          },
          timestamp: new Date(),
        },
      });
    } catch (error) {
      console.error("Error handling new message:", error);
      // Show error in UI
      const errorMessage: Message = {
        id: uuidv4(),
        type: "ai",
        content: "Sorry, I encountered an error. Please try again.",
        timestamp: new Date().toISOString(),
        status: "error",
      };
      updateMessages([...messages, errorMessage]);
    }
  };

  const handleSessionSelect = async (sessionId: string) => {
    try {
      setLocalActiveSessionId(sessionId);
      setActiveSessionId(sessionId);

      // Update UI immediately
      updateMessages([]);

      // Set up connection
      await chatStateService.setActiveSession(sessionId);
      aiService.setSessionId(sessionId);

      // Load messages for this session
      const sessionMessages = await chatStateService.getMessages();
      updateMessages(sessionMessages || []);

      // Update chat sessions to reflect the current selection
      setChatSessions((prevSessions) =>
        prevSessions.map((session) => ({
          ...session,
          isActive: session.session_id === sessionId,
        }))
      );
    } catch (error) {
      console.error("Error selecting session:", error);
      // Show error in UI but don't block interaction
      updateMessages([
        {
          id: uuidv4(),
          type: "ai",
          content:
            "Failed to load chat history. You can still type your message and try again.",
          timestamp: new Date().toISOString(),
          status: "error",
        },
      ]);
    }
  };

  // Normal render for enabled feature
  return (
    <>
      <div className="tw-fixed tw-bottom-4 tw-right-4 tw-z-50">
        {isOpen && (
          <div
            className={`${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-rounded-lg tw-shadow-lg`}
          >
            {isLoading ? (
              <div className="tw-w-[400px] tw-h-[600px] tw-flex tw-items-center tw-justify-center">
                <LoadingSpinner isDarkMode={isDarkMode} size="large" />
              </div>
            ) : (
              <AiChatWindow
                onClose={toggleChat}
                messages={messages}
                onSendMessage={handleNewMessage}
                isDarkMode={isDarkMode}
                onNewChat={handleNewChat}
                chatSessions={chatSessions}
                activeSessionId={activeSessionId}
                onSessionSelect={handleSessionSelect}
              />
            )}
          </div>
        )}
        <AiChatButton
          onClick={toggleChat}
          isOpen={isOpen}
          isDarkMode={isDarkMode}
        />
      </div>
    </>
  );
};
