import http from "../../services/general/httpService";
import config from "../../constants/config";

///universal_list/create_universal_list
export async function createUniversalList(
  universal_list,
  universal_list_items
) {
  const fullApiEndpoint =
    config.apiEndpoint + `/universal_list/create_universal_list`;
  const { data: result } = await http.post(fullApiEndpoint, {
    universal_list: universal_list,
    universal_list_items: universal_list_items,
  });

  return result;
}

export async function getUniversalList(universal_list_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/universal_list/get_universal_list_items/${universal_list_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

///update_universal_list_items/{universal_list_id}
export async function updateUniversalListItems(
  universal_list_id,
  universal_list_items
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/universal_list/update_universal_list_items/${universal_list_id}`;
  const { data: result } = await http.put(fullApiEndpoint, {
    universal_list_items: universal_list_items,
  });

  return result;
}

///delete_universal_list_and_items/{universal_list_id}
export async function deleteUniversalListAndItems(universal_list_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/universal_list/delete_universal_list_and_items/${universal_list_id}`;
  const { data: result } = await http.delete(fullApiEndpoint);

  return result;
}

///get_universal_list_and_items_by_campaign_id/{campaign_id}
export async function getUniversalListAndItemsByCampaignId(campaign_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/universal_list/get_universal_list_and_items_by_campaign_id/${campaign_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

///get_universal_list_and_items_by_user_id/{user_id}
export async function getUniversalListAndItemsByUserId(user_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/universal_list/get_universal_list_and_items_by_user_id/${user_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
