import React, { useEffect, useState, ChangeEvent } from "react";
import { toast } from "react-toastify";
// Remove unused import
// import { fetchAllPropertiesById } from "../../../services/property/retrieve/propertyRetrievalService";
import Dropdown from "react-bootstrap/Dropdown";
//import img_2 from "../../../styletheme/assets/img/photos/2.jpg";
import user_img from "../../../styletheme/images/user-icon/avatar.png";
import loader from "../../../styletheme/images/loading/loader.svg";
import { Link } from "react-router-dom";
import HeaderLabelBreadCrumb from "../../reusable/label/headerLabelBreadCrumb";
import { getPropertiesOwnedByUserId } from "../../../services/portfolio/ownership/portfolioOwnershipManagement";
import defaultHouseImg from "../../../styletheme/images/buildings/house.png";
import LoaderView from "../../reusable/loading/loaderView";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../services/utils/hooks/usePageTitle";
import PropertyCard from "./reusable/PropertyCard";
import NoPropertiesFound from "./reusable/NoPropertiesFound";

// Define types
interface PropertyImage {
  base64_data: string;
  file_name: string;
  unique_file_name: string;
  file_size: number;
  file_size_formatted: string;
  file_type: string;
  upload_time_stamp: number;
  url: string;
}

interface Property {
  city_town: string;
  country: string;
  country_id: string;
  portfolio_id: string;
  property_id: string;
  state_parish: string;
  street_address_1: string;
  zip_postal_code: string;
  currency_id: string;
  property_alias_name: string;
  property_type_id: string;
  street_address_2: string;
  sub_property_type_id: string;
  property_image_urls: PropertyImage[];
}

interface Portfolio {
  ownership_id: string;
  portfolio_id: string;
  portfolio_name: string;
}

interface OwnershipStructure {
  ownership_structure_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  owner_email: string;
  owner_phone: string;
  portfolio_id: string;
  ownership_percentage: string;
  ownership_type: string | null;
  is_controlling_partner: boolean;
  partnership_type: string;
  ownership_start_date: string;
  ownership_end_date: string | null;
  created_at: string | null;
}

interface PropertyWithOwnership {
  property: Property;
  portfolio: Portfolio;
  ownership_structures: OwnershipStructure[];
}

interface MyPropertiesProps {
  isDarkMode: boolean;
}

const MyProperties: React.FC<MyPropertiesProps> = ({ isDarkMode }) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  usePageTitle({ title: "My Properties" });

  // State declarations
  const [headerTitleDetail] = useState({
    title: "My Properties",
    subHeadline: "showing all your properties private and publicly available",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [portfolios, setPortfolios] = useState<string[]>([]);
  const [properties, setProperties] = useState<PropertyWithOwnership[]>([
    {
      property: {
        city_town: "",
        country: "",
        country_id: "",
        portfolio_id: "",
        property_id: "",
        state_parish: "",
        street_address_1: "",
        zip_postal_code: "",
        currency_id: "",
        property_alias_name: "",
        property_type_id: "",
        street_address_2: "",
        sub_property_type_id: "",
        property_image_urls: [
          {
            base64_data: "",
            file_name: "",
            unique_file_name: "",
            file_size: 0,
            file_size_formatted: "",
            file_type: "",
            upload_time_stamp: Date.now(),
            url: "",
          },
        ],
      },
      portfolio: {
        ownership_id: "",
        portfolio_id: "",
        portfolio_name: "",
      },
      ownership_structures: [
        {
          ownership_structure_id: "",
          user_id: "",
          first_name: "",
          last_name: "",
          owner_email: "",
          owner_phone: "",
          portfolio_id: "",
          ownership_percentage: "",
          ownership_type: null,
          is_controlling_partner: false,
          partnership_type: "",
          ownership_start_date: "",
          ownership_end_date: null,
          created_at: null,
        },
      ],
    },
  ]);
  const [filteredProperties, setFilteredProperties] = useState<
    PropertyWithOwnership[]
  >([]);

  // Event handlers
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page after search
  };

  const handlePortfolioChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPortfolio(e.target.value);
    setCurrentPage(1); // Reset to first page after filter change
  };

  // Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllMyProperties();
  }, []);

  // Extract unique portfolios from properties
  useEffect(() => {
    if (properties.length > 0) {
      const uniquePortfolios = Array.from(
        new Set(properties.map((item) => item.portfolio.portfolio_name))
      ).filter(Boolean) as string[];
      setPortfolios(uniquePortfolios);
    }
  }, [properties]);

  // Filter properties when search query or portfolio filter changes
  useEffect(() => {
    const results = properties.filter((item) => {
      // Portfolio filter
      const portfolioFilter = selectedPortfolio
        ? item.portfolio.portfolio_name === selectedPortfolio
        : true;

      // Search filter
      const nameMatch = item.property.property_alias_name
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const addressMatch = item.property.street_address_1
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const portfolioMatch = item.portfolio.portfolio_name
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());

      return (nameMatch || addressMatch || portfolioMatch) && portfolioFilter;
    });
    setFilteredProperties(results);
  }, [searchQuery, selectedPortfolio, properties]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredProperties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getAllMyProperties = async () => {
    try {
      setIsLoading(true);
      const properties = await getPropertiesOwnedByUserId();
      console.log(properties);
      setProperties(properties);
      setFilteredProperties(properties);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error loading properties");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}

      {isLoading && <LoaderView />}

      <div className={`${getThemeClasses()}`}>
        {/* start page title */}
        <PageTitleBox
          pageTitle={headerTitleDetail.title}
          previousPageTitle=""
          previousPageLink=""
          isDarkMode={isDarkMode}
        />
        {/* end page title */}

        <div className="container-fluid">
          {/* Top controls section with search, filters and Add Property button */}
          <div className="row g-3 mb-4">
            <div className="col-12 col-lg-9">
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-3">
                <div className="search-box md:tw-col-span-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search properties..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
                <select
                  className="form-select tw-w-full"
                  value={selectedPortfolio}
                  onChange={handlePortfolioChange}
                >
                  <option value="">All Portfolios</option>
                  {portfolios.map((portfolio, index) => (
                    <option key={index} value={portfolio}>
                      {portfolio}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select tw-w-full"
                  value={itemsPerPage}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="4">4 per page</option>
                  <option value="8">8 per page</option>
                  <option value="12">12 per page</option>
                  <option value="16">16 per page</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-lg-3 tw-flex tw-justify-end">
              <Link
                to={"/newproperty"}
                className="btn btn-success tw-whitespace-nowrap"
                data-bs-placement="top"
                data-bs-toggle="tooltip"
                title=""
              >
                <i className="ri-add-line align-bottom me-1"></i>
                Add New Property
              </Link>
            </div>
          </div>

          {/* Property cards row */}
          <div className="row " style={{ paddingTop: "1%" }}>
            {/*  Loop start*/}

            {isLoading ? (
              // Show shimmer effect while loading
              <>
                {[...Array(8)].map((_, index) => (
                  <div key={index} className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                      <div className="card-body">
                        <ShimmerEffect
                          type="card"
                          height="200px"
                          width="100%"
                          darkMode={isDarkMode}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : currentItems.length > 0 ? (
              <>
                {currentItems.map((port_property, index) => (
                  <PropertyCard
                    key={index}
                    property={port_property}
                    index={index}
                    isDarkMode={isDarkMode}
                  />
                ))}
              </>
            ) : (
              <NoPropertiesFound
                isDarkMode={isDarkMode}
                searchQuery={searchQuery}
              />
            )}

            {/*  Loop end*/}
          </div>
          {/*  Loop end*/}

          {totalPages > 1 && (
            <div className="tw-mt-4 tw-flex tw-justify-center tw-pb-4">
              <nav>
                <ul className="pagination pagination-rounded mb-0">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <i className="ri-arrow-left-s-line"></i>
                    </button>
                  </li>

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          page === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    )
                  )}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <i className="ri-arrow-right-s-line"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
        {/* /row */}
      </div>
    </React.Fragment>
  );
};

export default MyProperties;
