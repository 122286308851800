import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import defaultHouseImg from "../../../../styletheme/images/buildings/house.png";
import { format, isValid, parseISO } from "date-fns";
import { PropertyWithOwnership } from "../types";

interface PropertyCardProps {
  property: PropertyWithOwnership;
  index: number;
  isDarkMode?: boolean;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  property: port_property,
  index,
  isDarkMode = false,
}) => {
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      if (!isValid(date)) return "";
      return format(date, "MMM dd, yyyy 'at' h:mm a");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  return (
    <div key={index} className="col-xxl-3 col-sm-6 project-card">
      <div
        className={`card card-height-100 hover:tw-shadow-lg tw-transition-all tw-duration-300 ${
          isDarkMode ? "tw-bg-gray-800 tw-border-gray-700" : "tw-bg-white"
        }`}
      >
        <div className="card-body tw-p-0">
          {/* Property Image */}
          <div className="tw-relative tw-h-48 tw-w-full tw-overflow-hidden">
            <img
              src={
                port_property.property.property_image_urls &&
                port_property.property.property_image_urls.length > 0
                  ? port_property.property.property_image_urls[0].url
                  : defaultHouseImg
              }
              alt={port_property.property.property_alias_name || "Property"}
              className="tw-w-full tw-h-full tw-object-cover"
            />
            <div className="tw-absolute tw-top-4 tw-right-4">
              <Dropdown>
                <Dropdown.Toggle
                  variant={isDarkMode ? "dark" : "light"}
                  id={`dropdown-${index}`}
                  className={`${
                    isDarkMode ? "tw-bg-gray-700" : "tw-bg-white"
                  } tw-bg-opacity-90 tw-border-0 tw-rounded-lg tw-shadow-sm`}
                >
                  <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className={isDarkMode ? "tw-bg-gray-700" : ""}>
                  <Dropdown.Item
                    href={`/property/${port_property.property.property_id}`}
                    className={
                      isDarkMode ? "tw-text-gray-200 hover:tw-bg-gray-600" : ""
                    }
                  >
                    <i className="ri-eye-fill tw-mr-2 tw-opacity-75"></i>
                    View
                  </Dropdown.Item>
                  <Dropdown.Item
                    href={`/updateProperty/${port_property.property.property_id}`}
                    className={
                      isDarkMode ? "tw-text-gray-200 hover:tw-bg-gray-600" : ""
                    }
                  >
                    <i className="ri-pencil-fill tw-mr-2 tw-opacity-75"></i>
                    Edit
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="tw-p-4">
            {/* Portfolio and Update Info */}
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-3">
              <span
                className={`tw-text-sm tw-font-medium ${
                  isDarkMode
                    ? "tw-text-blue-300 tw-bg-blue-900/40"
                    : "tw-text-blue-600 tw-bg-blue-50"
                } tw-px-3 tw-py-1 tw-rounded-full tw-shadow-sm`}
              >
                {port_property.portfolio.portfolio_name}
              </span>
              {port_property.property.updated_at && (
                <span
                  className={`tw-text-xs ${
                    isDarkMode ? "tw-text-gray-300" : "tw-text-gray-500"
                  }`}
                >
                  {formatDate(port_property.property.updated_at) ? (
                    <>Updated {formatDate(port_property.property.updated_at)}</>
                  ) : null}
                </span>
              )}
            </div>

            {/* Property Name */}
            <h5 className="tw-text-lg tw-font-semibold tw-mb-2">
              <Link
                to={`/property/${port_property.property.property_id}`}
                className={`${
                  isDarkMode
                    ? "tw-text-white hover:tw-text-blue-300"
                    : "tw-text-gray-800 hover:tw-text-blue-600"
                } tw-transition-colors`}
              >
                {port_property.property.property_alias_name}
              </Link>
            </h5>

            {/* Address */}
            <p
              className={`tw-text-sm tw-mb-3 ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
              }`}
            >
              {[
                port_property.property.street_address_1,
                port_property.property.street_address_2,
                port_property.property.city_town,
                port_property.property.state_parish,
                port_property.property.zip_postal_code,
                port_property.property.country,
              ]
                .filter(Boolean)
                .join(", ")}
            </p>

            {/* Property Details */}
            <div
              className={`tw-flex tw-flex-wrap tw-gap-3 tw-mt-4 tw-pt-4 ${
                isDarkMode ? "tw-border-gray-700" : "tw-border-gray-100"
              } tw-border-t`}
            >
              <div
                className={`tw-text-xs ${
                  isDarkMode ? "tw-text-gray-300" : "tw-text-gray-500"
                }`}
              >
                <i className="ri-map-pin-line tw-mr-1 tw-opacity-75"></i>
                {port_property.property.city_town},{" "}
                {port_property.property.state_parish}
              </div>
              {port_property.property.created_at &&
                formatDate(port_property.property.created_at) && (
                  <div
                    className={`tw-text-xs ${
                      isDarkMode ? "tw-text-gray-300" : "tw-text-gray-500"
                    }`}
                  >
                    <i className="ri-time-line tw-mr-1 tw-opacity-75"></i>
                    Created {formatDate(port_property.property.created_at)}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
