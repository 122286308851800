import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getContractorsLinkedToUser } from "../../../../services/contractor/contractorManagement";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";
import Box from "@mui/material/Box";
import NoRecordsFound from "../../tickets/list/sub/noRecordsFound";
import {
  getUserRentalContractTemplateById,
  getUserRentalContractTemplatesByUserId,
} from "../../../../services/rental_contract/rentalContractService";
import config from "../../../../constants/config";
import HtmlPreview from "../../../reusable/preview/HtmlPreview";
import { useThemeClasses } from "../../../../services/utils/hooks/useThemeClasses";
import { usePageTitle } from "../../../../services/utils/hooks/usePageTitle";
import { ContractTemplateTable } from "./components/ContractTemplateTable";
import { ContractTemplateKanbanView } from "./components/ContractTemplateKanbanView";
import { ViewToggleHeader } from "components/common/header/ViewToggleHeader";

interface ContractTemplate {
  contract_template_id: string;
  user_id: string;
  portfolio_id: string;
  property_id: string;
  contract_name: string;
  contract_description: string;
  contract_template: string;
  created_at: number;
  updated_at: number;
  is_active: boolean;
  is_deleted: boolean;
}

interface AllContractTemplatesProps {
  isDarkMode: boolean;
}

const AllContractTemplates: React.FC<AllContractTemplatesProps> = ({
  isDarkMode,
}) => {
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const pageTitle = "Contracts";
  usePageTitle({ title: pageTitle });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [contractTemplates, setContractTemplates] = useState<
    ContractTemplate[]
  >([
    {
      contract_template_id: "",
      user_id: "",
      portfolio_id: "",
      property_id: "",
      contract_name: "",
      contract_description: "",
      contract_template: "",
      created_at: Date.now(),
      updated_at: Date.now(),
      is_active: true,
      is_deleted: false,
    },
  ]);

  const [isKanbanView, setIsKanbanView] = useState(false);

  const toggleView = () => setIsKanbanView(!isKanbanView);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getContractors();
  }, []);

  const getContractors = async () => {
    const user_id = localStorage.getItem(config.user_id);
    const contracts = await getUserRentalContractTemplatesByUserId(user_id);

    if (contracts?.length === 0) {
      setIsLoading(false);
      return;
    } else {
      setContractTemplates(contracts);
      setIsLoading(false);
    }
  };

  const handleRowClick = (template: ContractTemplate) => {
    navigate(`/contractTemplate/${template.contract_template_id}`);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div className={`${getThemeClasses()} tw-flex tw-flex-col tw-h-screen`}>
        <div className="container-fluid tw-flex-grow-0">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle=""
            previousPageLink=""
          />

          <ViewToggleHeader
            title="Rental Contracts"
            isKanbanView={isKanbanView}
            toggleView={toggleView}
            createLink="/newContractTemplate"
            createButtonText="Create Contract"
            isDarkMode={isDarkMode}
          />
          <div className="row">
            <div className="col-lg-12">
              {isKanbanView ? (
                <ContractTemplateKanbanView
                  data={contractTemplates}
                  isDarkMode={isDarkMode}
                  onRowClick={handleRowClick}
                />
              ) : (
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive table-card mb-4 px-3 mt-2">
                      <ContractTemplateTable
                        data={contractTemplates}
                        isDarkMode={isDarkMode}
                        onRowClick={handleRowClick}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllContractTemplates;
