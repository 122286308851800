import React, { createContext, useContext, useState, useCallback } from "react";
import { Message } from "../models/ai-chat/Message";

interface AiChatContextType {
  messages: Message[];
  activeSessionId: string | null;
  setActiveSessionId: (sessionId: string) => void;
  updateMessages: (messages: Message[]) => void;
  addMessage: (message: Message) => void;
  replaceMessage: (oldMessageId: string, newMessage: Message) => void;
  clearMessages: () => void;
}

const AiChatContext = createContext<AiChatContextType | undefined>(undefined);

export const useAiChat = () => {
  const context = useContext(AiChatContext);
  if (!context) {
    throw new Error("useAiChat must be used within an AiChatProvider");
  }
  return context;
};

interface AiChatProviderProps {
  children: React.ReactNode;
}

export const AiChatProvider: React.FC<AiChatProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [activeSessionId, setActiveSessionId] = useState<string | null>(null);

  const updateMessages = useCallback((newMessages: Message[]) => {
    setMessages(newMessages);
  }, []);

  const addMessage = useCallback((message: Message) => {
    setMessages((prev) => [...prev, message]);
  }, []);

  const replaceMessage = useCallback(
    (oldMessageId: string, newMessage: Message) => {
      setMessages((prev) =>
        prev.map((msg) => (msg.id === oldMessageId ? newMessage : msg))
      );
    },
    []
  );

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  const value = {
    messages,
    activeSessionId,
    setActiveSessionId,
    updateMessages,
    addMessage,
    replaceMessage,
    clearMessages,
  };

  return (
    <AiChatContext.Provider value={value}>{children}</AiChatContext.Provider>
  );
};
