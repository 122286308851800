import React, { useEffect, useRef } from "react";
import "./editorStyles.css";

function SimpleHTMLEditor({
  content,
  handleChange,
  height = "400px",
  isDarkMode,
}) {
  const editorRef = useRef(null);

  const execCommand = (command, value = null) => {
    editorRef.current?.focus();
    try {
      //TODO: need to fix this deprecated method
      document.execCommand(command, false, value);
    } catch (e) {
      console.warn("Command execution failed:", command, e);
    }
  };

  // Update editor content when prop changes
  useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== content) {
      editorRef.current.innerHTML = content || "";
    }
  }, [content]);

  // Function to strip background color from HTML content when saving
  const stripBackgroundColor = (html) => {
    // Create a temporary div to manipulate the HTML
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Remove background-color styles from all elements
    const elements = temp.querySelectorAll("*");
    elements.forEach((el) => {
      // Remove inline background styles
      if (el.style && el.style.backgroundColor) {
        el.style.backgroundColor = "";
      }

      // Remove Tailwind background classes
      if (el.classList) {
        const classesToRemove = [];
        el.classList.forEach((cls) => {
          if (cls.includes("tw-bg-") || cls.includes("bg-")) {
            classesToRemove.push(cls);
          }
        });
        classesToRemove.forEach((cls) => el.classList.remove(cls));
      }
    });

    return temp.innerHTML;
  };

  const onInput = (e) => {
    // Strip background colors when saving content
    const newContent = stripBackgroundColor(e.currentTarget.innerHTML);
    handleChange(newContent);
  };

  const containerClasses = `snow-editor tw-rounded-md tw-overflow-hidden tw-border ${
    isDarkMode
      ? "tw-border-gray-700 tw-bg-gray-800"
      : "tw-border-gray-200 tw-bg-white"
  }`;

  const toolbarClasses = `tw-flex tw-flex-wrap tw-items-center tw-gap-1 tw-p-2 tw-border-b ${
    isDarkMode
      ? "tw-border-gray-700 tw-bg-gray-800"
      : "tw-border-gray-200 tw-bg-gray-50"
  }`;

  const buttonClasses = `tw-px-2 tw-py-1 tw-rounded ${
    isDarkMode
      ? "tw-bg-gray-700 hover:tw-bg-gray-600 tw-text-gray-100"
      : "hover:tw-bg-gray-200 tw-text-gray-700"
  }`;

  const editorClasses = `tw-p-4 tw-min-h-[200px] focus:tw-outline-none tw-prose tw-max-w-none ${
    isDarkMode
      ? "tw-text-gray-100 tw-bg-gray-800"
      : "tw-text-gray-900 tw-bg-white"
  }`;

  return (
    <div className={containerClasses}>
      {/* Toolbar - styled to match Quill */}
      <div className={toolbarClasses}>
        <div className="tw-flex tw-items-center tw-gap-1 tw-px-2 tw-border-r tw-border-gray-200 dark:tw-border-gray-600">
          <button
            type="button"
            onClick={() => execCommand("formatBlock", "h1")}
            className={buttonClasses}
          >
            H1
          </button>
          <button
            type="button"
            onClick={() => execCommand("formatBlock", "h2")}
            className={buttonClasses}
          >
            H2
          </button>
          <select
            onChange={(e) => execCommand("fontName", e.target.value)}
            className={`${buttonClasses} tw-border-0 focus:tw-outline-none`}
          >
            <option value="Sans Serif">Sans Serif</option>
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
          </select>
        </div>

        <div className="tw-flex tw-items-center tw-gap-1 tw-px-2 tw-border-r tw-border-gray-200 dark:tw-border-gray-600">
          <button
            type="button"
            onClick={() => execCommand("bold")}
            className={buttonClasses}
          >
            <i className="ri-bold"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("italic")}
            className={buttonClasses}
          >
            <i className="ri-italic"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("underline")}
            className={buttonClasses}
          >
            <i className="ri-underline"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("strikeThrough")}
            className={buttonClasses}
          >
            <i className="ri-strikethrough"></i>
          </button>
        </div>

        <div className="tw-flex tw-items-center tw-gap-1 tw-px-2 tw-border-r tw-border-gray-200 dark:tw-border-gray-600">
          <button
            type="button"
            onClick={() => execCommand("insertUnorderedList")}
            className={buttonClasses}
          >
            <i className="ri-list-unordered"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("insertOrderedList")}
            className={buttonClasses}
          >
            <i className="ri-list-ordered"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("indent")}
            className={buttonClasses}
          >
            <i className="ri-indent-increase"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("outdent")}
            className={buttonClasses}
          >
            <i className="ri-indent-decrease"></i>
          </button>
        </div>

        <div className="tw-flex tw-items-center tw-gap-1 tw-px-2">
          <button
            type="button"
            onClick={() => {
              const url = prompt("Enter link URL");
              if (url) execCommand("createLink", url);
            }}
            className={buttonClasses}
          >
            <i className="ri-link"></i>
          </button>
          <button
            type="button"
            onClick={() => execCommand("removeFormat")}
            className={buttonClasses}
          >
            <i className="ri-format-clear"></i>
          </button>
        </div>
      </div>

      {/* Editor */}
      <div
        ref={editorRef}
        contentEditable
        onInput={onInput}
        className={editorClasses}
        style={{ height }}
        placeholder="Write something..."
      />
    </div>
  );
}

export default SimpleHTMLEditor;
