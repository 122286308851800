import React, { useState, useRef, useEffect } from "react";
import { Message, ChatHistory } from "../../../models/ai-chat/Message";
import { useNavigate } from "react-router-dom";
import { AiContextTrackingService } from "../../../services/ai/AiContextTrackingService";
import { ContextualSuggestion } from "../../../models/ai-chat/ContextualSuggestion";
import { LoadingDots } from "./LoadingDots";
import { formatMessageTime } from "../../../models/ai-chat/Message";
import { v4 as uuidv4 } from "uuid";
import { SkillMessage } from "./SkillMessage";
import config from "../../../constants/config";

interface AiChatWindowProps {
  onClose: () => void;
  messages: Message[];
  onSendMessage: (message: string) => void;
  isDarkMode: boolean;
  onNewChat: () => void;
  chatSessions: ChatHistory[];
  activeSessionId: string | null;
  onSessionSelect: (sessionId: string) => void;
}

export const AiChatWindow: React.FC<AiChatWindowProps> = ({
  onClose,
  messages,
  onSendMessage,
  isDarkMode,
  onNewChat,
  chatSessions,
  activeSessionId,
  onSessionSelect,
}) => {
  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const navigate = useNavigate();
  const contextService = AiContextTrackingService.getInstance();
  const [suggestions, setSuggestions] = useState<ContextualSuggestion[]>([]);
  

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (!messagesContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      messagesContainerRef.current;
    // Show button when scrolled more than 200px from bottom or 25% of the container height, whichever is smaller
    const scrollThreshold = Math.min(200, clientHeight * 0.25);
    const isNearBottom =
      scrollHeight - scrollTop - clientHeight < scrollThreshold;
    setShowScrollButton(!isNearBottom);
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // Initial check for scroll button visibility
      handleScroll();
      // Scroll to bottom when chat is opened
      scrollToBottom();
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const scrollThreshold = Math.min(200, clientHeight * 0.25);
      const isNearBottom =
        scrollHeight - scrollTop - clientHeight < scrollThreshold;
      if (isNearBottom) {
        scrollToBottom();
      }
      // Check if we should show the button after messages update
      handleScroll();
    }
  }, [messages]);

  useEffect(() => {
  
    // Update suggestions initially
    setSuggestions(contextService.getCurrentSuggestions());

    // Listen for context changes
    const handleContextChange = () => {
      setSuggestions(contextService.getCurrentSuggestions());
    };

    window.addEventListener("contextChanged", handleContextChange);

    // Also update suggestions when the chat window is opened
    if (messages.length === 0) {
      setSuggestions(contextService.getCurrentSuggestions());
    }

    return () => {
      window.removeEventListener("contextChanged", handleContextChange);
    };
  }, [messages.length]);

 

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim()) {
      onSendMessage(input.trim());
      setInput("");
    }
  };

  const handleOpenFullScreen = () => {
    navigate("/gurenAi");
  };

  const handleSuggestionClick = (question: string) => {
    setInput(question);
  };

  const handleNewChatClick = async () => {
    if (onNewChat) {
      await onNewChat();
      // Ensure we scroll to bottom in new chat
      setTimeout(scrollToBottom, 100);
    }
  };

  const handleSessionSelect = (sessionId: string) => {
    onSessionSelect(sessionId);
  };

  const renderMessageContent = (message: Message) => {
    if (message.status === "loading") {
      return (
        <div className="tw-flex tw-items-center tw-gap-2">
          <span className="tw-text-sm">Thinking</span>
          <LoadingDots isDarkMode={isDarkMode} />
        </div>
      );
    }

    if (
      message.status === "action_required" &&
      message.metadata?.skill_action
    ) {
      return (
        <SkillMessage
          message={message}
          isDarkMode={isDarkMode}
          sessionId={activeSessionId || ""}
        />
      );
    }

    const renderTextWithLinks = (text: string) => {
      // Match URLs in text
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const parts = text.split(urlRegex);

      return parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-blue-500 hover:tw-text-blue-600 tw-underline"
            >
              {part}
            </a>
          );
        }
        return <span key={index}>{part}</span>;
      });
    };

    return (
      <div className="tw-space-y-2">
        <p className="tw-text-sm tw-whitespace-pre-wrap">
          {renderTextWithLinks(message.content)}
        </p>
        {message.metadata?.tutorial_id && (
          <div className="tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-2">
            <a
              href={
                message.metadata.tutorial_url ||
                `https://gurenter.com/resources/tutorial/${message.metadata.tutorial_id}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="tw-inline-flex tw-items-start tw-gap-2 tw-text-sm hover:tw-bg-gray-50 tw-rounded-lg tw-p-2 tw-transition-colors -tw-ml-2"
            >
              <svg
                className="tw-w-5 tw-h-5 tw-text-blue-500 tw-flex-shrink-0 tw-mt-0.5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
              <div>
                <div className="tw-font-medium tw-text-blue-500 hover:tw-text-blue-600">
                  {message.metadata.tutorial_title || "View Tutorial"}
                </div>
                {message.metadata.tutorial_context && (
                  <div className="tw-flex tw-gap-2 tw-mt-1">
                    {message.metadata.tutorial_context.category && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.category}
                      </span>
                    )}
                    {message.metadata.tutorial_context.difficulty && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.difficulty}
                      </span>
                    )}
                    {message.metadata.tutorial_context.platform && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.platform}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </a>
          </div>
        )}
      </div>
    );
  };

  const getSessionTitle = (session: ChatHistory): string => {
    if (session.messages.length === 0) return "New Chat";
    const firstUserMessage = session.messages.find((m) => m.type === "user");
    if (firstUserMessage) {
      return (
        firstUserMessage.content.slice(0, 40) +
        (firstUserMessage.content.length > 40 ? "..." : "")
      );
    }
    return "New Chat";
  };

  const formatSessionDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (days === 1) {
      return "Yesterday";
    } else if (days < 7) {
      return date.toLocaleDateString([], { weekday: "long" });
    } else {
      return date.toLocaleDateString([], { month: "short", day: "numeric" });
    }
  };

  const renderChatHistory = (sessions: ChatHistory[]) => {
    // Sort sessions with newest first
    return sessions
      .sort(
        (a, b) =>
          new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime()
      )
      .map((session) => (
        <button
          key={session.session_id}
          onClick={() => handleSessionSelect(session.session_id)}
          className={`tw-w-full tw-text-left tw-p-2 tw-rounded-lg tw-transition-colors
            ${
              session.session_id === activeSessionId
                ? isDarkMode
                  ? "tw-bg-[#1e2124] tw-text-white"
                  : "tw-bg-gray-200 tw-text-gray-900"
                : isDarkMode
                ? "tw-bg-gray-700 tw-text-gray-100 hover:tw-bg-gray-600"
                : "tw-bg-gray-50 tw-text-gray-900 hover:tw-bg-gray-100"
            }`}
        >
          <div className="tw-text-sm tw-font-medium tw-truncate">
            {getSessionTitle(session)}
          </div>
          <div
            className={`tw-text-xs ${
              isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
            } tw-mt-0.5`}
          >
            {formatSessionDate(session.lastUpdated)}
          </div>
        </button>
      ));
  };



  return (
    <>
    
        <div
          className={`tw-fixed tw-bottom-20 tw-right-4 tw-w-[450px] tw-max-w-[calc(100vw-2rem)] ${
            isDarkMode ? "tw-bg-[#0f1012]" : "tw-bg-white"
          } tw-rounded-xl tw-shadow-2xl tw-flex tw-flex-row tw-max-h-[600px] tw-transition-all tw-duration-300 tw-ease-in-out tw-border ${
            isDarkMode ? "tw-border-gray-800" : "tw-border-gray-100"
          }`}
        >
          {/* Main Chat Area */}
          <div className="tw-flex-1 tw-flex tw-flex-col">
            {/* Header */}
            <div
              className={`tw-p-4 tw-border-b ${
                isDarkMode
                  ? "tw-border-gray-800 tw-bg-[#0f1012]"
                  : "tw-border-gray-100 tw-bg-white"
              } tw-flex tw-justify-between tw-items-center tw-rounded-t-xl`}
            >
              <div className="tw-flex tw-items-center tw-gap-2">
                <div className="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-lg tw-bg-[#1e2124]">
                  <span className="tw-text-white tw-text-lg">✨</span>
                </div>
                <h3
                  className={`tw-text-lg tw-font-semibold ${
                    isDarkMode ? "tw-text-gray-100" : "tw-text-gray-800"
                  }`}
                >
                  Guren
                </h3>
              </div>
              <div className="tw-flex tw-items-center tw-gap-2">
                <button
                  onClick={handleNewChatClick}
                  className={`tw-p-2 tw-rounded-lg ${
                    isDarkMode
                      ? "hover:tw-bg-[#1e2124]"
                      : "hover:tw-bg-gray-100"
                  } tw-transition-colors`}
                  aria-label="Start new chat"
                >
                  <svg
                    className={`tw-w-5 tw-h-5 ${
                      isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleOpenFullScreen}
                  className={`tw-p-2 tw-rounded-lg ${
                    isDarkMode
                      ? "hover:tw-bg-[#1e2124]"
                      : "hover:tw-bg-gray-100"
                  } tw-transition-colors`}
                  aria-label="Open full screen"
                >
                  <svg
                    className={`tw-w-5 tw-h-5 ${
                      isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 8V4m0 0h4M4 4l5 5m11-5h-4m4 0v4m0-4l-5 5M4 16v4m0-4l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                    />
                  </svg>
                </button>
                <button
                  onClick={onClose}
                  className={`tw-p-2 tw-rounded-lg ${
                    isDarkMode
                      ? "hover:tw-bg-[#1e2124]"
                      : "hover:tw-bg-gray-100"
                  } tw-transition-colors`}
                  aria-label="Close chat"
                >
                  <svg
                    className={`tw-w-5 tw-h-5 ${
                      isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Welcome Message */}
            <div
              className={`tw-p-4 ${
                isDarkMode ? "tw-bg-[#0f1012]" : "tw-bg-gray-50"
              } tw-border-b ${
                isDarkMode ? "tw-border-gray-800" : "tw-border-gray-100"
              }`}
            >
              <div className="tw-flex tw-items-start tw-gap-3">
                <div className="tw-flex-shrink-0 tw-w-6 tw-h-6 tw-mt-1">
                  <span className="tw-text-lg">✨</span>
                </div>
                <div>
                  <h4
                    className={`tw-font-medium ${
                      isDarkMode ? "tw-text-gray-100" : "tw-text-gray-900"
                    }`}
                  >
                    Ask anything or write with AI
                  </h4>
                  <p
                    className={`tw-text-sm ${
                      isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                    } tw-mt-1`}
                  >
                    Use Guren AI to help manage your properties or answer
                    questions about tasks and documentation.
                  </p>
                </div>
              </div>
            </div>

            {/* Suggestions Section */}
            {suggestions.length > 0 && messages.length === 0 && (
              <div
                className={`tw-p-4 tw-border-b ${
                  isDarkMode ? "tw-border-gray-800" : "tw-border-gray-100"
                }`}
              >
                <h4
                  className={`tw-text-sm tw-font-medium tw-mb-2 ${
                    isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                  }`}
                >
                  Suggested Questions
                </h4>
                <div className="tw-space-y-2">
                  {suggestions.map((suggestion, index) => (
                    <button
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion.question)}
                      className={`tw-w-full tw-text-left tw-p-2 tw-rounded-lg tw-text-sm ${
                        isDarkMode
                          ? "tw-bg-[#1a1c1e] tw-text-gray-300 hover:tw-bg-[#2a2d31]"
                          : "tw-bg-gray-50 tw-text-gray-700 hover:tw-bg-gray-100"
                      } tw-transition-colors`}
                    >
                      {suggestion.question}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* Messages */}
            <div
              ref={messagesContainerRef}
              className={`tw-flex-1 tw-overflow-y-auto tw-p-4 tw-space-y-4 tw-relative ${
                isDarkMode ? "tw-bg-[#0f1012]" : "tw-bg-white"
              }`}
            >
              {messages
                .slice()
                .sort(
                  (a, b) =>
                    new Date(a.timestamp).getTime() -
                    new Date(b.timestamp).getTime()
                )
                .map((message) => (
                  <div
                    key={message.id}
                    className={`tw-flex ${
                      message.type === "user"
                        ? "tw-justify-end"
                        : "tw-justify-start"
                    }`}
                  >
                    <div
                      className={`tw-max-w-[80%] tw-rounded-xl tw-p-3 ${
                        message.type === "user"
                          ? "tw-bg-[#1e2124] tw-text-gray-100"
                          : isDarkMode
                          ? "tw-bg-[#1a1c1e] tw-text-gray-100"
                          : "tw-bg-gray-100 tw-text-gray-800"
                      }`}
                    >
                      {renderMessageContent(message)}
                      <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                        <span
                          className={`tw-text-xs ${
                            message.type === "user"
                              ? "tw-text-gray-400"
                              : isDarkMode
                              ? "tw-text-gray-500"
                              : "tw-text-gray-500"
                          }`}
                        >
                          {formatMessageTime(message.timestamp)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              <div ref={messagesEndRef} />

              {/* Scroll to Bottom Button */}
              {showScrollButton && (
                <button
                  onClick={scrollToBottom}
                  className={`tw-absolute tw-bottom-4 tw-right-4 tw-p-2 tw-rounded-full tw-shadow-lg tw-transition-all tw-duration-300 tw-ease-in-out ${
                    isDarkMode
                      ? "tw-bg-gray-800 hover:tw-bg-gray-700"
                      : "tw-bg-white hover:tw-bg-gray-100"
                  }`}
                  aria-label="Scroll to latest messages"
                >
                  <svg
                    className={`tw-w-5 tw-h-5 ${
                      isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 14l-7 7m0 0l-7-7m7 7V3"
                    />
                  </svg>
                </button>
              )}
            </div>

            {/* Input Form */}
            <form
              onSubmit={handleSubmit}
              className={`tw-p-4 tw-border-t ${
                isDarkMode
                  ? "tw-border-gray-800 tw-bg-[#0f1012]"
                  : "tw-border-gray-100 tw-bg-white"
              }`}
            >
              <div className="tw-flex tw-space-x-2">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Ask Guren anything..."
                  className={`tw-flex-1 tw-rounded-lg tw-border tw-p-3 tw-text-sm ${
                    isDarkMode
                      ? "tw-bg-[#1a1c1e] tw-border-gray-800 tw-text-gray-100 placeholder:tw-text-gray-500"
                      : "tw-bg-white tw-border-gray-200 tw-text-gray-900"
                  } focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#1e2124]`}
                />
                <button
                  type="submit"
                  className="tw-bg-[#1e2124] tw-text-gray-100 tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-[#2a2d31] focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#1e2124] focus:tw-ring-offset-2 tw-transition-colors tw-text-sm tw-font-medium"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      
    </>
  );
};
