import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import { getPropertiesOwnedByUserId } from "services/portfolio/ownership/portfolioOwnershipManagement";
import { searchReports } from "services/reports/reportService";
import { PropertyFinancialReportModel } from "types/reports";
import Select from "react-select";
import { toast } from "react-toastify";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";
import config from "../../../constants/config";
import { devLog } from "services/utils/logger";
import { getPropertyByPropertyId } from "services/property/retrieve/propertyRetrievalService";
import { getPropertyUnitsByPropertyId } from "services/propertyUnits/propertyUnitManagement";
import { formatReportType } from "services/utils/formatters";

interface ReportsDashboardProps {
  isDarkMode: boolean;
}

interface Property {
  property: {
    property_id: string;
    property_alias_name: string;
    portfolio_id: string;
    units: Array<{
      property_unit_id: string;
      unit_alias_name: string;
      unit_name: string;
    }>;
    property_image_urls?: Array<{ url: string }>;
    street_address_1?: string;
    street_address_2?: string;
    city_town?: string;
    state_parish?: string;
    zip_postal_code?: string;
  };
  portfolio: {
    portfolio_id: string;
    portfolio_name: string;
  };
}
//Next need to use user freindly names and not monthly_noi instead Monthly NOI (Net Operating Income)
const ReportsDashboard: React.FC<ReportsDashboardProps> = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState<string | null>(
    null
  );
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [latestReports, setLatestReports] = useState<
    PropertyFinancialReportModel[]
  >([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(true);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState<{
    [key: string]: any;
  }>({});
  const [propertyUnits, setPropertyUnits] = useState<{ [key: string]: any }>(
    {}
  );

  // Move portfolio options to top level
  const portfolioOptions = useMemo(() => {
    if (!properties || properties.length === 0) return [];

    const uniquePortfolioIds = new Set<string>();
    const options: Array<{ value: string; label: string }> = [];

    properties.forEach((p) => {
      if (
        p.portfolio?.portfolio_id &&
        !uniquePortfolioIds.has(p.portfolio.portfolio_id)
      ) {
        uniquePortfolioIds.add(p.portfolio.portfolio_id);
        options.push({
          value: p.portfolio.portfolio_id,
          label: p.portfolio.portfolio_name || "Unnamed Portfolio",
        });
      }
    });

    return options;
  }, [properties]);

  // Move getPortfolioProperties to top level
  const getPortfolioProperties = useCallback(
    (portfolioId: string) => {
      if (!properties || !portfolioId) return [];
      return properties.filter(
        (p) =>
          p.portfolio?.portfolio_id === portfolioId && p.property?.property_id
      );
    },
    [properties]
  );

  useEffect(() => {
    loadProperties();
  }, []);

  useEffect(() => {
    if (selectedPortfolio) {
      loadLatestReports(selectedPortfolio);
    }
  }, [selectedPortfolio]);

  const loadProperties = async () => {
    setIsLoadingProperties(true);
    try {
      const data = await getPropertiesOwnedByUserId();
      // Validate and transform the data
      if (!data || !Array.isArray(data)) {
        console.error("Invalid data format received:", data);
        toast.error("Failed to load properties: Invalid data format");
        setProperties([]);
        return;
      }

      // Filter out any invalid property entries
      const validProperties = data.filter(
        (item) =>
          item &&
          item.property &&
          item.property.property_id &&
          item.portfolio &&
          item.portfolio.portfolio_id
      );
      devLog("validProperties", validProperties);
      setProperties(validProperties);
    } catch (error) {
      console.error("Error loading properties:", error);
      toast.error("Failed to load properties");
      setProperties([]);
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const loadLatestReports = async (portfolioId: string) => {
    setIsLoadingReports(true);
    try {
      const userId = localStorage.getItem(config.user_id);
      if (!userId) {
        throw new Error("User ID not found");
      }

      // Use the searchReports function with portfolio_id and user_id
      const reports = await searchReports({
        user_id: userId,
        portfolio_id: portfolioId,
        is_latest: true,
      });
      devLog("reports", reports);
      setLatestReports(reports);
    } catch (error) {
      console.error("Error loading reports:", error);
      toast.error("Failed to load reports");
    } finally {
      setIsLoadingReports(false);
    }
  };

  const loadPropertyDetails = async (portfolioProperties: Property[]) => {
    const detailsPromises = portfolioProperties.map(async (property) => {
      try {
        const details = await getPropertyByPropertyId(
          property.property.property_id
        );
        return { [property.property.property_id]: details };
      } catch (error) {
        console.error(
          `Error loading details for property ${property.property.property_id}:`,
          error
        );
        return { [property.property.property_id]: null };
      }
    });

    const details = await Promise.all(detailsPromises);
    const detailsMap = details.reduce(
      (acc, detail) => ({ ...acc, ...detail }),
      {}
    );
    setPropertyDetails(detailsMap);
  };

  const loadPropertyUnits = async (portfolioProperties: Property[]) => {
    const unitsPromises = portfolioProperties.map(async (property) => {
      try {
        const units = await getPropertyUnitsByPropertyId(
          property.property.property_id
        );
        return { [property.property.property_id]: units };
      } catch (error) {
        console.error(
          `Error loading units for property ${property.property.property_id}:`,
          error
        );
        return { [property.property.property_id]: [] };
      }
    });

    const unitsResults = await Promise.all(unitsPromises);
    const unitsMap = unitsResults.reduce(
      (acc, unit) => ({ ...acc, ...unit }),
      {}
    );
    setPropertyUnits(unitsMap);
  };

  const handlePortfolioChange = async (option: any) => {
    setSelectedPortfolio(option?.value || null);
    // Reset property filter when changing portfolios
    setSelectedProperty(null);

    if (option?.value) {
      const portfolioProperties = getPortfolioProperties(option.value);
      await loadPropertyDetails(portfolioProperties);
      await loadPropertyUnits(portfolioProperties);
    } else {
      setPropertyDetails({});
      setPropertyUnits({});
    }
  };

  const filteredReports = useMemo(() => {
    if (!selectedProperty) return latestReports;
    return latestReports.filter(
      (report) => report.property_id === selectedProperty
    );
  }, [latestReports, selectedProperty]);

  const handlePropertyClick = (propertyId: string) => {
    const portfolioProperties = getPortfolioProperties(selectedPortfolio || "");
    // Only allow filtering if there's more than one property
    if (portfolioProperties.length > 1) {
      setSelectedProperty(selectedProperty === propertyId ? null : propertyId);
    }
  };

  const navigateToPropertyReports = (propertyId: string) => {
    navigate(`/reports/property/${propertyId}`);
  };

  if (isLoadingProperties) {
    return (
      <div className={`${getThemeClasses()} tw-min-h-screen`}>
        <div className="tw-flex tw-items-center tw-justify-center tw-h-[60vh]">
          <ShimmerEffect
            type="list"
            height={200}
            width={800}
            className="mb-2"
            darkMode={isDarkMode}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={`${getThemeClasses()} tw-min-h-screen`}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Reports Dashboard"
          previousPageLink="/dashboard"
          previousPageTitle="Dashboard"
        />

        <div className="row">
          <div className="col-12 mb-4">
            <div
              className={`card ${
                isDarkMode ? "tw-bg-gray-800 tw-border-gray-700" : "tw-bg-white"
              }`}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <h5
                      className={`card-title mb-1 ${
                        isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                      }`}
                    >
                      Select Portfolio
                    </h5>
                    <p
                      className={`mb-0 ${
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                      }`}
                    >
                      Choose a portfolio to view its properties and reports
                    </p>
                  </div>
                  {selectedPortfolio && (
                    <span className="badge bg-success">
                      <i className="ri-check-line me-1"></i>
                      Portfolio Selected
                    </span>
                  )}
                </div>
                <Select
                  className="react-select"
                  classNamePrefix="select"
                  options={portfolioOptions}
                  onChange={handlePortfolioChange}
                  isClearable
                  placeholder="Select a portfolio..."
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0d6efd",
                      primary75: "#3d8bfd",
                      primary50: "#6ea8fe",
                      primary25: "#9ec5fe",
                      neutral0: isDarkMode ? "#1f2937" : "#ffffff",
                      neutral5: isDarkMode ? "#374151" : "#f8f9fa",
                      neutral10: isDarkMode ? "#4b5563" : "#f1f3f5",
                      neutral20: isDarkMode ? "#6b7280" : "#ced4da",
                      neutral30: isDarkMode ? "#9ca3af" : "#adb5bd",
                      neutral40: isDarkMode ? "#d1d5db" : "#6c757d",
                      neutral50: isDarkMode ? "#e5e7eb" : "#495057",
                      neutral60: isDarkMode ? "#f3f4f6" : "#343a40",
                      neutral70: isDarkMode ? "#f9fafb" : "#212529",
                      neutral80: isDarkMode ? "#ffffff" : "#000000",
                      neutral90: isDarkMode ? "#ffffff" : "#000000",
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      background: isDarkMode ? "#1f2937" : "#ffffff",
                      borderColor: isDarkMode ? "#4b5563" : "#ced4da",
                      "&:hover": {
                        borderColor: isDarkMode ? "#6b7280" : "#adb5bd",
                      },
                    }),
                    menu: (base) => ({
                      ...base,
                      background: isDarkMode ? "#1f2937" : "#ffffff",
                      border: `1px solid ${isDarkMode ? "#4b5563" : "#ced4da"}`,
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused
                        ? isDarkMode
                          ? "#374151"
                          : "#f8f9fa"
                        : "transparent",
                      color: isDarkMode ? "#e5e7eb" : "#212529",
                      "&:hover": {
                        backgroundColor: isDarkMode ? "#4b5563" : "#f1f3f5",
                      },
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: isDarkMode ? "#e5e7eb" : "#212529",
                    }),
                    input: (base) => ({
                      ...base,
                      color: isDarkMode ? "#e5e7eb" : "#212529",
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          {!selectedPortfolio ? (
            <div className="col-12">
              <div
                className={`card ${
                  isDarkMode
                    ? "tw-bg-gray-800 tw-border-gray-700"
                    : "tw-bg-white"
                }`}
              >
                <div className="card-body text-center py-5">
                  <div className="tw-max-w-2xl tw-mx-auto">
                    <i
                      className={`ri-file-chart-line tw-text-6xl mb-4 ${
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                      }`}
                    ></i>
                    <h3
                      className={`tw-text-xl mb-3 ${
                        isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                      }`}
                    >
                      Welcome to Your Reports Dashboard
                    </h3>
                    <p
                      className={`mb-4 ${
                        isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                      }`}
                    >
                      This dashboard provides access to all your property
                      financial reports. Select a portfolio above to:
                    </p>
                    <div className="row g-4 justify-content-center">
                      <div className="col-md-4">
                        <div
                          className={`p-4 rounded-lg ${
                            isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                          }`}
                        >
                          <i className="ri-building-line tw-text-2xl tw-text-primary mb-3"></i>
                          <h6
                            className={`mb-2 ${
                              isDarkMode
                                ? "tw-text-gray-200"
                                : "tw-text-gray-700"
                            }`}
                          >
                            View Properties
                          </h6>
                          <p
                            className={`tw-text-sm mb-0 ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            }`}
                          >
                            See all properties in your portfolio with their
                            details
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className={`p-4 rounded-lg ${
                            isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                          }`}
                        >
                          <i className="ri-file-list-3-line tw-text-2xl tw-text-primary mb-3"></i>
                          <h6
                            className={`mb-2 ${
                              isDarkMode
                                ? "tw-text-gray-200"
                                : "tw-text-gray-700"
                            }`}
                          >
                            Access Reports
                          </h6>
                          <p
                            className={`tw-text-sm mb-0 ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            }`}
                          >
                            View and download financial reports for each
                            property
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className={`p-4 rounded-lg ${
                            isDarkMode ? "tw-bg-gray-700" : "tw-bg-gray-50"
                          }`}
                        >
                          <i className="ri-line-chart-line tw-text-2xl tw-text-primary mb-3"></i>
                          <h6
                            className={`mb-2 ${
                              isDarkMode
                                ? "tw-text-gray-200"
                                : "tw-text-gray-700"
                            }`}
                          >
                            Track Performance
                          </h6>
                          <p
                            className={`tw-text-sm mb-0 ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            }`}
                          >
                            Monitor financial performance across your properties
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-12 mb-4">
                <div
                  className={`card ${
                    isDarkMode
                      ? "tw-bg-gray-800 tw-border-gray-700"
                      : "tw-bg-white"
                  }`}
                >
                  <div className="card-body">
                    <h5
                      className={`card-title mb-4 ${
                        isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                      }`}
                    >
                      Properties in Portfolio
                    </h5>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
                      {(() => {
                        const portfolioProperties = getPortfolioProperties(
                          selectedPortfolio || ""
                        );

                        if (portfolioProperties.length === 0) {
                          return (
                            <div className="col-12">
                              <p
                                className={`text-center ${
                                  isDarkMode
                                    ? "tw-text-gray-400"
                                    : "tw-text-gray-500"
                                }`}
                              >
                                No properties found in this portfolio
                              </p>
                            </div>
                          );
                        }

                        return portfolioProperties.map((property) => (
                          <div
                            key={property.property.property_id}
                            onClick={() =>
                              handlePropertyClick(property.property.property_id)
                            }
                            className={`tw-p-4 tw-rounded-lg tw-border ${
                              portfolioProperties.length === 1
                                ? "tw-cursor-default"
                                : "tw-cursor-pointer tw-transition-all tw-duration-200 hover:tw-shadow-md"
                            } ${
                              selectedProperty === property.property.property_id
                                ? isDarkMode
                                  ? "tw-border-blue-500 tw-bg-gray-800"
                                  : "tw-border-blue-500 tw-bg-blue-50"
                                : isDarkMode
                                ? "tw-border-gray-700 tw-bg-gray-800"
                                : "tw-border-gray-200 tw-bg-white"
                            } ${
                              portfolioProperties.length > 1
                                ? isDarkMode
                                  ? "hover:tw-border-gray-600"
                                  : "hover:tw-border-gray-300"
                                : ""
                            }`}
                          >
                            <div className="tw-flex tw-items-center tw-gap-3">
                              <div className="tw-flex-shrink-0">
                                <img
                                  src={
                                    property.property.property_image_urls?.[0]
                                      ?.url ||
                                    "https://via.placeholder.com/300x200?text=No+Image"
                                  }
                                  alt={property.property.property_alias_name}
                                  className="tw-w-16 tw-h-16 tw-rounded-lg tw-object-cover"
                                />
                              </div>
                              <div className="tw-flex-grow">
                                <div className="tw-flex tw-items-center tw-gap-2">
                                  <h6
                                    className={`tw-text-sm tw-font-semibold tw-mb-1 ${
                                      isDarkMode
                                        ? "tw-text-gray-200"
                                        : "tw-text-gray-700"
                                    }`}
                                  >
                                    {property.property.property_alias_name}
                                  </h6>
                                  {selectedProperty ===
                                    property.property.property_id &&
                                    portfolioProperties.length > 1 && (
                                      <span
                                        className={`tw-text-xs tw-px-2 tw-py-1 tw-rounded ${
                                          isDarkMode
                                            ? "tw-bg-blue-900/30 tw-text-blue-400"
                                            : "tw-bg-blue-100 tw-text-blue-700"
                                        }`}
                                      >
                                        Filtered
                                      </span>
                                    )}
                                </div>
                                <div
                                  className={`tw-text-xs ${
                                    isDarkMode
                                      ? "tw-text-gray-400"
                                      : "tw-text-gray-600"
                                  }`}
                                >
                                  <div className="tw-flex tw-items-center">
                                    <i className="ri-map-pin-line tw-mr-1"></i>
                                    <span className="tw-truncate">
                                      {property.property.street_address_1}
                                    </span>
                                  </div>
                                  <div className="tw-flex tw-items-center tw-mt-1">
                                    <i className="ri-building-line tw-mr-1"></i>
                                    <span>
                                      {propertyUnits[
                                        property.property.property_id
                                      ]?.length || 0}{" "}
                                      {propertyUnits[
                                        property.property.property_id
                                      ]?.length === 1
                                        ? "unit"
                                        : "units"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {(() => {
                                const propertyReports = latestReports.filter(
                                  (report) =>
                                    report.property_id ===
                                    property.property.property_id
                                );
                                if (propertyReports.length > 0) {
                                  const latestReport = propertyReports[0];
                                  return (
                                    <div
                                      className={`tw-text-right tw-text-xs ${
                                        isDarkMode
                                          ? "tw-text-gray-400"
                                          : "tw-text-gray-500"
                                      }`}
                                    >
                                      <div className="tw-whitespace-nowrap">
                                        {new Date(
                                          latestReport.report_date
                                        ).toLocaleDateString()}
                                      </div>
                                      <div
                                        className={`tw-mt-1 tw-font-medium ${
                                          isDarkMode
                                            ? "tw-text-gray-300"
                                            : "tw-text-gray-700"
                                        }`}
                                      >
                                        {formatReportType(
                                          latestReport.report_type
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className={`tw-text-xs ${
                                      isDarkMode
                                        ? "tw-text-gray-500"
                                        : "tw-text-gray-400"
                                    }`}
                                  >
                                    No reports
                                  </div>
                                );
                              })()}
                            </div>
                          </div>
                        ));
                      })()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div
                  className={`card ${
                    isDarkMode
                      ? "tw-bg-gray-800 tw-border-gray-700"
                      : "tw-bg-white"
                  }`}
                >
                  <div className="card-body">
                    <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                      <h5
                        className={`card-title tw-mb-0 ${
                          isDarkMode ? "tw-text-gray-200" : "tw-text-gray-700"
                        }`}
                      >
                        All Reports
                      </h5>
                      {selectedProperty && (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <span
                            className={`tw-text-sm ${
                              isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-600"
                            }`}
                          >
                            Filtered by:{" "}
                            {
                              properties.find(
                                (p) =>
                                  p.property.property_id === selectedProperty
                              )?.property.property_alias_name
                            }
                          </span>
                          <button
                            onClick={() => setSelectedProperty(null)}
                            className={`tw-text-sm tw-px-2 tw-py-1 tw-rounded-md tw-transition-colors ${
                              isDarkMode
                                ? "tw-bg-gray-700 tw-text-gray-300 hover:tw-bg-gray-600"
                                : "tw-bg-gray-100 tw-text-gray-600 hover:tw-bg-gray-200"
                            }`}
                          >
                            <i className="ri-close-line tw-mr-1"></i>
                            Clear Filter
                          </button>
                        </div>
                      )}
                    </div>
                    {isLoadingReports ? (
                      <ShimmerEffect
                        type="list"
                        height={150}
                        width={800}
                        className="mb-2"
                        darkMode={isDarkMode}
                      />
                    ) : (
                      <div className="table-responsive">
                        <table
                          className={`table ${
                            isDarkMode ? "table-dark" : ""
                          } tw-mb-0`}
                        >
                          <thead>
                            <tr
                              className={
                                isDarkMode
                                  ? "tw-text-gray-300"
                                  : "tw-text-gray-600"
                              }
                            >
                              <th>Report Type</th>
                              <th>Property</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredReports.length === 0 ? (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <p
                                    className={`tw-my-4 ${
                                      isDarkMode
                                        ? "tw-text-gray-400"
                                        : "tw-text-gray-500"
                                    }`}
                                  >
                                    {selectedProperty
                                      ? "No reports available for this property"
                                      : "No reports available for this portfolio"}
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              filteredReports.map((report) => (
                                <tr
                                  key={report.report_id}
                                  className={
                                    isDarkMode
                                      ? "tw-text-gray-300"
                                      : "tw-text-gray-700"
                                  }
                                >
                                  <td>
                                    {formatReportType(report.report_type)}
                                  </td>
                                  <td>
                                    {properties.find(
                                      (p) =>
                                        p.property.property_id ===
                                        report.property_id
                                    )?.property.property_alias_name ||
                                      report.property_id}
                                  </td>
                                  <td>
                                    {new Date(
                                      report.report_date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>
                                    <span
                                      className={`badge ${
                                        report.status === "completed"
                                          ? "tw-bg-green-500"
                                          : "tw-bg-yellow-500"
                                      } tw-text-white`}
                                    >
                                      {report.status}
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary tw-mr-2"
                                      onClick={() =>
                                        navigate(`/reports/${report.report_id}`)
                                      }
                                    >
                                      <i className="ri-eye-line tw-mr-1"></i>{" "}
                                      View
                                    </button>
                                    <button
                                      className="btn btn-sm btn-light"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (report.pdf_document?.url) {
                                          const link =
                                            document.createElement("a");
                                          link.href = report.pdf_document.url;
                                          link.download =
                                            report.pdf_document.file_name ||
                                            `${report.report_type}-${
                                              new Date(report.report_date)
                                                .toISOString()
                                                .split("T")[0]
                                            }.pdf`;
                                          link.target = "_blank";
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        } else {
                                          toast.error(
                                            "PDF not available for download"
                                          );
                                        }
                                      }}
                                      title="Download Report"
                                    >
                                      <i className="ri-download-line"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsDashboard;
