import React from "react";

const FilePDFImagePreview = ({
  itemToPreview,
  setItemToDelete,
  setShowConfirm,
  showDeleteBtn = true,
  onClose,
}) => {
  const handleDownload = (e) => {
    e.preventDefault();
    // Open the file in a new tab, which will either display it or download it
    window.open(itemToPreview.url, "_blank");
  };

  const renderPreview = () => {
    const fileType = itemToPreview.file_type;

    if (fileType === "application/pdf") {
      return (
        <div style={{ height: "calc(80vh - 120px)", width: "100%" }}>
          <iframe
            src={itemToPreview.url}
            title="PDF Preview"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        </div>
      );
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return (
        <div
          style={{
            height: "calc(80vh - 120px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p>PowerPoint preview is not available.</p>
          <a
            href={itemToPreview.url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            Open PowerPoint
          </a>
        </div>
      );
    } else if (
      fileType === "application/zip" ||
      fileType === "application/x-zip-compressed"
    ) {
      return (
        <div
          style={{
            height: "calc(80vh - 120px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p>ZIP file preview is not available.</p>
          <button onClick={handleDownload} className="btn btn-primary">
            Download ZIP File
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "calc(80vh - 120px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f8f9fa",
          }}
        >
          <img
            src={itemToPreview.url}
            alt={itemToPreview.file_name}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1 position-relative">{renderPreview()}</div>
      <div className="p-3 border-top">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h6 className="text-wrap mb-1">
              {itemToPreview.file_name.length > 100
                ? `${itemToPreview.file_name.substring(0, 100)}...`
                : itemToPreview.file_name}
            </h6>
            <p className="text-muted fs-12 mb-0">
              {itemToPreview.file_size_formatted}
            </p>
          </div>
          <div className="d-flex gap-2">
            <button onClick={handleDownload} className="btn btn-link btn-sm">
              Download
            </button>
            {showDeleteBtn && (
              <button
                onClick={() => {
                  setItemToDelete(itemToPreview);
                  setShowConfirm(true);
                  if (onClose) onClose();
                }}
                className="btn btn-link btn-sm text-danger"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePDFImagePreview;
