export interface TutorialContext {
  category?: string;
  difficulty?: string;
  platform?: string;
}

export interface DialogAction {
  label: string;
  value: string;
  variant: "primary" | "secondary";
}

export enum ActionType {
  CREATE_TICKET = "create_ticket",
  SELECT_PORTFOLIO = "select_portfolio",
  CREATE_PORTFOLIO = "create_portfolio",
  SELECT_PROPERTY = "select_property",
  CREATE_PROPERTY = "create_property",
  SELECT_UNIT = "select_unit",
  CREATE_UNIT = "create_unit",
  ENTER_TICKET_DETAILS = "enter_ticket_details",
}

export interface ActionStep {
  type: ActionType;
  description: string;
  required_data?: Record<string, any>;
  options?: Array<Record<string, any>>;
  next_step?: string;
  ui_component?: string;
}

export interface ActionFlow {
  current_step: string;
  steps: Record<string, ActionStep>;
  context_data?: Record<string, any>;
}

export interface SkillAction {
  skill_type: string;
  current_step: string;
  action_flow: ActionFlow;
  status: "active" | "completed" | "error";
  error_message?: string;
}

export interface MessageMetadata {
  tutorial_id?: string;
  tutorial_title?: string;
  tutorial_url?: string;
  tutorial_context?: TutorialContext;
  suggestions?: string[];
  skill_action?: SkillAction;
  request_type?: string;
  model_used?: string;
  embedding_id?: string;
  processing_time?: number;
  token_usage?: number;
  generation_timestamp?: string;
  citations?: Array<Record<string, any>>;
}

export interface Message {
  id: string;
  type: "user" | "ai";
  content: string;
  timestamp: string;
  status: "sending" | "sent" | "error" | "loading" | "action_required";
  metadata?: MessageMetadata;
}

export interface ChatHistory {
  session_id: string;
  user_id: string;
  messages: Message[];
  lastUpdated: string;
  metadata?: Record<string, any>; // For session-level metadata
  isActive?: boolean; // To track which chat is currently selected
}

// Helper function to format timestamp to user's local time
export const formatMessageTime = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();

  // If the message is from today, just show the time
  if (date.toDateString() === now.toDateString()) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  // If the message is from this year, show date and time
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  // If the message is from a different year, show full date and time
  return date.toLocaleDateString([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
