import React, { useState } from "react";
import { FiImage } from "react-icons/fi";

interface ImageWithFallbackProps {
  src?: string;
  alt: string;
  className?: string;
  isDarkMode?: boolean;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  className = "",
  isDarkMode = false,
}) => {
  const [hasError, setHasError] = useState(false);

  if (!src || hasError) {
    return (
      <div
        className={`tw-flex tw-items-center tw-justify-center ${className} ${
          isDarkMode ? "tw-bg-gray-800" : "tw-bg-gray-100"
        } tw-rounded-lg`}
      >
        <FiImage
          className={`tw-w-8 tw-h-8 ${
            isDarkMode ? "tw-text-gray-600" : "tw-text-gray-400"
          }`}
        />
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={() => setHasError(true)}
      loading="lazy"
    />
  );
};
