import {
  ActionStep,
  ActionType,
  SkillAction,
} from "../../models/ai-chat/Message";
import { AiApiService } from "./AiApiService";

export class SkillFlowManager {
  private static instance: SkillFlowManager;
  private apiService: AiApiService;

  private constructor() {
    this.apiService = AiApiService.getInstance();
  }

  public static getInstance(): SkillFlowManager {
    if (!SkillFlowManager.instance) {
      SkillFlowManager.instance = new SkillFlowManager();
    }
    return SkillFlowManager.instance;
  }

  public async handleStepTransition(
    sessionId: string,
    currentStep: string,
    action: any
  ): Promise<void> {
    try {
      // Submit the action to the API
      await this.apiService.submitSkillAction(sessionId, currentStep, action);
    } catch (error) {
      console.error("Error handling step transition:", error);
      throw error;
    }
  }

  public validateStepRequirements(step: ActionStep): boolean {
    if (!step.required_data) return true;

    // Check if all required data is present
    return Object.entries(step.required_data).every(([key, value]) => {
      return value !== undefined && value !== null;
    });
  }

  public getStepComponent(step: ActionStep): string {
    // Map action types to component names
    const componentMap: Record<ActionType, string> = {
      [ActionType.CREATE_TICKET]: "TicketCreationDialog",
      [ActionType.SELECT_PORTFOLIO]: "PortfolioSelector",
      [ActionType.CREATE_PORTFOLIO]: "PortfolioCreationForm",
      [ActionType.SELECT_PROPERTY]: "PropertySelector",
      [ActionType.CREATE_PROPERTY]: "PropertyCreationForm",
      [ActionType.SELECT_UNIT]: "UnitSelector",
      [ActionType.CREATE_UNIT]: "UnitCreationForm",
      [ActionType.ENTER_TICKET_DETAILS]: "TicketDetailsForm",
    };

    return componentMap[step.type] || "ActionDialog";
  }

  public async startSkillFlow(
    sessionId: string,
    skillType: string
  ): Promise<void> {
    try {
      await this.apiService.submitSkillAction(sessionId, "start", {
        skill_type: skillType,
      });
    } catch (error) {
      console.error("Error starting skill flow:", error);
      throw error;
    }
  }

  public async cancelSkillFlow(
    sessionId: string,
    skillType: string
  ): Promise<void> {
    try {
      await this.apiService.cancelSkillFlow(sessionId, skillType);
    } catch (error) {
      console.error("Error canceling skill flow:", error);
      throw error;
    }
  }

  public getProgressSteps(skillAction: SkillAction): Array<{
    key: string;
    label: string;
    isCompleted: boolean;
    isActive: boolean;
  }> {
    return Object.entries(skillAction.action_flow.steps).map(([key, step]) => ({
      key,
      label: this.getStepLabel(step.type),
      isCompleted: this.isStepCompleted(key, skillAction),
      isActive: skillAction.current_step === key,
    }));
  }

  public getStepLabel(type: ActionType): string {
    const labels: Record<ActionType, string> = {
      [ActionType.CREATE_TICKET]: "Create Ticket",
      [ActionType.SELECT_PORTFOLIO]: "Select Portfolio",
      [ActionType.CREATE_PORTFOLIO]: "Create Portfolio",
      [ActionType.SELECT_PROPERTY]: "Select Property",
      [ActionType.CREATE_PROPERTY]: "Create Property",
      [ActionType.SELECT_UNIT]: "Select Unit",
      [ActionType.CREATE_UNIT]: "Create Unit",
      [ActionType.ENTER_TICKET_DETAILS]: "Ticket Details",
    };

    return labels[type] || type;
  }

  private isStepCompleted(step: string, skillAction: SkillAction): boolean {
    const steps = Object.keys(skillAction.action_flow.steps);
    const currentIndex = steps.indexOf(skillAction.current_step);
    const stepIndex = steps.indexOf(step);

    return stepIndex < currentIndex;
  }
}
