import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Guide,
  GuideSection,
  categories,
  updateGuide,
  getGuideById,
} from "../../../../services/guidebook/guidebookService";
import { useThemeClasses } from "services/utils/hooks/useThemeClasses";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import { toast } from "react-toastify";
import SimpleHTMLEditor from "../../../reusable/form/ckeditor/SimpleHTMLEditor";
import { devLog } from "services/utils/logger";
import config from "../../../../constants/config";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, MultiValue } from "react-select";
//Test this thoroughly including the delete and share, create etc
// Reuse the same Tailwind classes from CreateGuide
const inputClasses =
  "tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm focus:tw-border-primary focus:tw-ring-primary tw-text-sm tw-bg-white dark:tw-bg-gray-800 dark:tw-border-gray-600 dark:tw-text-gray-200 tw-px-3 tw-py-2";
const labelClasses =
  "tw-block tw-text-sm tw-font-medium tw-text-gray-700 dark:tw-text-gray-200 tw-mb-1";
const buttonPrimaryClasses =
  "tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-bg-primary tw-text-white hover:tw-bg-primary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary tw-transition-colors tw-duration-200 dark:tw-ring-offset-gray-900";
const buttonSecondaryClasses =
  "tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-bg-white tw-text-gray-700 tw-border tw-border-gray-300 hover:tw-bg-gray-50 dark:tw-bg-gray-800 dark:tw-text-gray-200 dark:tw-border-gray-600 dark:hover:tw-bg-gray-700 tw-transition-colors tw-duration-200";
const sectionTitleInputClasses =
  "tw-w-full tw-rounded-md tw-border tw-border-gray-300 dark:tw-border-gray-600 tw-bg-white dark:tw-bg-gray-800 tw-px-3 tw-py-2 tw-text-sm tw-text-gray-900 dark:tw-text-gray-200 focus:tw-border-primary focus:tw-ring-2 focus:tw-ring-primary/25 tw-transition-all tw-duration-200";
const sectionListItemClasses = (isActive: boolean, isDarkMode: boolean) =>
  isActive
    ? isDarkMode
      ? "tw-flex tw-items-center tw-justify-between tw-p-3 tw-cursor-pointer tw-border-2 tw-border-primary tw-bg-white/80 tw-rounded-md tw-transition-all tw-duration-200"
      : "tw-flex tw-items-center tw-justify-between tw-p-3 tw-cursor-pointer tw-border-2 tw-border-primary tw-bg-primary/5 tw-rounded-md tw-transition-all tw-duration-200"
    : isDarkMode
    ? "tw-flex tw-items-center tw-justify-between tw-p-3 tw-cursor-pointer tw-border-2 tw-border-gray-900 tw-bg-gray-900 tw-rounded-md tw-transition-all tw-duration-200 hover:tw-border-primary"
    : "tw-flex tw-items-center tw-justify-between tw-p-3 tw-cursor-pointer tw-border-2 tw-border-gray-200 tw-bg-white tw-rounded-md tw-transition-all tw-duration-200 hover:tw-border-primary";

interface UpdateGuideProps {
  isDarkMode: boolean;
}

interface FormData {
  title: string;
  sections: GuideSection[];
  category: string;
  tags: string;
  isPublic: boolean;
}

// Interface for tag options
interface TagOption {
  value: string;
  label: string;
}

const UpdateGuide: React.FC<UpdateGuideProps> = ({ isDarkMode }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [originalGuide, setOriginalGuide] = useState<Guide | null>(null);
  const [tagOptions, setTagOptions] = useState<TagOption[]>([]);

  const [formData, setFormData] = useState<FormData>({
    title: "",
    sections: [],
    category: "",
    tags: "",
    isPublic: true,
  });

  // Load the guide data when component mounts
  useEffect(() => {
    const loadGuide = async () => {
      if (!id) return;

      try {
        const guide = await getGuideById(id);
        setOriginalGuide(guide);
        setFormData({
          title: guide.title,
          sections: guide.sections,
          category: guide.category,
          tags: guide.tags.join(", "),
          isPublic: guide.is_public,
        });

        // Set tag options
        setTagOptions(
          guide.tags.map((tag) => ({
            value: tag,
            label: tag,
          }))
        );

        if (guide.sections.length > 0) {
          setActiveSection(guide.sections[0].section_id);
        }
      } catch (error) {
        console.error("Error loading guide:", error);
        toast.error("Failed to load guide");
        navigate("/guidebook");
      } finally {
        setIsLoading(false);
      }
    };

    loadGuide();
  }, [id, navigate]);

  // Update tag options when formData.tags changes
  useEffect(() => {
    if (formData.tags) {
      const tags = formData.tags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);

      const options = tags.map((tag) => ({
        value: tag,
        label: tag,
      }));

      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [formData.tags]);

  // Handle tag selection change
  const handleTagChange = (
    newValue: MultiValue<TagOption>,
    actionMeta: ActionMeta<TagOption>
  ) => {
    const tags =
      newValue.length > 0
        ? newValue.map((option) => option.value).join(", ")
        : "";

    setFormData((prev) => ({
      ...prev,
      tags,
    }));
  };

  // Create a new tag
  const handleCreateTag = (inputValue: string) => {
    const newTag = inputValue.trim();
    if (!newTag) return;

    // Add the new tag to the existing tags
    const newTags = formData.tags ? `${formData.tags}, ${newTag}` : newTag;

    setFormData((prev) => ({
      ...prev,
      tags: newTags,
    }));

    return { value: newTag, label: newTag };
  };

  // Set initial active section and handle dark mode changes
  React.useEffect(() => {
    if (formData.sections.length > 0 && !activeSection) {
      setActiveSection(formData.sections[0].section_id);
    }
  }, [formData.sections, isDarkMode]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id || !originalGuide) return;

    setIsSubmitting(true);

    try {
      const tagsArray = formData.tags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);

      const guideData = {
        ...originalGuide,
        title: formData.title,
        sections: formData.sections,
        category: formData.category,
        tags: tagsArray,
        is_public: formData.isPublic,
        updated_at: new Date(),
      };

      devLog("guideData", guideData);
      await updateGuide(id, guideData, originalGuide.author_id);
      toast.success("Guide updated successfully");
      navigate(`/guidebook/${id}`);
    } catch (error) {
      console.error("Error updating guide:", error);
      toast.error("Failed to update guide. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    input: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string
  ) => {
    if (typeof input !== "string" && "currentTarget" in input) {
      // Handle regular form inputs
      const { name, value } = input.currentTarget;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (typeof input === "string" && activeSection) {
      // Update the content in the sections array
      setFormData((prev) => {
        const updatedSections = prev.sections.map((section) =>
          section.section_id === activeSection
            ? { ...section, content: input }
            : section
        );

        return {
          ...prev,
          sections: updatedSections,
        };
      });
    }
  };

  const handleSectionClick = (sectionId: string) => {
    if (activeSection === sectionId) return;
    setActiveSection(sectionId);
  };

  const addSection = () => {
    const newSection: GuideSection = {
      section_id: crypto.randomUUID(),
      title: `Section ${formData.sections.length + 1}`,
      content: "",
      order: formData.sections.length,
      created_at: new Date(),
      updated_at: new Date(),
    };

    setFormData((prev) => ({
      ...prev,
      sections: [...prev.sections, newSection],
    }));

    setActiveSection(newSection.section_id);
  };

  const removeSection = (sectionId: string) => {
    setFormData((prev) => {
      const newSections = prev.sections
        .filter((section) => section.section_id !== sectionId)
        .map((section, index) => ({ ...section, order: index }));

      if (activeSection === sectionId && newSections.length > 0) {
        setActiveSection(newSections[0].section_id);
      } else if (newSections.length === 0) {
        setActiveSection(null);
      }

      return {
        ...prev,
        sections: newSections,
      };
    });
  };

  const handleSectionTitleChange = (sectionId: string, title: string) => {
    setFormData((prev) => ({
      ...prev,
      sections: prev.sections.map((section) =>
        section.section_id === sectionId
          ? { ...section, title, updated_at: new Date() }
          : section
      ),
    }));
  };

  // Get the content of the active section
  const activeSectionContent = React.useMemo(() => {
    const content =
      formData.sections.find((section) => section.section_id === activeSection)
        ?.content || "";

    return content;
  }, [activeSection, formData.sections]);

  if (isLoading) {
    return (
      <div className={getThemeClasses()}>
        <div className="container-fluid">
          <div className="text-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getThemeClasses()}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Edit Guide"
          previousPageLink={`/guidebook/${id}`}
          previousPageTitle="Guide Details"
        />

        <div className="row">
          <div className="col-lg-12">
            <div className={`card ${getThemeClasses()}`}>
              <div className="card-body">
                <form onSubmit={handleSubmit} className="tw-space-y-6">
                  <div>
                    <label
                      htmlFor="title"
                      className={`form-label ${getThemeClasses()}`}
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className={`form-control ${getThemeClasses()}`}
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      required
                      placeholder="Enter guide title"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="category"
                      className={`form-label ${getThemeClasses()}`}
                    >
                      Category
                    </label>
                    <select
                      className={`form-select ${getThemeClasses()}`}
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a category</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-mb-3 tw-gap-3">
                      <h5
                        className={`tw-text-lg tw-font-medium ${getThemeClasses()}`}
                      >
                        Sections
                      </h5>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={addSection}
                      >
                        <i className="ri-add-line align-middle me-1"></i>
                        Add Section
                      </button>
                    </div>

                    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4">
                      <div className="lg:tw-col-span-3">
                        <div className="tw-space-y-3">
                          {formData.sections.map((section) => (
                            <div
                              key={section.section_id}
                              className={sectionListItemClasses(
                                activeSection === section.section_id,
                                isDarkMode
                              )}
                              onClick={() =>
                                handleSectionClick(section.section_id)
                              }
                            >
                              <div className="tw-flex tw-items-center tw-flex-1 tw-min-w-0 tw-gap-3">
                                <div className="tw-flex-shrink-0">
                                  <div
                                    className={`tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border-2 ${
                                      activeSection === section.section_id
                                        ? isDarkMode
                                          ? "tw-border-primary tw-bg-white/5"
                                          : "tw-border-primary tw-bg-primary/5"
                                        : isDarkMode
                                        ? "tw-border-gray-500 tw-bg-transparent"
                                        : "tw-border-gray-200 tw-bg-white"
                                    }`}
                                  >
                                    <i
                                      className={`ri-edit-line tw-text-sm ${
                                        activeSection === section.section_id
                                          ? isDarkMode
                                            ? "tw-text-primary"
                                            : "tw-text-primary"
                                          : isDarkMode
                                          ? "tw-text-gray-500"
                                          : "tw-text-gray-400"
                                      }`}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={`form-control ${getThemeClasses()} placeholder:tw-text-gray-400 dark:placeholder:tw-text-gray-500`}
                                  value={section.title}
                                  onChange={(e) =>
                                    handleSectionTitleChange(
                                      section.section_id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter section title"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-icon btn-sm btn-ghost-danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeSection(section.section_id);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="lg:tw-col-span-9">
                        {activeSection ? (
                          <div className={`card ${getThemeClasses()}`}>
                            <SimpleHTMLEditor
                              isDarkMode={isDarkMode}
                              handleChange={handleChange}
                              content={activeSectionContent}
                              height="400px"
                            />
                            {activeSectionContent && (
                              <div className={`card-body ${getThemeClasses()}`}>
                                <label
                                  className={`form-label ${getThemeClasses()}`}
                                >
                                  Preview
                                </label>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: activeSectionContent,
                                  }}
                                  className={`tw-prose tw-max-w-none ${
                                    isDarkMode
                                      ? "dark:tw-prose-invert"
                                      : "tw-prose-gray"
                                  } [&>ul]:tw-list-disc [&>ol]:tw-list-decimal [&>ul]:tw-pl-5 [&>ol]:tw-pl-5 [&>h1]:tw-text-2xl [&>h2]:tw-text-xl [&>ul]:tw-my-2 [&>ol]:tw-my-2 [&>ul>li]:tw-my-1 [&>ol>li]:tw-my-1 [&>p]:tw-my-2`}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`tw-flex tw-items-center tw-justify-center tw-h-64 tw-rounded-lg tw-border-2 tw-border-dashed ${getThemeClasses()}`}
                          >
                            <div className="tw-text-center">
                              <i className="ri-edit-box-line tw-text-3xl tw-mb-2"></i>
                              <p>Select a section to edit its content</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="tags"
                      className={`form-label ${getThemeClasses()}`}
                    >
                      Tags
                    </label>
                    <CreatableSelect
                      isMulti
                      id="tags"
                      name="tags"
                      placeholder="Add tags..."
                      value={tagOptions}
                      onChange={handleTagChange}
                      onCreateOption={handleCreateTag}
                      classNamePrefix="select"
                      isClearable
                      formatCreateLabel={(inputValue: string) =>
                        `Create "${inputValue}"`
                      }
                      className={getThemeClasses()}
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: isDarkMode ? "#1e1e2d" : "#fff",
                          borderColor: isDarkMode ? "#2f2f45" : "#ced4da",
                          color: isDarkMode ? "#fff" : "#212529",
                        }),
                        menu: (base) => ({
                          ...base,
                          backgroundColor: isDarkMode ? "#1e1e2d" : "#fff",
                          color: isDarkMode ? "#fff" : "#212529",
                          zIndex: 99,
                        }),
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isFocused
                            ? isDarkMode
                              ? "#2f2f45"
                              : "#e9ecef"
                            : state.isSelected
                            ? isDarkMode
                              ? "#374151"
                              : "#e9ecef"
                            : "transparent",
                          color: isDarkMode ? "#fff" : "#212529",
                        }),
                        multiValue: (base) => ({
                          ...base,
                          backgroundColor: isDarkMode ? "#2f2f45" : "#e9ecef",
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          color: isDarkMode ? "#fff" : "#212529",
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          color: isDarkMode ? "#fff" : "#212529",
                          ":hover": {
                            backgroundColor: isDarkMode ? "#4b5563" : "#dee2e6",
                            color: isDarkMode ? "#fff" : "#212529",
                          },
                        }),
                        input: (base) => ({
                          ...base,
                          color: isDarkMode ? "#fff" : "#212529",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: isDarkMode ? "#6c757d" : "#6c757d",
                        }),
                      }}
                    />
                    <small className="text-muted">
                      Type a tag and press enter to add it. You can add multiple
                      tags.
                    </small>
                  </div>

                  <div className="tw-flex tw-justify-end tw-gap-3">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => navigate(`/guidebook/${id}`)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                          ></span>
                          Updating...
                        </>
                      ) : (
                        "Update Guide"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGuide;
