import React, { useState, useEffect, useRef } from "react";
import { Message, ChatHistory } from "../../models/ai-chat/Message";
import { AiApiService } from "../../services/ai/AiApiService";
import { AiChatStateService } from "../../services/ai/AiChatStateService";
import { LoadingSpinner } from "../../components/reusable/ai-chat/LoadingSpinner";
import { LoadingDots } from "../../components/reusable/ai-chat/LoadingDots";
import { v4 as uuidv4 } from "uuid";
import { useAiChat } from "../../context/AiChatContext";
import { devLog } from "services/utils/logger";
import { AiChatHistoryService } from "../../services/ai/AiChatHistoryService";
import config from "../../constants/config";
import { useSettings } from "../../services/authentication/contexts/settings/settingsContext";

interface AiAssistantPageProps {
  isDarkMode: boolean;
}
//TODO: start with working on create tutorials with the new api
//TODO: work on the action feature to for example create a new ticket (when have portflio etc then when none exist)

export const AiAssistantPage: React.FC<AiAssistantPageProps> = ({
  isDarkMode,
}) => {
  const [input, setInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [chatSessions, setChatSessions] = useState<ChatHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const aiService = AiApiService.getInstance();
  const chatHistoryService = AiChatHistoryService.getInstance();
  const chatStateService = AiChatStateService.getInstance();
  const {
    messages,
    updateMessages,
    addMessage,
    replaceMessage,
    setActiveSessionId,
    clearMessages,
  } = useAiChat();
  const [activeSessionId, setLocalActiveSessionId] = useState<string | null>(
    null
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  //Handle feature flag
  const { settings } = useSettings();
  const [showDisabledMessage, setShowDisabledMessage] = useState(false);

  // Update the resize effect to only handle screen size tracking
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    // Set initial states
    handleResize();
    // Only set sidebar open on initial load for large screens
    if (window.innerWidth >= 1024) {
      setIsSidebarOpen(true);
    }

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const categories = [
    { id: "all", name: "All Messages", icon: "💬" },
    { id: "properties", name: "Properties", icon: "🏠" },
    { id: "tenants", name: "Tenants", icon: "👥" },
    { id: "finances", name: "Finances", icon: "💰" },
    { id: "maintenance", name: "Maintenance", icon: "🔧" },
    { id: "documents", name: "Documents", icon: "📄" },
  ];

  useEffect(() => {
    if (!settings.isAiChatEnabled) {
      return;
    }
    loadChatSessions();
  }, [settings.isAiChatEnabled]);

  useEffect(() => {
    // Check if this is a direct access to the chat page
    const path = window.location.pathname;
    const isDirectAccess =
      path.includes("/chat") ||
      path.includes("/gurenAi") ||
      path.includes("/guren-ai");

    setShowDisabledMessage(!settings.isAiChatEnabled && isDirectAccess);
  }, [settings.isAiChatEnabled]);

  //TODO: work on showing the history of chats
  const loadChatSessions = async () => {
    try {
      setIsLoading(true);

      // Load sessions first
      const sessions = await chatStateService.getAllSessions();
      if (!Array.isArray(sessions)) {
        console.error("Expected sessions to be an array, got:", sessions);
        setChatSessions([]);
        setIsLoading(false);

        // Create new chat if no sessions exist
        const newSessionId = await chatStateService.createNewChat();
        setActiveSessionId(newSessionId);
        setLocalActiveSessionId(newSessionId);
        aiService.setSessionId(newSessionId);
        return;
      }

      // Sort sessions with newest first and mark active session
      const sortedSessions = [...sessions]
        .sort((a, b) => {
          const dateA = new Date(a.lastUpdated).getTime();
          const dateB = new Date(b.lastUpdated).getTime();
          return dateB - dateA;
        })
        .map((session) => ({
          ...session,
          isActive: session.session_id === activeSessionId,
        }));

      setChatSessions(sortedSessions);
      setIsLoading(false);

      // If we have existing sessions, select the most recent one
      if (sortedSessions.length > 0) {
        const mostRecentSession = sortedSessions[0];
        handleSessionSelect(mostRecentSession.session_id);
      } else {
        // Create new chat if no sessions exist
        const newSessionId = await chatStateService.createNewChat();
        setActiveSessionId(newSessionId);
        setLocalActiveSessionId(newSessionId);
        aiService.setSessionId(newSessionId);
      }
    } catch (error) {
      console.error("Error loading chat sessions:", error);
      setChatSessions([]);
      setIsLoading(false);

      // Create new chat even if loading fails
      try {
        const newSessionId = await chatStateService.createNewChat();
        setActiveSessionId(newSessionId);
        setLocalActiveSessionId(newSessionId);
        aiService.setSessionId(newSessionId);
      } catch (createError) {
        console.error("Error creating fallback chat:", createError);
      }
    }
  };

  const handleNewChat = async () => {
    try {
      // Create new chat session using the state service
      const newSessionId = await chatStateService.createNewChat();

      // Update active session IDs
      setActiveSessionId(newSessionId);
      setLocalActiveSessionId(newSessionId);
      aiService.setSessionId(newSessionId);

      // Clear existing messages immediately
      updateMessages([]);

      // Create new session object and add it to the list immediately
      const newSession: ChatHistory = {
        session_id: newSessionId,
        user_id: localStorage.getItem(config.user_id) || "",
        messages: [],
        lastUpdated: new Date().toISOString(),
        isActive: true,
      };

      // Add new session to the top of the list and mark others as inactive
      setChatSessions((prevSessions) => [
        newSession,
        ...prevSessions.map((session) => ({
          ...session,
          isActive: false,
        })),
      ]);

      // Close mobile sidebar if open
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }

      // Reload sessions to ensure everything is in sync
      const sessions = await chatStateService.getAllSessions();
      if (Array.isArray(sessions)) {
        const sortedSessions = [...sessions]
          .sort((a, b) => {
            const dateA = new Date(a.lastUpdated).getTime();
            const dateB = new Date(b.lastUpdated).getTime();
            return dateB - dateA;
          })
          .map((session) => ({
            ...session,
            isActive: session.session_id === newSessionId,
          }));
        setChatSessions(sortedSessions);
      }
    } catch (error) {
      console.error("Error creating new chat:", error);
      // Show error in UI but don't block interaction
      updateMessages([
        {
          id: uuidv4(),
          type: "ai",
          content:
            "Failed to create chat session. You can still type your message and try again.",
          timestamp: new Date().toISOString(),
          status: "error",
        },
      ]);
    }
  };

  const handleSessionSelect = async (sessionId: string) => {
    try {
      setLocalActiveSessionId(sessionId);
      // Update UI immediately
      updateMessages([]);

      // Set up connection
      await chatStateService.setActiveSession(sessionId);
      aiService.setSessionId(sessionId);

      // Load messages in background
      const sessionMessages = await chatStateService.getMessages();
      updateMessages(sessionMessages || []);
    } catch (error) {
      console.error("Error selecting session:", error);
      // Show error but don't block interaction
      updateMessages([
        {
          id: uuidv4(),
          type: "ai",
          content:
            "Failed to load chat history. You can still type your message and try again.",
          timestamp: new Date().toISOString(),
          status: "error",
        },
      ]);
    }
  };

  const handleSendMessage = async (content: string) => {
    try {
      // Add loading message immediately to UI for better UX
      const loadingMessage: Message = {
        id: uuidv4(),
        type: "ai",
        content: "Thinking...",
        timestamp: new Date().toISOString(),
        status: "loading",
      };

      // Create temporary messages array with user message and loading state
      const userMessage: Message = {
        id: uuidv4(),
        type: "user",
        content,
        timestamp: new Date().toISOString(),
        status: "sent",
      };

      const tempMessages = [...messages, userMessage, loadingMessage];
      updateMessages(tempMessages);

      // Send message and get response
      const chatHistory = await chatHistoryService.sendMessageToServer(
        activeSessionId || "",
        userMessage
      );

      // After a brief delay, update with the server state
      setTimeout(() => {
        updateMessages(chatHistory.messages);
      }, 300);
    } catch (error) {
      console.error("Error handling new message:", error);
      const errorMessage: Message = {
        id: uuidv4(),
        type: "ai",
        content: "Sorry, I encountered an error. Please try again.",
        timestamp: new Date().toISOString(),
        status: "error",
      };
      updateMessages([...messages, errorMessage]);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim()) {
      handleSendMessage(input.trim());
      setInput("");
    }
  };

  const getSessionPreview = (session: ChatHistory) => {
    devLog("session here: ", session);
    if (session.messages.length === 0) return "New Chat";

    // Find the first user message
    const userMessages = session.messages.filter((m) => m.type === "user");
    if (userMessages.length > 0) {
      const firstQuestion = userMessages[0].content;
      return (
        firstQuestion.slice(0, 50) + (firstQuestion.length > 50 ? "..." : "")
      );
    }
    return "New Chat";
  };

  const getCurrentChatTitle = () => {
    const firstUserMessage = messages.find((m) => m.type === "user");
    if (firstUserMessage) {
      return (
        firstUserMessage.content.slice(0, 50) +
        (firstUserMessage.content.length > 50 ? "..." : "")
      );
    }
    return "New Chat";
  };

  const formatSessionDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      // Today - show time only
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else if (days === 1) {
      return "Yesterday";
    } else if (days < 7) {
      // Within last week - show day name
      return date.toLocaleDateString([], { weekday: "long" });
    } else {
      // Older - show date
      return date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
    }
  };

  const renderMessageContent = (message: Message) => {
    if (message.status === "loading") {
      return (
        <div className="tw-flex tw-items-center tw-gap-2">
          <span className="tw-text-sm">Thinking</span>
          <LoadingDots isDarkMode={isDarkMode} />
        </div>
      );
    }

    const renderTextWithLinks = (text: string) => {
      // Match URLs in text
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const parts = text.split(urlRegex);

      return parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-blue-500 hover:tw-text-blue-600 tw-underline"
            >
              {part}
            </a>
          );
        }
        return <span key={index}>{part}</span>;
      });
    };

    // If feature is disabled but we need to show the disabled message (e.g., direct URL access)
    if (!settings.isAiChatEnabled && showDisabledMessage) {
      return (
        <div className="tw-fixed tw-bottom-4 tw-right-4 tw-z-50">
          <div
            className={`${
              isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
            } tw-rounded-lg tw-shadow-lg tw-p-4 tw-max-w-sm`}
          >
            <div className="tw-flex tw-items-center tw-space-x-3 tw-mb-2">
              <svg
                className={`tw-h-6 tw-w-6 ${
                  isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h3
                className={`tw-font-medium ${
                  isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                }`}
              >
                AI Chat Disabled
              </h3>
            </div>
            <p
              className={`tw-text-sm ${
                isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
              }`}
            >
              The AI chat feature is currently disabled. You can enable it in
              your profile settings under the "Experimental Features" tab.
            </p>
            <button
              onClick={() => (window.location.href = "/portal/profile")}
              className="tw-mt-3 tw-w-full tw-bg-primary tw-text-white tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-font-medium hover:tw-bg-primary-dark tw-transition-colors"
            >
              Go to Settings
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="tw-space-y-2">
        <p className="tw-text-sm tw-whitespace-pre-wrap">
          {renderTextWithLinks(message.content)}
        </p>
        {message.metadata?.tutorial_id && (
          <div className="tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-2">
            <a
              href={
                message.metadata.tutorial_url ||
                `https://gurenter.com/resources/tutorial/${message.metadata.tutorial_id}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="tw-inline-flex tw-items-start tw-gap-2 tw-text-sm hover:tw-bg-gray-50 tw-rounded-lg tw-p-2 tw-transition-colors -tw-ml-2"
            >
              <svg
                className="tw-w-5 tw-h-5 tw-text-blue-500 tw-flex-shrink-0 tw-mt-0.5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
              <div>
                <div className="tw-font-medium tw-text-blue-500 hover:tw-text-blue-600">
                  {message.metadata.tutorial_title || "View Tutorial"}
                </div>
                {message.metadata.tutorial_context && (
                  <div className="tw-flex tw-gap-2 tw-mt-1">
                    {message.metadata.tutorial_context.category && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.category}
                      </span>
                    )}
                    {message.metadata.tutorial_context.difficulty && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.difficulty}
                      </span>
                    )}
                    {message.metadata.tutorial_context.platform && (
                      <span className="tw-text-xs tw-bg-gray-100 tw-text-gray-600 tw-px-2 tw-py-0.5 tw-rounded">
                        {message.metadata.tutorial_context.platform}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </a>
          </div>
        )}
      </div>
    );
  };

  // Update toggleSidebar to work on all screen sizes
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (!messagesContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      messagesContainerRef.current;
    // Show button when scrolled more than 200px from bottom or 25% of the container height, whichever is smaller
    const scrollThreshold = Math.min(200, clientHeight * 0.25);
    const isNearBottom =
      scrollHeight - scrollTop - clientHeight < scrollThreshold;
    setShowScrollButton(!isNearBottom);
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // Initial check for scroll button visibility
      handleScroll();
      // Scroll to bottom when component mounts
      scrollToBottom();
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []); // Run when component mounts

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const scrollThreshold = Math.min(200, clientHeight * 0.25);
      const isNearBottom =
        scrollHeight - scrollTop - clientHeight < scrollThreshold;
      if (isNearBottom) {
        scrollToBottom();
      }
      // Check if we should show the button after messages update
      handleScroll();
    }
  }, [messages]);

  // Add effect to scroll to bottom when loading completes
  useEffect(() => {
    if (!isLoading) {
      setTimeout(scrollToBottom, 100); // Small delay to ensure messages are rendered
    }
  }, [isLoading]);

  // Add WebSocket effect
  useEffect(() => {
    if (activeSessionId) {
      const unsubscribe = chatStateService.subscribeToUpdates((update) => {
        switch (update.type) {
          case "processing":
            // Loading state is already handled
            break;

          case "ai_response":
            if (update.data.message) {
              // Replace loading message with actual response
              const loadingMessages = messages.filter(
                (m) => m.status === "loading"
              );
              if (loadingMessages.length > 0) {
                replaceMessage(loadingMessages[0].id, update.data.message);
              } else {
                addMessage(update.data.message);
              }
            }
            break;

          case "error":
            const errorMessage: Message = {
              id: uuidv4(),
              type: "ai",
              content: update.data.message || "An error occurred",
              timestamp: new Date().toISOString(),
              status: "error",
            };
            addMessage(errorMessage);
            break;
        }
      });

      return () => unsubscribe();
    }
  }, [activeSessionId]);

  if (!settings.isAiChatEnabled && showDisabledMessage) {
    return (
      <div
        className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-screen tw-p-4 ${
          isDarkMode
            ? "tw-bg-gray-900 tw-text-white"
            : "tw-bg-white tw-text-gray-900"
        }`}
      >
        <h1 className="tw-text-2xl tw-font-bold tw-mb-4">
          AI Assistant Disabled
        </h1>
        <p className="tw-text-center tw-max-w-md">
          The AI Assistant feature is currently disabled. Please contact support
          to enable this feature.
        </p>
      </div>
    );
  }

  return (
    <div
      className={`tw-flex tw-flex-col tw-min-h-screen ${
        isDarkMode
          ? "tw-bg-gray-900 tw-text-white"
          : "tw-bg-white tw-text-gray-900"
      }`}
    >
      <div className="tw-max-w-[1600px] tw-mx-auto tw-p-2 sm:tw-p-4">
        {/* Header */}
        <div
          className={`${
            isDarkMode
              ? "tw-bg-gray-800 tw-border-gray-700"
              : "tw-bg-white tw-border-gray-200"
          } tw-rounded-xl tw-shadow-sm tw-p-4 sm:tw-p-6 tw-mb-4 sm:tw-mb-6 tw-border`}
        >
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-3">
              <div className="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 sm:tw-w-12 sm:tw-h-12 tw-rounded-xl tw-bg-[#1e2124]">
                <span className="tw-text-white tw-text-xl sm:tw-text-2xl">
                  ✨
                </span>
              </div>
              <div>
                <h1
                  className={`tw-text-xl sm:tw-text-2xl tw-font-bold ${
                    isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                  }`}
                >
                  {getCurrentChatTitle()}
                </h1>
                <p
                  className={`tw-text-sm sm:tw-text-base ${
                    isDarkMode ? "tw-text-gray-400" : "tw-text-gray-600"
                  }`}
                >
                  Your property management AI companion
                </p>
              </div>
            </div>
            <div className="tw-flex tw-gap-2 sm:tw-gap-4">
              {/* Mobile sidebar toggle - only show on mobile */}
              <button
                onClick={toggleSidebar}
                className="lg:tw-hidden tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg tw-transition-colors tw-bg-[#1e2124] tw-text-white hover:tw-bg-[#2a2d31]"
              >
                <svg
                  className="tw-w-5 tw-h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              <button
                onClick={handleNewChat}
                className={`tw-px-3 sm:tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg tw-transition-colors
              ${
                isDarkMode
                  ? "tw-bg-[#1e2124] tw-text-white hover:tw-bg-[#2a2d31]"
                  : "tw-bg-[#1e2124] tw-text-white hover:tw-bg-[#2a2d31]"
              }`}
              >
                New Chat
              </button>
              <button
                onClick={clearMessages}
                className={`tw-hidden sm:block tw-px-4 tw-py-2 tw-text-sm tw-font-medium ${
                  isDarkMode
                    ? "tw-text-gray-300 tw-bg-gray-700 hover:tw-bg-gray-600"
                    : "tw-text-gray-700 tw-bg-gray-100 hover:tw-bg-gray-200"
                } tw-rounded-lg tw-transition-colors`}
              >
                Clear History
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        {isLoading ? (
          <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[700px]">
            <LoadingSpinner isDarkMode={isDarkMode} size="large" />
          </div>
        ) : (
          <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 sm:tw-gap-6">
            {/* Left Sidebar - Chat History */}
            <div
              className={`
            tw-fixed lg:tw-relative 
            tw-inset-0 
            tw-z-40 
            lg:tw-col-span-3 
            ${isSidebarOpen ? "tw-block lg:tw-block" : "tw-hidden lg:tw-block"}
          `}
            >
              {/* Overlay for mobile only */}
              {isSidebarOpen && (
                <div
                  className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 lg:tw-hidden"
                  onClick={toggleSidebar}
                />
              )}

              <div
                className={`${
                  isDarkMode
                    ? "tw-bg-gray-800 tw-border-gray-700"
                    : "tw-bg-white tw-border-gray-200"
                } tw-rounded-xl tw-shadow-sm tw-border tw-h-[calc(100vh-240px)] tw-flex tw-flex-col
              tw-relative tw-z-50 
              lg:tw-max-w-[300px] lg:tw-mx-0
              ${isSidebarOpen ? "tw-ml-0" : ""}
              lg:tw-p-4
              // Mobile styles
              tw-fixed lg:tw-relative tw-inset-y-0 tw-left-0
              tw-w-full lg:tw-w-auto
              tw-h-full lg:tw-h-[calc(100vh-240px)]
              tw-m-0 lg:tw-m-auto
              tw-rounded-none lg:tw-rounded-xl
            `}
              >
                {/* Mobile Header */}
                <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 lg:tw-p-0">
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <h2
                      className={`tw-font-semibold ${
                        isDarkMode ? "tw-text-white" : "tw-text-gray-900"
                      }`}
                    >
                      Chat History
                    </h2>
                    {/* Close button - only show on mobile */}
                    <button
                      onClick={toggleSidebar}
                      className="lg:tw-hidden tw-p-2 tw-rounded-lg hover:tw-bg-gray-700"
                    >
                      <svg
                        className="tw-w-6 tw-h-6 tw-text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Mobile New Chat Button */}
                  <button
                    onClick={() => {
                      handleNewChat();
                      toggleSidebar();
                    }}
                    className="lg:tw-hidden tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg tw-bg-[#1e2124] tw-text-white hover:tw-bg-[#2a2d31] tw-transition-colors"
                  >
                    New Chat
                  </button>
                </div>

                {/* Chat List */}
                <div className="tw-flex-1 tw-overflow-y-auto tw-p-4 lg:tw-p-0">
                  <div className="tw-space-y-2">
                    {chatSessions.map((session) => (
                      <button
                        key={session.session_id}
                        onClick={() => {
                          handleSessionSelect(session.session_id);
                          if (!isLargeScreen) {
                            toggleSidebar();
                          }
                        }}
                        className={`tw-w-full tw-text-left tw-p-3 tw-rounded-lg tw-transition-colors
                      ${
                        session.session_id === activeSessionId
                          ? isDarkMode
                            ? "tw-bg-[#1e2124] tw-text-white"
                            : "tw-bg-gray-200 tw-text-gray-900"
                          : isDarkMode
                          ? "tw-bg-gray-700 tw-text-gray-100 hover:tw-bg-gray-600"
                          : "tw-bg-gray-50 tw-text-gray-900 hover:tw-bg-gray-100"
                      }`}
                      >
                        <div className="tw-text-sm tw-font-medium tw-line-clamp-2">
                          {getSessionPreview(session)}
                        </div>
                        <div
                          className={`tw-text-xs ${
                            isDarkMode ? "tw-text-gray-400" : "tw-text-gray-500"
                          } tw-mt-1`}
                        >
                          {formatSessionDate(session.lastUpdated)}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Main Chat Area */}
            <div className="tw-col-span-1 lg:tw-col-span-9">
              <div
                className={`${
                  isDarkMode
                    ? "tw-bg-gray-800 tw-border-gray-700"
                    : "tw-bg-white tw-border-gray-200"
                } tw-rounded-xl tw-shadow-sm tw-h-[calc(100vh-240px)] tw-flex tw-flex-col tw-border`}
              >
                {/* Messages */}
                <div
                  ref={messagesContainerRef}
                  className="tw-flex-1 tw-p-4 sm:tw-p-6 tw-overflow-y-auto tw-space-y-4 tw-relative"
                >
                  {messages
                    .slice()
                    .sort(
                      (a, b) =>
                        new Date(a.timestamp).getTime() -
                        new Date(b.timestamp).getTime()
                    )
                    .map((message) => (
                      <div
                        key={message.id}
                        className={`tw-flex ${
                          message.type === "user"
                            ? "tw-justify-end"
                            : "tw-justify-start"
                        }`}
                      >
                        <div
                          className={`tw-max-w-[80%] tw-rounded-xl tw-p-4 ${
                            message.type === "user"
                              ? "tw-bg-[#1e2124] tw-text-white"
                              : isDarkMode
                              ? "tw-bg-gray-700 tw-text-white"
                              : "tw-bg-gray-100 tw-text-gray-800"
                          }`}
                        >
                          {renderMessageContent(message)}
                          <span
                            className={`tw-text-xs ${
                              message.type === "user"
                                ? "tw-text-gray-300"
                                : isDarkMode
                                ? "tw-text-gray-400"
                                : "tw-text-gray-500"
                            } tw-mt-1 tw-block`}
                          >
                            {formatSessionDate(message.timestamp)}
                          </span>
                        </div>
                      </div>
                    ))}
                  <div ref={messagesEndRef} />

                  {/* Scroll to Bottom Button */}
                  {showScrollButton && (
                    <button
                      onClick={scrollToBottom}
                      className={`tw-absolute tw-bottom-4 tw-right-4 tw-p-3 tw-rounded-full tw-shadow-lg tw-transition-all tw-duration-300 tw-ease-in-out ${
                        isDarkMode
                          ? "tw-bg-gray-700 hover:tw-bg-gray-600"
                          : "tw-bg-white hover:tw-bg-gray-100"
                      } tw-z-10`}
                      aria-label="Scroll to latest messages"
                    >
                      <svg
                        className={`tw-w-5 tw-h-5 ${
                          isDarkMode ? "tw-text-gray-300" : "tw-text-gray-600"
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                      </svg>
                    </button>
                  )}
                </div>

                {/* Input */}
                <div
                  className={`tw-border-t ${
                    isDarkMode ? "tw-border-gray-700" : "tw-border-gray-200"
                  } tw-p-3 sm:tw-p-4`}
                >
                  <form onSubmit={handleSubmit} className="tw-flex tw-gap-2">
                    <input
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Ask Guren anything..."
                      className={`tw-flex-1 tw-rounded-lg tw-border tw-p-2 sm:tw-p-3 tw-text-sm ${
                        isDarkMode
                          ? "tw-bg-gray-700 tw-border-gray-600 tw-text-white placeholder:tw-text-gray-400"
                          : "tw-bg-white tw-border-gray-200 tw-text-gray-900"
                      } focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#1e2124]`}
                    />
                    <button
                      type="submit"
                      className="tw-bg-[#1e2124] tw-text-white tw-px-4 sm:tw-px-6 tw-py-2 sm:tw-py-3 tw-rounded-lg hover:tw-bg-gray-800 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#1e2124] focus:tw-ring-offset-2 tw-transition-colors tw-text-sm tw-font-medium"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiAssistantPage;
